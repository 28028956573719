import { useMemo } from 'react';
import { useMatches } from 'react-router-dom';

/**
 * Use this hook to get the current page details, more reliable than matching
 * against the route path since route paths can be changed over time but the
 * handle can remain the same within the route definitions not requiring
 * major changes to the codebase
 */
export function usePageMatcher() {
  const matches = useMatches();

  return useMemo(() => {
    const lastMatch = matches[matches.length - 1];

    const route = (lastMatch.handle as { route: string } | undefined)?.route;

    return {
      isParticipationPage: route === 'participation',
      isHomePage: route === 'home',
      isRewardsPage: route === 'rewards',
      isChallengesPage: route === 'challenges',
      isNotificationsPage: route === 'notifications',
    };
  }, [matches]);
}
