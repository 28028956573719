import { SVGReactComponents } from '@assembly-web/assets';
import { useGiveRecognitionFlowDetails } from '@assembly-web/participation';
import { mapHexCodeToEmoticon } from '@assembly-web/services';
import {
  ApprovalBanner,
  Avatar,
  AvatarSize,
  AwardsTrigger,
  FeedActionBar,
  IconButton,
  Tooltip,
  useAssemblyNavigate,
} from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';

import { usePendingAwardApprovalCount } from '../../../hooks/usePendingAwardApprovalCount';
import { useOpenAwardsModal } from '../../awards/Awards';
import { DraftBadge } from '../../discover/components/ActionBar/DraftBadge';
import { useOpenRecognitionParticipation } from '../../discover/hooks/participation/useOpenRecognitionParticipation';
import { useActionBarOptions } from '../../discover/hooks/useActionBarOptions';
import { useActionBarQuery } from '../../discover/hooks/useActionBarQuery';
import { useActionBarVisibility } from '../../discover/hooks/useActionBarVisibility';
import { useCurrentUserDetail } from '../../discover/hooks/useCurrentUserDetail';
import { enterFullScreen } from '../../discover/services/FullScreenMode';

const labels = defineMessages({
  buttonText: {
    defaultMessage: 'Write a recognition post...',
    id: 'Mt3DIk',
  },
  draft: {
    defaultMessage: 'Draft',
    id: 'W6nwjo',
  },
});

export function RecognitionActionBar() {
  const { formatMessage } = useIntl();

  const { data: actionBarData } = useActionBarQuery();

  const { giveRecognition } = useActionBarOptions();
  const count = usePendingAwardApprovalCount();

  const navigate = useAssemblyNavigate();
  const openAwardModal = useOpenAwardsModal();

  const {
    data: {
      flowDetails: { description, name, icon },
      fullScreenTooltip,
    },
  } = useGiveRecognitionFlowDetails();
  const userDetails = useCurrentUserDetail();

  const { showGiveRecognitionButton, showAwards } = useActionBarVisibility({
    memberStatus: userDetails.status,
    activeFlowsCount: actionBarData?.flowsToView.length ?? 0,
    isRecognitionOnly: giveRecognition === 'primary',
  });

  const recognize = useOpenRecognitionParticipation();

  return (
    <FeedActionBar.Root>
      <FeedActionBar.Header>
        <FeedActionBar.Title
          shareType="everyone"
          trailing={
            <Tooltip tooltipText={fullScreenTooltip}>
              <IconButton
                variation="secondaryLite"
                size="xSmall"
                onClick={() => {
                  enterFullScreen();
                  navigate(`fullscreen`);
                }}
              >
                <SVGReactComponents.TVIcon />
              </IconButton>
            </Tooltip>
          }
        >
          {mapHexCodeToEmoticon(icon.value)} {name}
        </FeedActionBar.Title>
        <FeedActionBar.Description>{description}</FeedActionBar.Description>
      </FeedActionBar.Header>
      <FeedActionBar.FooterContainer>
        {Boolean(showGiveRecognitionButton) && (
          <FeedActionBar.Footer>
            <Avatar
              memberID={userDetails.memberID}
              name={userDetails.name}
              size={AvatarSize.Large}
              image={userDetails.image}
              isRounded
              className="flex-shrink-0"
            />
            <FeedActionBar.ActionButton
              onClick={() => recognize()}
              className="flex items-baseline"
            >
              {formatMessage(labels.buttonText)}
              <DraftBadge />
            </FeedActionBar.ActionButton>
            {Boolean(showAwards) && <AwardsTrigger onClick={openAwardModal} />}
          </FeedActionBar.Footer>
        )}
        {Boolean(count) && (
          <FeedActionBar.Footer>
            <ApprovalBanner
              count={count}
              onClick={() => {
                navigate('/a/notifications');
              }}
            />
          </FeedActionBar.Footer>
        )}
      </FeedActionBar.FooterContainer>
    </FeedActionBar.Root>
  );
}
