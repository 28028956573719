import type { FlowPostResponse } from '@assembly-web/services';
import { useUserDetails } from '@assembly-web/services';
import { getRespondentForAnonymousFlowPost } from '@assembly-web/ui';
import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import invariant from 'tiny-invariant';

import {
  getPostQuery,
  getPostRepliesQuery,
} from '../queries/getAssemblyPostQuery';

type GetPostAndRepliesProps = {
  flowId: string;
  enabled?: boolean;
  responseId: string;
};

export function useGetPostAndRepliesDetails(props: GetPostAndRepliesProps) {
  const { flowId, responseId, enabled = true } = props;

  const { data: userDetails } = useUserDetails();
  invariant(userDetails);

  const { formatMessage } = useIntl();

  const {
    data: postCardDetails,
    isLoading: isPostCardDataLoading,
    isError: isPostCardDataError,
    error,
    isSuccess,
  } = useQuery({
    ...getPostQuery(
      {
        flowId: flowId,
        responseId: responseId,
        currencyName: userDetails.assembly.currency.pluralName,
        asEmbed: false,
      },
      formatMessage
    ),
    retry: 0,
    enabled,
  });

  const postCardDetailsError = error as AxiosError<{ message: string }>;
  const postCardErrorMessage = isPostCardDataError
    ? postCardDetailsError.response?.data.message
    : null;
  const isPostNotFoundError = postCardErrorMessage === 'NOT_FOUND';

  const flowResponse: FlowPostResponse | undefined = useMemo(() => {
    return postCardDetails
      ? {
          ...postCardDetails,
          respondent:
            postCardDetails.respondent ?? getRespondentForAnonymousFlowPost(),
          instanceId:
            postCardDetails.respondent === null
              ? ''
              : postCardDetails.instanceId,
        }
      : undefined;
  }, [postCardDetails]);

  const {
    isLoading: isPostCardRepliesDataLoading,
    data: postCardRepliesDetails,
  } = useQuery(
    getPostRepliesQuery({
      flowId,
      responseId,
      enabled,
    })
  );

  return {
    isSuccess,
    flowResponse,
    postCardDetails,
    isPostCardDataError,
    isPostNotFoundError,
    isPostCardDataLoading,
    postCardRepliesDetails,
    currentUser: userDetails,
    isPostCardRepliesDataLoading,
  };
}
