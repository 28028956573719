import {
  trackParticipationAction,
  useCurrency,
  useDisplayCurrency,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import { PointsBlock, PointsContainer, TextStyle } from '@assembly-web/ui';
import { EyeSlashIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { usePointsConfig } from '../../hooks/usePointsConfig';
import { useParticipationModalContext } from '../../Provider';

const labels = defineMessages({
  hideAmount: {
    id: 'SAk6zf',
    defaultMessage: 'Points will be hidden',
  },
  noEditTooltip: {
    id: 'Foj9hm',
    defaultMessage: '{currencyName} can’t be edited.',
  },
  pointsError: {
    id: 'IC20A2',
    defaultMessage: 'Points must be a number',
  },
  error: {
    id: 'FmeswJ',
    defaultMessage:
      'You do not have enough points to give, but you can still post. Yay!',
  },
  placeHolder: {
    id: 'SARX44',
    defaultMessage: 'Add up to {pointsToGive} per person',
  },
});

export function PointsSelector() {
  const { formatMessage } = useIntl();

  const { name: currencyName } = useCurrency();
  const currencyIcon = useDisplayCurrency();
  const {
    data: { assembly },
  } = useSuspenseUserDetails();

  const { formState } = useParticipationModalContext();
  const isEditFlow = Boolean(formState?.postId);
  const { setValue, getValues, watch, control } = useFormContext();
  const memberCount = watch('recipientsCount');

  const { getMaxPointsPerHead } = usePointsConfig();

  const pointsToGive = useMemo(() => {
    return getMaxPointsPerHead(memberCount);
  }, [memberCount, getMaxPointsPerHead]);

  const hasAvailablePoints = Boolean(pointsToGive);
  const disablePointsField = !hasAvailablePoints || isEditFlow;
  const showError = Boolean(!hasAvailablePoints && !isEditFlow);

  const calculatePoints = useCallback(
    (value: number) => {
      const val = Math.round(Number(value));
      if (memberCount > 1) {
        if (val > pointsToGive) {
          const maxTotalPoints = pointsToGive * memberCount;
          if (val > maxTotalPoints) {
            return Math.floor(maxTotalPoints / memberCount);
          } else {
            return Math.floor(pointsToGive);
          }
        } else {
          return Math.floor(val);
        }
      } else if (memberCount <= 1) {
        if (val > pointsToGive) {
          return pointsToGive;
        } else {
          return Math.floor(val);
        }
      }
    },
    [pointsToGive, memberCount]
  );

  useEffect(() => {
    const points = getValues('points');
    setValue('points', calculatePoints(points));
  }, [calculatePoints, getValues, setValue]);

  return (
    <Controller
      control={control}
      name="points"
      render={({ field: { onChange } }) => (
        <PointsBlock
          placeholder={formatMessage(labels.placeHolder, {
            pointsToGive: getMaxPointsPerHead(memberCount),
          })}
          value={watch('points')}
          onValueChange={(value: string | number) => {
            if (typeof value !== 'number') {
              onChange(0);
              return;
            }
            if (Number(value) < 0) {
              onChange(0);
              return;
            }
            onChange(calculatePoints(value));
          }}
          connectedRight={
            <>
              {Boolean(
                assembly.settings.postPermission.canHidePoints.enabled
              ) && (
                <div className="ml-2 flex items-center gap-2 md:ml-0">
                  <EyeSlashIcon className="h-4 w-4" />
                  <TextStyle variant="sm-regular">
                    {formatMessage(labels.hideAmount)}
                  </TextStyle>
                </div>
              )}
            </>
          }
          disabled={disablePointsField || isEditFlow}
          currencyIcon={currencyIcon}
          tooltipText={
            isEditFlow
              ? formatMessage(labels.noEditTooltip, {
                  currencyName,
                })
              : ''
          }
          className={twJoin(!hasAvailablePoints && '!text-gray-7')}
          error={
            Boolean(showError) && (
              <TextStyle variant="sm-regular" className="text-error-7">
                {formatMessage(labels.error)}
              </TextStyle>
            )
          }
          trackAction={() => {
            trackParticipationAction('pointsSelected', {
              postType: 'recognition',
            });
          }}
        />
      )}
    />
  );
}

export function PointsSelectorBlock() {
  const { pluralName: currencyName } = useCurrency();
  return (
    <PointsContainer currencyName={currencyName}>
      <PointsSelector />
    </PointsContainer>
  );
}
