import { useMemo } from 'react';

import { GlobalFilterOption } from '../../constants/filter';
import type { Recipients } from '../../types/recognitionParticipation';
import { useDepartmentMembers } from './useDepartmentMembers';
import { type SearchPayload, useSearchIndex } from './useSearchIndex';

export const useRecipientsMembers = ({
  customSort,
  filter = GlobalFilterOption.People,
  searchTerm,
  secondaryFilters,
  criteriaId,
  department,
  postId,
  customFilterCallback,
}: {
  department?: string;
  filter?: GlobalFilterOption;
  searchTerm: string;
  customSort?: SearchPayload['customSort'];
  secondaryFilters?: SearchPayload['secondaryFilters'];
  criteriaId?: string;
  postId?: string;
  customFilterCallback?: (member: Recipients['individuals'][number]) => boolean;
}) => {
  const {
    hasNextPage: hasNextPageInSearchMembersList,
    fetchNextPage: fetchNextPageInSearchMembersList,
    isFetchingNextPage: isFetchingNextPageInSearchMembersList,
    status: searchMembersQueryStatus,
    fetchStatus: searchMembersQueryFetchStatus,
    data: searchMembersData,
  } = useSearchIndex<{ members: Recipients['individuals']; total: number }>(
    {
      searchTerm,
      enabled: !criteriaId,
      selectedFilters: [filter],
      populateCardDetails: true,
      customSort,
      secondaryFilters: {
        ...secondaryFilters,
        state: secondaryFilters?.state || ['ACTIVE', 'INACTIVE'],
        memberState: secondaryFilters?.memberState || ['ACTIVE', 'PENDING'],
      },
    },
    {
      select: (data) => {
        return {
          ...data,
          pages: data.pages.map((page) => {
            return {
              total: page.data.total,
              members: page.data.data
                .filter((searchResult) => searchResult.type === 'member')
                .map((searchResult) => {
                  return {
                    department: searchResult._meta.department,
                    email: searchResult._meta.email,
                    image: searchResult._meta.profileImageUrl,
                    memberId: searchResult.id,
                    memberID: searchResult.id,
                    name: searchResult.name,
                    role: searchResult._meta.roles,
                    status: searchResult._meta.profileStatus,
                    firstName: searchResult._meta.firstName,
                    lastName: searchResult._meta.lastName,
                    memberState: searchResult._meta.state,
                    jobTitle: searchResult._meta.jobTitle,
                    pronouns: searchResult._meta.pronouns,
                    username: searchResult._meta.username,
                    type: 'member',
                  };
                }),
            };
          }),
        };
      },
    }
  );
  const {
    hasNextPage: hasNextPageInDepartmentMembersList,
    fetchNextPage: fetchNextPageInDepartmentMembersList,
    isFetchingNextPage: isFetchingNextPageInDepartmentMembersList,
    status: departmentMembersQueryStatus,
    fetchStatus: departmentMembersQueryFetchStatus,
    data: departmentMembersData,
  } = useDepartmentMembers<{
    members: Recipients['individuals'];
    total: number;
  }>({
    criteriaId,
    group: department,
    postId,
    keyword: searchTerm,
    options: {
      enabled: Boolean(postId && criteriaId && department),
      select(data) {
        return {
          ...data,
          pages: data.pages.map((page) => {
            return {
              total: page.total,
              members: page.data.map((searchResult) => {
                return {
                  ...searchResult,
                  memberId: searchResult.memberID,
                  type: 'member',
                };
              }),
            };
          }),
        };
      },
    },
  });

  const {
    fetchNextPageInMembersList,
    hasNextPageInMembersList,
    isFetchingNextPageInMembersList,
    membersData,
    membersQueryFetchStatus,
    membersQueryStatus,
  } = useMemo(() => {
    if (postId && criteriaId && department) {
      return {
        hasNextPageInMembersList: hasNextPageInDepartmentMembersList,
        fetchNextPageInMembersList: fetchNextPageInDepartmentMembersList,
        isFetchingNextPageInMembersList:
          isFetchingNextPageInDepartmentMembersList,
        membersQueryStatus: departmentMembersQueryStatus,
        membersQueryFetchStatus: departmentMembersQueryFetchStatus,
        membersData: departmentMembersData,
      };
    }
    return {
      hasNextPageInMembersList: hasNextPageInSearchMembersList,
      fetchNextPageInMembersList: fetchNextPageInSearchMembersList,
      isFetchingNextPageInMembersList: isFetchingNextPageInSearchMembersList,
      membersQueryStatus: searchMembersQueryStatus,
      membersQueryFetchStatus: searchMembersQueryFetchStatus,
      membersData: searchMembersData,
    };
  }, [
    criteriaId,
    department,
    departmentMembersData,
    departmentMembersQueryFetchStatus,
    departmentMembersQueryStatus,
    fetchNextPageInDepartmentMembersList,
    fetchNextPageInSearchMembersList,
    hasNextPageInDepartmentMembersList,
    hasNextPageInSearchMembersList,
    isFetchingNextPageInDepartmentMembersList,
    isFetchingNextPageInSearchMembersList,
    postId,
    searchMembersData,
    searchMembersQueryFetchStatus,
    searchMembersQueryStatus,
  ]);

  const members = useMemo(
    () => membersData?.pages.flatMap((x) => x.members) ?? [],
    [membersData]
  );
  const transformedMembers = useMemo(() => {
    const formattedArray: Recipients['individuals'] = [];

    if (!customFilterCallback) {
      return members;
    }

    for (const member of members) {
      if (customFilterCallback(member)) {
        formattedArray.push(member);
      } else {
        continue;
      }
    }

    return formattedArray;
  }, [members, customFilterCallback]);

  return useMemo(
    () => ({
      hasNextPageInMembersList,
      fetchNextPageInMembersList,
      isFetchingNextPageInMembersList,
      membersQueryStatus,
      membersQueryFetchStatus,
      members: transformedMembers,
      totalMembers: membersData?.pages[0].total ?? 0,
    }),
    [
      fetchNextPageInMembersList,
      hasNextPageInMembersList,
      isFetchingNextPageInMembersList,
      membersData?.pages,
      membersQueryFetchStatus,
      membersQueryStatus,
      transformedMembers,
    ]
  );
};
