import { XMarkIcon } from '@heroicons/react/24/outline';
import { useComposedRefs } from '@radix-ui/react-compose-refs';
import {
  Close,
  Content,
  Overlay,
  Portal,
  Root,
  Title,
} from '@radix-ui/react-dialog';
import { AnimatePresence, motion } from 'framer-motion';
import type { ReactNode } from 'react';
import { forwardRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { RefProvider } from '../../context/RefContext';
import { useDeviceInfo } from '../../UI/hooks/useDeviceInfo';
import { IconButton } from '../Inputs/IconButton';
import { HorizontalRule } from '../Layout/HorizontalRule';
import { TextStyle } from './TextStyle';

export type ModalProps = {
  dataTestId?: string;
  isOpen: boolean;
  onClose: () => void;
  title: ReactNode;
  children: ReactNode;
  ctaSection?: ReactNode;
  className?: string;
  ctaSectionClassName?: string;
  bodyClassName?: string;
  headerClassName?: string;
  subtitle?: ReactNode;
  showHeaderSeparator?: boolean;
  headerSeparatorClassName?: string;
  closeButtonSize?: 'small' | 'large' | 'xSmall' | 'xLarge';
  disableCloseOnOverlayClick?: boolean;
  onEscapeKeyDown?: (e: KeyboardEvent) => void;
  onOpenAutoFocus?: (e: Event) => void;
};

const messages = defineMessages({
  close: {
    defaultMessage: 'Close',
    id: 'rbrahO',
  },
});

export const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      closeButtonSize = 'xSmall',
      disableCloseOnOverlayClick = false,
      onEscapeKeyDown,
      onOpenAutoFocus,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useIntl();
    const device = useDeviceInfo();

    const notMobile = device.deviceType !== 'mobile';

    const [bodyRef, setBodyRef] = useState<HTMLDivElement | null>(null);
    const combinedRef = useComposedRefs(ref, setBodyRef);

    const avoidDefaultDomBehavior = (e: Event) => {
      if (disableCloseOnOverlayClick) {
        e.preventDefault();
      }
    };

    const handleEscapeKeyDown = (e: KeyboardEvent) => {
      avoidDefaultDomBehavior(e as unknown as Event);
      onEscapeKeyDown?.(e);
    };

    return (
      <RefProvider value={bodyRef}>
        <Root
          open={props.isOpen}
          onOpenChange={(open) => {
            if (!open) {
              props.onClose();
            }
          }}
        >
          <AnimatePresence>
            {Boolean(props.isOpen) && (
              <Portal forceMount>
                <Overlay
                  asChild
                  forceMount
                  className="fixed inset-0 z-20 bg-neutral-secondary bg-opacity-75"
                >
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{
                      opacity: 0,
                      transition: { duration: 0.2, ease: 'easeIn' },
                    }}
                    transition={{ duration: 0.3, ease: 'easeOut' }}
                  />
                </Overlay>
                <Content
                  asChild
                  forceMount
                  onEscapeKeyDown={handleEscapeKeyDown}
                  onPointerDownOutside={avoidDefaultDomBehavior}
                  onInteractOutside={avoidDefaultDomBehavior}
                  onOpenAutoFocus={onOpenAutoFocus}
                >
                  <motion.div
                    initial={{
                      opacity: 0,
                      x: '-50%',
                      y: '-50%',
                      ...(notMobile && { scale: 0.95 }),
                    }}
                    animate={{
                      opacity: 1,
                      x: '-50%',
                      y: '-50%',
                      ...(notMobile && { scale: 1 }),
                    }}
                    exit={{
                      opacity: 0,
                      x: '-50%',
                      y: '-50%',
                      ...(notMobile && { scale: 0.95 }),
                      transition: { duration: 0.2, ease: 'easeIn' },
                    }}
                    transition={{ duration: 0.3, ease: 'easeOut' }}
                    className={twMerge(
                      'fixed left-[50%] top-[50%] z-20 max-h-[85vh] w-[90vw] -translate-x-1/2 -translate-y-1/2 transform overflow-y-auto rounded-md bg-gray-1 text-left shadow-xl-down focus:outline-none sm:w-full sm:max-w-lg',
                      props.className
                    )}
                    data-testid={props.dataTestId}
                    ref={combinedRef as never}
                    aria-describedby={undefined}
                  >
                    <header
                      className={twMerge(
                        'sticky top-0 z-50 flex h-fit w-full justify-between bg-gray-1 px-5 py-3',
                        props.subtitle ? 'items-start' : 'items-center',
                        props.headerClassName
                      )}
                    >
                      <div className="flex w-full flex-col gap-1">
                        <Title asChild className="leading-6">
                          <h3>
                            <TextStyle
                              variant="lg-medium"
                              className="text-gray-9"
                              as="span"
                            >
                              {props.title}
                            </TextStyle>
                          </h3>
                        </Title>
                        {Boolean(props.subtitle) && (
                          <TextStyle
                            variant="sm-regular"
                            className="leading-[22px] text-gray-9"
                          >
                            {props.subtitle}
                          </TextStyle>
                        )}
                      </div>

                      <Close asChild>
                        <IconButton
                          variation="tertiaryLite"
                          size={closeButtonSize}
                          data-testid="modalCloseButton"
                        >
                          <span className="sr-only">
                            {formatMessage(messages.close)}
                          </span>
                          <XMarkIcon
                            className={twMerge(
                              'h-4 w-4',
                              closeButtonSize === 'large' && 'h-6 w-6',
                              closeButtonSize === 'xLarge' && 'h-8 w-8'
                            )}
                            aria-hidden="true"
                          />
                        </IconButton>
                      </Close>
                    </header>
                    {Boolean(props.showHeaderSeparator) && (
                      <HorizontalRule
                        className={twMerge(
                          'sticky top-[60px] z-50 px-6',
                          props.headerSeparatorClassName
                        )}
                      />
                    )}
                    <div
                      className={twMerge(
                        'px-6 pt-1 text-gray-9',
                        props.ctaSection ? 'pt-2' : 'pb-6',
                        props.bodyClassName,
                        !notMobile ? 'flex-1' : ''
                      )}
                    >
                      {props.children}
                    </div>
                    {Boolean(props.ctaSection) && (
                      <div
                        className={twMerge('p-6', props.ctaSectionClassName)}
                      >
                        {props.ctaSection}
                      </div>
                    )}
                  </motion.div>
                </Content>
              </Portal>
            )}
          </AnimatePresence>
        </Root>
      </RefProvider>
    );
  }
);

Modal.displayName = 'Modal';
