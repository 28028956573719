import {
  type FolderColor,
  type SecondaryFiltersType,
  SplitNames,
  useFeatureSplit,
} from '@assembly-web/services';
import {
  Avatar,
  AvatarSize,
  Button,
  DateRangeDropdown,
  getFolderStyles,
  SelectableListDropdown,
  type SelectableOptionProps,
  TextStyle,
} from '@assembly-web/ui';
import { useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { useGetDateRangeFilters } from '../../hooks/secondaryFilters/useGetDateRangeFilters';
import { useGetPostsSecondaryFilters } from '../../hooks/secondaryFilters/useGetPostsSecondaryFilters';
import { usePostedBySecondaryFilter } from '../../hooks/secondaryFilters/usePostedBySecondaryFilter';
import { useSecondaryFilterState } from '../../hooks/secondaryFilters/useSecondaryFilterState';
import { Loader } from './Loader';
import { messages } from './messages';

const secondaryFilterTypes: Record<string, SecondaryFiltersType> = {
  postedBy: 'FROM',
  mentionedBy: 'MENTIONED',
};

export function RecognitionFilter() {
  const { formatMessage } = useIntl();

  const { isTreatmentActive: isAwardsEnabled } = useFeatureSplit(
    SplitNames.AwardsPage
  );

  const postContentTypeOptions: SelectableOptionProps[] = [
    { id: 'recognition', value: formatMessage(messages.recognition) },
    { id: 'birthday', value: formatMessage(messages.birthday) },
    { id: 'anniversary', value: formatMessage(messages.anniversary) },
    ...(isAwardsEnabled
      ? [{ id: 'award', value: formatMessage(messages.award) }]
      : []),
  ];

  const { handleDoneClick, handleClearClick, handleSearchChange } =
    useSecondaryFilterState();

  const {
    dateCreatedProps: {
      dateCreatedOptions,
      onDateCreatedChange,
      hasSelectedDateCreated,
    },
  } = useGetDateRangeFilters();

  const {
    isPostedByDataLoading,
    hasMorePostedByMembers,
    fetchMorePostedByMembers,
    postedByOptions,
    isFetchingMorePostedByMembers,
    postedBySelectedOptions,
    hasSelectedPeople,
  } = usePostedBySecondaryFilter({
    topLevelFilter: 'ALL',
    secondaryFilterTypes: {
      postedBy: 'FROM',
    },
  });

  const {
    mentionedByProps: {
      isMentionedPeopleLoading,
      hasMoreMentionedPeople,
      isFetchingMoreMentionedPeople,
      mentionedByPeopleOptions,
      hasSelectedMentionedPeople,
      mentionedBySelectedOptions,
      fetchMoreMentionedPeople,
    },
    postContentTypeProps: {
      postContentTypeSelectedOptions,
      hasSelectedContentType,
    },
  } = useGetPostsSecondaryFilters({
    secondaryFilterTypes,
    postContentTypeOptions,
  });

  const renderPostFilterOptionItem = ({
    label,
    option,
  }: {
    label: string;
    option: SelectableOptionProps;
  }) => {
    const getPrefixItemToRender = () => {
      if (
        label === formatMessage(messages.postedByLabel) ||
        label === formatMessage(messages.mentionedLabel)
      ) {
        return (
          <Avatar
            name={option.value as string}
            size={AvatarSize.Small}
            image={option.imageUrl as string}
            memberID={option.memberID as string}
            className={twMerge(
              'h-6 w-6',
              option.state === 'INACTIVE' && 'grayscale'
            )}
          />
        );
      } else {
        return null;
      }
    };
    return (
      <div className="grid grid-cols-[max-content_minmax(0,1fr)] gap-2">
        {getPrefixItemToRender()}
        <TextStyle
          variant="sm-regular"
          className={twMerge(
            'line-clamp-1 text-gray-8',
            'colorName' in option && 'rounded-lg px-2',
            'colorName' in option &&
              getFolderStyles(option.colorName as FolderColor).background
          )}
        >
          {option.value}{' '}
          {option.state === 'INACTIVE' &&
            `${formatMessage(messages.deactivated)}`}
        </TextStyle>
      </div>
    );
  };

  return (
    <>
      <SelectableListDropdown
        label={formatMessage(messages.postedByLabel)}
        showSearch
        isLoading={isPostedByDataLoading}
        postFixLabel={formatMessage(messages.peopleLabel)}
        onSearchChange={({ value }) =>
          handleSearchChange({
            key: 'from',
            value,
            keyParamMap: {
              from: 'postedBySearchValue',
            },
          })
        }
        isFetchingNextPage={isFetchingMorePostedByMembers}
        selectableListOptions={postedByOptions}
        renderOption={(option) =>
          renderPostFilterOptionItem({
            label: formatMessage(messages.postedByLabel),
            option,
          })
        }
        hasNextPage={hasMorePostedByMembers}
        onLoadMore={fetchMorePostedByMembers}
        onDoneClick={({ currentSelectedOptions }) =>
          handleDoneClick({
            key: 'from',
            currentSelectedOptions,
            keyParamMap: {
              from: 'postedBy',
            },
          })
        }
        onClearAllClick={() => handleClearClick(['postedBy'])}
        selectedOptions={postedBySelectedOptions}
        loader={<Loader showAvatarLoader />}
      />
      <SelectableListDropdown
        label={formatMessage(messages.mentionedLabel)}
        showSearch
        isLoading={isMentionedPeopleLoading}
        postFixLabel={formatMessage(messages.peopleLabel)}
        onSearchChange={({ value }) =>
          handleSearchChange({
            key: 'mention',
            value,
            keyParamMap: {
              mention: 'mentionedBySearchValue',
            },
          })
        }
        isFetchingNextPage={isFetchingMoreMentionedPeople}
        selectableListOptions={mentionedByPeopleOptions}
        renderOption={(option) =>
          renderPostFilterOptionItem({
            label: formatMessage(messages.mentionedLabel),
            option,
          })
        }
        hasNextPage={hasMoreMentionedPeople}
        onLoadMore={fetchMoreMentionedPeople}
        onDoneClick={({ currentSelectedOptions }) =>
          handleDoneClick({
            key: 'mention',
            currentSelectedOptions,
            keyParamMap: {
              mention: 'mentionedBy',
            },
          })
        }
        onClearAllClick={() => handleClearClick(['mentionedBy'])}
        loader={<Loader showAvatarLoader />}
        selectedOptions={mentionedBySelectedOptions}
      />
      <SelectableListDropdown
        label={
          postContentTypeSelectedOptions.length
            ? formatMessage(messages.typeLabel)
            : formatMessage(messages.contentTypeLabel)
        }
        isLoading={false}
        postFixLabel={formatMessage(messages.postsAndRepliesLabel)}
        renderOption={(option) =>
          renderPostFilterOptionItem({
            label: formatMessage(messages.contentTypeLabel),
            option,
          })
        }
        onDoneClick={({ currentSelectedOptions }) => {
          handleDoneClick({
            key: 'contentType',
            currentSelectedOptions,
            keyParamMap: {
              contentType: 'postContentType',
            },
          });
        }}
        selectedOptions={postContentTypeSelectedOptions}
        onLoadMore={() => {}}
        limit={4}
        loader={<Loader showAvatarLoader={false} />}
        onClearAllClick={() => handleClearClick(['postContentType'])}
        selectableListOptions={postContentTypeOptions}
      />
      <DateRangeDropdown
        onChange={onDateCreatedChange}
        onClearAllClick={() =>
          handleClearClick([
            'dateCreated',
            'dateCreatedEnd',
            'dateCreatedStart',
          ])
        }
        maxDate={new Date()}
        selectedOption={dateCreatedOptions}
        label={formatMessage(messages.dateCreatedLabel)}
      />
      {Boolean(
        hasSelectedPeople ||
          hasSelectedDateCreated ||
          hasSelectedMentionedPeople ||
          hasSelectedContentType
      ) && (
        <Button
          variation="tertiaryLite"
          size="small"
          className="bg-transparent"
          onClick={() =>
            handleClearClick([
              'postedBy',
              'mentionedBy',
              'dateCreated',
              'dateCreatedEnd',
              'postContentType',
              'dateCreatedStart',
            ])
          }
        >
          {formatMessage(messages.clearButtonText)}
        </Button>
      )}
    </>
  );
}
