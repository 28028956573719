import { defaultAwardImage } from '@assembly-web/assets';
import type { AwardDetail } from '@assembly-web/services';
import { QuickParticipation } from '@assembly-web/ui';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { defineMessage, useIntl } from 'react-intl';

import type { GiveAwardForm } from '../types';
import { ApprovalProcessBanner } from './components/ApprovalProcessBanner';
import { AwardForm } from './components/AwardForm';
import { Description } from './components/Description';
import { Footer } from './components/Footer';
import { useGetAward } from './hooks/useGetAward';
import { useTrackAction } from './hooks/useTrackAction';

function defaultValue(data: AwardDetail): GiveAwardForm {
  return {
    approvals: data.approvals,
    coreValue: data.post.coreValue,
    points: data.budget.unitPoints,
    isPrivate: false,
  };
}

const altLabel = defineMessage({
  defaultMessage: 'Default Award image',
  id: 'fjU1fT',
});

function Image() {
  const { formatMessage } = useIntl();
  const { data } = useGetAward();

  return (
    <img
      src={data.post.image ? data.post.image.location : defaultAwardImage}
      alt={data.post.image ? data.post.image.name : formatMessage(altLabel)}
      className="aspect-video h-fit w-1/2 rounded-2xl border-0 object-cover"
    />
  );
}

export function GiveAward() {
  const { data } = useGetAward();
  const { trackAwardAction } = useTrackAction();
  const methods = useForm({
    reValidateMode: 'onChange',
    defaultValues: defaultValue(data),
  });

  useEffect(() => {
    trackAwardAction('participationStarted');
  }, [trackAwardAction]);

  return (
    <FormProvider {...methods}>
      <QuickParticipation.BlockList>
        <div className="flex gap-4">
          <Description />
          <Image />
        </div>
        <AwardForm />
        {!data.approvals.autoApprove && <ApprovalProcessBanner />}
      </QuickParticipation.BlockList>
      <Footer />
    </FormProvider>
  );
}
