import {
  APIEndpoints,
  CriteriaRuleType,
  type GetMembersForPreviewResponse,
  type Nullable,
} from '@assembly-web/services';
import { assemblyAPI } from '@assembly-web/services';
import {
  type UndefinedInitialDataInfiniteOptions,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';

import type { GetMembersForPreviewRequest as GetMembersForPreviewRequestForChallenge } from '../../shareChallenge/usePreviewShareChallengeRules';
import type { GetMembersForPreviewRequest as GetMembersForPreviewRequestForCollection } from '../../shareCollections/queries/usePreviewCriteriaQuery';

export type GetMembersFromCriteriaJobResponse = {
  jobId: string;
  previewId: string;
  createdAt: string;
};

export type GetMembersFromCriteriaResponse = {
  previewId: string;
  createdAt: string;
};

export type GetMembersFromCriteriaForPreviewResponse = {
  data: GetMembersFromCriteriaJobResponse | GetMembersFromCriteriaResponse;
};

export function useGetMembersPreview(
  previewId: Nullable<string>,
  previewMemberFilter: {
    search: string | undefined;
    filter: string | undefined;
  },
  flow?: string
) {
  return useInfiniteQuery<GetMembersForPreviewResponse>(
    getPreviewMembers(
      previewId,
      previewMemberFilter.search,
      previewMemberFilter.filter === 'all'
        ? undefined
        : previewMemberFilter.filter,
      flow
    )
  );
}

export function getPreviewMembers(
  previewId: Nullable<string>,
  search: string | undefined,
  filter: string | undefined,
  flow?: string
): UndefinedInitialDataInfiniteOptions<GetMembersForPreviewResponse> {
  return {
    enabled: Boolean(previewId),
    queryKey: ['getMembersCriteriaPreview', previewId, search, filter],
    queryFn: async ({ pageParam }) => {
      const response = await assemblyAPI.get(
        APIEndpoints.previewMembersForShareCriteria(previewId ?? ''),
        {
          params: {
            for: flow,
            search,
            filter,
            ...(pageParam ? { cursor: pageParam } : {}),
          },
        }
      );
      return response.data;
    },
    initialPageParam: 0,
    getNextPageParam: (page: GetMembersForPreviewResponse) =>
      page.metadata.pagination.cursor.next,
    getPreviousPageParam: (page: GetMembersForPreviewResponse) =>
      page.metadata.pagination.cursor.previous,
  };
}

export type PreviewIdForCriteriaUnion =
  | {
      flow: 'generic' | 'challenge';
    }
  | {
      ownerId: string;
      flow: 'collection';
    };

export const usePreviewIDForCriteria = ({
  payload,
  enabled,
  ...rest
}: {
  payload:
    | GetMembersForPreviewRequestForChallenge
    | GetMembersForPreviewRequestForCollection;
  enabled?: boolean;
} & PreviewIdForCriteriaUnion) => {
  return useQuery({
    queryKey: ['getCriteriaPreview', payload],

    queryFn: () =>
      assemblyAPI.post(APIEndpoints.previewShareCriteria, {
        ...payload,
        for: rest.flow,
        ...(rest.flow === 'collection' && {
          includes: [
            {
              type: CriteriaRuleType.Member.toUpperCase(),
              value: [rest.ownerId],
            },
          ],
        }),
      }),

    gcTime: 0,
    enabled,
  });
};
