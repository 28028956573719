import { SVGReactComponents } from '@assembly-web/assets';
import type { PropsWithChildren, ReactNode } from 'react';

import { V3Modal } from '../../Shared/Modal/V3Modal';

type AwardsModalProps = PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
  title: ReactNode;
  ctaSection: ReactNode;
}>;

export function AwardsModal({
  children,
  isOpen,
  onClose,
  title,
  ctaSection,
}: AwardsModalProps) {
  return (
    <V3Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      theme="cyan"
      size="medium"
      icon={<SVGReactComponents.AwardMedalIcon className="size-12" />}
      ctaSectionClassName="px-6 pb-4"
      headerClassName="p-4"
      className="p-0"
      bodyClassName="p-0"
      ctaSection={ctaSection}
      focusElementOnFirstMount
    >
      {children}
    </V3Modal>
  );
}
