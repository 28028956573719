import { useInfiniteQuery } from '@tanstack/react-query';

import { APIEndpoints } from '../../../APIEndpoints';
import { assemblyAPI } from '../../../assemblyAPI';
import type { PendingAwardApprovalResponse } from '../../../types/awards';

export function useGetPendingAwardApprovals() {
  return useInfiniteQuery<PendingAwardApprovalResponse>({
    queryKey: ['pendingAwardApproval'],
    queryFn: async ({ pageParam }) => {
      const params: Record<string, unknown> = {};
      if (pageParam) {
        params.cursor = pageParam;
      }
      return await assemblyAPI.get(APIEndpoints.getPendingAwardApprovals, {
        params,
      });
    },
    initialPageParam: undefined,
    getNextPageParam: (page: PendingAwardApprovalResponse) =>
      page.data.metadata.pagination.cursor.next,
    getPreviousPageParam: (page: PendingAwardApprovalResponse) =>
      page.data.metadata.pagination.cursor.previous,
  });
}
