import type { PropsWithChildren, ReactNode } from 'react';

import { QuickParticipation } from './QuickParticipation';

export function ParticipationBlock({
  label,
  children,
}: PropsWithChildren & { label: ReactNode }) {
  return (
    <QuickParticipation.Block>
      <QuickParticipation.Label>{label}</QuickParticipation.Label>
      {children}
    </QuickParticipation.Block>
  );
}
