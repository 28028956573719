import {
  LockClosedIcon,
  PlusCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { type ReactNode, useCallback, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { Chip } from '../../../DesignSystem/Inputs/Chip';
import { NumberInput } from '../../../DesignSystem/Inputs/NumberInput';
import { cardParentDataAttributes, handleParentClick } from '../Cards/utils';

const labels = defineMessages({
  chip: {
    id: 'r5AwpC',
    defaultMessage: 'Add <iconTag>{currencyIcon}</iconTag>',
  },
  value: {
    defaultMessage: '<iconTag>{currencyIcon}</iconTag> {value} each {lock}',
    id: 'TScXSN',
  },
  none: {
    defaultMessage: 'None',
    id: '450Fty',
  },
});

export type PointsBlockProps = {
  placeholder: string;
  onValueChange: (val: string | number) => void;
  value: number;
  connectedRight?: ReactNode;
  disabled: boolean;
  currencyIcon: ReactNode;
  tooltipText?: string;
  className?: string;
  error?: ReactNode;
  trackAction?: () => void;
  isLocked?: boolean;
};

export function PointsBlock({
  placeholder,
  onValueChange,
  value,
  disabled,
  connectedRight,
  currencyIcon,
  tooltipText,
  className,
  error = null,
  trackAction,
  isLocked = false,
}: PointsBlockProps) {
  const [showPointsField, setShowPointsField] = useState(false);
  const { formatMessage } = useIntl();

  const handleClick = useCallback(() => {
    if (!disabled) {
      setShowPointsField(true);
    }
  }, [disabled]);

  const content = useMemo(() => {
    if (!error && disabled && !value) {
      return formatMessage(labels.none);
    }
    if (value) {
      return formatMessage(labels.value, {
        value,
        currencyIcon,
        iconTag: () => currencyIcon,
        lock: isLocked && <LockClosedIcon className="h-4 w-4 text-gray-8" />,
      });
    }

    return formatMessage(labels.chip, {
      currencyIcon,
      iconTag: () => currencyIcon,
    });
  }, [value, currencyIcon, formatMessage, disabled, error, isLocked]);

  const buttonActionIcon = useMemo(() => {
    return (
      <>
        {value > 0 ? (
          <XCircleIcon
            className={twJoin(
              'h-6 w-6',
              Boolean(disabled) && 'cursor-not-allowed'
            )}
            onClick={() => {
              onValueChange('');
              setShowPointsField(false);
            }}
          />
        ) : (
          <PlusCircleIcon className="h-6 w-6" onClick={handleClick} />
        )}
      </>
    );
  }, [disabled, handleClick, value, onValueChange]);

  const pointsContainerBlock = useMemo(() => {
    return (
      <Chip.Root
        intent="badge"
        color="gold"
        isDisabled={disabled}
        className={className}
      >
        <Chip.Text className="flex items-center gap-1">{content}</Chip.Text>
        {!disabled && (
          <Chip.Button className="focus:ring-0" aria-label="button">
            {buttonActionIcon}
          </Chip.Button>
        )}
      </Chip.Root>
    );
  }, [content, disabled, className, buttonActionIcon]);

  return (
    <>
      {showPointsField ? (
        <div className="flex flex-wrap items-center gap-4 md:flex-nowrap md:justify-between md:gap-0">
          <div className="w-full md:w-4/6">
            <NumberInput
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              className="w-full text-base ring-0 placeholder:text-gray-7 focus:ring-0"
              placeholder={placeholder}
              onBlur={() => {
                setShowPointsField(false);
                trackAction?.();
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setShowPointsField(false);
                  trackAction?.();
                }
              }}
              value={value}
              dynamicWidth={false}
              label={''}
              showStepper={false}
              leadingIcon={currencyIcon}
              onChange={onValueChange}
            />
          </div>
          {Boolean(connectedRight) && connectedRight}
        </div>
      ) : (
        <div
          className={twJoin(
            'flex flex-col gap-2',
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          )}
          onClick={handleParentClick(() => handleClick())}
          onKeyDown={handleParentClick(() => handleClick())}
          {...cardParentDataAttributes}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          role="presentation"
        >
          <Tooltip tooltipText={tooltipText}>{pointsContainerBlock}</Tooltip>
          {error}
        </div>
      )}
    </>
  );
}
