import { TextStyle } from '@assembly-web/ui';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

export function NavSectionEmptyState({ text }: { text: string }) {
  return (
    <div className="m-2 flex gap-1 rounded bg-primary-1 p-3 text-primary-7">
      <InformationCircleIcon className="h-4 w-4 flex-shrink-0 text-primary-7" />
      <TextStyle variant="xs-regular">{text}</TextStyle>
    </div>
  );
}
