import type { Icon } from '@assembly-web/services';
import { FilePreviewerContext, useAssemblyNavigate } from '@assembly-web/ui';
import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGetDownloadableFile } from './useGetDownloadableFile';

export const useOpenFlowsPreviewer = () => {
  const { mutate } = useGetDownloadableFile();

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useAssemblyNavigate();

  const { openModal: openV3Modal } = useContext(FilePreviewerContext);

  return ({
    blockId,
    fileName,
    flowId,
    responseId,
    workspaceSlug,
    fileType,
    memberID,
    memberName,
    memberImage,
    dateShared,
    sharedIn,
    sharedInIcon,
    locationUrl, // use locationUrl for recognition V3
  }: {
    blockId: string;
    fileName: string;
    flowId: string;
    responseId: string;
    workspaceSlug?: string;
    fileType: string;
    memberID: string;
    memberName: string;
    memberImage?: string;
    dateShared: string | number | Date;
    sharedIn: string;
    sharedInIcon?: Icon;
    locationUrl?: string;
  }) => {
    function handleOpenV3Modal(locationUrl: string) {
      openV3Modal({
        file: {
          fileName: fileName,
          locationUrl,
          fileType: fileType,
          memberID,
          memberName,
          memberImage,
          handleGoToPost: () => {
            searchParams.set('flowId', flowId);
            searchParams.set('responseId', responseId);
            setSearchParams(searchParams.toString());
          },
          dateShared,
          sharedIn,
          sharedInIcon,
          handleSharedInClick: () => {
            const urlSlug = `/flows/${flowId}`;
            navigate(
              urlSlug.includes('recognition')
                ? `/a/${workspaceSlug}/flows/recognition`
                : `/a/${workspaceSlug}${urlSlug}`
            );
          },
        },
        showGoToPost: true,
        isChallengeClaim: false,
      });
    }

    if (locationUrl && flowId === 'recognition') {
      handleOpenV3Modal(locationUrl);
      return;
    }
    mutate(
      { flowId, responseId, blockId, fileName, fileType },
      {
        onSuccess: (data) => {
          handleOpenV3Modal(data.url);
        },
      }
    );
  };
};
