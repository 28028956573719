import type { Primitive, RequireAllOrNone, ValueOf } from 'type-fest';

import { SSOProvider } from '../sso';

const RegistrationMethods = {
  [SSOProvider.Google]: 'Google',
  [SSOProvider.ADP]: 'ADP',
  [SSOProvider.Slack]: 'Slack',
  [SSOProvider.Office365]: 'Office365',
  Email: 'Email',
};

export type UserProps = {
  $distinct_id: string;
  $email: string;
  $name: string;
  'Allowance Points Available': number;
  'Allowance Type': string;
  'Company Plan Type': string;
  'Registration Date': string;
  'Registration Method': ValueOf<typeof RegistrationMethods>;
  'Total Allowance': number;
  'Total Points Given': number;
  'User Status': string;
  active: number;
  Anniversary: string;
  archived: number;
  Birthday: string;
  canSendPrivate: boolean;
  completed: number;
  Department: string;
  Email: string;
  isAdmin: boolean;
  isManager: boolean;
  isPaid: boolean;
  Name: string;
  planType: string;
  Platform: string;
  numWorkspacesActive: number;
};

export type ActionProps = {
  userProps?: Partial<UserProps>;
} & RequireAllOrNone<{
  eventName: string;
  eventProps: Record<string, Primitive>;
}>;

export type TrackEventProps = Record<
  string,
  Primitive | string[] | Record<string, Primitive>
>;
