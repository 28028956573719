import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const useTextOverflow = () => {
  const [contentOverflows, setContentOverflows] = useState(false);
  const textRef = useRef<HTMLParagraphElement | null>(null);

  const checkIfTextOverflowing = useCallback(
    (parentElement?: HTMLParagraphElement) => {
      const parent = parentElement ?? textRef.current?.parentElement;

      const fullWidth = textRef.current?.getBoundingClientRect().width ?? 0;
      const displayWidth = parent?.getBoundingClientRect().width ?? 0;

      const fullHeight = textRef.current?.getBoundingClientRect().height ?? 0;
      const displayHeight = parent?.getBoundingClientRect().height ?? 0;

      const isOverflowing =
        fullWidth > displayWidth || fullHeight > displayHeight;
      return isOverflowing;
    },
    []
  );

  useEffect(() => {
    function handler() {
      setContentOverflows(checkIfTextOverflowing());
    }

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
      textRef.current?.remove();
    };
  }, [checkIfTextOverflowing]);

  const textMountCallback = useCallback(
    (e: HTMLParagraphElement | null) => {
      if (!e) {
        if (textRef.current) {
          textRef.current.remove();
          textRef.current = null;
        }
        return;
      }
      textRef.current = e.cloneNode(true) as HTMLParagraphElement;

      textRef.current.className = '';
      textRef.current.style.position = 'fixed';
      textRef.current.style.overflow = 'visible';
      textRef.current.style.whiteSpace = 'nowrap';
      textRef.current.style.visibility = 'hidden';

      e.parentNode?.appendChild(textRef.current);

      setContentOverflows(checkIfTextOverflowing(e));
    },
    [checkIfTextOverflowing]
  );

  return useMemo(
    () => ({ contentOverflows, textMountCallback }),
    [contentOverflows, textMountCallback]
  );
};
