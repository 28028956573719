import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { ORDERED_LIST, QUOTE, UNORDERED_LIST } from '@lexical/markdown';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import {
  type InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { QuoteNode } from '@lexical/rich-text';
import { motion } from 'framer-motion';
import type { LexicalEditor } from 'lexical';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Tooltip } from '../../../..';
import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';
import { ExternalOnChangePlugin } from '../base/plugins/ExternalOnChangePlugin';
import { FormatPlugin } from '../base/plugins/FormatPlugin';
import { UpdateEditorValuePlugin } from '../base/plugins/UpdateEditorValuePlugin';
import { EditorTheme } from '../base/theme';
import { MATCHERS, validateUrl } from '../base/utils/regexUtils';
import { HandleEnterKeyPressPlugin } from '../RepliesEditor/plugins/HandleEnterKeyPressPlugin';
import { ListMaxIndentLevelPlugin } from '../RepliesEditor/plugins/ListMaxIndentLevelPlugin';
import { RepliesHeaderPlugin } from '../RepliesEditor/plugins/RepliesHeaderPlugin';

const config = {
  theme: EditorTheme,
  namespace: 'rich-text-editor',
  nodes: [LinkNode, ListNode, AutoLinkNode, ListItemNode, QuoteNode],
  onError: () => {},
} satisfies InitialConfigType;

export type SerializedData = {
  html: string;
  json: string;
  plainText: string;
};

export type RichTextEditorProps = {
  value?: string;
  hasError?: boolean;
  disabled?: boolean;
  placeholder: string;
  onError?: (error: Error, editor: LexicalEditor) => void;
  onChange: ({ html, json, plainText }: SerializedData) => void;
  toolbarClassName?: string;
  editorClassName?: string;
  tooltipText?: string;
};

export function RichTextEditor({
  value,
  onError,
  hasError,
  onChange,
  placeholder,
  disabled = false,
  toolbarClassName,
  editorClassName,
  tooltipText,
}: RichTextEditorProps) {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div
      className={twMerge(
        'relative flex flex-1 flex-col overflow-hidden rounded-lg border border-gray-4',
        hasError && 'flex-shrink-0 border-error-6 ring-1 ring-error-6',
        isFocused && !hasError && 'ring-1 ring-primary-3'
      )}
    >
      <LexicalComposer
        initialConfig={{
          ...config,
          onError: (error, editor) => {
            onError?.(error, editor);
          },
          editable: !disabled,
        }}
      >
        <MarkdownShortcutPlugin
          transformers={[
            ORDERED_LIST,
            { ...UNORDERED_LIST, regExp: /^(\s*)([-*])\s/ },
            QUOTE,
          ]}
        />
        <RepliesHeaderPlugin
          isVisible={true}
          hideBottomBorder={true}
          hideHeadingDropdown={true}
          className={toolbarClassName}
        />
        <RichTextPlugin
          ErrorBoundary={LexicalErrorBoundary}
          placeholder={
            <motion.div
              className="pointer-events-none absolute left-2 top-[48px]"
              layout="position"
              transition={{ layout: { duration: 0.2 } }}
            >
              <TextStyle
                as="span"
                disabled={disabled}
                variant="sm-regular"
                className="text-gray-7"
              >
                {placeholder}
              </TextStyle>
            </motion.div>
          }
          contentEditable={
            <Tooltip tooltipText={tooltipText}>
              <div
                className={twMerge(
                  'flex-1 overflow-hidden rounded-lg border-0 outline-[0] focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0',
                  disabled && 'cursor-not-allowed bg-gray-2'
                )}
              >
                <ContentEditable
                  disabled={disabled}
                  onBlur={() => {
                    setIsFocused(false);
                  }}
                  onFocus={() => {
                    setIsFocused(true);
                  }}
                  className={twMerge(
                    'flex h-full min-h-[36px] flex-col gap-1 overflow-y-auto p-2 text-base text-gray-9 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0',
                    editorClassName,
                    disabled && 'text-gray-7'
                  )}
                  ariaLabel="Rich text editor"
                />
              </div>
            </Tooltip>
          }
        />
        <ListPlugin />
        <LinkPlugin validateUrl={validateUrl} />
        <AutoLinkPlugin matchers={MATCHERS} />
        <FormatPlugin />
        <ListMaxIndentLevelPlugin maxDepth={5} />
        <HistoryPlugin />
        <ExternalOnChangePlugin onChange={onChange} />
        <UpdateEditorValuePlugin value={value} />
        <HandleEnterKeyPressPlugin />
      </LexicalComposer>
    </div>
  );
}
