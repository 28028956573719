export function GiveAwardLoader() {
  return (
    <div className="flex flex-col gap-6 px-6 py-4">
      <div className="aspect-video w-full animate-pulse rounded-2xl bg-gray-4" />
      {[...Array(3)].map((item, index) => (
        <div className="flex w-full animate-pulse flex-col gap-4" key={index}>
          <div className="h-4 w-44 rounded-lg bg-gray-4" />
          <div className="h-6 w-16 rounded-full bg-gray-4" />
        </div>
      ))}
      <div className="flex w-full flex-col gap-4">
        <div className="h-4 w-44 animate-pulse rounded-full bg-gray-4"></div>
        <div className="h-[150px] w-full rounded-lg border border-gray-5 p-4">
          <div className="h-4 w-full animate-pulse rounded-full bg-gray-4"></div>
        </div>
      </div>
    </div>
  );
}
