import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type { UndefinedInitialDataInfiniteOptions } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';

import type { TemplateListsApiResponse } from '../types';

export type Filter = {
  actions: string[];
  jobTypes: string[];
  useCases: string[];
  categories: string[];
};

type GetTemplatesQueryPayload = Partial<{
  keyword: string;
  filter: Partial<Filter>;
}>;

export function getTemplatesQuery(
  payload: GetTemplatesQueryPayload,
  options?: Partial<
    UndefinedInitialDataInfiniteOptions<TemplateListsApiResponse>
  >
): UndefinedInitialDataInfiniteOptions<TemplateListsApiResponse> {
  const { keyword, filter } = payload;

  return {
    ...options,
    queryKey: ['templates', keyword, filter],
    queryFn: async ({ pageParam }) =>
      await assemblyAPI.post(
        APIEndpoints.listTemplates,
        {
          limit: 20,
          cursor: pageParam,
          filter,
        },
        {
          params: {
            keyword,
          },
        }
      ),
    initialPageParam: 0,
    getNextPageParam: (page: TemplateListsApiResponse) =>
      page.data.metadata?.pagination.cursor.next ?? undefined,
    getPreviousPageParam: (page: TemplateListsApiResponse) => {
      return page.data.metadata?.pagination.cursor.previous;
    },
  };
}

export function useGetTemplatesQuery(
  payload: GetTemplatesQueryPayload,
  options?: Partial<
    UndefinedInitialDataInfiniteOptions<TemplateListsApiResponse>
  >
) {
  return useInfiniteQuery<TemplateListsApiResponse>(
    getTemplatesQuery(payload, options)
  );
}
