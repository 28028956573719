import { GlobeAltIcon } from '@heroicons/react/24/outline';
import type { SlotProps } from '@radix-ui/react-slot';
import { Slot } from '@radix-ui/react-slot';
import { forwardRef, type PropsWithChildren, type ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';

const labels = defineMessages({
  visibilityTooltip: {
    defaultMessage: 'Visible to everyone in your company',
    id: 'mTkk9u',
  },
});

function Root({ children }: PropsWithChildren) {
  return (
    <section className="flex flex-col gap-6 rounded-2xl bg-gray-1 p-4 shadow-feed-post @container/root">
      {children}
    </section>
  );
}

function Header({ children }: PropsWithChildren) {
  return <header className="flex flex-col gap-2">{children}</header>;
}

function Title({
  children,
  trailing,
}: {
  shareType: 'everyone';
  trailing?: ReactNode;
} & PropsWithChildren) {
  const { formatMessage } = useIntl();
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <h3>
          <TextStyle variant="xl-bold" as="span" className="line-clamp-3">
            {children}
          </TextStyle>
        </h3>
        <Tooltip tooltipText={formatMessage(labels.visibilityTooltip)}>
          <GlobeAltIcon className="h-4 w-4 text-gray-8" />
        </Tooltip>
      </div>
      {Boolean(trailing) && (
        <div className="flex items-center gap-2">{trailing}</div>
      )}
    </div>
  );
}

function Description({ children }: PropsWithChildren) {
  return (
    <TextStyle variant="base-regular" subdued>
      {children}
    </TextStyle>
  );
}

function FooterContainer({ children }: PropsWithChildren) {
  return <div className="flex flex-col gap-4">{children}</div>;
}

function Footer({
  children,
  className,
}: PropsWithChildren & { className?: string }) {
  return (
    <footer className={twJoin('flex gap-4', className)}>{children}</footer>
  );
}

const ActionButton = forwardRef<HTMLElement, SlotProps & { asChild?: boolean }>(
  function ActionButton({ children, className, asChild, ...rest }, ref) {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        {...rest}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref={ref as any}
        className={twJoin(
          'flex-1 rounded-[20px] bg-gray-3 px-4 py-2 text-start text-sm text-gray-7',
          className
        )}
      >
        {children}
      </Comp>
    );
  }
);

export const FeedActionBar = {
  ActionButton,
  Description,
  FooterContainer,
  Footer,
  Header,
  Root,
  Title,
};
