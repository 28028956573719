import {
  type ParticipationAction,
  type TrackEventProps,
  trackParticipationAction,
  trackParticipationError,
} from '@assembly-web/services';
import { useCallback, useMemo } from 'react';

import { useGetAward } from './useGetAward';

export const useTrackAction = () => {
  const { data } = useGetAward();
  const awardProps = useMemo(() => {
    return {
      postType: 'award',
      awardName: data.name,
    };
  }, [data]);

  const trackAwardAction = useCallback(
    (event: ParticipationAction, props?: TrackEventProps) => {
      trackParticipationAction(event, { ...awardProps, ...props });
    },
    [awardProps]
  );

  const trackAwardError = useCallback(
    (errorInfo: TrackEventProps) => {
      trackParticipationError({ ...errorInfo, ...awardProps });
    },
    [awardProps]
  );

  return {
    trackAwardAction,
    trackAwardError,
    awardProps,
  };
};
