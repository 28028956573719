import { useParticipationModalContext } from '@assembly-web/participation';
import { useCallback } from 'react';

export const useOpenRecognitionParticipation = () => {
  const { openModal } = useParticipationModalContext();

  return useCallback(
    (editPostId?: string) =>
      openModal({ postId: editPostId, type: 'recognition' }),
    [openModal]
  );
};
