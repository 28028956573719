import { Fallback, Root } from '@radix-ui/react-avatar';

import type {
  AvatarProps,
  AvatarSize,
  UserAvatarProps,
} from '../../DesignSystem/Feedback/Avatar';
import { Avatar, avatarSizes } from '../../DesignSystem/Feedback/Avatar';
import { classNames } from '../../DesignSystem/Utils/classNames';

export function AvatarGroup({
  avatars,
  limit = 5,
  size,
  totalCount,
}: {
  avatars: (UserAvatarProps & Pick<AvatarProps, 'tooltipText'>)[];
  limit?: number;
  size: AvatarSize;
  totalCount: number;
}) {
  return (
    <div className="relative flex">
      {avatars.slice(0, limit).map((avatar, index) => (
        <Avatar
          key={`${avatar.memberID}-avatar-${index}`}
          image={avatar.image}
          memberID={avatar.memberID}
          name={avatar.name}
          size={size}
          className={classNames(
            'border border-gray-1 group-hover:border-primary-1',
            {
              '-ml-3': index > 0,
            }
          )}
          tooltipText={avatar.tooltipText ?? avatar.name}
        />
      ))}
      {totalCount > limit && (
        <Root
          className={classNames(
            'z-1 absolute right-0 inline-flex',
            avatarSizes({ size })
          )}
        >
          <Fallback
            className={classNames(
              'flex items-center justify-center rounded-full bg-gray-9 opacity-70',
              avatarSizes({ size })
            )}
            delayMs={600}
          >
            <span className={classNames('text-sm font-medium text-gray-1')}>
              +{totalCount - limit}
            </span>
          </Fallback>
        </Root>
      )}
    </div>
  );
}
