import { TrackMessage } from '@assembly-web/ui';

import { useTrackAction } from '../hooks/useTrackAction';
import { CoreValue } from './CoreValue';
import { OpenEnded } from './OpenEnded';
import { PersonSelector } from './PersonSelector';
import { PointsSelector } from './PointsSelector';

export function AwardForm() {
  const { awardProps } = useTrackAction();
  return (
    <>
      <TrackMessage props={awardProps} />
      <PersonSelector />
      <PointsSelector />
      <CoreValue />
      <OpenEnded />
    </>
  );
}
