// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isFileUploadInProgress = (attachments: any[]) => {
  if (attachments.length) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return attachments.some((attachment: any) =>
      attachment && 'uploadComplete' in attachment
        ? !attachment.uploadComplete
        : false
    );
  }
  return false;
};
