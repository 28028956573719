import { SVGReactComponents } from '@assembly-web/assets';
import { useParticipationModalContext } from '@assembly-web/participation';
import {
  GlobalFilterOption,
  isTruthy,
  MemberStatus,
  SplitNames,
  useFeatureSplit,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import { RoutineIcon, TextStyle, Tooltip } from '@assembly-web/ui';
import {
  BellIcon,
  ChartBarIcon,
  DocumentTextIcon,
  GiftIcon,
  HomeIcon,
  LockClosedIcon,
  SparklesIcon,
  TrophyIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { usePendingAwardApprovalCount } from '../../hooks/usePendingAwardApprovalCount';
import { useUnreadNotificationsCount } from '../../hooks/useUnreadNotificationsCount';
import { trackAppConnectionAction } from '../../modules/connections/services/analytics';
import { useCreateWorkflow } from '../../modules/discover/hooks/flowsEditor/useCreateWorkflow';
import { useGetPlanFeatures } from '../../modules/discover/hooks/useGetPlanFeaturesQuery';
import {
  trackDoraAction,
  trackNavAction,
} from '../../modules/discover/services/analytics';
import { isDoraIncludedInWorkspacePlan } from '../../modules/discover/services/dora';
import { setFullScreenDrawerId } from '../../modules/discover/services/drawers';
import { isAdminMember } from '../../modules/discover/services/member';
import { useRewardsDetails } from '../../modules/rewards/hooks/useRewardsDetails';
import { useMultiDrawerStore } from '../../stores/useMultiDrawerStore';
import { useNavBarStore } from '../../stores/useNavBarStore';
import { NavCollapsibleSection } from './NavCollapsibleSection';
import { NavItem } from './NavItem';

const messages = defineMessages({
  mainSectionTitle: {
    id: 'EFTSMc',
    defaultMessage: 'Main',
  },
  home: {
    defaultMessage: 'Home',
    id: 'ejEGdx',
  },
  notifications: {
    defaultMessage: 'Notifications',
    id: 'NAidKb',
  },
  rewards: {
    defaultMessage: 'Rewards',
    id: 'y0EYpw',
  },
  challenges: {
    defaultMessage: 'Challenges',
    id: '///zLu',
  },
  aiReporting: {
    defaultMessage: 'AI Reporting',
    id: '7cdCss',
  },
  moreToggle: {
    defaultMessage: 'More',
    id: 'I5NMJ8',
  },
  doraDrawerTitle: {
    defaultMessage: 'DoraAI Reporting',
    id: 'eE3c8F',
  },
  upgrade: {
    defaultMessage: 'Upgrade',
    id: '0h/lPM',
  },
  visibleToAdmins: {
    defaultMessage: 'Only visible to admins.',
    id: 'uzTBkg',
  },
  flows: {
    defaultMessage: 'Flows',
    id: '3Pbj9T',
  },
  people: {
    defaultMessage: 'People',
    id: 'Tpy00S',
  },
  files: {
    defaultMessage: 'Files',
    id: 'm4vqJl',
  },
  appConnections: {
    defaultMessage: 'App Connections',
    id: 'Cgi2RU',
  },
  recognition: {
    defaultMessage: 'Recognition',
    id: 'sV4Od1',
  },
  aiRecognition: {
    defaultMessage: 'DoraAI Recognition',
    id: 'ORPB/M',
  },
  betaTag: {
    defaultMessage: 'BETA',
    id: 'vroSRZ',
  },
});

export function MainNavSectionSkeleton() {
  const { formatMessage } = useIntl();
  const isMainSectionOpen = useNavBarStore(
    useShallow((state) => state.sectionOpenState.main)
  );

  const toggleNavSection = useNavBarStore(
    useShallow((state) => state.toggleSection)
  );

  return (
    <div className="flex flex-col gap-2">
      <TextStyle variant="sm-bold" subdued className="uppercase">
        {formatMessage(messages.mainSectionTitle)}
      </TextStyle>
      <div className="mt-4 flex flex-col items-start gap-2 px-1">
        <NavItem.Skeleton />
        <NavItem.Skeleton />
        <NavItem.Skeleton />
        <NavItem.Skeleton />
        <NavItem.Skeleton />
        <NavCollapsibleSection
          title={formatMessage(messages.moreToggle)}
          isSectionOpen={isMainSectionOpen}
          onSectionToggle={() => toggleNavSection('main')}
          isSubSection
        >
          <NavItem.Skeleton />
          <NavItem.Skeleton />
          <NavItem.Skeleton />
          <NavItem.Skeleton />
        </NavCollapsibleSection>
      </div>
    </div>
  );
}

export function MainNavSection({
  isRecognitionOnlyCustomer,
}: {
  isRecognitionOnlyCustomer: boolean;
}) {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();

  const { data: userDetails } = useSuspenseUserDetails();

  const { data: planFeatureDetails } = useGetPlanFeatures();

  const createWorkflow = useCreateWorkflow();

  const notificationsCount = useUnreadNotificationsCount();

  const pendingApprovalCount = usePendingAwardApprovalCount();

  const isMainSectionOpen = useNavBarStore(
    useShallow((state) => state.sectionOpenState.main)
  );

  const toggleNavSection = useNavBarStore(
    useShallow((state) => state.toggleSection)
  );

  const { setAiRecognitionModalOpen } = useParticipationModalContext();

  const { isTreatmentActive: addMergeAppsEnabled } = useFeatureSplit(
    SplitNames.AddMergeApps
  );

  const { isTreatmentActive: isDoraReportingInsightsManagersEnabled } =
    useFeatureSplit(SplitNames.DoraReportingInsightsManagers);

  const { isTreatmentActive: isDoraAiRecognitionWriterEnabled } =
    useFeatureSplit(SplitNames.DoraAiRecognitionWriter);

  const createDrawer = useMultiDrawerStore((store) => store.createDrawer);

  const isDoraIncludedInPlan =
    isDoraIncludedInWorkspacePlan(planFeatureDetails);

  const handleDoraReportingClick = useCallback(() => {
    const drawerId = createDrawer({
      type: 'doraChat',
      title: formatMessage(messages.doraDrawerTitle),
      data: {},
    });

    setFullScreenDrawerId(drawerId); // Open in full-screen mode by default
    trackDoraAction('aiReportingInsightsClicked');
  }, [createDrawer, formatMessage]);

  const isAdmin = isAdminMember(userDetails.member);
  const workspaceSlugPath = userDetails.assembly.workspaceSlugPath;
  const { isManager } = userDetails.member.profile;
  const canSendRecognition =
    userDetails.member.status == MemberStatus.Normal ||
    userDetails.member.status == MemberStatus.Giver;

  const { isRewardsSetupForUser } = useRewardsDetails();

  const isDoraReportingInsightsVisible =
    Boolean(isDoraIncludedInPlan) &&
    (isAdmin || (isDoraReportingInsightsManagersEnabled && isManager));

  const isDoraAiRecognitionWriterVisible =
    Boolean(isDoraIncludedInPlan) &&
    isDoraAiRecognitionWriterEnabled &&
    canSendRecognition;

  const canAddConnections =
    addMergeAppsEnabled && isAdminMember(userDetails.member);

  const showChallenges =
    userDetails.assembly.settings.challenge.creation.enabled || isAdmin;

  return (
    <div className="flex flex-col gap-2">
      <TextStyle variant="sm-bold" subdued className="uppercase">
        {formatMessage(messages.mainSectionTitle)}
      </TextStyle>
      <div className="mt-4 flex flex-col items-start gap-2">
        <NavItem.InternalLink
          url="/a"
          icon={HomeIcon}
          text={formatMessage(messages.home)}
          onClick={() => {
            trackNavAction('myFeedClicked');
          }}
        />

        <NavItem.InternalLink
          url="/a/notifications"
          icon={BellIcon}
          text={formatMessage(messages.notifications)}
          isActive={pathname.includes('/notifications')}
          textClassName="flex-grow"
          onClick={() => {
            trackNavAction('activityClicked');
          }}
        >
          <div className="flex gap-2">
            {pendingApprovalCount > 0 && (
              <TextStyle
                variant="xs-medium"
                className="ml-auto flex h-6 w-6 items-center justify-center rounded-lg border border-warning-5 bg-warning-2 px-3 py-1"
              >
                {pendingApprovalCount}
              </TextStyle>
            )}
            {notificationsCount > 0 && (
              <TextStyle
                variant="xs-medium"
                className="ml-auto flex h-6 w-6 items-center justify-center rounded-lg border border-primary-3 bg-primary-1 px-3 py-1"
              >
                {notificationsCount}
              </TextStyle>
            )}
          </div>
        </NavItem.InternalLink>

        {isTruthy(isAdmin || isRewardsSetupForUser) && (
          <NavItem.InternalLink
            url="/a/rewards"
            icon={GiftIcon}
            text={formatMessage(messages.rewards)}
            onClick={() => {
              trackNavAction('rewardsClicked');
            }}
          />
        )}

        {!isRecognitionOnlyCustomer && (
          <NavItem.InternalLink
            url={`/a/${workspaceSlugPath}/flows/recognition`}
            icon={SVGReactComponents.RecognitionIcon}
            text={formatMessage(messages.recognition)}
            onClick={() => {
              trackNavAction('recognitionClicked');
            }}
          />
        )}

        {isTruthy(showChallenges) && (
          <NavItem.InternalLink
            url="/a/challenges"
            icon={TrophyIcon}
            text={formatMessage(messages.challenges)}
            onClick={() => {
              trackNavAction('challengesClicked');
            }}
          />
        )}

        {isTruthy(isDoraReportingInsightsVisible) && (
          <NavItem.Button
            onClick={() => {
              trackNavAction('aiReportingClicked');
              handleDoraReportingClick();
            }}
            icon={ChartBarIcon}
            text={formatMessage(messages.aiReporting)}
          />
        )}

        {isTruthy(isDoraAiRecognitionWriterVisible) && (
          <NavItem.Button
            onClick={() => {
              setAiRecognitionModalOpen(true);
              trackDoraAction('aiRecognitionClicked', {
                triggerSource: 'navItem',
              });
            }}
            icon={SparklesIcon}
            text={formatMessage(messages.aiRecognition)}
          >
            <TextStyle
              variant="xs-medium"
              className="flex items-center justify-center rounded-md border border-gray-5 bg-gray-3 px-2 py-1 text-gray-8"
            >
              {formatMessage(messages.betaTag)}
            </TextStyle>
          </NavItem.Button>
        )}

        {isRecognitionOnlyCustomer ? (
          <>
            <NavItem.InternalLink
              url="/a/people"
              icon={UsersIcon}
              text={formatMessage(messages.people)}
            />
            {isTruthy(isAdmin) && (
              <NavItem.Button
                onClick={() => createWorkflow()}
                icon={RoutineIcon}
                text={
                  <div className="flex items-center gap-4">
                    {formatMessage(messages.flows)}
                    <Tooltip
                      tooltipText={formatMessage(messages.visibleToAdmins)}
                    >
                      <div className="flex items-center gap-2 rounded-3xl bg-upgrade-4 px-2 py-1">
                        <LockClosedIcon className="h-4 w-4" />
                        <TextStyle variant="sm-medium">
                          {formatMessage(messages.upgrade)}
                        </TextStyle>
                      </div>
                    </Tooltip>
                  </div>
                }
              />
            )}
          </>
        ) : (
          <NavCollapsibleSection
            title={formatMessage(messages.moreToggle)}
            isSectionOpen={isMainSectionOpen}
            onSectionToggle={() => {
              toggleNavSection('main');
              if (isMainSectionOpen) {
                trackNavAction('moreCollapsed');
              } else {
                trackNavAction('moreExpanded');
              }
            }}
            isSubSection
          >
            <NavItem.InternalLink
              url="/a/flows"
              icon={RoutineIcon}
              text={formatMessage(messages.flows)}
              onClick={() => {
                trackNavAction('flowsClicked');
                trackAppConnectionAction('manageAppsOpened', {
                  filterType: GlobalFilterOption.Files,
                });
              }}
            />

            <NavItem.InternalLink
              url="/a/people"
              icon={UsersIcon}
              onClick={() => {
                trackNavAction('peopleClicked');
              }}
              text={formatMessage(messages.people)}
            />

            <NavItem.InternalLink
              url="/a/files"
              icon={DocumentTextIcon}
              text={formatMessage(messages.files)}
            />

            {isTruthy(canAddConnections) && (
              <NavItem.InternalLink
                url="/a/connections"
                icon={SVGReactComponents.AppConnectionsIcon}
                text={formatMessage(messages.appConnections)}
                onClick={() => {
                  trackNavAction('appConnectionsClicked');
                }}
              />
            )}
          </NavCollapsibleSection>
        )}
      </div>
    </div>
  );
}
