import { fileIcons } from '@assembly-web/assets';
import {
  type CollectionItem as FolderItem,
  type FileSearchResult,
  type FileType,
  type FlowSearchResult,
  formatAssemblySearchResult,
  getFileTypeForFile,
  isChallengeFile,
  mapHexCodeToEmoticon,
  useUserDetails,
  withoutDefaultEventBehavior,
} from '@assembly-web/services';
import {
  Button,
  IconButton,
  OverflowText,
  useDeviceInfo,
} from '@assembly-web/ui';
import { ChevronLeftIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { MinusCircleIcon } from '@heroicons/react/24/solid';
import { AnimatePresence, motion } from 'framer-motion';
import capitalize from 'lodash/capitalize';
import { memo, useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { twJoin, twMerge } from 'tailwind-merge';

import { useGetListItems } from '../../../hooks/folder/useGetListItems';
import { useOpenChallengesPreviewer } from '../../../hooks/useOpenChallengesPreviewer';
import { useOpenFlowsPreviewer } from '../../../hooks/useOpenFlowsPreviewer';
import { useCollectionItemsMutation as useFolderItemsMutation } from '../../../modules/discover/hooks/useCollectionItemsMutation';
import { useGetCollectionItemsQuery as useGetFolderItemsQuery } from '../../../modules/discover/hooks/useGetCollectionItemsQuery';
import { trackNavAction } from '../../../modules/discover/services/analytics';
import { messages, NoResultsFound, SearchBar, SkeletonLoader } from './util';

const Suffix = {
  Added: 'Added',
  Removed: 'Removed',
} as const;

type ListItemType = FlowSearchResult | FileSearchResult;

function AddToFolderImpl({
  folderId,
  folderName,
  onBack,
}: {
  folderId: string;
  folderName: string;
  onBack: () => void;
}) {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');
  const { isMobileDevice } = useDeviceInfo();

  const [suffix, setSuffix] = useState<keyof typeof Suffix | null>(null);
  const [appendSuffixId, setAppendSuffixId] = useState<string | null>(null);

  const mutateFolder = useFolderItemsMutation();
  const { data: folderData } = useGetFolderItemsQuery(folderId);

  const lastPageRef = useRef<HTMLDivElement>(null);

  const {
    listItems,
    isDataLoading,
    isFetchingNewPage,
    noResults,
    hasNextPage,
    totalItemsLExceedLimit,
  } = useGetListItems({
    searchTerm,
    folderId,
    lastPageRef,
  });

  const handleUpdateCollection = (props: {
    entityId: string;
    type: 'file' | 'flow';
    action: 'add' | 'remove';
    flowIcon?: string;
    flowName?: string;
    responseId?: string;
    fileName?: string;
  }) => {
    const { entityId, type, action, flowIcon, flowName, responseId, fileName } =
      props;
    setSuffix(action === 'add' ? Suffix.Added : Suffix.Removed);
    setAppendSuffixId(entityId);
    setTimeout(() => {
      setSuffix(null);
      setAppendSuffixId(null);
    }, 2000);
    if (action === 'add') {
      trackNavAction('folderItemAdded', {
        folderItemType: capitalize(type),
        addItemFromSearch: Boolean(searchTerm),
        flowName: flowName,
        flowId: entityId,
        fileName,
      });
    } else {
      trackNavAction('folderItemRemoved');
    }
    mutateFolder.mutate({
      action,
      collectionId: folderId,
      entityId: entityId,
      type: 'flow',
      collectionName: folderName,
      enableSuccessToast: false,
      name: flowName,
      icon: flowIcon,
      ...(responseId ? { responseId } : {}),
    });
  };

  const isItemInFolder = useCallback(
    (entityId: string) => {
      return Boolean(
        folderData?.data.map((item: FolderItem) => item.id).includes(entityId)
      );
    },
    [folderData]
  );

  return (
    <div
      className={twMerge(
        'flex h-[290px] w-[300px] flex-col gap-y-4 rounded-2xl bg-gray-1 px-4 py-2 sm:w-[416px]',
        isMobileDevice && 'w-[300px] px-2'
      )}
    >
      <div
        className={twMerge(
          'flex flex-shrink-0 items-center',
          isMobileDevice ? 'gap-x-1' : 'gap-x-3'
        )}
      >
        {totalItemsLExceedLimit || isMobileDevice ? (
          <IconButton
            onClick={withoutDefaultEventBehavior(() => onBack())}
            variation="tertiaryLite"
            size="small"
          >
            <ChevronLeftIcon
              className={twMerge(
                'text-gray-9',
                isMobileDevice ? 'size-4' : 'size-6'
              )}
            />
          </IconButton>
        ) : (
          <Button
            onClick={withoutDefaultEventBehavior(() => onBack())}
            variation="tertiaryLite"
            size="small"
          >
            <ChevronLeftIcon className="h-6 w-6 text-gray-9" />
            {formatMessage(messages.back)}
          </Button>
        )}

        {Boolean(totalItemsLExceedLimit) && (
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        )}
      </div>
      <div className="flex flex-col gap-y-0.5 overflow-scroll">
        {!isDataLoading ? (
          noResults ? (
            <NoResultsFound searchTerm={searchTerm} />
          ) : (
            listItems?.map((item) => {
              return (
                <ListItem
                  key={item.id}
                  listItem={item as ListItemType}
                  handleUpdateFolder={handleUpdateCollection}
                  isItemInFolder={isItemInFolder(item.id)}
                  showSuffix={item.id === appendSuffixId}
                  suffix={suffix}
                  {...('icon' in item._meta && {
                    icon: item._meta.icon,
                  })}
                  {...('type' in item._meta && {
                    iconLabel: getFileTypeForFile(item._meta.type),
                  })}
                />
              );
            })
          )
        ) : (
          <SkeletonLoader />
        )}
        {Boolean(isFetchingNewPage) && <SkeletonLoader />}
        {Boolean(hasNextPage) && (
          <div ref={lastPageRef} className="p-1.5"></div>
        )}
      </div>
    </div>
  );
}

function ListItem({
  listItem,
  handleUpdateFolder,
  isItemInFolder,
  showSuffix = true,
  suffix,
  iconLabel,
  icon,
}: {
  listItem: ListItemType;
  handleUpdateFolder: (param: {
    entityId: string;
    type: 'file' | 'flow';
    action: 'add' | 'remove';
    flowIcon?: string;
    flowName?: string;
    responseId?: string;
  }) => void;
  isItemInFolder: boolean;
  showSuffix?: boolean;
  suffix: keyof typeof Suffix | null;
  iconLabel?: FileType;
  icon?: string;
}) {
  const { formatMessage } = useIntl();

  const claimId =
    '_meta' in listItem && 'claimId' in listItem._meta
      ? listItem._meta.claimId
      : undefined;

  const { openChallengeFileInPreviewer } = useOpenChallengesPreviewer({
    claimId,
    internalFileName: 'name' in listItem ? (listItem.name ?? '') : '',
  });

  const openFilePreviewer = useOpenFlowsPreviewer();
  const userDetails = useUserDetails();

  const isFile = listItem.type === 'file';

  const emoji = icon ? mapHexCodeToEmoticon(icon) : null;

  if (!('name' in listItem)) return null;

  return (
    <div key={listItem.id} className="flex items-center gap-x-1.5 p-1">
      <IconButton
        size="xSmall"
        variation="tertiaryEmphasized"
        onClick={() => {
          handleUpdateFolder({
            entityId: listItem.id,
            type: listItem.type,
            action: isItemInFolder ? 'remove' : 'add',
            flowIcon: emoji ?? '',
            flowName: listItem.name,
            responseId:
              'responseId' in listItem._meta ? listItem._meta.responseId : '',
          });
        }}
        className="flex-shrink-0"
      >
        {isItemInFolder ? (
          <MinusCircleIcon className="h-5 w-5" />
        ) : (
          <PlusCircleIcon className="h-5 w-5" />
        )}
      </IconButton>
      <div className="flex-shrink-0 self-center">
        {!isFile ? (
          emoji
        ) : iconLabel ? (
          <img src={fileIcons[iconLabel]} className="h-5 w-5" alt="" />
        ) : null}
      </div>
      <OverflowText
        variant="base-regular"
        className={twJoin('truncate text-gray-9', isFile && 'cursor-pointer')}
        {...(isFile && {
          onClick: () => {
            if (isChallengeFile(listItem)) {
              openChallengeFileInPreviewer();
            } else {
              const {
                flowId,
                fileType,
                responseId,
                blockId,
                creatorId: memberID,
                creatorImage: memberImage,
                creatorName: memberName,
                originalFileName: fileName,
                uploadedAt: dateShared,
                flowName: sharedIn,
              } = formatAssemblySearchResult(listItem);

              openFilePreviewer({
                flowId,
                fileType,
                responseId,
                blockId,
                workspaceSlug: userDetails.data?.assembly.workspaceSlugPath,
                fileName,
                memberID,
                memberName,
                memberImage,
                dateShared,
                sharedIn,
              });
            }
          },
        })}
      >
        {listItem.name}
      </OverflowText>
      <AnimatePresence>
        {Boolean(showSuffix) && (
          <motion.div
            initial={{ opacity: 0, x: '100%' }}
            animate={{
              opacity: 1,
              x: 0,
              transition: { duration: 0.5, delay: 0.3 },
            }}
            exit={{
              opacity: 0,
              x: 0,
              transition: { duration: 1, delay: 2 },
            }}
            className="flex-shrink-0 text-sm font-normal text-gray-8"
          >
            {formatMessage(
              suffix === Suffix.Added ? messages.added : messages.removed
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export const AddToFolder = memo(AddToFolderImpl);
