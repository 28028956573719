import type { Nullable } from '@assembly-web/services';
import { autoUpdate, flip, offset, shift, size } from '@floating-ui/dom';
import { FloatingPortal, useFloating } from '@floating-ui/react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import type {
  MenuOption,
  MenuTextMatch,
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { useBasicTypeaheadTriggerMatch } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { mergeRegister } from '@lexical/utils';
import { Slot, type SlotProps } from '@radix-ui/react-slot';
import type { DecoratorNode } from 'lexical';
import {
  $createRangeSelection,
  $createTextNode,
  $getSelection,
  $isTextNode,
  $setSelection,
  BLUR_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_EDITOR,
  KEY_SPACE_COMMAND,
  KEY_TAB_COMMAND,
  TextNode,
} from 'lexical';
import {
  createContext,
  forwardRef,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { useRefContainer } from '../../../../../context/RefContext';
import { $getComboboxChipNodes } from '../utils';
import { ComboboxTypeaheadMenuPlugin } from './ComboboxTypeaheadMenuPlugin';

const PUNCTUATION =
  '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';
const NAME = '\\b[A-Z][^\\s' + PUNCTUATION + ']';

const DocumentMentionsRegex = {
  NAME,
  PUNCTUATION,
};

const PUNC = DocumentMentionsRegex.PUNCTUATION;

const TRIGGERS = ['\\w'].join('');

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = '[^' + PUNC + '\\s]';

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  '(?:' +
  '\\.[ |$]|' + // E.g. "r. " in "Mr. Smith"
  ' |' + // E.g. " " in "Josh Duck"
  '[' +
  PUNC +
  ']|' + // E.g. "-' in "Salier-Hellendag"
  ')';

const LENGTH_LIMIT = 75;

const AtSignMentionsRegex = new RegExp(
  '(^|\\s|\\()(' +
    '[' +
    TRIGGERS +
    ']' +
    '((?:' +
    VALID_CHARS +
    VALID_JOINS +
    '){0,' +
    LENGTH_LIMIT +
    '})' +
    ')$'
);

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;

// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp(
  '(^|\\s|\\()(' +
    '[' +
    TRIGGERS +
    ']' +
    '((?:' +
    VALID_CHARS +
    '){0,' +
    ALIAS_LENGTH_LIMIT +
    '})' +
    ')$'
);

function checkForAtSignMentions(
  text: string,
  minMatchLength: number
): MenuTextMatch | null {
  let match = AtSignMentionsRegex.exec(text);

  if (match === null) {
    match = AtSignMentionsRegexAliasRegex.exec(text);
  }
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
    }
  }
  return null;
}

function getPossibleQueryMatch(text: string): MenuTextMatch | null {
  return checkForAtSignMentions(text, 0);
}

function ComboboxTypeaheadMenuItem<TOptionClass extends MenuOption>({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
  renderOption,
}: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: TOptionClass;
  renderOption: (props: {
    option: TOptionClass;
    isSelected: boolean;
  }) => ReactNode;
}) {
  return (
    <div
      role="option"
      key={option.key}
      tabIndex={-1}
      ref={option.setRefElement}
      aria-selected={isSelected}
      id={'typeahead-item-' + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClick();
        }
      }}
      data-state={isSelected ? 'checked' : 'unchecked'}
    >
      {renderOption({ option, isSelected })}
    </div>
  );
}

export type ComboboxPluginProps<
  TOptionClass extends MenuOption,
  TNode extends DecoratorNode<ReactNode>,
> = {
  $createChipNode: (data: TOptionClass) => TNode;
  children: (args: {
    selectOptionAndCleanUp: (option: TOptionClass) => void;
    setHighlightedIndex: (index: number) => void;
    selectedIndex: number | null;
    options: TOptionClass[];
    isSelected: (option: TOptionClass) => boolean;
  }) => ReactNode;
  getChipKey: (node: TNode) => string;
  getKey: (option: TOptionClass) => string;
  onSelectOption?: (
    selectedOption: TOptionClass,
    nodeToReplace: TextNode | null
  ) => boolean;
  onSearchQueryChange: (query: Nullable<string>) => void;
  onSelectedNodesChange: (nodes: TNode[]) => void;
  options: TOptionClass[];
  type?: 'single' | 'multiple';
  clearOnSpace?: boolean;
};

export function ComboboxPlugin<
  TOptionClass extends MenuOption,
  TNode extends DecoratorNode<ReactNode>,
>({
  $createChipNode,
  children,
  clearOnSpace = false,
  type = 'single',
  getChipKey,
  getKey,
  options,
  onSearchQueryChange,
  onSelectedNodesChange,
  onSelectOption: onSelectOptionOverride,
}: ComboboxPluginProps<TOptionClass, TNode>) {
  const [editor] = useLexicalComposerContext();

  const [selectedChips, setSelectedChip] = useState<TNode[]>([]);

  const { x, y, refs, strategy } = useFloating({
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    middleware: [offset(8), shift(), flip(), size()],
    strategy: 'fixed',
  });
  const bodyContainer = useRefContainer();

  const getChipKeyRef = useRef(getChipKey);
  const getKeyRef = useRef(getKey);
  const onSelectedNodesChangeRef = useRef(onSelectedNodesChange);
  const onSelectOptionOverrideRef = useRef(onSelectOptionOverride);

  useEffect(() => {
    getChipKeyRef.current = getChipKey;
  }, [getChipKey]);

  useEffect(() => {
    getKeyRef.current = getKey;
  }, [getKey]);

  useEffect(() => {
    onSelectedNodesChangeRef.current = onSelectedNodesChange;
  }, [onSelectedNodesChange]);

  useEffect(() => {
    onSelectOptionOverrideRef.current = onSelectOptionOverride;
  }, [onSelectOptionOverride]);

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0,
  });

  useEffect(() => {
    return editor.registerUpdateListener(() => {
      editor.read(() => {
        const chipNodes = $getComboboxChipNodes<TNode>();
        setSelectedChip(chipNodes);
        onSelectedNodesChangeRef.current(chipNodes);
      });
    });
  }, [editor, onSelectedNodesChange]);

  const selectedChipSet = useMemo(
    () => new Set(selectedChips.map((chip) => getChipKeyRef.current(chip))),
    [selectedChips]
  );

  const onSelectOption = useCallback(
    (
      selectedOption: TOptionClass,
      nodeToReplace: TextNode | null,
      close: () => void
    ) => {
      const handled =
        onSelectOptionOverrideRef.current?.(selectedOption, nodeToReplace) ??
        false;

      if (handled) {
        return;
      }

      editor.update(() => {
        function insertSpacerAtEnd(chipNode: TNode) {
          const spaceNode = $createTextNode(' ');
          chipNode.getParent()?.append(spaceNode);

          const selection = $createRangeSelection();
          selection.anchor.set(spaceNode.getKey(), 1, 'text');
          selection.focus.set(spaceNode.getKey(), 1, 'text');
          $setSelection(selection);
        }

        if (type === 'single') {
          if (selectedChips.length > 0) {
            const selectedChipNode = selectedChips[0];
            selectedChipNode.remove();
          }
          const chipNode = $createChipNode(selectedOption);
          if (nodeToReplace) {
            nodeToReplace.replace(chipNode);
          } else {
            const selection = $getSelection();
            selection?.insertNodes([chipNode]);
          }
          insertSpacerAtEnd(chipNode);
          setTimeout(() => {
            close();
            editor.blur();
          }, 0);
          return;
        }

        if (selectedChipSet.has(getKeyRef.current(selectedOption))) {
          const selectedChipNode = selectedChips.find(
            (chip) =>
              getChipKeyRef.current(chip) === getKeyRef.current(selectedOption)
          );
          if (selectedChipNode) {
            const nextSibling = selectedChipNode.getNextSibling();
            if (
              nextSibling instanceof TextNode &&
              nextSibling.getTextContent() === ' '
            ) {
              nextSibling.remove();
            }
            const spaceNode = $createTextNode(' ');
            selectedChipNode.getParent()?.append(spaceNode);

            selectedChipNode.remove();
          }
          nodeToReplace?.remove();
          return;
        }
        const chipNode = $createChipNode(selectedOption);
        if (nodeToReplace) {
          nodeToReplace.replace(chipNode);
        } else {
          const selection = $getSelection();
          selection?.insertNodes([chipNode]);
        }

        insertSpacerAtEnd(chipNode);
      });
    },
    [$createChipNode, editor, selectedChipSet, selectedChips, type]
  );

  const checkForMentionMatch = useCallback(
    (text: string) => {
      const slashMatch = checkForSlashTriggerMatch(text, editor);
      if (slashMatch !== null) {
        return null;
      }
      return getPossibleQueryMatch(text);
    },
    [checkForSlashTriggerMatch, editor]
  );

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        BLUR_COMMAND,
        (e, editor) => {
          if (e.relatedTarget instanceof HTMLElement) {
            if (e.relatedTarget.closest('.mentions-menu')) {
              e.preventDefault();
              return true;
            }
          }
          editor.update(() => {
            const nodes = $getSelection()?.getNodes();
            if (!nodes) {
              return;
            }
            for (const node of nodes) {
              if ($isTextNode(node)) {
                const text = node.getTextContent();

                if (text.length <= 1) {
                  continue;
                }

                if (text.startsWith(' ')) {
                  const spacerNode = $createTextNode(' ');
                  node.getParent()?.append(spacerNode);

                  const selection = $createRangeSelection();
                  selection.anchor.set(spacerNode.getKey(), 1, 'text');
                  selection.focus.set(spacerNode.getKey(), 1, 'text');
                  $setSelection(selection);
                }
                node.remove();
              }
            }
            onSearchQueryChange(null);

            setTimeout(() => {
              editor.blur();
            }, 0);
          });
          return false;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        KEY_SPACE_COMMAND,
        (e, editor) => {
          let handled = false;
          if (!clearOnSpace) {
            return handled;
          }
          editor.update(() => {
            const nodes = $getSelection()?.getNodes();
            if (!nodes) {
              return;
            }
            for (const node of nodes) {
              if ($isTextNode(node)) {
                if (node.getTextContent().startsWith(' ')) {
                  const spacerNode = $createTextNode(' ');
                  node.getParent()?.append(spacerNode);
                  const selection = $createRangeSelection();
                  selection.anchor.set(spacerNode.getKey(), 1, 'text');
                  selection.focus.set(spacerNode.getKey(), 1, 'text');
                  $setSelection(selection);
                }
                node.remove();
              }
            }
            e.preventDefault();
            handled = true;
          });
          return handled;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        KEY_TAB_COMMAND,
        () => {
          editor.blur();
          return true;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [clearOnSpace, editor, onSearchQueryChange]);

  return (
    <ComboboxTypeaheadMenuPlugin<TOptionClass>
      onQueryChange={onSearchQueryChange}
      onSelectOption={onSelectOption}
      triggerFn={checkForMentionMatch}
      options={options}
      commandPriority={COMMAND_PRIORITY_CRITICAL}
      onOpen={(menuResolution) => {
        refs.setPositionReference({
          getBoundingClientRect: menuResolution.getRect,
        });
      }}
      menuRenderFn={(anchorElementRef, args) =>
        anchorElementRef.current ? (
          <FloatingPortal root={bodyContainer}>
            <div
              ref={refs.setFloating}
              style={{
                top: y,
                left: x,
                position: strategy,
                width: refs.reference.current?.getBoundingClientRect().width,
                height: '45%',
              }}
            >
              {children({
                ...args,
                isSelected(option) {
                  return selectedChipSet.has(getKeyRef.current(option));
                },
              })}
            </div>
          </FloatingPortal>
        ) : null
      }
    />
  );
}

ComboboxPlugin.Root = forwardRef<
  HTMLElement,
  SlotProps & {
    asChild?: boolean;
  }
>(function Root({ asChild, className, children, ...rest }, ref) {
  const Comp = asChild ? Slot : 'div';

  return (
    <Comp
      {...rest}
      ref={ref as never}
      className={twMerge(
        'w-full rounded-lg bg-gray-1 p-4 pb-0 shadow-lg-down ring-1 ring-gray-10 ring-opacity-5',
        className
      )}
    >
      {children}
    </Comp>
  );
});

ComboboxPlugin.List = forwardRef<
  HTMLElement,
  SlotProps & {
    asChild?: boolean;
  }
>(function Listbox({ asChild, className, children, ...rest }, ref) {
  const Comp = asChild ? Slot : 'div';

  return (
    <Comp
      {...rest}
      ref={ref as never}
      className={twMerge('mentions-menu flex flex-col gap-2 pb-4', className)}
      role="list"
    >
      {children}
    </Comp>
  );
});

ComboboxPlugin.ListItem = ComboboxTypeaheadMenuItem;

const GroupLabelContext = createContext<string | null>(null);
const useGroupLabelContext = () => {
  const context = useContext(GroupLabelContext);
  if (context === null) {
    throw new Error('GroupLabel must be used within a Group');
  }
  return context;
};
ComboboxPlugin.Group = forwardRef<
  HTMLElement,
  SlotProps & {
    asChild?: boolean;
  }
>(function Group({ asChild, className, children, ...rest }, ref) {
  const Comp = asChild ? Slot : 'div';
  const id = useId();

  return (
    <GroupLabelContext.Provider value={id}>
      <Comp
        {...rest}
        role="group"
        ref={ref as never}
        className={twMerge('flex flex-col gap-2', className)}
        aria-labelledby={id}
      >
        {children}
      </Comp>
    </GroupLabelContext.Provider>
  );
});

ComboboxPlugin.GroupLabel = forwardRef<
  HTMLElement,
  Omit<SlotProps, 'id'> & {
    asChild?: boolean;
  }
>(function GroupLabel({ asChild, className, children, ...rest }, ref) {
  const Comp = asChild ? Slot : 'div';
  const id = useGroupLabelContext();

  return (
    <Comp
      {...rest}
      ref={ref as never}
      className={twMerge('text-sm font-medium text-gray-9', className)}
      id={id}
    >
      {children}
    </Comp>
  );
});

ComboboxPlugin.Loader = function Loader({
  asChild,
  ...rest
}: SlotProps & {
  asChild?: boolean;
}) {
  const Comp = asChild ? Slot : 'div';
  return (
    <Comp role="option" {...rest}>
      <span className="sr-only">
        <FormattedMessage defaultMessage="Loading new items" id="CdqY7+" />
      </span>
      <div className="flex w-full items-center !justify-start gap-2 px-3 py-2">
        <div className="h-6 w-6 animate-pulse rounded-full bg-gray-5" />
        <div className="h-6 w-48 animate-pulse rounded-md bg-gray-5" />
      </div>
    </Comp>
  );
};

ComboboxPlugin.$generateInitialState = function (chipNodes: unknown[]) {
  return chipNodes.length > 0
    ? JSON.stringify({
        root: {
          children: [
            {
              children: [
                ...chipNodes,
                {
                  detail: 0,
                  format: 0,
                  mode: 'normal',
                  style: '',
                  text: ' ',
                  type: 'text',
                  version: 1,
                },
              ],
              direction: null,
              format: '',
              indent: 0,
              type: 'paragraph',
              version: 1,
              textFormat: 0,
              textStyle: '',
            },
          ],
          direction: null,
          format: '',
          indent: 0,
          type: 'root',
          version: 1,
        },
      })
    : undefined;
};
