import { PlanStatus, useSuspenseUserDetails } from '@assembly-web/services';

import { useGetPlanFeatures } from '../modules/discover/hooks/useGetPlanFeaturesQuery';

export function useIsDynamicTranslationsAvailable() {
  const { data: currentPlan } = useGetPlanFeatures();

  const {
    data: { member },
  } = useSuspenseUserDetails();

  return (
    currentPlan.features.some(
      (feature) =>
        feature.id === 'AI_ASSISTED_TRANSLATIONS' &&
        feature.status === PlanStatus.Enabled
    ) && member.profile.language === 'es'
  );
}
