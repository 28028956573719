import type { CacheDraftFile } from './types';

export const getDraftFileFormat = (file: CacheDraftFile) => {
  return {
    original: {
      location: file.location,
      name: file.name,
      size: file.size,
    },
    location: file.url,
    name: file.name,
    size: file.size,
    originalName: file.name,
    createdAt: new Date().toISOString(),
    type: file.type,
    _id: crypto.randomUUID(),
    thumbnails: {},
  };
};
