import {
  type FileDetails,
  logger,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import {
  OpenEndedBlock,
  OpenEndedContainer,
  useOpenEndedFileUpload,
  useOpenEndedValidation,
} from '@assembly-web/ui';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { defineMessage, useIntl } from 'react-intl';

const placeholder = defineMessage({
  defaultMessage: 'Write your recognition message here',
  id: 'EjFXHr',
});

function Editor() {
  const { control } = useFormContext();
  const { formatMessage } = useIntl();

  const { setValue } = useFormContext();

  const [draftFiles, setDraftFiles] = useState<FileDetails[]>(
    control._defaultValues.attachments ?? []
  );

  const userDetails = useSuspenseUserDetails();

  const { fileUploadOptions, fileAttachment } = useOpenEndedFileUpload({
    key: 'recognition-participation',
    draftFiles,
  });

  const { validate } = useOpenEndedValidation({
    assemblyDetails: userDetails.data.assembly,
  });

  const { files, removeFile } = fileUploadOptions;

  useEffect(() => {
    setValue('attachments', [
      ...draftFiles.map((draftFile) => {
        return {
          ...draftFile.original,
          url: draftFile.location,
          type: draftFile.type,
        };
      }),
      ...files.map((file) => {
        return {
          location: file.meta.location,
          name: file.meta.name,
          size: file.size,
          type: file.meta.type,
          uploadComplete: file.progress.uploadComplete,
          url: file.response?.uploadURL,
        };
      }),
    ]);
  }, [draftFiles, files, setValue]);

  function handleRemoveFile(fileId: string) {
    if (files.find((file) => file.id === fileId)) {
      removeFile(fileId);
    } else {
      setDraftFiles((draftFiles) =>
        draftFiles.filter((draftFile) => draftFile.name !== fileId)
      );
    }
  }

  return (
    <Controller
      control={control}
      name="message"
      defaultValue={{ json: control._defaultValues.message?.json }}
      rules={{
        validate,
      }}
      render={({
        field: { value, onChange, disabled },
        fieldState: { error },
      }) => (
        <OpenEndedBlock
          fileAttachment={fileAttachment}
          placeholder={formatMessage(placeholder)}
          files={files}
          draftFiles={draftFiles}
          draftState={value.json}
          onChange={onChange}
          error={error}
          disabled={disabled}
          handleRemoveFile={handleRemoveFile}
          onError={(error, editor) => {
            logger.error(
              'Participation Open ended Editor Error',
              {
                editor,
              },
              error
            );
          }}
        />
      )}
    />
  );
}

export function OpenEnded() {
  return (
    <OpenEndedContainer>
      <Editor />
    </OpenEndedContainer>
  );
}
