import {
  canGivePrivateRecognition,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import { VisibilitySelectorBlock } from '@assembly-web/ui';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';

const labels = defineMessages({
  privateToolTip: {
    id: 'hed4Wz',
    defaultMessage:
      'Your post will be visible to the recipient(s), approver(s), and admins.',
  },
});

export function VisibilitySelector() {
  const { data: userDetails } = useSuspenseUserDetails();
  const { formatMessage } = useIntl();
  const { control } = useFormContext();

  const isPrivateRecognitionAllowed = useMemo(() => {
    return canGivePrivateRecognition(userDetails);
  }, [userDetails]);

  return (
    <Controller
      control={control}
      name="isPrivate"
      render={({ field: { onChange, value } }) => (
        <VisibilitySelectorBlock
          isPrivatePostAllowed={isPrivateRecognitionAllowed}
          privateToolTip={formatMessage(labels.privateToolTip)}
          onValueChange={onChange}
          value={value}
        />
      )}
    />
  );
}
