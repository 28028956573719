import {
  isTruthy,
  SplitNames,
  useFeatureSplit,
  userAuthStore,
  useSuspenseUserDetails,
  withoutDefaultEventBehavior,
} from '@assembly-web/services';
import {
  GlobalSearchInput,
  Greeting,
  IconButton,
  useTimeOfDay,
} from '@assembly-web/ui';
import {
  Bars3Icon,
  HomeIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import debounce from 'lodash/debounce';
import {
  type ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { twJoin, twMerge } from 'tailwind-merge';
import { Drawer as MobileDrawer } from 'vaul';

import { usePageMatcher } from '../../hooks/usePageMatcher';
import { useMobileMessageBridge } from '../../modules/discover/hooks/useMobileMessageBridge';
import { NavHeader } from '../Nav/NavHeader';
import { UserMenu } from '../Nav/UserMenu';

const messages = defineMessages({
  clearSearch: {
    defaultMessage: 'Clear search',
    id: '4YJHut',
  },
  clearSearchButton: {
    defaultMessage: 'Clear',
    id: '/GCoTA',
  },
});

export function MobileAppHeader() {
  const { isMobileAppV3: isNativeMobileApp } = userAuthStore.getState();

  const [showSearchInput, setShowSearchInput] = useState(isNativeMobileApp);
  const { formatMessage } = useIntl();

  const {
    redirectToHomePage,
    isRewardsPage,
    isChallengesPage,
    isNotificationsPage,
    isMSTeamsTestAccount,
    searchTerm,
    onSearchClearClick,
    onSearchInputChange,
    onSearchInputClick,
  } = useAppHeader();

  const searchInputRef = useRef<HTMLInputElement>(null);
  useMobileMessageBridge((data) => {
    if (data.type === 'focusSearchInput') {
      searchInputRef.current?.focus();
    }
  });

  if (
    isNativeMobileApp &&
    (isRewardsPage || isChallengesPage || isNotificationsPage)
  ) {
    // We don't return null here to account for the native header styling
    return (
      <div className="sticky top-0 z-10 flex h-1 w-full items-center bg-gray-1 p-2" />
    );
  }

  return (
    <header
      className={twMerge(
        'sticky top-0 z-10 flex h-14 w-full items-center bg-gray-1 p-2',
        isNativeMobileApp ? 'justify-end' : 'justify-between'
      )}
    >
      <AnimatePresence>
        {isTruthy(showSearchInput) ? (
          <Fragment key="searchView">
            <GlobalSearchInput
              hideClearButton
              ref={searchInputRef}
              searchTerm={searchTerm}
              className="min-w-[310px]"
              onClick={onSearchInputClick}
              onChange={onSearchInputChange}
              onClearClick={onSearchClearClick}
            />
            <button
              {...(!isNativeMobileApp
                ? { 'aria-label': formatMessage(messages.clearSearch) }
                : {})}
              type="button"
              className={twMerge(
                'z-[1] flex h-full cursor-pointer items-center justify-center p-2',
                !searchTerm && isNativeMobileApp && 'hidden'
              )}
              onClick={withoutDefaultEventBehavior(function () {
                if (!isNativeMobileApp) {
                  setShowSearchInput(false);
                }
                onSearchClearClick();
              })}
              data-testid="clearSearch"
            >
              {isNativeMobileApp ? (
                formatMessage(messages.clearSearchButton)
              ) : (
                <XMarkIcon className="size-6 text-gray-9" />
              )}
            </button>
          </Fragment>
        ) : (
          <Fragment key="defaultView">
            {!isNativeMobileApp && <NavHeader />}
            <motion.div className="flex items-center justify-center gap-3">
              <button
                className="rounded-lg px-1"
                onClick={() => {
                  setShowSearchInput(true);
                  setTimeout(() => searchInputRef.current?.focus(), 100);
                }}
              >
                <MagnifyingGlassIcon className="size-6 text-gray-9" />
              </button>

              {isMSTeamsTestAccount ? (
                <>
                  <IconButton
                    variation="tertiaryEmphasized"
                    onClick={redirectToHomePage}
                  >
                    <HomeIcon className="size-6 bg-transparent text-gray-9" />
                  </IconButton>
                  <UserMenu />
                </>
              ) : isNativeMobileApp ? null : (
                <MobileDrawer.Trigger>
                  <button className="flex justify-center rounded-lg px-1">
                    <Bars3Icon className="size-6 text-gray-9" />
                  </button>
                </MobileDrawer.Trigger>
              )}
            </motion.div>
          </Fragment>
        )}
      </AnimatePresence>
    </header>
  );
}

function useAppHeader() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchTerm, setSearchTerm] = useState(
    searchParams.get('search') || ''
  );

  const { isTreatmentActive: isMSTeamsTestAccount } = useFeatureSplit(
    SplitNames.MSTeamsAppSubmission
  );

  const { isRewardsPage, isChallengesPage, isNotificationsPage } =
    usePageMatcher();

  const debouncedUpdateRef = useRef<ReturnType<typeof debounce>>();

  useEffect(() => {
    debouncedUpdateRef.current = debounce((value: string) => {
      setSearchParams((state) => {
        const newState = new URLSearchParams(state);
        if (value) {
          newState.set('search', value);
        } else {
          newState.delete('search');
        }
        return newState;
      });
    }, 300);

    return () => debouncedUpdateRef.current?.cancel();
  }, [setSearchParams]);

  useEffect(() => {
    debouncedUpdateRef.current = debounce((value: string) => {
      setSearchParams((state) => {
        const newState = new URLSearchParams(state);
        if (value) {
          newState.set('search', value);
        } else {
          newState.delete('search');
        }
        return newState;
      });
    }, 300);

    return () => debouncedUpdateRef.current?.cancel();
  }, [setSearchParams]);

  const handleSearchUpdate = useCallback(
    (value: string) => debouncedUpdateRef.current?.(value),
    []
  );

  const onSearchInputClick = () => {
    if (isRewardsPage) {
      const $rewardsSearchInput =
        document.querySelector<HTMLInputElement>('#search-rewards');

      $rewardsSearchInput?.focus();
      $rewardsSearchInput?.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    }
  };

  const onSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    handleSearchUpdate(e.target.value);
  };

  const onSearchClearClick = () => {
    setSearchTerm('');
    handleSearchUpdate('');
  };

  const redirectToHomePage = () => navigate('/a');

  return {
    redirectToHomePage,
    isMSTeamsTestAccount,
    searchTerm,
    setSearchTerm,
    isRewardsPage,
    isChallengesPage,
    isNotificationsPage,
    handleSearchUpdate,
    onSearchClearClick,
    onSearchInputChange,
    onSearchInputClick,
  };
}

export function DesktopAppHeader() {
  const { data: userDetails } = useSuspenseUserDetails();
  const timeOfDay = useTimeOfDay(dayjs().hour());

  const {
    redirectToHomePage,
    isRewardsPage,
    isMSTeamsTestAccount,
    searchTerm,
    onSearchClearClick,
    onSearchInputChange,
    onSearchInputClick,
  } = useAppHeader();

  return (
    <header
      className={twJoin(
        'fixed top-0 z-10 flex h-[88px] items-center justify-between border-b-[0.75px] border-gray-5 bg-gray-1 px-6 py-3',
        timeOfDay === 'morning'
          ? 'header-bg-morning-gradient'
          : timeOfDay === 'afternoon'
            ? 'header-bg-afternoon-gradient'
            : 'header-bg-evening-gradient',
        isMSTeamsTestAccount ? 'w-full' : 'w-[calc(100%-300px)]'
      )}
    >
      <section>
        <Greeting
          firstName={userDetails.member.profile.firstName}
          hour={dayjs().hour()}
        />
      </section>
      <section className="flex flex-row items-center gap-1">
        <GlobalSearchInput
          searchTerm={searchTerm}
          className={twMerge('w-[310px]', !isRewardsPage && 'focus:w-[600px]')}
          onClick={onSearchInputClick}
          onChange={onSearchInputChange}
          onClearClick={onSearchClearClick}
        />
        {isTruthy(isMSTeamsTestAccount) && (
          <IconButton
            variation="tertiaryEmphasized"
            onClick={redirectToHomePage}
          >
            <HomeIcon className="size-6 bg-transparent text-gray-9" />
          </IconButton>
        )}
        <UserMenu />
      </section>
    </header>
  );
}

export function TabletAppHeader() {
  const [showSearchInput, setShowSearchInput] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const { formatMessage } = useIntl();

  const { data: userDetails } = useSuspenseUserDetails();
  const timeOfDay = useTimeOfDay(dayjs().hour());

  const {
    isMSTeamsTestAccount,
    searchTerm,
    onSearchClearClick,
    onSearchInputChange,
    onSearchInputClick,
    redirectToHomePage,
  } = useAppHeader();

  return (
    <header
      className={twJoin(
        'fixed top-0 z-10 flex h-[88px] items-center justify-between border-b-[0.75px] border-gray-5 bg-gray-1 px-6 py-3',
        timeOfDay === 'morning'
          ? 'header-bg-morning-gradient'
          : timeOfDay === 'afternoon'
            ? 'header-bg-afternoon-gradient'
            : 'header-bg-evening-gradient',
        isMSTeamsTestAccount ? 'w-full' : 'w-[calc(100%-222px)]'
      )}
    >
      {!showSearchInput && (
        <section>
          <Greeting
            firstName={userDetails.member.profile.firstName}
            hour={dayjs().hour()}
          />
        </section>
      )}

      <section
        className={twMerge(
          'flex flex-row items-center gap-1',
          showSearchInput && 'w-full'
        )}
      >
        <AnimatePresence>
          {showSearchInput ? (
            <>
              <GlobalSearchInput
                ref={searchInputRef}
                searchTerm={searchTerm}
                className="w-full"
                onClick={onSearchInputClick}
                onChange={onSearchInputChange}
                onClearClick={onSearchClearClick}
              />
              <button
                aria-label={formatMessage(messages.clearSearch)}
                type="button"
                className="z-[1] flex h-full cursor-pointer items-center justify-center p-2"
                onClick={withoutDefaultEventBehavior(function () {
                  setShowSearchInput(false);
                  onSearchClearClick();
                })}
                data-testid="clearSearch"
              >
                <XMarkIcon className="size-6 text-gray-9" />
              </button>
            </>
          ) : (
            <button
              className="rounded-lg px-1"
              onClick={() => {
                setShowSearchInput(true);
                setTimeout(() => searchInputRef.current?.focus(), 100);
              }}
            >
              <MagnifyingGlassIcon className="size-6 text-gray-9" />
            </button>
          )}
        </AnimatePresence>
        {isTruthy(isMSTeamsTestAccount) && (
          <IconButton
            variation="tertiaryEmphasized"
            onClick={redirectToHomePage}
          >
            <HomeIcon className="size-6 !bg-transparent text-gray-9" />
          </IconButton>
        )}
        <UserMenu />
      </section>
    </header>
  );
}
