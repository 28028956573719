import {
  useMutation,
  type UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { useCallback } from 'react';

import { APIEndpoints } from '../../APIEndpoints';
import { assemblyAPI } from '../../assemblyAPI';
import type { SaveDraftPayload } from '../../types/draft';
import type { RecognitionDraft } from '../../types/recognitionParticipation';

export type DraftPayload = {
  payload: SaveDraftPayload;
  queryCacheToUpdate?: string[];
};

export const useUpdateRecognitionDraftCache = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (queryKey: string[], draft: RecognitionDraft) => {
      const isDraftAvailable = Object.keys(draft).length > 0;
      let response;
      if (isDraftAvailable) {
        response = {
          draft: { postData: draft },
        };
      } else {
        response = {
          draft: {},
        };
      }
      queryClient.setQueryData(queryKey, response);
    },
    [queryClient]
  );
};

export function useSaveDrafts(
  options?: UseMutationOptions<unknown, unknown, DraftPayload>
) {
  const updateCache = useUpdateRecognitionDraftCache();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ payload }: DraftPayload) => {
      const response = await assemblyAPI.post(
        APIEndpoints.saveDrafts,
        payload.toServer
      );
      return response.data;
    },
    onMutate: (context) => {
      if (context.queryCacheToUpdate) {
        const queryKey = context.queryCacheToUpdate;
        const draftData = context.payload.toCache.postData;
        updateCache(queryKey, draftData);
      }

      options?.onMutate?.(context);
    },
    onSuccess: (data, context) => {
      if (context.queryCacheToUpdate) {
        queryClient.setQueryData(context.queryCacheToUpdate, data);
      }
    },
  });
}
