import {
  trackParticipationAction,
  useSuspenseUserDetails,
  type WorkspaceSettings,
} from '@assembly-web/services';
import { CoreValueContainer, CoreValueSelector } from '@assembly-web/ui';
import { Controller, useFormContext } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';

import { useParticipationModalContext } from '../../Provider';

const messages = defineMessages({
  label: {
    defaultMessage: 'Core value',
    id: '+hmbqD',
  },
  requiredValidation: {
    defaultMessage: 'You are required to select a core value.',
    id: '+cid17',
  },
});

function Selector({
  coreValues,
}: {
  coreValues: WorkspaceSettings['coreValues'];
}) {
  const { formatMessage } = useIntl();
  const { control, getValues } = useFormContext();
  const { formState } = useParticipationModalContext();
  const isCreateFlow = Boolean(!formState?.postId);

  return (
    <Controller
      name="coreValue"
      control={control}
      rules={{
        validate: {
          required: (value?: string) => {
            if (coreValues.isRequired && (!value || value.length === 0)) {
              return formatMessage(messages.requiredValidation);
            }
            return true;
          },
        },
      }}
      render={({ field: { onBlur, onChange }, fieldState: { error } }) => (
        <CoreValueSelector
          label={formatMessage(messages.label)}
          onBlur={() => {
            onBlur();
            if (isCreateFlow && getValues().coreValue?.length > 0) {
              trackParticipationAction('coreValueSelected', {
                postType: 'recognition',
              });
            }
          }}
          onChange={onChange}
          error={error}
          coreValues={coreValues}
          defaultValue={control._defaultValues.coreValue}
        />
      )}
    />
  );
}

export function CoreValue() {
  const {
    data: {
      assembly: {
        settings: { coreValues },
      },
    },
  } = useSuspenseUserDetails();

  if (!coreValues.enabled) {
    return null;
  }

  return (
    <CoreValueContainer>
      <Selector coreValues={coreValues} />
    </CoreValueContainer>
  );
}
