import { TextStyle } from '@assembly-web/ui';
import { Suspense } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useAwardDetail } from './Awards';
import { useGetAward } from './GiveAward/hooks/useGetAward';

const messages = defineMessages({
  title: {
    defaultMessage: 'Give award',
    id: 'tXj5MO',
  },
});

function TitleWithAwardName() {
  const { data } = useGetAward();

  return data.name;
}

function DefaultTitle() {
  const { formatMessage } = useIntl();

  return formatMessage(messages.title);
}

export function Title() {
  const awardDetail = useAwardDetail();

  return (
    <span className="inline-flex w-full items-baseline gap-4">
      <TextStyle
        variant="xl-bold"
        as="span"
        className="w-[439px] text-wrap text-gray-9"
      >
        {awardDetail?.id ? (
          <Suspense fallback={<DefaultTitle />}>
            <TitleWithAwardName />
          </Suspense>
        ) : (
          <DefaultTitle />
        )}
      </TextStyle>
    </span>
  );
}
