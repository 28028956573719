import { SVGReactComponents } from '@assembly-web/assets';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import * as Tabs from '@radix-ui/react-tabs';
import { type ReactNode, useContext, useEffect, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../../DesignSystem/Inputs/Button';
import { StepperContext } from './StepperContext';

const messages = defineMessages({
  step: {
    defaultMessage: 'Step {step}: {title}',
    id: 'rXVfDw',
  },
  back: {
    defaultMessage: 'Back',
    id: 'cyR7Kh',
  },
  next: {
    defaultMessage: 'Next: {title}',
    id: '54CX/3',
  },
  tabTitle: {
    defaultMessage: '{index}. {title}',
    id: '3dEjl3',
  },
});

export type Step = {
  title: string;
  content: ReactNode;
  disabled?: boolean;
};

export function StepperHeader() {
  const { formatMessage } = useIntl();
  const { steps, activeStepIndex } = useContext(StepperContext);

  return (
    <Tabs.List className="flex w-full gap-x-4 py-2 text-gray-9">
      {steps.map(({ title }, index) => {
        return (
          <Tabs.Trigger
            key={`tab-trigger-${index}`}
            value={index.toString()}
            className="flex items-center gap-x-2"
            disabled={index > 0 && activeStepIndex < index}
          >
            <TextStyle
              variant={index === activeStepIndex ? 'sm-medium' : 'sm-regular'}
              className={activeStepIndex < index ? 'text-gray-8' : ''}
            >
              {formatMessage(messages.tabTitle, {
                index: index + 1,
                title,
              })}
            </TextStyle>
            {activeStepIndex > index ? (
              <SVGReactComponents.CheckSuccessIcon className="size-6" />
            ) : (
              <SVGReactComponents.CheckDisabledIcon className="size-6" />
            )}
          </Tabs.Trigger>
        );
      })}
    </Tabs.List>
  );
}

export function StepperContent() {
  const { formatMessage } = useIntl();
  const { steps, goToNextStep, finalStepLabel, goToPreviousStep } =
    useContext(StepperContext);

  return steps.map(({ content, disabled, title }, index) => (
    <Tabs.Content value={index.toString()} key={`tab-content-${index}`}>
      <div>
        <Step index={index} title={title} content={content} />
        <footer className="fixed bottom-0 left-0 flex w-full flex-row-reverse justify-between bg-gray-1 p-4">
          <Button
            variation="primary"
            onClick={goToNextStep}
            disabled={disabled}
            className="w-44"
          >
            {index === steps.length - 1
              ? finalStepLabel
              : formatMessage(messages.next, {
                  title: steps[index + 1].title,
                })}
          </Button>
          {index !== 0 && (
            <Button variation="tertiaryLite" onClick={goToPreviousStep}>
              <ChevronLeftIcon className="size-3" />
              {formatMessage(messages.back)}
            </Button>
          )}
        </footer>
      </div>
    </Tabs.Content>
  ));
}

function Step({
  index,
  title,
  content,
}: {
  index: number;
  title: string;
  content: ReactNode;
}) {
  const { formatMessage } = useIntl();
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    contentRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [content]);

  return (
    <div
      ref={contentRef}
      className="mb-16 flex flex-col items-center gap-y-4 px-10 text-gray-9"
    >
      <TextStyle variant="xl-bold" className="!block truncate">
        {formatMessage(messages.step, {
          step: index + 1,
          title,
        })}
      </TextStyle>
      <div className="w-full self-start px-8">{content}</div>
    </div>
  );
}
