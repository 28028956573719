import { useAwardsCatalog } from '@assembly-web/services';

import { useAwardDetail } from '../../Awards';

export const useGetAward = () => {
  const awardDetail = useAwardDetail();

  return useAwardsCatalog({
    options: {
      select({ data }) {
        const award = data.find((award) => award.awardId === awardDetail?.id);
        if (!award) {
          throw new Error('Award not found');
        }
        return award;
      },
    },
  });
};
