import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { ParticipationBlock } from '../../QuickParticipation/ParticipationBlock';

export const PointsContainer = ({
  currencyName,
  children,
}: {
  currencyName: string;
  children?: ReactNode;
}) => {
  return (
    <ParticipationBlock
      label={
        <FormattedMessage
          defaultMessage="Add {currencyName}"
          id="C/h2hp"
          values={{ currencyName }}
        />
      }
    >
      {children}
    </ParticipationBlock>
  );
};
