import { zustandLocalStorage } from '@assembly-web/services';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type ConfirmationApprovalStore = {
  notShowAgain: { approve: boolean; deny: boolean };
  setNotShowAgain: (type: 'approve' | 'deny', checked: boolean) => void;
  getNotShowAgain: () => { approve: boolean; deny: boolean };
};

export const useConfirmationApprovalStore = create<ConfirmationApprovalStore>()(
  persist(
    immer<ConfirmationApprovalStore>((set, get) => {
      return {
        notShowAgain: { approve: false, deny: false },
        setNotShowAgain: (type: 'approve' | 'deny', checked: boolean) => {
          set(() => ({
            notShowAgain: {
              ...get().notShowAgain,
              [type]: checked,
            },
          }));
        },
        getNotShowAgain: () => {
          return get().notShowAgain;
        },
      };
    }),
    {
      name: 'confirmation-approval-store',
      storage: createJSONStorage(() => zustandLocalStorage),
      version: 1,
    }
  )
);
