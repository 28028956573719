import { SVGReactComponents } from '@assembly-web/assets';
import { type ButtonHTMLAttributes, forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { IconButton } from '../../../DesignSystem/Inputs/IconButton';

export const AwardsTrigger = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement> & { onClick: () => void }
>(function AwardsTrigger({ onClick }, ref) {
  return (
    <Tooltip
      tooltipText={
        <FormattedMessage defaultMessage="Give an award" id="PgaBPh" />
      }
    >
      <IconButton
        ref={ref}
        onClick={onClick}
        variation="tertiaryLite"
        className="border-none bg-primary-1 hover:bg-primary-2 focus:bg-primary-2"
      >
        <SVGReactComponents.ActionBarAwardIcon className="size-6 text-primary-6" />
      </IconButton>
    </Tooltip>
  );
});
