import {
  config,
  type FileDetails,
  type FileUploadMetaData,
  GlobalFilterOption,
  useCurrency,
  useSearchIndex,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import type { UppyFile } from '@uppy/core';
import type { LexicalEditor } from 'lexical';
import { useDeferredValue, useMemo, useState } from 'react';
import type { FieldError } from 'react-hook-form';

import type { MentionedUser } from './Editors/base/plugins/MentionsPlugin';
import { QuickParticipation } from './QuickParticipation/QuickParticipation';

export function OpenEndedBlock({
  files,
  draftFiles,
  fileAttachment,
  handleRemoveFile,
  draftState,
  onChange,
  error,
  disabled,
  hideTextFormattingToolbar,
  onError,
  placeholder,
  hideGifSelector,
  hideMentions,
  helperText,
}: {
  files?: UppyFile<FileUploadMetaData, Record<string, never>>[];
  draftFiles?: FileDetails[];
  fileAttachment?: React.ReactNode;
  handleRemoveFile?: (fileId: string) => void;
  draftState?: string;
  onChange: (data: { plainText: string; html: string; json: string }) => void;
  error?: FieldError | null;
  disabled?: boolean;
  hideTextFormattingToolbar?: boolean;
  onError?: ((error: Error, editor: LexicalEditor) => void) | undefined;
  placeholder?: string;
  hideGifSelector?: boolean;
  hideMentions?: boolean;
  helperText?: string;
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const differedSearchTerm = useDeferredValue(searchTerm);

  const userDetails = useSuspenseUserDetails();

  const {
    hasNextPage: hasNextPageInMembersList,
    fetchNextPage: fetchNextPageInMembersList,
    isFetchingNextPage: isFetchingNextPageInMembersList,
    status: membersQueryStatus,
    fetchStatus: membersQueryFetchStatus,
    data: membersData,
  } = useSearchIndex<MentionedUser[]>(
    {
      searchTerm: differedSearchTerm,
      enabled: true,
      selectedFilters: [GlobalFilterOption.People],
      populateCardDetails: true,
      secondaryFilters: {
        state: ['ACTIVE', 'INACTIVE'],
        memberState: ['ACTIVE', 'PENDING'],
      },
    },
    {
      select: (data) => {
        return {
          ...data,
          pages: data.pages.map((page) => {
            return page.data.data
              .filter((searchResult) => searchResult.type === 'member')
              .map((searchResult) => {
                return {
                  applyBoostToAll: false,
                  id: searchResult.id,
                  isCurrentUserAnon: false,
                  pointsHidden: false,
                  selectedReply: null,
                  department: searchResult._meta.department,
                  email: searchResult._meta.email,
                  image: searchResult._meta.profileImageUrl,
                  memberId: searchResult.id,
                  memberID: searchResult.id,
                  name: searchResult.name,
                  role: searchResult._meta.jobTitle,
                  status: searchResult._meta.profileStatus,
                  firstName: searchResult._meta.firstName,
                  lastName: searchResult._meta.lastName,
                  memberState: searchResult._meta.state,
                  jobTitle: searchResult._meta.jobTitle,
                  pronouns: searchResult._meta.pronouns,
                  isFlowViewer: true,
                };
              });
          }),
        };
      },
    }
  );
  const members = useMemo(
    () => membersData?.pages.flatMap((x) => x) ?? [],
    [membersData]
  );

  const currency = useCurrency();

  return (
    <QuickParticipation.Editor
      files={files}
      placeholder={placeholder}
      draftFiles={draftFiles}
      fileAttachment={fileAttachment}
      onRemoveFile={handleRemoveFile}
      currency={currency}
      gifRating={userDetails.data.assembly.settings.gifAccessibility.value}
      giphyAPIKey={config.giphyKey}
      error={error?.message}
      disabled={disabled}
      onChange={onChange}
      draftState={draftState}
      hasNextPage={hasNextPageInMembersList}
      isFetchingNextPage={isFetchingNextPageInMembersList}
      isMembersFetching={membersQueryFetchStatus === 'fetching'}
      isMembersLoading={membersQueryStatus === 'pending'}
      members={members}
      onNextPageScrolled={fetchNextPageInMembersList}
      onSearchQueryChange={(query) => setSearchTerm(query ?? '')}
      hideTextFormattingToolbar={hideTextFormattingToolbar}
      onError={onError}
      hideGifSelector={hideGifSelector}
      hideMentions={hideMentions}
      helperText={helperText}
    />
  );
}
