import type {
  AssemblySearchIndexRequestBody,
  ChallengeState,
  ContentTypeFilter,
  ContentTypeFilterExpanded,
  DateRange,
  FlowStatusType,
  RecognitionContentTypeState,
  SearchIndexDocumentType,
  UserDetails,
  UserFeedApiResponse,
} from '@assembly-web/services';
import {
  APIEndpoints,
  assemblyAPI,
  getSecondaryFiltersQueryCacheKey,
  GlobalFilterOption,
  isRecognitionContentTypeState,
  RewardType,
  UserActivitySortType,
} from '@assembly-web/services';
import { convertPostDataToFlowPostFormat } from '@assembly-web/ui';
import type {
  InfiniteData,
  QueryClient,
  UndefinedInitialDataInfiniteOptions,
} from '@tanstack/react-query';
import type { IntlShape } from 'react-intl';

export type GetUserFeedPayload = {
  filters: {
    type: string[];
    tags?: string[];
  };
  sortBy?: string;
  dueDate?: DateRange;
  createdDate?: DateRange;
  userActivitySortType?: UserActivitySortType;
  excludeRecognitionFlow?: boolean;
  populateCardDetails?: boolean;
  secondaryFilters?: {
    dueDate?: DateRange;
    dateCreated?: DateRange;
    flowStatus: FlowStatusType[];
    fromRef?: string[];
    entityIn?: string[];
    mentionedMemberIds?: string[];
    postContentType?: ContentTypeFilter | ContentTypeFilterExpanded;
    assignedToRef?: string[];
    taskState?: string[];
    fileMimeTypes?: string[];
    department?: string[];
    homeLocation?: string[];
    workLocation?: string[];
    jobTitle?: string[];
    managers?: string[];
    challengeStatus?: ChallengeState[];
  };
  rewardsSecondaryFilters?: {
    category?: string;
    country?: string;
    sortOrder?: string;
  };
};

export function getUserFeedCacheKey(
  payload: GetUserFeedPayload,
  requestFiltersType?: SearchIndexDocumentType[] | undefined
) {
  const {
    filters,
    sortBy,
    userActivitySortType,
    excludeRecognitionFlow,
    secondaryFilters: {
      entityIn,
      fromRef,
      mentionedMemberIds,
      postContentType,
      flowStatus,
      assignedToRef,
      taskState,
      fileMimeTypes,
      department,
      homeLocation,
      workLocation,
      managers,
      jobTitle,
      dueDate,
      dateCreated,
      challengeStatus,
    } = {},
    rewardsSecondaryFilters: {
      category,
      country,
      sortOrder: rewardsSortOrder,
    } = {},
  } = payload;
  const filterTypes = filters.type.sort().join('-');
  const cardFilterTypes = requestFiltersType?.sort().join('-');
  const secondaryFiltersCacheKey = getSecondaryFiltersQueryCacheKey({
    entityIn,
    fromRef,
    mentionedMemberIds,
    flowStatus,
    assignedToRef,
    taskState,
    fileMimeTypes,
    department,
    homeLocation,
    workLocation,
    managers,
    jobTitle,
    challengeStatus,
  });
  const dueDateString = dueDate
    ? `${dueDate.gte + '-' + dueDate.lte}-dueDate`
    : '';
  const dateCreatedString = dateCreated
    ? `${dateCreated.gte + '-' + dateCreated.lte}-dateCreated`
    : '';

  const url = new URLSearchParams(window.location.search);
  const tab = url.get('tab') ?? '';
  const excludedTabs = ['discounts'];
  const rewardTypeString =
    filters.type.includes('rewards') && !excludedTabs.includes(tab)
      ? `rewards-${tab}`
      : '';

  const userFeedCacheKey = [
    'userFeed',
    filterTypes,
    sortBy,
    userActivitySortType,
    excludeRecognitionFlow,
    postContentType,
    dueDateString,
    dateCreatedString,
    rewardTypeString,
    category,
    country,
    rewardsSortOrder,
    secondaryFiltersCacheKey,
    cardFilterTypes,
  ].filter(Boolean);

  return userFeedCacheKey;
}

export function getUserFeedQuery({
  payload,
  queryClient,
  options,
  isExcludePostsWithRepliesEnabled,
  isAwardsEnabled,
  formatMessage,
}: {
  payload: GetUserFeedPayload;
  queryClient: QueryClient;
  options?: Partial<UndefinedInitialDataInfiniteOptions<UserFeedApiResponse>>;
  isExcludePostsWithRepliesEnabled?: boolean;
  formatMessage: IntlShape['formatMessage'];
  isAwardsEnabled?: boolean;
}): UndefinedInitialDataInfiniteOptions<
  UserFeedApiResponse & {
    isExcludePostsWithRepliesEnabled?: boolean;
  }
> {
  const selectedFilters = payload.filters.type;
  const dueDate = payload.secondaryFilters?.dueDate;
  const dateCreated = payload.secondaryFilters?.dateCreated;
  const userActivitySortType = payload.userActivitySortType;
  const assemblyEntityIn = payload.secondaryFilters?.entityIn;
  const assemblyFromRef = payload.secondaryFilters?.fromRef;
  const assignedToRef = payload.secondaryFilters?.assignedToRef;
  const fileMimeTypes = payload.secondaryFilters?.fileMimeTypes;
  const departments = payload.secondaryFilters?.department;
  const homeLocation = payload.secondaryFilters?.homeLocation;
  const workLocation = payload.secondaryFilters?.workLocation;
  const managers = payload.secondaryFilters?.managers;
  const jobTitle = payload.secondaryFilters?.jobTitle;
  const assemblyMentionedMemberIds =
    payload.secondaryFilters?.mentionedMemberIds;
  const flowStatus = payload.secondaryFilters?.flowStatus;
  const postContentType = payload.secondaryFilters?.postContentType;
  const taskState = payload.secondaryFilters?.taskState;
  const country = payload.rewardsSecondaryFilters?.country;
  const category = payload.rewardsSecondaryFilters?.category;
  const rewardsSortOrder = payload.rewardsSecondaryFilters?.sortOrder;
  const challengeStatus = payload.secondaryFilters?.challengeStatus;

  const requestBody: AssemblySearchIndexRequestBody = {
    cursor: {
      from: 0,
      limit: 20,
    },
    filters: {
      createdAt: dateCreated,
      source: [
        'assembly',
        'box',
        'dropbox',
        'googleDrive',
        'oneDrive',
        'sharePoint',
      ],
      ...(assemblyEntityIn?.length && {
        'assembly.entityIn': assemblyEntityIn.map((entity) => {
          return entity === 'recognition' ? 'post' : entity;
        }),
      }),
      ...(assemblyFromRef?.length && {
        'assembly.fromRef': assemblyFromRef,
      }),
      ...(dueDate && {
        'assembly.task.dueDate': dueDate,
      }),
      ...(assemblyMentionedMemberIds?.length && {
        'assembly.mentionedMemberIds': assemblyMentionedMemberIds,
      }),
      ...(assignedToRef?.length && {
        'assembly.task.assignedToRef': assignedToRef,
      }),
      ...(taskState?.length && {
        'assembly.task.state': taskState,
      }),
      ...(fileMimeTypes && {
        'assembly.file.fileMimeType': fileMimeTypes,
      }),
      ...(departments && {
        'assembly.member.department': departments,
      }),
      ...(homeLocation && {
        'assembly.member.location': homeLocation,
      }),
      ...(workLocation && {
        'assembly.member.workLocation': workLocation,
      }),
      ...(managers && {
        'assembly.member.managerIds': managers,
      }),
      ...(jobTitle && {
        'assembly.member.jobTitle': jobTitle,
      }),
    },
    tangoFilter: {},
    indexName: 'search-assembly-v3-std',
    searchTerm: '',
    userActivitySortType:
      userActivitySortType === UserActivitySortType.AtoZ
        ? null
        : userActivitySortType,
    populateCardDetails: payload.populateCardDetails,
  };

  const shouldExcludePostsHavingReplies = (
    requestBody: AssemblySearchIndexRequestBody
  ) => {
    if (
      isExcludePostsWithRepliesEnabled &&
      requestBody.filters.type?.includes('groupedComment')
    ) {
      requestBody.excludePostsWithReplies = true;
    }
  };

  if (selectedFilters.includes(GlobalFilterOption.Rewards)) {
    delete requestBody.userActivitySortType;
  }

  if (selectedFilters.includes(GlobalFilterOption.Files)) {
    requestBody.filters.type = ['file'];
  } else if (selectedFilters.includes(GlobalFilterOption.Tasks)) {
    requestBody.filters.type = ['task'];
  } else if (selectedFilters.includes(GlobalFilterOption.Flows)) {
    requestBody.filters.type = ['flow'];
    requestBody.filters.state = flowStatus ? flowStatus : ['ACTIVE'];

    requestBody.sortOptions = [
      {
        state: 'asc',
      },
    ];

    if (userActivitySortType === UserActivitySortType.AtoZ) {
      requestBody.sortOptions = [
        {
          state: 'asc',
        },
        {
          'assembly.flow.name': 'asc',
        },
      ];
    }
  } else if (selectedFilters.includes(GlobalFilterOption.People)) {
    requestBody.filters.type = ['member'];
    requestBody.filters.state = ['ACTIVE'];

    if (userActivitySortType === UserActivitySortType.AtoZ) {
      requestBody.sortOptions = [
        {
          'assembly.member.fullName': 'asc',
        },
      ];
    }
  } else if (selectedFilters.includes(GlobalFilterOption.Challenges)) {
    requestBody.filters.type = ['challenge'];
    requestBody.filters.state = ['ACTIVE', 'INACTIVE'];
    if (challengeStatus) {
      requestBody.filters['assembly.challenge.state'] = challengeStatus;
    }

    requestBody.sortOptions = [
      {
        'assembly.challenge.stateGroup': 'asc',
      },
      {
        createdAt: 'desc',
      },
    ];

    requestBody.userActivitySortType = null;
  } else if (selectedFilters.includes(GlobalFilterOption.Rewards)) {
    const queryParams = new URLSearchParams(window.location.search);
    const tab = queryParams.get('tab');
    if (tab === RewardType.GiftCards) {
      requestBody.filters.type = ['tangoReward'];
      requestBody.filters.tags = [
        'gift card',
        'cash equivalent',
        'reward link',
      ];
      //Handling an edge case when country selected is GB. It'll get cards for both GB and UK.
      if (country && country === 'GB') {
        requestBody.tangoFilter.countries = [country, 'UK'];
      } else {
        country && (requestBody.tangoFilter.countries = [country]);
      }
      rewardsSortOrder
        ? (requestBody.sortOptions = [
            {
              'tango.name': rewardsSortOrder,
            },
          ])
        : null;
    } else if (tab === RewardType.Swag) {
      requestBody.filters.type = ['swag'];
      category && (requestBody.filters['swag.categories'] = [Number(category)]);
      rewardsSortOrder
        ? (requestBody.sortOptions = [
            {
              'swag.name': rewardsSortOrder,
            },
          ])
        : null;
    } else if (tab === RewardType.Charities) {
      requestBody.filters.type = ['tangoReward'];
      requestBody.filters.tags = ['donation'];
      rewardsSortOrder
        ? (requestBody.sortOptions = [
            {
              'tango.name': rewardsSortOrder,
            },
          ])
        : null;
    } else if (tab === RewardType.Culture) {
      requestBody.filters.type = ['customReward'];
      rewardsSortOrder
        ? (requestBody.sortOptions = [
            {
              'culture.name': rewardsSortOrder,
            },
          ])
        : null;
    }
  } else if (selectedFilters.includes(GlobalFilterOption.Recognition)) {
    const commentFilter =
      requestBody.filters['assembly.fromRef']?.length ??
      requestBody.filters.createdAt ??
      assemblyMentionedMemberIds?.length;

    requestBody.filters.type = commentFilter ? ['post', 'comment'] : ['post'];

    if (postContentType) {
      let filterValues: RecognitionContentTypeState[] = [];
      if (postContentType.includes('replies')) {
        filterValues = commentFilter ? ['postComment'] : ['postGroupedComment'];
      }
      filterValues.push(
        ...postContentType
          .split(',')
          .filter((value: string) =>
            isRecognitionContentTypeState(value, isAwardsEnabled)
          )
      );
      requestBody.filters['assembly.post.type'] = filterValues;
    }
    shouldExcludePostsHavingReplies(requestBody);
  } else {
    const defaultFilters: SearchIndexDocumentType[] = ['post', 'response'];

    if (
      requestBody.filters.createdAt ||
      requestBody.filters['assembly.fromRef']
    ) {
      defaultFilters.push('comment');
    }

    requestBody.filters.type = defaultFilters;

    shouldExcludePostsHavingReplies(requestBody);

    if (
      userActivitySortType === UserActivitySortType.RecentlyViewed ||
      userActivitySortType === UserActivitySortType.MostViewed
    ) {
      requestBody.filters.type.push('url');
    }
  }

  if (selectedFilters.includes('all')) {
    if (isAwardsEnabled) {
      const typesToExcludeForAllFilter = ['collection', 'file'];
      const postTypes = ['recognition', 'award', 'birthday', 'anniversary'];

      if (postContentType) {
        // replies not selected
        if (!postContentType.includes('replies')) {
          requestBody.filters.type =
            requestBody.filters.type?.filter(
              (item) => item !== 'comment' && item !== 'groupedComment'
            ) ?? [];
        } else {
          const commentFilter =
            requestBody.filters['assembly.fromRef']?.length ??
            requestBody.filters.createdAt ??
            assemblyMentionedMemberIds?.length;

          requestBody.filters.type =
            requestBody.filters.type?.filter(
              (item) => item !== 'comment' && item !== 'groupedComment'
            ) ?? [];

          requestBody.filters.type = [
            ...requestBody.filters.type,
            ...(commentFilter
              ? ['comment' as const]
              : ['groupedComment' as const]),
          ];
        }

        // flows not selected
        if (!postContentType.includes('flows')) {
          requestBody.filters.type = requestBody.filters.type.filter(
            (item) => item !== 'response'
          );
        }

        // postTypes not selected
        if (!postTypes.some((type) => postContentType.includes(type))) {
          requestBody.filters.type = requestBody.filters.type.filter(
            (item) => item !== 'post'
          );
        } else {
          let filterValues: RecognitionContentTypeState[] = [];
          filterValues = (postContentType as ContentTypeFilterExpanded)
            .split(',')
            .filter((type): type is RecognitionContentTypeState => {
              return (
                type === 'recognition' ||
                type === 'award' ||
                type === 'birthday' ||
                type === 'anniversary'
              );
            });
          requestBody.filters['assembly.post.type'] = filterValues;
        }

        requestBody.filters.type = requestBody.filters.type.filter(
          (type) => !typesToExcludeForAllFilter.includes(type)
        );
        shouldExcludePostsHavingReplies(requestBody);
      }
    } else {
      const typesToExcludeForAllFilter = ['collection', 'file'];
      if (postContentType === 'replies' || postContentType === 'posts') {
        const commentFilter =
          requestBody.filters['assembly.fromRef']?.length ??
          requestBody.filters.createdAt ??
          assemblyMentionedMemberIds?.length;

        const contentTypeMappings: Record<string, SearchIndexDocumentType[]> = {
          posts: ['post', 'response'],
          replies: commentFilter ? ['comment'] : [],
        };

        requestBody.filters.type =
          contentTypeMappings[postContentType as ContentTypeFilter];
        shouldExcludePostsHavingReplies(requestBody);
      } else {
        requestBody.filters.type = requestBody.filters.type?.filter(
          (type) => !typesToExcludeForAllFilter.includes(type)
        );

        if (
          !postContentType &&
          assemblyMentionedMemberIds &&
          requestBody.filters.type
        ) {
          requestBody.filters.type = [
            ...requestBody.filters.type.filter(
              (type) => !typesToExcludeForAllFilter.includes(type)
            ),
            'comment',
          ];
        }
      }
    }
  }

  return {
    ...options,
    queryKey: getUserFeedCacheKey(payload, requestBody.filters.type),
    queryFn: async ({ pageParam, signal }): Promise<UserFeedApiResponse> => {
      const response = await assemblyAPI.post(
        APIEndpoints.getUserFeed,
        {
          ...requestBody,
          cursor: {
            from: pageParam,
            limit: 20,
          },
        },
        { signal }
      );

      return response.data;
    },
    getNextPageParam: (page: UserFeedApiResponse) => {
      return page.metadata.pagination.cursor.from +
        page.metadata.pagination.cursor.limit <=
        page.total
        ? page.metadata.pagination.cursor.from +
            page.metadata.pagination.cursor.limit
        : undefined;
    },
    initialPageParam: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const userDetails: UserDetails | undefined = queryClient.getQueryData([
        'userDetails',
      ]);
      const modifiedUserFeedData: InfiniteData<UserFeedApiResponse> = {
        ...data,
        pages: data.pages.map((page) => {
          return {
            ...page,
            data: page.data.map((item) => {
              return item.type === 'post' && item.cardDetails
                ? {
                    ...item,
                    cardDetails: item.cardDetails.post
                      ? {
                          ...convertPostDataToFlowPostFormat(
                            {
                              ...item.cardDetails.post,
                              currencyName:
                                userDetails?.assembly.currency.pluralName ?? '',
                            },
                            formatMessage
                          ),
                          replySummary: item.cardDetails.replySummary,
                        }
                      : item.cardDetails,
                  }
                : item;
            }),
          };
        }),
      };
      return modifiedUserFeedData;
    },
  };
}
