import {
  type FileDetails,
  type FileUploadMetaData,
  formatFileSize,
  isTruthy,
  truncateFilename,
  useDownload,
} from '@assembly-web/services';
import { ClockIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ArrowDownIcon } from '@heroicons/react/24/solid';
import type { UppyFile } from '@uppy/core';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin, twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { useToastStore } from '../Toast/useToastStore';
import { getFilePreview } from './utils';

const messages = defineMessages({
  preparingToUploadLabel: {
    defaultMessage: 'Preparing to upload',
    id: 'OKAb1Y',
  },
  preparingToDownload: {
    defaultMessage: 'Download in progress',
    id: 'Ka4XgI',
  },
  downloadFailed: {
    defaultMessage: 'Download failed. Retry later.',
    id: '7LPql5',
  },
  downloadSuccess: {
    defaultMessage: 'Download success',
    id: 'E9nYXm',
  },
  download: {
    defaultMessage: 'Download',
    id: '5q3qC0',
  },
});

type VariantProps = {
  fileSize: number;
  imageUrl: string;
  file?: FileDetails;
  isImageUrl: boolean;
  isUppyFile: boolean;
  handleRemove: () => void;
  hideRemoveButton?: boolean;
  fileName: string | undefined;
  showDownloadButton?: boolean;
  onRemoveFileClick?: (fileId: string) => void;
  filePreview: ReturnType<typeof getFilePreview>;
  variant: 'challenge-claim' | 'challenge-preview' | 'participation' | 'editor';
  uppyFile?: UppyFile<FileUploadMetaData, Record<string, never>>;
};

type FilePreviewProps = (
  | {
      variant:
        | 'challenge-claim'
        | 'challenge-preview'
        | 'participation'
        | 'editor';
      hideRemoveButton?: boolean;
      onRemoveFileClick: (fileId: string) => void;
      uppyFile: UppyFile<FileUploadMetaData, Record<string, never>>;
    }
  | {
      file: FileDetails;
      showDownloadButton?: boolean;
      hideRemoveButton?: boolean;
      variant:
        | 'challenge-claim'
        | 'challenge-preview'
        | 'participation'
        | 'editor';
      onRemoveFileClick?: (fileId: string) => void;
    }
) & { onClick?: () => void };

const UploadStatus = ({
  file,
  variant,
}: {
  variant: 'challenge-claim' | 'challenge-preview' | 'participation' | 'editor';
  file: UppyFile<FileUploadMetaData, Record<string, never>>;
}) => {
  const { formatMessage } = useIntl();

  const isPreparingToUpload =
    !file.progress.uploadComplete &&
    file.progress.percentage === 0 &&
    file.progress.uploadStarted;

  const isUploading =
    !file.progress.uploadComplete && file.progress.percentage !== 0;

  return (
    <div className="mr-2 flex w-full items-center justify-end">
      <AnimatePresence mode="wait">
        {Boolean(isPreparingToUpload) && (
          <motion.div
            key="preparing"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="flex items-center space-x-1"
          >
            <ClockIcon className="h-3 w-3 animate-spin text-gray-8" />
            {variant !== 'challenge-claim' && (
              <TextStyle variant="xs-regular" className="text-gray-8">
                {formatMessage(messages.preparingToUploadLabel)}
              </TextStyle>
            )}
          </motion.div>
        )}
        {Boolean(isUploading) && (
          <TextStyle variant="xs-regular" className="text-gray-8">
            {file.progress.percentage}%
          </TextStyle>
        )}
      </AnimatePresence>
    </div>
  );
};

function ImageComponent({
  file,
  fileName,
  imageUrl,
  className,
}: {
  fileName?: string;
  imageUrl?: string;
  file?: FileDetails;
  className?: string;
}) {
  const [src, setSrc] = useState(
    file?.thumbnails &&
      (256 in file.thumbnails ? file.thumbnails[256] : file.location)
  );

  const imageClasses = twMerge(
    'h-16 w-[84px] rounded-l-lg border-r border-gray-5 object-cover',
    className
  );

  if (imageUrl) {
    return <img alt={fileName} src={imageUrl} className={imageClasses} />;
  }

  return (
    <img
      alt={fileName}
      src={src}
      onError={() => {
        setSrc(file?.location);
      }}
      className={imageClasses}
    />
  );
}

export function DownloadButton({
  name,
  url,
  absolutePositioning = true,
}: {
  name: string;
  url: string;
  absolutePositioning?: boolean;
}) {
  const { formatMessage } = useIntl();

  const { progress, isDownloading, error, downloadFile, cancelDownload } =
    useDownload();

  const { showSuccessToast, showErrorToast, showInfoToast } = useToastStore();

  useEffect(() => {
    return () => {
      cancelDownload();
    };
  }, [cancelDownload]);

  useEffect(() => {
    if (error) {
      showErrorToast(formatMessage(messages.downloadFailed));
    }
  }, [error, formatMessage, showErrorToast]);

  useEffect(() => {
    if (isDownloading) {
      showInfoToast(formatMessage(messages.preparingToDownload));
    }
  }, [formatMessage, isDownloading, showInfoToast]);

  useEffect(() => {
    if (progress === 100) {
      showSuccessToast(formatMessage(messages.downloadSuccess));
    }
  }, [formatMessage, progress, showSuccessToast]);

  return (
    <button
      disabled={isDownloading}
      className={twMerge(
        'bg-gray-0 group right-2 top-5 flex items-center justify-center overflow-hidden rounded-full border border-primary-6 p-1 transition-all duration-300 ease-in-out hover:bg-gray-3',
        absolutePositioning && 'absolute'
      )}
      onClick={async (e) => {
        e.stopPropagation();
        await downloadFile(name, url);
      }}
    >
      {Boolean(absolutePositioning) &&
        progress !== null &&
        progress !== 100 &&
        !error && (
          <div
            className="absolute inset-0 rounded-full"
            style={{
              background: `conic-gradient(#2F54EB ${progress * 3.6}deg, transparent 0deg)`,
            }}
          />
        )}
      <ArrowDownIcon
        className={twJoin(
          'h-3 w-3 text-primary-6 transition-transform duration-300 ease-in-out group-hover:translate-y-0.5',
          progress !== null && progress !== 100 && !error
            ? 'invisible'
            : 'visible'
        )}
      />
    </button>
  );
}

function FilePreviewWrapper({
  onClick,
  children,
  className,
}: {
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
}) {
  return (
    <motion.section
      layout
      onClick={onClick}
      className={className}
      transition={{ duration: 0.3 }}
      exit={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      initial={{ opacity: 0, scale: 0.8 }}
    >
      {children}
    </motion.section>
  );
}

function ChallengeClaimVariant({
  file,
  uppyFile,
  fileSize,
  imageUrl,
  fileName,
  isUppyFile,
  isImageUrl,
  filePreview,
  handleRemove,
  hideRemoveButton,
  onRemoveFileClick,
}: VariantProps) {
  return (
    <div className="relative rounded-lg border border-gray-5">
      {Boolean(onRemoveFileClick) && !hideRemoveButton && (
        <button
          type="button"
          onClick={handleRemove}
          className="absolute -right-2 -top-2 z-[99] rounded-md border border-gray-5 bg-gray-1 p-1 hover:bg-gray-3"
        >
          <XMarkIcon className="h-4 w-4 text-gray-9" />
        </button>
      )}
      <div className="grid h-16 grid-cols-[auto_1fr] gap-2">
        {isImageUrl ? (
          <div className="flex items-center justify-center">
            {isTruthy(uppyFile) ? (
              <ImageComponent fileName={fileName} imageUrl={imageUrl} />
            ) : (
              isTruthy(file) && (
                <ImageComponent fileName={fileName} file={file} />
              )
            )}
          </div>
        ) : (
          <div
            className={twJoin(
              'flex h-16 w-[84px] items-center justify-center rounded-l-lg border-r border-gray-5',
              filePreview.background
            )}
          >
            {filePreview.icon}
          </div>
        )}
        <div className="mr-2 flex w-full flex-col justify-center">
          <TextStyle variant="sm-medium" className="w-full text-gray-9">
            {truncateFilename(fileName ?? '', 15)}
          </TextStyle>
          <div className="flex justify-between">
            <TextStyle variant="sm-regular" className="w-full text-gray-8">
              {formatFileSize(fileSize)}
            </TextStyle>
            {isTruthy(isUppyFile) && isTruthy(uppyFile) && (
              <UploadStatus file={uppyFile} variant="challenge-claim" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function ChallengePreviewVariant({
  file,
  uppyFile,
  isImageUrl,
  filePreview,
  fileName,
  fileSize,
  handleRemove,
  imageUrl,
  isUppyFile,
  onRemoveFileClick,
  hideRemoveButton,
  showDownloadButton,
}: VariantProps) {
  const { formatMessage } = useIntl();

  return (
    <div className="relative rounded-lg border border-gray-5">
      {isTruthy(onRemoveFileClick) && !hideRemoveButton && (
        <button
          type="button"
          onClick={handleRemove}
          className="absolute -right-2 -top-2 z-[99] rounded-md border border-gray-5 bg-gray-1 p-1 hover:bg-gray-3"
        >
          <XMarkIcon className="h-4 w-4 text-gray-9" />
        </button>
      )}
      <div className="grid h-16 grid-cols-[auto_1fr] gap-2">
        {isImageUrl ? (
          <div className="flex items-center justify-center">
            {isTruthy(uppyFile) ? (
              <ImageComponent fileName={fileName} imageUrl={imageUrl} />
            ) : (
              isTruthy(file) && (
                <ImageComponent fileName={fileName} file={file} />
              )
            )}
          </div>
        ) : (
          <div
            className={twJoin(
              'flex h-16 w-[84px] items-center justify-center rounded-l-lg border-r border-gray-5',
              filePreview.background
            )}
          >
            {filePreview.icon}
          </div>
        )}
        <div className="mr-2 flex w-full flex-col justify-center">
          <TextStyle variant="sm-medium" className="w-full text-gray-9">
            {truncateFilename(fileName ?? '', 30)}
          </TextStyle>
          <div className="flex justify-between">
            <TextStyle variant="sm-regular" className="w-full text-gray-8">
              {formatFileSize(fileSize)}
            </TextStyle>
            {isTruthy(isUppyFile) && isTruthy(uppyFile) && (
              <UploadStatus file={uppyFile} variant="challenge-preview" />
            )}
          </div>
          {!isUppyFile && isTruthy(showDownloadButton) && isTruthy(file) && (
            <Tooltip
              tooltipText={formatMessage(messages.download)}
              side="top"
              align="center"
            >
              <DownloadButton name={file.name} url={file.location} />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
}

function ParticipationVariant({
  file,
  fileName,
  fileSize,
  uppyFile,
  imageUrl,
  isImageUrl,
  isUppyFile,
  filePreview,
  handleRemove,
  hideRemoveButton,
  onRemoveFileClick,
}: VariantProps) {
  return (
    <div className="relative border-none bg-gray-3">
      {Boolean(onRemoveFileClick) && !hideRemoveButton && (
        <button
          type="button"
          onClick={handleRemove}
          className="absolute right-1 top-2 z-[99] border-none bg-gray-3 p-1 hover:bg-gray-4"
        >
          <XMarkIcon className="h-4 w-4 text-gray-9" />
        </button>
      )}
      <div className="grid h-16 grid-cols-[auto_1fr] gap-2">
        {isImageUrl ? (
          <div className="flex items-center justify-center">
            {isTruthy(uppyFile) ? (
              <ImageComponent
                fileName={fileName}
                imageUrl={imageUrl}
                className="ml-3 h-12 w-12 rounded-md border-none"
              />
            ) : (
              isTruthy(file) && (
                <ImageComponent
                  fileName={fileName}
                  file={file}
                  className="ml-3 h-12 w-12 rounded-md border-none"
                />
              )
            )}
          </div>
        ) : (
          <div
            className={twJoin(
              'my-2 ml-3 flex h-12 w-12 items-center justify-center rounded-md border-none',
              filePreview.background
            )}
          >
            {filePreview.icon}
          </div>
        )}
        <div className="mr-2 flex w-full flex-col justify-center">
          <TextStyle variant="sm-medium" className="w-full text-gray-9">
            {truncateFilename(fileName ?? '', 30)}
          </TextStyle>
          <div className="flex justify-between">
            <TextStyle variant="sm-regular" className="w-full text-gray-8">
              {formatFileSize(fileSize)}
            </TextStyle>
            {isTruthy(isUppyFile) && isTruthy(uppyFile) && (
              <UploadStatus file={uppyFile} variant="participation" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function EditorVariant({
  file,
  fileName,
  uppyFile,
  fileSize,
  imageUrl,
  isImageUrl,
  isUppyFile,
  filePreview,
  handleRemove,
  hideRemoveButton,
  onRemoveFileClick,
}: VariantProps) {
  return (
    <div className="relative rounded-lg border border-gray-5">
      {Boolean(onRemoveFileClick) && !hideRemoveButton && (
        <button
          type="button"
          onClick={handleRemove}
          className="absolute -right-2 -top-2 z-[99] rounded-full border border-primary-6 bg-gray-1 p-1 hover:bg-gray-3"
        >
          <XMarkIcon className="h-4 w-4 text-primary-6" />
        </button>
      )}
      <div
        className={twMerge(
          'grid h-16 grid-cols-[auto_1fr]',
          isImageUrl ? 'gap-0' : 'gap-2 bg-gray-3'
        )}
      >
        {isImageUrl ? (
          <div className="flex items-center justify-center">
            {isTruthy(uppyFile) ? (
              <ImageComponent
                fileName={fileName}
                imageUrl={imageUrl}
                className="rounded-lg"
              />
            ) : (
              isTruthy(file) && (
                <ImageComponent
                  fileName={fileName}
                  file={file}
                  className="rounded-lg"
                />
              )
            )}
          </div>
        ) : (
          <div
            className={twJoin(
              'my-2 ml-3 flex h-12 w-12 items-center justify-center rounded-lg border-none border-gray-6',
              filePreview.background
            )}
          >
            {filePreview.icon}
          </div>
        )}
        {!isImageUrl && (
          <div className="mr-2 flex w-full flex-col justify-center rounded-lg border-gray-4 bg-gray-3">
            <TextStyle variant="sm-medium" className="w-full text-gray-9">
              {truncateFilename(fileName ?? '', 30)}
            </TextStyle>
            <div className="flex justify-between">
              <TextStyle variant="sm-regular" className="w-full text-gray-8">
                {formatFileSize(fileSize)}
              </TextStyle>
              {isTruthy(isUppyFile) && isTruthy(uppyFile) && (
                <UploadStatus file={uppyFile} variant="editor" />
              )}
            </div>
          </div>
        )}
      </div>
      {isTruthy(uppyFile) && !uppyFile.progress.uploadComplete && (
        <div className="absolute bottom-0 w-full">
          <div
            className="h-1 rounded-lg bg-primary-5"
            style={{ width: `${uppyFile.progress.percentage}%` }}
          />
        </div>
      )}
    </div>
  );
}

export function FilePreview(props: FilePreviewProps) {
  const { formatMessage } = useIntl();

  const [imageUrl, setImageUrl] = useState<string>('');

  const isUppyFile = 'uppyFile' in props;

  const fileType = isUppyFile ? props.uppyFile.type : props.file.type;
  const fileName = isUppyFile ? props.uppyFile.name : props.file.name;
  const fileSize = isUppyFile ? props.uppyFile.size || 0 : props.file.size || 0;
  const uppyFileData = isUppyFile ? props.uppyFile.data : null;

  const filePreview = getFilePreview(fileType);
  const isImageUrl = /\.(jpe?g|png|gif|bmp|webp)$/i.test(fileName ?? '');

  useEffect(() => {
    if (
      isUppyFile &&
      uppyFileData &&
      (fileType.startsWith('image/') || fileType.startsWith('video/'))
    ) {
      const url = URL.createObjectURL(uppyFileData);
      setImageUrl(url);
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [fileType, isUppyFile, uppyFileData]);

  const handleRemove = useCallback(() => {
    if (isUppyFile) {
      props.onRemoveFileClick(props.uppyFile.id);
    } else {
      props.onRemoveFileClick?.(props.file.name);
    }
  }, [isUppyFile, props]);

  const config = {
    ...props,
    imageUrl,
    fileSize,
    fileName,
    isImageUrl,
    isUppyFile,
    filePreview,
    handleRemove,
    formatMessage,
  };

  if (props.variant === 'challenge-claim') {
    return (
      <FilePreviewWrapper onClick={props.onClick}>
        <ChallengeClaimVariant {...config} />
      </FilePreviewWrapper>
    );
  }

  if (props.variant === 'challenge-preview') {
    return (
      <FilePreviewWrapper onClick={props.onClick}>
        <ChallengePreviewVariant {...config} />
      </FilePreviewWrapper>
    );
  }

  if (props.variant === 'participation') {
    return (
      <FilePreviewWrapper onClick={props.onClick} className="rounded-lg">
        <ParticipationVariant {...config} />
      </FilePreviewWrapper>
    );
  }

  return (
    <FilePreviewWrapper onClick={props.onClick}>
      <EditorVariant {...config} />
    </FilePreviewWrapper>
  );
}
