import {
  config,
  SplitNames,
  type ThemePreset,
  useFeatureSplit,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import {
  ProfileMenu,
  ThemeSelectorModal,
  useAssemblyNavigate,
  useDeviceInfo,
} from '@assembly-web/ui';
import {
  ArrowRightEndOnRectangleIcon,
  BuildingOfficeIcon,
  Cog6ToothIcon,
  GiftIcon,
  PaintBrushIcon,
  TrophyIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import type { ComponentProps, Key } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useAssemblySettingsMutation } from '../../modules/discover/hooks/useAssemblySettingsMutation';
import { trackDiscoverAction } from '../../modules/discover/services/analytics';
import { isAdminMember } from '../../modules/discover/services/member';
import { useRewardsDetails } from '../../modules/rewards/hooks/useRewardsDetails';
import { useWorkspace } from '../../queries/useWorkspace';

const messages = defineMessages({
  goToMyProfile: {
    defaultMessage: 'Profile',
    id: 'itPgxd',
  },
  goToAdmin: {
    defaultMessage: 'Admin',
    id: 'iHN12u',
  },
  themeYourWorkspace: {
    defaultMessage: 'Change Theme',
    id: 'm1YRjG',
  },
  myRewards: {
    defaultMessage: 'My Rewards',
    id: 'Wocb8I',
  },
  myChallenges: {
    defaultMessage: 'My Challenges',
    id: 'ayp0hH',
  },
  mySettings: {
    defaultMessage: 'My Settings',
    id: 'ECJpOU',
  },
  switchAccounts: {
    defaultMessage: 'Switch accounts',
    id: '6xNr8c',
  },
  logout: {
    defaultMessage: 'Logout',
    id: 'C81/uG',
  },
});

enum ProfileMenuItem {
  MyProfile = 'my-profile',
  MySettings = 'my-settings',
  MyRewards = 'my-rewards',
  MyChallenges = 'my-challenges',
  Admin = 'admin',
  ChangeTheme = 'change-theme',
  Logout = 'logout',
}

export function UserMenu() {
  const { data: userDetails } = useSuspenseUserDetails();

  const { deviceType } = useDeviceInfo();

  const [openProfileMenu, setOpenProfileMenu] = useState(false);

  const { isTreatmentActive: isMSTeamsTestAccount } = useFeatureSplit(
    SplitNames.MSTeamsAppSubmission
  );

  const variant: ComponentProps<typeof ProfileMenu>['variant'] = useMemo(() => {
    if (
      deviceType === 'desktop' ||
      deviceType === 'laptop' ||
      deviceType === 'tablet' ||
      isMSTeamsTestAccount
    ) {
      return 'dropdown';
    }

    return 'collapsible';
  }, [deviceType, isMSTeamsTestAccount]);

  const currentTheme =
    userDetails.assembly.settings.theme?.preset ?? ('neutral' as ThemePreset);

  const [showThemeSelector, setShowThemeSelector] = useState<boolean>(false);

  const { formatMessage } = useIntl();
  const { isRewardsSetupForUser } = useRewardsDetails();
  const isAdmin = isAdminMember(userDetails.member);
  const { isMobileDevice } = useDeviceInfo();

  const { data: workspaceData } = useWorkspace();
  const hasMultipleWorkspaces = workspaceData.workspaceIDs.length > 1;

  const navigate = useAssemblyNavigate();

  const menuItems = useMemo(() => {
    const items: ComponentProps<typeof ProfileMenu>['menuItems'] = new Map();

    items.set(ProfileMenuItem.MyProfile, {
      text: formatMessage(messages.goToMyProfile),
      icon: UserCircleIcon,
    });

    items.set(ProfileMenuItem.MySettings, {
      text: formatMessage(messages.mySettings),
      icon: Cog6ToothIcon,
    });

    if (isRewardsSetupForUser) {
      items.set(ProfileMenuItem.MyRewards, {
        text: formatMessage(messages.myRewards),
        icon: GiftIcon,
      });
    }

    if (isMSTeamsTestAccount) {
      items.set(ProfileMenuItem.MyChallenges, {
        text: formatMessage(messages.myChallenges),
        icon: TrophyIcon,
      });
    }

    if (isAdmin && !isMSTeamsTestAccount) {
      items.set(ProfileMenuItem.Admin, {
        text: formatMessage(messages.goToAdmin),
        icon: BuildingOfficeIcon,
      });
    }

    if (isAdmin && !isMobileDevice) {
      items.set(ProfileMenuItem.ChangeTheme, {
        text: formatMessage(messages.themeYourWorkspace),
        icon: PaintBrushIcon,
      });
    }

    items.set(ProfileMenuItem.Logout, {
      text: formatMessage(
        hasMultipleWorkspaces ? messages.switchAccounts : messages.logout
      ),
      icon: ArrowRightEndOnRectangleIcon,
    });

    return items;
  }, [
    formatMessage,
    isRewardsSetupForUser,
    isAdmin,
    isMSTeamsTestAccount,
    isMobileDevice,
    hasMultipleWorkspaces,
  ]);

  const onMenuItemClick = useCallback(
    (key: Key) => {
      switch (key) {
        case ProfileMenuItem.MySettings: {
          navigate(
            `/a/${userDetails.assembly.workspaceSlugPath}/settings/general`
          );
          trackDiscoverAction('profileMenuItemClicked', {
            profileMenuItem: 'mySettings',
            v3enabled: true,
          });
          break;
        }
        case ProfileMenuItem.MyRewards: {
          navigate(`/a/rewards?sortOrder=asc&tab=myRewards`);
          trackDiscoverAction('profileMenuItemClicked', {
            profileMenuItem: 'myRewards',
            v3enabled: true,
          });
          break;
        }
        case ProfileMenuItem.MyChallenges: {
          navigate(`/a/challenges`);
          break;
        }
        case ProfileMenuItem.ChangeTheme: {
          trackDiscoverAction('profileMenuItemClicked', {
            profileMenuItem: 'changeTheme',
            v3enabled: true,
          });
          setShowThemeSelector(true);
          break;
        }
        case ProfileMenuItem.Admin: {
          trackDiscoverAction('profileMenuItemClicked', {
            profileMenuItem: 'goToAdmin',
            v3enabled: true,
          });

          window.location.href = `${config.domains.newAdminApp}/settings`;
          break;
        }
        case ProfileMenuItem.Logout: {
          trackDiscoverAction('profileMenuItemClicked', {
            profileMenuItem: 'switchAccounts',
            v3enabled: true,
          });
          window.location.href = `${config.domains.legacyApp}/signout`;
          break;
        }
        case ProfileMenuItem.MyProfile: {
          trackDiscoverAction('profileMenuItemClicked', {
            profileMenuItem: 'goToProfile',
            v3enabled: true,
          });
          navigate(
            `/a/${userDetails.assembly.workspaceSlugPath}/user/${userDetails.member.memberId}`
          );
          break;
        }
      }
    },
    [
      navigate,
      userDetails.assembly.workspaceSlugPath,
      userDetails.member.memberId,
    ]
  );

  const { mutate: setTheme } = useAssemblySettingsMutation();

  const onThemeSelected = (selectedTheme: ThemePreset) => {
    setTheme({
      theme: { preset: selectedTheme },
    });

    trackDiscoverAction('themeApplied', {
      themeSelected: selectedTheme,
    });
  };

  return (
    <>
      <ProfileMenu
        variant={variant}
        member={userDetails.member}
        onMenuItemClick={onMenuItemClick}
        menuItems={menuItems}
        open={openProfileMenu}
        onOpenChange={setOpenProfileMenu}
      />
      {Boolean(showThemeSelector) && (
        <ThemeSelectorModal
          onClose={() => setShowThemeSelector(false)}
          currentTheme={currentTheme}
          onThemeSelected={onThemeSelected}
        />
      )}
    </>
  );
}
