import {
  type AdditionalInfoData,
  config,
  DoraAPIEndpoints,
  type DoraErrorServerSideEvent,
  logger,
} from '@assembly-web/services';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { messages as sharedMessages } from '../../services/dora';

const dataCloseChar = {
  '{': '}',
  '[': ']',
  '"': '"',
};

// Function pulled from this library https://medium.com/@vinju404/how-to-stream-completed-json-from-openais-gpt-b61920e66357
// Another option for production use is(which may be more efficient since it doesnt use regex) is best-effort-json-parser https://www.mikeborozdin.com/post/json-streaming-from-openai
function parseJsonChunk(jsonString: string) {
  if (!jsonString) return null;

  let string = jsonString
    .trim()
    .replace(/(\r\n|\n|\r|\s{2,})/gm, '')
    .replace(/(?<=:)([a-zA-Z]+)(?=\s*(?![,}])(?:[,}\s]|$))/g, ' null');

  let missingChars: string[] = [];

  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < string.length; i++) {
    const char = string[i];
    if (char === missingChars[missingChars.length - 1]) {
      missingChars.pop();
    } else if (dataCloseChar[char as keyof typeof dataCloseChar]) {
      missingChars.push(dataCloseChar[char as keyof typeof dataCloseChar]);

      if (char === '{') {
        missingChars.push(':');
      }
    }
  }

  if (missingChars[missingChars.length - 1] === ':') {
    if (!string.endsWith('{')) {
      missingChars[missingChars.length - 1] = ': null';
    } else {
      missingChars.pop();
    }
  }
  const missingCharsString = missingChars.reverse().join('');
  const completeString = string + missingCharsString;
  const cleanedString = completeString
    .replace(/"":/g, '')
    .replace(/":}|": }/g, '": null }')
    .replace(/,""}|,}|,"\w+"}/g, '}')
    .replace(/},]/g, '}]');

  try {
    return JSON.parse(cleanedString) as RecognitionDraft;
  } catch (error) {
    logger.error('Error parsing JSON', { error, cleanedString });
    return {};
  }
}

type RecognitionDraft = {
  content?: string;
  users?: string[];
  promptId?: string;
  coreValue?: string;
};

export const streamingTimeout = 120000; // 120 seconds

export function useGetRecognitionWriterResponse() {
  const { formatMessage } = useIntl();
  const [recognitionDraft, setRecognitionDraft] =
    useState<RecognitionDraft | null>();
  const [drafts, setDrafts] = useState<RecognitionDraft[]>([]);
  const [currentDraftIndex, setCurrentDraftIndex] = useState<number>(-1);
  const [threadId, setThreadId] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  useEffect(() => {
    return () => {
      abortController?.abort();
    };
  }, [abortController]);

  const resetWriter = useCallback(() => {
    setRecognitionDraft(null);
    setDrafts([]);
    setCurrentDraftIndex(-1);
    setThreadId(undefined);
    setIsLoading(false);
    setIsStreaming(false);
    setErrorMessage(null);
  }, []);

  const getPreviousDraft = useCallback(() => {
    setRecognitionDraft(drafts[currentDraftIndex - 1]);
    setCurrentDraftIndex(currentDraftIndex - 1);
    setDrafts((drafts) => drafts.slice(0, currentDraftIndex));
  }, [currentDraftIndex, drafts]);

  const getRecognitionDraft = useCallback(
    (prompt: string, recipientIds?: string) => {
      setRecognitionDraft(null);
      setIsLoading(true);
      setIsStreaming(true);
      setErrorMessage(null);

      //Setup logic to cancel/timeout request
      const controller = new AbortController();
      const signal = controller.signal;
      setAbortController(controller);

      const updateError = (message: string) => {
        setIsLoading(false);
        setIsStreaming(false);
        setErrorMessage(message);
      };
      const abortTimer = setTimeout(() => {
        logger.warn(
          `Timing out for Dora request to GET ${DoraAPIEndpoints.recognitionBuilder} failed`,
          {
            question: prompt,
            timeout: streamingTimeout,
          }
        );

        controller.abort();
        updateError(formatMessage(sharedMessages.genericError));
      }, streamingTimeout);

      //Setup request
      const url = new URL(config.domains.doraApi);
      let streamedData = '';

      url.pathname = DoraAPIEndpoints.recognitionBuilder;
      url.searchParams.append('text', prompt);

      if (threadId) {
        url.searchParams.append('threadId', threadId);
      }
      if (recipientIds) {
        url.searchParams.append('recipientIds', recipientIds);
      }

      fetchEventSource(url.toString(), {
        credentials: 'include',

        async onopen(resp) {
          clearTimeout(abortTimer);
          if (!resp.ok) {
            let responseBody;

            if (resp.headers.get('content-type') === 'application/json') {
              responseBody = await resp.json();
            } else {
              responseBody = await resp.text();
            }

            logger.error(
              `Dora request for GET ${DoraAPIEndpoints.recognitionBuilder} failed`,
              {
                prompt,
                threadId,
                responseBody,
                statusCode: resp.status,
                url: resp.url,
              }
            );

            throw new Error('Dora Recognition Writer');
          }
        },

        onerror(err) {
          updateError(formatMessage(sharedMessages.genericError));
          logger.error(
            `Error event received from GET ${DoraAPIEndpoints.recognitionBuilder}`,
            { error: err, prompt, threadId }
          );
        },

        async onmessage(msg) {
          if (signal.aborted) {
            return;
          }

          if (msg.event === 'error') {
            const response = JSON.parse(msg.data) as DoraErrorServerSideEvent;

            if (response.error === 'RATE_LIMIT_ERROR') {
              updateError(formatMessage(sharedMessages.rateLimitError));
            } else {
              updateError(formatMessage(sharedMessages.genericError));
            }

            logger.error(
              `Error event received from GET ${DoraAPIEndpoints.recognitionBuilder}`,
              { error: response, prompt, threadId }
            );
          } else if (msg.event === 'json') {
            const parsedMessage = JSON.parse(msg.data) as { data: string };
            streamedData += parsedMessage.data;

            setRecognitionDraft(parseJsonChunk(streamedData));
            setIsLoading(false);
            setIsStreaming(true);
          } else if (msg.event === 'additional_info') {
            const response = JSON.parse(msg.data) as AdditionalInfoData;
            setThreadId(response.data.thread_id);
            setRecognitionDraft((recognitionDraft) => ({
              ...recognitionDraft,
              promptId: response.data.prompt_id,
            }));
          } else if (msg.event === 'end') {
            abortController?.abort();
            if (recognitionDraft) {
              setDrafts((drafts) => [...drafts, recognitionDraft]);
              setCurrentDraftIndex(drafts.length + 1);
            }
            setIsStreaming(false);
          }
        },
        signal,
      });
    },
    [abortController, drafts, formatMessage, recognitionDraft, threadId]
  );

  return {
    getRecognitionDraft,
    recognitionDraft,
    getPreviousDraft,
    currentDraftIndex,
    isLoading,
    isStreaming,
    errorMessage,
    threadId,
    resetWriter,
  };
}
