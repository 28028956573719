import { useParticipationModalContext } from '@assembly-web/participation';
import {
  useRecognitionParticipationRoute,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import { useAssemblyNavigate } from '@assembly-web/ui';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export function RecognitionParticipationRoute() {
  const navigate = useAssemblyNavigate();
  const { isEditFlow, editPostId } = useRecognitionParticipationRoute();
  const { openModal, setAiRecognitionModalOpen } =
    useParticipationModalContext();
  const { data } = useSuspenseUserDetails();
  const workspacePath = data.assembly.workspaceSlugPath;
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (isEditFlow) {
      openModal({ postId: editPostId, type: 'recognition' });
    } else {
      const recipientIds = searchParams.get('userId')?.split(',');
      const postMessage = searchParams.get('postMessage') || undefined; //TODO: Deprecate once v3 participation is enabled
      const useAIDraft = searchParams.get('useAIDraft') === 'true';
      if (useAIDraft) {
        setAiRecognitionModalOpen(true);
      } else {
        openModal({ recipientIds, postMessage, type: 'recognition' });
      }
    }
    navigate(`/a/${workspacePath}/flows/recognition${window.location.search}`, {
      replace: true,
    });
  }, [
    editPostId,
    isEditFlow,
    navigate,
    openModal,
    workspacePath,
    searchParams,
    setAiRecognitionModalOpen,
  ]);

  return null;
}
