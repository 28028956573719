export enum SplitNames {
  DoraUI = 'DORA_UI',
  AddMergeApps = 'ADD_MERGE_APPS',
  EnableUserActivitiesTracking = 'ENABLE_USER_ACTIVITIES_TRACKING',
  DisableRecentlyViewedDropdownOption = 'V3_DISABLE_RECENTLY_VIEWED_OPTION',
  FlowSummaries = 'FLOW_SUMMARIES_V0',
  AskDoraPopout = 'ASK_DORA_POPOUT',
  MultiPopoutDrawer = 'MULTI_POPOUT_DRAWER',

  GoogleRecaptcha = 'RECAPTCHA',
  AllowAssemblyCreation = 'ALLOW_ASSEMBLY_CREATION',

  EnableAssemblyMagicLink = 'ASSEMBLY_MAGIC_LINK',
  DoraReportingInsightsV0 = 'DORA_REPORTING_INSIGHTS_V0',
  DoraReportingInsightsManagers = 'DORA_REPORTING_INSIGHTS_MANAGERS',
  DoraAiRecognitionWriter = 'DORA_AI_RECOGNITION_WRITER',
  AmazonRewards = 'AMAZON_REWARDS',
  FlowCreation = 'FLOW_CREATION',
  FlowCreationOverride = 'FLOW_CREATION_OVERRIDE',
  FlowResponse = 'DORA_ENABLE_FLOW_RESPONSE_REPORTS',
  FlowDistribution = 'FLOW_DISTRIBUTION',
  FlowSimplifiedSelectionCriteria = 'FLOW_SIMPLIFIED_SELECTION_CRITERIA',
  SentrySessionReplay = 'SENTRY_SESSION_REPLAY',
  MSTeamsAppSubmission = 'MS_TEAMS_TESTING',
  MSTeamsNoWorkspaceInfo = 'MS_TEAMS_NO_WORKSPACE_INFO',
  G2Review = 'G2_REVIEW',
  Chat = 'V3_CHAT',
  ActivityUIRevamp = 'ACTIVITY_UI_REVAMP',
  EditorMilestones = 'EDITOR_MILESTONES',
  ReplyWithPostPreview = 'REPLY_WITH_POST_PREVIEW',
  ExcludePostsWithReplies = 'EXCLUDE_POSTS_WITH_REPLIES',
  AwardsPage = 'AWARDS_ENABLED',
}

export enum AdminSplitNames {
  NewAllowancePage = 'ALLOWANCE_ADMIN',
  NewManageUsersPage = 'MANAGE_USERS_ADMIN',
  EarnedBalancesPage = 'EARNED_BALANCES_ADMIN',
  ExchangeRates = 'EXCHANGE_RATES_ADMIN',
  AwardsPage = 'AWARDS_ENABLED',
  NewAnniversaryPage = 'NEW_ANNIVERSARY',
  AllowanceOneTimeDeposit = 'ALLOWANCE_ONE_TIME_DEPOSIT',
  WelcomeBot = 'ADMIN_WELCOME_BOT',
}
