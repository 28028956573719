import { logger } from '@assembly-web/services';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useDeferredValue, useMemo, useState } from 'react';
import type { FieldError } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { Banner } from '../../DesignSystem/Feedback/Banner';
import {
  $createTextChipNode,
  type SerializedTextChipNode,
  TextChipNode,
  TextTypeaheadOption,
} from './Editors/base/nodes/ComboboxChipNode/TextChipNode';
import { ComboboxPlugin } from './Editors/base/plugins/ComboboxPlugin';
import { Combobox } from './Editors/Combobox/Combobox';
import { QuickParticipation } from './QuickParticipation/QuickParticipation';

const config = { ...Combobox.initialConfig, nodes: [TextChipNode] };

export function CoreValueSelector({
  error,
  onBlur,
  onChange,
  coreValues,
  defaultValue,
  label,
}: {
  coreValues: {
    enabled: boolean;
    value: string[];
    isRequired: boolean;
  };
  error?: FieldError;
  onBlur: () => void;
  onChange: (value: string) => void;
  defaultValue?: string;
  label: string;
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const differedSearchTerm = useDeferredValue(searchTerm);

  const options = useMemo(() => {
    const options: TextTypeaheadOption[] = [];
    for (const value of coreValues.value) {
      if (
        value
          .toLocaleLowerCase()
          .includes(differedSearchTerm.toLocaleLowerCase())
      ) {
        options.push(new TextTypeaheadOption(value));
      }
    }
    return options.sort((a, b) => a.__text.localeCompare(b.__text));
  }, [coreValues.value, differedSearchTerm]);

  const deSerializedDraftValue = useMemo(() => {
    if (!defaultValue) {
      return undefined;
    }

    return ComboboxPlugin.$generateInitialState([
      {
        text: defaultValue,
        type: 'combobox-chip',
        version: 1,
      } satisfies SerializedTextChipNode,
    ]);
  }, [defaultValue]);

  return (
    <Combobox<TextTypeaheadOption, TextChipNode>
      error={error?.message}
      config={config}
      onBlur={onBlur}
      onError={(error, editor) => {
        logger.error(
          'Participation Core Value Editor Error',
          {
            editor,
          },
          error
        );
      }}
      placeholderVariant="purple"
      draftValue={deSerializedDraftValue}
      ComboboxProps={{
        onSelectedNodesChange(nodes) {
          onChange(nodes.map((node) => node.__text)[0]);
        },
        $createChipNode: $createTextChipNode,
        type: 'single',
        getKey(option) {
          return option.__text;
        },
        getChipKey(chip) {
          return chip.__text;
        },
        onSearchQueryChange(query) {
          if (query == null || query === ' ') {
            setSearchTerm('');
            return;
          }
          setSearchTerm(query.trim());
        },
        options,
        children({
          isSelected,
          options,
          selectOptionAndCleanUp,
          selectedIndex,
          setHighlightedIndex,
        }) {
          return (
            <ComboboxPlugin.Root
              className={twJoin(
                'z-20 max-h-[288px] overflow-auto',
                options.length > 5 && 'h-full'
              )}
            >
              <ComboboxPlugin.List>
                {options.length === 0 && (
                  <Banner status="info">
                    <FormattedMessage
                      defaultMessage="No results found. Try a new search."
                      id="KxjDle"
                    />
                  </Banner>
                )}
                {options.map((option, index) => {
                  const isHighlighted = selectedIndex === index;

                  const onClick = () => {
                    selectOptionAndCleanUp(option);
                  };

                  const onMouseEnter = () => {
                    setHighlightedIndex(index);
                  };

                  return (
                    <ComboboxPlugin.ListItem
                      key={option.__text}
                      onClick={onClick}
                      onMouseEnter={onMouseEnter}
                      index={index}
                      isSelected={isSelected(option)}
                      option={option}
                      renderOption={({ option, isSelected }) => (
                        <QuickParticipation.ItemWrapper
                          isSelected={isSelected}
                          highlighted={isHighlighted}
                        >
                          <span className="text w-full truncate">
                            {option.__text}
                          </span>
                          {Boolean(isSelected) && (
                            <CheckIcon className="h-6 w-6" />
                          )}
                        </QuickParticipation.ItemWrapper>
                      )}
                    />
                  );
                })}
              </ComboboxPlugin.List>
            </ComboboxPlugin.Root>
          );
        },
      }}
      label={label}
    />
  );
}
