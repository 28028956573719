import { Button, Modal, TextStyle, useDeviceInfo } from '@assembly-web/ui';
import { CheckIcon } from '@heroicons/react/24/outline';
import { defineMessages, useIntl } from 'react-intl';

const labels = defineMessages({
  title: {
    id: 'Fm+q7l',
    defaultMessage: 'Draft is auto-saved',
  },
  savingTitle: {
    id: 'QEMsbu',
    defaultMessage: 'Draft saving in progress',
  },
  content: {
    id: 'h6pMOT',
    defaultMessage:
      'Your Give Recognition post will be pre-filled with your draft next time you start a Recognition post.',
  },
  contentInProgress: {
    id: 'bOn+yn',
    defaultMessage:
      'Some files are still uploading, please keep this modal while they finish for your draft to save. Once saved, your Give Recognition post will be pre-filled with your draft next time you start a Recognition post.',
  },
  delete: {
    id: '4RpVDe',
    defaultMessage: 'Delete Draft',
  },
  close: {
    id: 'rbrahO',
    defaultMessage: 'Close',
  },
});

export function DraftModal({
  isOpen,
  onClose,
  isSaving = false,
}: {
  isOpen: boolean;
  onClose: (param?: { isDeleteDraft: true }) => void;
  isSaving?: boolean;
}) {
  const { formatMessage } = useIntl();
  const { isMobileDevice } = useDeviceInfo();
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (isSaving) {
          onClose({
            isDeleteDraft: true,
          });
        } else {
          onClose();
        }
      }}
      className="flex max-h-[unset] flex-col max-sm:h-full max-sm:w-full max-sm:max-w-[unset] sm:max-h-[85vh]"
      bodyClassName="max-sm:flex-1"
      closeButtonSize="xLarge"
      title={
        <div className="flex items-center gap-2">
          <CheckIcon className="m-1 h-6 w-6 rounded-full bg-success-3 p-1 text-success-7" />
          <TextStyle
            as="span"
            variant={isMobileDevice ? 'xl-bold' : '2xl-bold'}
          >
            {formatMessage(isSaving ? labels.savingTitle : labels.title)}
          </TextStyle>
        </div>
      }
      ctaSection={
        <div className="flex w-full justify-end gap-3">
          <Button
            variation="dangerLite"
            className="w-1/2"
            onClick={() =>
              onClose({
                isDeleteDraft: true,
              })
            }
          >
            {formatMessage(labels.delete)}
          </Button>
          <Button
            variation="primary"
            onClick={() => onClose()}
            className="w-1/2"
            disabled={isSaving}
          >
            {formatMessage(labels.close)}
          </Button>
        </div>
      }
    >
      <TextStyle>
        {formatMessage(isSaving ? labels.contentInProgress : labels.content)}
      </TextStyle>
    </Modal>
  );
}
