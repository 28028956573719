import { useFormContext, useWatch } from 'react-hook-form';

export const useRecipientCount = () => {
  const { control } = useFormContext();

  const recipientsCount = useWatch({
    control,
    name: 'recipientsCount',
    defaultValue: 0,
  });

  return recipientsCount as number;
};
