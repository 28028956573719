import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';

import { APIEndpoints } from '../../APIEndpoints';
import { assemblyAPI } from '../../assemblyAPI';
import type { Criteria } from '../../types/recognitionParticipation';

type Response = AxiosResponse<{ count: number }>;

export const useCriteriaCount = (
  payload: {
    criteria: Criteria;
    for?: string;
  },
  options?: UseQueryOptions<Response, Error, Response>
) => {
  return useQuery({
    ...options,
    queryKey: ['getCriteriaCount', payload],
    queryFn: async ({ signal }) => {
      return await assemblyAPI.post<{ count: number }>(
        APIEndpoints.getCriteriaCount(),
        payload,
        { signal }
      );
    },
    enabled:
      payload.criteria.include.reduce(
        (acc, item) =>
          acc + (item.field === 'everyone' ? 1 : item.values.length),
        0
      ) > 0,
  });
};
