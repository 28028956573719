import {
  getUserMaxGivingPointsPerPost,
  hasPermissionToGiveRecognition,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

export function usePointsConfig() {
  const { data: currentUser } = useSuspenseUserDetails();
  const showPointsBlock = hasPermissionToGiveRecognition(currentUser.member);
  const { setValue } = useFormContext();

  useEffect(() => {
    if (!showPointsBlock) {
      setValue('points', '');
    }
  }, [showPointsBlock, setValue]);

  const getMaxPointsPerHead = useCallback(
    (memberCount: number) => {
      const eligiblePointsToGivePerHead =
        getUserMaxGivingPointsPerPost(currentUser);
      const accountBalance = currentUser.member.pointsLeftThisCycle;
      let pointsToGive;
      if (accountBalance >= eligiblePointsToGivePerHead * memberCount) {
        pointsToGive = eligiblePointsToGivePerHead;
      } else {
        pointsToGive = accountBalance / memberCount;
      }
      if (!memberCount) {
        return eligiblePointsToGivePerHead;
      }
      return Math.floor(pointsToGive);
    },
    [currentUser]
  );

  return {
    showPointsBlock,
    getMaxPointsPerHead,
  };
}
