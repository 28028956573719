import type {
  InfiniteData,
  UndefinedInitialDataInfiniteOptions,
} from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';

import { APIEndpoints } from '../../APIEndpoints';
import { assemblyAPI } from '../../assemblyAPI';
import {
  defaultAggregates,
  getSecondaryFiltersQueryCacheKey,
  GlobalFilterOption,
} from '../../constants/filter';
import { SplitNames } from '../../constants/SplitNames';
import { useFeatureSplit } from '../../split/useFeatureSplit';
import type { ChallengeState } from '../../types/challenges';
import type { Cursor, SearchAggregation } from '../../types/response';
import { RewardType } from '../../types/rewards';
import {
  type AssemblySearchIndexRequestBody,
  type ContentTypeFilter,
  type ContentTypeFilterExpanded,
  type DateRange,
  type DocumentSource,
  type FlowStatusType,
  isRecognitionContentTypeState,
  type RecognitionContentTypeState,
  type SearchIndexDocumentType,
  type SearchIndexResult,
} from '../../types/searchIndex';

export enum SearchSortType {
  LexicographicalAscending = 'lexicographicalAscending',
  LexicographicalDescending = 'lexicographicalDescending',
  Newest = 'newest',
  Oldest = 'oldest',
}

export type SearchPayload = {
  searchTerm: string;
  selectedFilters: GlobalFilterOption[];
  from: number;
  sortBy: SearchSortType;
  excludeRecognitionFlow: boolean;
  customSort?: {
    profileStatus?: boolean;
  };
  secondaryFilters?: {
    dueDate?: DateRange;
    dateCreated?: DateRange;
    fromRef?: string[];
    entityIn?: string[];
    mentionedMemberIds?: string[];
    postContentType?: ContentTypeFilter;
    assignedToRef?: string[];
    taskState?: string[];
    fileMimeTypes?: string[];
    department?: string[];
    workLocation?: string[];
    homeLocation?: string[];
    jobTitle?: string[];
    managers?: string[];
    flowStatus?: FlowStatusType[];
    challengeStatus?: ChallengeState[];
    state?: FlowStatusType[];
    memberState?: string[];
    source?: DocumentSource[];
  };
  rewardsSecondaryFilters?: {
    category?: string;
    country?: string;
    sortOrder?: string;
  };
};

export type SearchParams = {
  searchTerm?: string;
  selectedFilters?: GlobalFilterOption[];
  from?: number;
  sortBy?: SearchSortType;
  enabled: boolean;
  excludeRecognitionFlow?: boolean;
  populateCardDetails?: boolean;
  customSort?: {
    profileStatus?: boolean;
  };
  secondaryFilters?: {
    fromRef?: string[];
    entityIn?: string[];
    mentionedMemberIds?: string[];
    postContentType?: ContentTypeFilter;
    assignedToRef?: string[];
    taskState?: string[];
    fileMimeTypes?: string[];
    department?: string[];
    workLocation?: string[];
    managers?: string[];
    homeLocation?: string[];
    jobTitle?: string[];
    dueDate?: DateRange;
    dateCreated?: DateRange;
    flowStatus?: FlowStatusType[];
    challengeStatus?: ChallengeState[];
    state?: FlowStatusType[];
    memberState?: string[];
    source?: DocumentSource[];
  };
  rewardsSecondaryFilters?: {
    category?: string;
    country?: string;
    sortOrder?: string;
  };
};

export function generateAggregates(
  metadata: MetadataProps
): Record<GlobalFilterOption, number> {
  if (!metadata.aggregations) {
    return defaultAggregates;
  }

  const aggregates = { ...defaultAggregates };
  const aggregations = metadata.aggregations as SearchAggregation[];
  if (Array.isArray(aggregations)) {
    aggregations.forEach((record) => {
      const key = record.type;
      const value = record.count;

      if (key === 'file') {
        aggregates[GlobalFilterOption.Files] = value;
      } else if (key === 'task') {
        aggregates[GlobalFilterOption.Tasks] = value;
      } else if (key === 'flow') {
        aggregates[GlobalFilterOption.Flows] = value;
      } else if (key === 'recognition') {
        aggregates[GlobalFilterOption.Flows] =
          value + (aggregates[GlobalFilterOption.Flows] || 0);
      } else if (key === 'member') {
        aggregates[GlobalFilterOption.People] = value;
      } else if (['tangoReward', 'customReward', 'swag'].includes(key)) {
        aggregates[GlobalFilterOption.Rewards] =
          aggregates[GlobalFilterOption.Rewards] + value;
      } else if (key === 'challenge') {
        aggregates[GlobalFilterOption.Challenges] =
          aggregates[GlobalFilterOption.Challenges] + value;
      } else if (key === 'all') {
        aggregates[GlobalFilterOption.All] = value;
      }
    });
  } else {
    return metadata.aggregations as Record<GlobalFilterOption, number>;
  }

  return aggregates;
}

function fetchSearchResults<TData>(
  params: SearchParams,
  options?: Partial<
    UndefinedInitialDataInfiniteOptions<
      SearchIndexApiResponse,
      Error,
      InfiniteData<TData>
    >
  >,
  isAwardsEnabled?: boolean
): UndefinedInitialDataInfiniteOptions<
  SearchIndexApiResponse,
  Error,
  InfiniteData<TData>
> {
  const {
    searchTerm = '',
    from = 0,
    selectedFilters = [GlobalFilterOption.All],
    sortBy = SearchSortType.LexicographicalAscending,
    enabled = true,
    excludeRecognitionFlow = false,
    populateCardDetails,
    customSort: { profileStatus } = {},
    secondaryFilters: {
      fromRef: assemblyFromRef,
      entityIn: assemblyEntityIn,
      mentionedMemberIds: assemblyMentionedMemberIds,
      postContentType: assemblyContentType,
      assignedToRef,
      taskState,
      fileMimeTypes,
      department,
      workLocation,
      managers,
      homeLocation,
      jobTitle,
      flowStatus,
      dueDate,
      dateCreated,
      challengeStatus,
      state,
      memberState,
      source,
    } = {},
    rewardsSecondaryFilters: {
      category,
      country,
      sortOrder: rewardsSortOrder,
    } = {},
  } = params;
  const requestBody: AssemblySearchIndexRequestBody = {
    cursor: {
      from,
      limit: 20,
    },
    filters: {
      createdAt: !selectedFilters.includes(GlobalFilterOption.People)
        ? dateCreated
        : undefined,
      source:
        source ||
        ([
          'assembly',
          'box',
          'dropbox',
          'googleDrive',
          'oneDrive',
          'sharePoint',
        ] as DocumentSource[]),
      ...(assemblyEntityIn?.length && {
        'assembly.entityIn': assemblyEntityIn.map((entity) => {
          return entity === 'recognition' ? 'post' : entity;
        }),
      }),
      ...(assemblyFromRef?.length && {
        'assembly.fromRef': assemblyFromRef,
      }),
      ...(dueDate && {
        'assembly.task.dueDate': dueDate,
      }),
      ...(assemblyMentionedMemberIds?.length && {
        'assembly.mentionedMemberIds': assemblyMentionedMemberIds,
      }),
      ...(assignedToRef?.length && {
        'assembly.task.assignedToRef': assignedToRef,
      }),
      ...(taskState?.length && {
        'assembly.task.state': taskState,
      }),
      ...(fileMimeTypes && {
        'assembly.file.fileMimeType': fileMimeTypes,
      }),
      ...(department && {
        'assembly.member.department': department,
      }),
      ...(homeLocation && {
        'assembly.member.location': homeLocation,
      }),
      ...(workLocation && {
        'assembly.member.workLocation': workLocation,
      }),
      ...(managers && {
        'assembly.member.managerIds': managers,
      }),
      ...(jobTitle && {
        'assembly.member.jobTitle': jobTitle,
      }),
      ...(memberState && {
        'assembly.member.state': memberState,
      }),
    },
    tangoFilter: {},
    indexName: 'search-assembly-v3-std',
    searchTerm,
    populateCardDetails,
  };

  if (selectedFilters.includes(GlobalFilterOption.Files)) {
    requestBody.filters.type = ['file'];
  } else if (selectedFilters.includes(GlobalFilterOption.Tasks)) {
    requestBody.filters.type = ['task'];

    requestBody.sortOptions = [
      {
        updatedAt: sortBy === SearchSortType.Oldest ? 'asc' : 'desc',
      },
    ];
  } else if (selectedFilters.includes(GlobalFilterOption.Flows)) {
    requestBody.filters.type = ['flow'];
    requestBody.filters.state = flowStatus
      ? flowStatus
      : ['ACTIVE', 'INACTIVE'];

    requestBody.sortOptions = [
      {
        'assembly.flow.name':
          sortBy === SearchSortType.LexicographicalAscending ? 'asc' : 'desc',
      },
    ];
  } else if (selectedFilters.includes(GlobalFilterOption.Recognition)) {
    requestBody.filters.type = ['post', 'comment'];
    const postContentType = params.secondaryFilters?.postContentType;
    if (postContentType) {
      let filterValues: RecognitionContentTypeState[] = [];
      if (postContentType.includes('replies')) {
        filterValues = ['postComment'];
      }
      filterValues.push(
        ...postContentType
          .split(',')
          .filter((value) =>
            isRecognitionContentTypeState(value, isAwardsEnabled)
          )
      );
      requestBody.filters['assembly.post.type'] = filterValues;
    }
  } else if (selectedFilters.includes(GlobalFilterOption.People)) {
    requestBody.filters.type = ['member'];
    requestBody.filters.state = state ?? ['ACTIVE'];

    requestBody.sortOptions = [
      ...(profileStatus
        ? [
            {
              'assembly.member.profileStatus': 'asc',
            },
          ]
        : []),
      {
        'assembly.member.fullName':
          sortBy === SearchSortType.LexicographicalAscending ? 'asc' : 'desc',
      },
    ];
  } else if (selectedFilters.includes(GlobalFilterOption.Challenges)) {
    requestBody.filters.type = ['challenge'];
    requestBody.filters.state = ['ACTIVE', 'INACTIVE'];
    if (challengeStatus) {
      requestBody.filters['assembly.challenge.state'] = challengeStatus;
    }
    requestBody.sortOptions = [
      {
        'assembly.challenge.state': 'asc',
      },
      {
        createdAt: 'desc',
      },
    ];
    requestBody.userActivitySortType = null;
  } else if (selectedFilters.includes(GlobalFilterOption.Rewards)) {
    const queryParams = new URLSearchParams(window.location.search);
    const tab = queryParams.get('tab');
    if (tab === RewardType.GiftCards) {
      requestBody.filters.type = ['tangoReward'];
      requestBody.filters.tags = [
        'gift card',
        'cash equivalent',
        'reward link',
      ];
      //Handling an edge case when country selected is GB. It'll get cards for both GB and UK.
      if (country && country === 'GB') {
        requestBody.tangoFilter.countries = [country, 'UK'];
      } else {
        country && (requestBody.tangoFilter.countries = [country]);
      }
      if (rewardsSortOrder) {
        requestBody.sortOptions = [
          {
            'tango.name': rewardsSortOrder,
          },
        ];
      }
    } else if (tab === RewardType.Swag) {
      requestBody.filters.type = ['swag'];
      category && (requestBody.filters['swag.categories'] = [Number(category)]);
      if (rewardsSortOrder) {
        requestBody.sortOptions = [
          {
            'swag.name': rewardsSortOrder,
          },
        ];
      }
    } else if (tab === RewardType.Charities) {
      requestBody.filters.type = ['tangoReward'];
      requestBody.filters.tags = ['donation'];
      if (rewardsSortOrder) {
        requestBody.sortOptions = [
          {
            'tango.name': rewardsSortOrder,
          },
        ];
      }
    } else if (tab === RewardType.Culture) {
      requestBody.filters.type = ['customReward'];
      if (rewardsSortOrder) {
        requestBody.sortOptions = [
          {
            'culture.name': rewardsSortOrder,
          },
        ];
      }
    }
  } else if (selectedFilters.includes(GlobalFilterOption.Department)) {
    requestBody.filters.type = ['member'];
    requestBody.filters.state = ['ACTIVE', 'INACTIVE'];
    requestBody.filters['assembly.member.department'] = department;
    requestBody.sortOptions = [
      {
        'assembly.member.profileStatus': 'asc',
      },
      {
        'assembly.member.fullName': 'asc',
      },
    ];
  } else {
    const defaultFilters: SearchIndexDocumentType[] = [
      'post',
      'comment',
      'response',
    ];

    if (isAwardsEnabled) {
      const typesToExcludeForAllFilter = ['collection', 'file'];
      requestBody.filters.type = defaultFilters;

      const postContentType = params.secondaryFilters?.postContentType;

      const postTypes = ['recognition', 'award', 'birthday', 'anniversary'];

      if (postContentType) {
        if (!postContentType.includes('flow')) {
          requestBody.filters.type = requestBody.filters.type.filter(
            (item) => item !== 'response'
          );
        }

        if (postContentType.includes('replies')) {
          requestBody.filters.type = [...requestBody.filters.type, 'comment'];
        }

        if (!postTypes.some((type) => postContentType.includes(type))) {
          requestBody.filters.type = requestBody.filters.type.filter(
            (item) => item !== 'post'
          );
        } else {
          let filterValues: RecognitionContentTypeState[] = [];
          filterValues = (postContentType as ContentTypeFilterExpanded)
            .split(',')
            .filter((type): type is RecognitionContentTypeState => {
              return (
                type === 'recognition' ||
                type === 'award' ||
                type === 'birthday' ||
                type === 'anniversary'
              );
            });
          requestBody.filters['assembly.post.type'] = filterValues;
        }

        requestBody.filters.type = requestBody.filters.type.filter(
          (type) => !typesToExcludeForAllFilter.includes(type)
        );
      }
    } else {
      const requestFiltersType: SearchIndexDocumentType[] = defaultFilters;

      const postContentType = params.secondaryFilters?.postContentType;
      if (postContentType === 'replies' || postContentType === 'posts') {
        const commentFilter =
          requestBody.filters['assembly.fromRef']?.length ??
          requestBody.filters.createdAt ??
          assemblyMentionedMemberIds?.length;

        const contentTypeMappings: Record<string, SearchIndexDocumentType[]> = {
          posts: ['post', 'response'],
          replies: commentFilter ? ['comment'] : ['groupedComment'],
        };

        requestBody.filters.type = contentTypeMappings[postContentType];
      } else {
        requestBody.filters.type = requestFiltersType;
      }
    }
  }

  if (searchTerm) {
    requestBody.sortOptions = [];
  }

  return {
    ...options,
    queryKey: getSearchCacheKey({
      searchTerm,
      from,
      selectedFilters,
      sortBy,
      excludeRecognitionFlow,
      customSort: {
        profileStatus,
      },
      secondaryFilters: {
        fromRef: assemblyFromRef,
        entityIn: assemblyEntityIn,
        mentionedMemberIds: assemblyMentionedMemberIds,
        postContentType: assemblyContentType,
        assignedToRef,
        taskState,
        fileMimeTypes,
        department,
        workLocation,
        managers,
        flowStatus,
        homeLocation,
        jobTitle,
        dueDate,
        dateCreated,
        challengeStatus,
      },
      rewardsSecondaryFilters: {
        country,
        category,
        sortOrder: rewardsSortOrder,
      },
    }),
    queryFn: async ({ pageParam, signal }) => {
      return await assemblyAPI.post(
        APIEndpoints.searchIndex,
        {
          ...requestBody,
          cursor: {
            from: pageParam,
            limit: 20,
          },
        },
        { signal }
      );
    },
    initialPageParam: 0,
    getNextPageParam: (page: SearchIndexApiResponse) => {
      return page.data.metadata.pagination.cursor.from +
        page.data.metadata.pagination.cursor.limit <=
        page.data.total
        ? page.data.metadata.pagination.cursor.from +
            page.data.metadata.pagination.cursor.limit
        : undefined;
    },
    enabled,
  };
}

type MetadataProps = {
  pagination: {
    cursor: Cursor;
  };
  aggregations:
    | SearchAggregation[]
    | Record<GlobalFilterOption, number>
    | undefined;
};

export type SearchIndexApiResponse = {
  data: {
    total: number;
    data: SearchIndexResult[];
    metadata: MetadataProps;
  };
};

export function getSearchCacheKey(payload: SearchPayload) {
  const {
    searchTerm,
    selectedFilters,
    sortBy,
    from,
    excludeRecognitionFlow,
    customSort = {},
    secondaryFilters: {
      entityIn,
      fromRef,
      mentionedMemberIds,
      assignedToRef,
      taskState,
      fileMimeTypes,
      department,
      homeLocation,
      workLocation,
      managers,
      postContentType: assemblyContentType,
      dueDate,
      dateCreated,
      flowStatus,
      jobTitle,
      challengeStatus,
      memberState,
      state,
    } = {},
    rewardsSecondaryFilters: {
      category,
      country,
      sortOrder: rewardsSortOrder,
    } = {},
  } = payload;
  const dueDateString = dueDate
    ? `${dueDate.gte + '-' + dueDate.lte}-dueDate`
    : '';
  const dateCreatedString = dateCreated
    ? `${dateCreated.gte + '-' + dateCreated.lte}-dateCreated`
    : '';
  const url = new URLSearchParams(window.location.search);
  const rewardTypeString = selectedFilters.includes(GlobalFilterOption.Rewards)
    ? `rewards-${url.get('tab')}`
    : '';
  const secondaryFiltersCacheKey = getSecondaryFiltersQueryCacheKey({
    entityIn,
    fromRef,
    mentionedMemberIds,
    flowStatus,
    assignedToRef,
    taskState,
    fileMimeTypes,
    department,
    homeLocation,
    workLocation,
    managers,
    jobTitle,
    challengeStatus,
    memberState,
    state,
  });

  return [
    'searchResults',
    searchTerm,
    selectedFilters,
    sortBy,
    from,
    excludeRecognitionFlow,
    assemblyContentType,
    dueDateString,
    dateCreatedString,
    rewardTypeString,
    category,
    country,
    rewardsSortOrder,
    secondaryFiltersCacheKey,
    customSort,
  ].filter(Boolean);
}

export function useSearchIndex<TData = SearchIndexApiResponse>(
  payload: SearchParams,
  options?: Partial<
    UndefinedInitialDataInfiniteOptions<
      SearchIndexApiResponse,
      Error,
      InfiniteData<TData>
    >
  >
) {
  const { isTreatmentActive: isAwardsEnabled } = useFeatureSplit(
    SplitNames.AwardsPage
  );
  return useInfiniteQuery<SearchIndexApiResponse, Error, InfiniteData<TData>>(
    fetchSearchResults<TData>(payload, options, isAwardsEnabled)
  );
}
