import type {
  AssemblyCurrency,
  Nullable,
  ReplyData,
  Timeout,
} from '@assembly-web/services';
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  size,
  useFloating,
} from '@floating-ui/react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import type { MenuTextMatch } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import {
  LexicalTypeaheadMenuPlugin,
  MenuOption,
  useBasicTypeaheadTriggerMatch,
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { mergeRegister } from '@lexical/utils';
import { Portal } from '@radix-ui/react-portal';
import {
  $createRangeSelection,
  $createTextNode,
  $insertNodes,
  $setSelection,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  type TextNode,
} from 'lexical';
import {
  type ElementRef,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useInViewport } from 'react-in-viewport';
import { defineMessages, useIntl } from 'react-intl';
import { RemoveScroll } from 'react-remove-scroll';
import { twMerge } from 'tailwind-merge';

import { PostDrawerContext } from '../../../../../context/PostDrawerContext';
import { useRefContainer } from '../../../../../context/RefContext';
import {
  Avatar,
  AvatarSize,
} from '../../../../../DesignSystem/Feedback/Avatar';
import { TextStyle } from '../../../../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../../../../DesignSystem/Inputs/Button';
import { ProfileIcon } from '../../../../../Icons';
import { useMobileOrTouchDevice } from '../../../../hooks/useMobileOrTouchDevice';
import { BoostManager } from '../../../Boost/BoostManager';
import { BoostProfileCard } from '../../../Boost/BoostProfileCard';
import {
  Popover,
  PopoverAnchor,
  PopoverContent,
  PopoverPortal,
} from '../../../Boost/ProfileBasePopover';
import type { BoostOptions } from '../../RepliesEditor/RepliesEditor';
import type { MentionNode, MentionNodeUser } from '../nodes/MentionNode';
import { $createMentionNode } from '../nodes/MentionNode';
import { $getMentionNodes } from '../utils';

const LengthLimit = 75;
const AliasLengthLimit = 50;

const Punctuation =
  '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';
const Name = '\\b[A-Z][^\\s' + Punctuation + ']';
const DocumentMentionsRegex = {
  Name,
  Punctuation,
};
const PunctuationRegex = DocumentMentionsRegex.Punctuation;
const MentionTrigger = ['@'].join('');
const ValidCharacters = '[^' + MentionTrigger + PunctuationRegex + '\\s]';
const ValidJoins =
  '(?:' + '\\.[ |$]|' + ' |' + '[' + PunctuationRegex + ']|' + ')';

const AtSignMentionsRegex = new RegExp(
  '(^|\\s|\\()(' +
    '[' +
    MentionTrigger +
    ']' +
    '((?:' +
    ValidCharacters +
    ValidJoins +
    '){0,' +
    LengthLimit +
    '})' +
    ')$'
);

const AtSignMentionsRegexAliasRegex = new RegExp(
  '(^|\\s|\\()(' +
    '[' +
    MentionTrigger +
    ']' +
    '((?:' +
    ValidCharacters +
    '){0,' +
    AliasLengthLimit +
    '})' +
    ')$'
);

const mentionedTeammatesId = 'mentionedTeammates';

const messages = defineMessages({
  mentionedTeammates: {
    id: 'kGHso0',
    defaultMessage: `{count, plural,
                      =1 {1 mentioned teammate}
                      other {All {count} mentioned teammates}
                     }`,
  },
  cantViewPostLabel: {
    defaultMessage: `Can’t view post`,
    id: 'IfQShS',
  },
  mentionListHeader: {
    defaultMessage: 'Select who you want to boost',
    id: '1/RNq9',
  },
});

function checkForAtSignMentions(
  text: string,
  minMatchLength: number
): MenuTextMatch | null {
  let match = AtSignMentionsRegex.exec(text);

  if (match === null) {
    match = AtSignMentionsRegexAliasRegex.exec(text);
  }
  if (match !== null) {
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
    }
  }
  return null;
}

function getPossibleQueryMatch(text: string): MenuTextMatch | null {
  return checkForAtSignMentions(text, 0);
}

class MentionTypeaheadOption extends MenuOption {
  public isFlowViewer?: boolean;
  public mentionNodeUser: MentionNodeUser;

  constructor(mentionNodeUser: MentionNodeUser, isFlowViewer?: boolean) {
    super(mentionNodeUser.id);

    const { id } = mentionNodeUser;

    this.key = id;
    this.isFlowViewer = isFlowViewer;
    this.mentionNodeUser = mentionNodeUser;
  }
}

function MentionsTypeaheadMenuItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
}: {
  index: number;
  isSelected: boolean;
  onMouseEnter: () => void;
  option: MentionTypeaheadOption;
  onClick: (e: React.MouseEvent) => void;
}) {
  const { formatMessage } = useIntl();

  return (
    <Button
      key={option.key}
      variation="tertiaryLite"
      ref={option.setRefElement}
      onMouseEnter={onMouseEnter}
      id={'typeahead-item-' + index}
      onClick={onClick}
      className={twMerge(
        'flex w-full items-center !justify-start gap-2 rounded-none px-3 py-2 hover:bg-gray-2',
        isSelected && 'bg-gray-2'
      )}
    >
      <div
        className={twMerge(
          'grid gap-2',
          option.isFlowViewer
            ? 'grid-cols-[24px_312px]'
            : 'grid-cols-[24px_212px_100px]'
        )}
      >
        {option.key === mentionedTeammatesId ? (
          <ProfileIcon className="h-6 w-6" />
        ) : (
          <Avatar
            memberID={option.key}
            size={AvatarSize.Small}
            name={option.mentionNodeUser.name}
            image={option.mentionNodeUser.image ?? undefined}
          />
        )}
        <TextStyle className="truncate text-left text-gray-8">
          {option.mentionNodeUser.name}
        </TextStyle>
        {!option.isFlowViewer && (
          <TextStyle variant="xs-regular" className="text-gray-8">
            {formatMessage(messages.cantViewPostLabel)}
          </TextStyle>
        )}
      </div>
    </Button>
  );
}

export type MentionedUser = MentionNodeUser & {
  isFlowViewer?: boolean;
};

export type MentionedUserInReply = {
  id: string;
  name: string;
  image?: string;
  email?: string;
  lastName?: string;
  username?: string;
  memberID?: string;
  firstName?: string;
  pointsGiven?: number;
  memberState?: string;
  role?: string | string[];
  totalPointsGiven?: number;
  status?: string;
};

export type CurrentUserDetails = {
  id: string;
  name: string;
  allowance: number;
  image?: string;
};

type MentionPluginProps = {
  hasNextPage?: boolean;
  isEditing: boolean;
  selectedReply: Nullable<ReplyData>;
  members: MentionedUser[];
  isMembersLoading?: boolean;
  isMembersFetching?: boolean;
  currency: AssemblyCurrency;
  isFetchingNextPage?: boolean;
  onNextPageScrolled: () => void;
  onMenuOpenChange?: (open: boolean) => void;
  mentionedUsersInPost: MentionedUser[] | null;
  onSearchQueryChange?: (query: Nullable<string>) => void;
  boostOptions: BoostOptions;
  isAnonymousReply: boolean;
  onViewProfileClick?: (memberId: string) => void;
};

export const MENTION_NODE_CLICK_COMMAND = createCommand<{
  mention: MentionedUser | null;
  node: HTMLElement | null;
}>('MENTION_NODE_CLICK_COMMAND');

export const BOOST_CLICK_COMMAND = createCommand('BOOST_CLICK_COMMAND');

export const MENTION_NODE_COMMAND = createCommand<{ node: HTMLElement | null }>(
  'MENTION_NODE_COMMAND'
);

export const GIVE_BOOST_COMMAND = createCommand<{
  mention: MentionedUser;
  node: HTMLElement | null;
}>('GIVE_BOOST_REDIRECT');

export const IS_EDIT_MODE = createCommand('IS_EDIT_MODE');

export const REPLY_TYPE_CHANGE_COMMAND = createCommand(
  'REPLY_TYPE_CHANGE_COMMAND'
);

export const POINTS_EXHAUSTED_COMMAND = createCommand<{ exhausted: boolean }>(
  'POINTS_EXHAUSTED'
);

export const BOOST_POPUP_CONTENT_HOVERED_COMMAND = createCommand(
  'BOOST_POPUP_CONTENT_HOVERED_COMMAND'
);

export const BOOST_POPUP_CLOSE_COMMAND = createCommand(
  'BOOST_POPUP_CLOSE_COMMAND'
);

const getFilteredNodes = <T extends MentionNode | MentionNodeUser>(
  mentionedUsers: T[]
) => {
  const seenMentions = new Set<string>();
  return mentionedUsers.filter((user) => {
    const el =
      'mentionedMember' in user
        ? user.mentionedMember
        : (user as MentionNodeUser);

    if (el.currentUserID === el.memberID) {
      return false;
    }
    if (seenMentions.has(el.memberID)) {
      return false;
    }
    seenMentions.add(el.memberID);
    return true;
  });
};

export function MentionsPlugin({
  members,
  onMenuOpenChange,
  onNextPageScrolled,
  isFetchingNextPage,
  currency,
  onSearchQueryChange,
  mentionedUsersInPost,
  boostOptions,
  isAnonymousReply,
  selectedReply,
  isEditing,
  onViewProfileClick,
}: MentionPluginProps) {
  const endOfListRef = useRef<ElementRef<'div'>>(null);
  const { inViewport: hasReachedEndOfDropdownItem } =
    useInViewport(endOfListRef);

  const { x, y, refs, strategy } = useFloating({
    placement: 'top-start',
    whileElementsMounted: autoUpdate,
    middleware: [offset(15), shift(), flip(), size()],
  });

  const [editor] = useLexicalComposerContext();
  const drawerContainer = useRefContainer();

  const postDrawerContext = useContext(PostDrawerContext);

  const { formatMessage } = useIntl();

  // Selected Mentioned User and Node in the Editor
  const [selectedMentionedUser, setSelectedMentionedUser] =
    useState<MentionedUser | null>(null);
  const [selectedMentionedNode, setSelectedMentionedNode] =
    useState<HTMLElement | null>(null);

  // List of users who were selected from the typeahead
  const [currentMentionedUsers, setCurrentMentionedUsers] = useState<
    MentionedUser[] | null
  >(null);

  const [showProfileCard, setShowProfileCard] = useState(false);
  const [isMentionsTriggeredViaBoost, setIsMentionsTriggeredViaBoost] =
    useState(false);
  const [showBoostManager, setShowBoostManager] = useState(false);

  const [queryString, setQueryString] = useState<string | null>(null);

  const [pointsLimit, setPointsLimit] = useState({
    remainingWhenAll: 0,
    remaining: 0,
    total: 0,
  });
  const [mentionedUsersInEditor, setMentionedUsersInEditor] = useState<
    MentionedUser[]
  >([]);
  const [previousMentionedUsersInEditor, setPreviousMentionedUsersInEditor] =
    useState<MentionedUser[]>(mentionedUsersInEditor);

  const rootContainerRef = useRefContainer();

  const closeTimerRef = useRef<Timeout | null>(null);

  const isMobileOrTouchDevice = useMobileOrTouchDevice();

  const handleBoostPopoverClose = useCallback(() => {
    setShowProfileCard(false);
    setShowBoostManager(false);

    setSelectedMentionedNode(null);
    setSelectedMentionedUser(null);
    setCurrentMentionedUsers(null);
  }, []);

  useEffect(() => {
    onSearchQueryChange?.(queryString);
  }, [onSearchQueryChange, queryString]);

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        MENTION_NODE_CLICK_COMMAND,
        (payload) => {
          if (closeTimerRef.current) {
            clearTimeout(closeTimerRef.current);
          }

          setShowProfileCard(true);
          setSelectedMentionedNode(payload.node);
          setSelectedMentionedUser(payload.mention);
          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        MENTION_NODE_COMMAND,
        (payload) => {
          setSelectedMentionedNode(payload.node);
          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        BOOST_CLICK_COMMAND,
        () => {
          setIsMentionsTriggeredViaBoost(true);
          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        BOOST_POPUP_CLOSE_COMMAND,
        () => {
          handleBoostPopoverClose();
          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [editor, handleBoostPopoverClose]);

  useEffect(() => {
    if (hasReachedEndOfDropdownItem) {
      onNextPageScrolled();
    }
  }, [hasReachedEndOfDropdownItem, onNextPageScrolled]);

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const mentionNodes = $getMentionNodes();
        const filteredMentionedNodes = getFilteredNodes(mentionNodes);

        const totalPoints = filteredMentionedNodes.reduce(
          (total, node) => total + (node.mentionedMember.points ?? 0),
          0
        );

        const isApplyAll = mentionNodes.some(
          (node) => node.mentionedMember.applyBoostToAll
        );

        if (!isApplyAll) {
          if (totalPoints >= boostOptions.maxGivingPointsPerPost) {
            editor.dispatchCommand(POINTS_EXHAUSTED_COMMAND, {
              exhausted: true,
            });
          } else {
            editor.dispatchCommand(POINTS_EXHAUSTED_COMMAND, {
              exhausted: false,
            });
          }
        }

        setPointsLimit({
          remaining: Math.round(
            boostOptions.maxGivingPointsPerPost - totalPoints
          ),
          remainingWhenAll:
            filteredMentionedNodes.length > 0
              ? Math.floor(
                  boostOptions.maxGivingPointsPerPost /
                    filteredMentionedNodes.length
                )
              : 0,
          total: totalPoints,
        });

        setMentionedUsersInEditor(
          mentionNodes.map((node) => node.exportJSON().mentionedMember)
        );
      });
    });
  }, [boostOptions, editor]);

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0,
  });

  const options = useMemo(() => {
    const celebrateOption =
      mentionedUsersInPost?.length && !queryString
        ? [
            new MentionTypeaheadOption(
              {
                ...mentionedUsersInPost[0],
                name: formatMessage(messages.mentionedTeammates, {
                  count: mentionedUsersInPost.length,
                }),
                id: mentionedTeammatesId,
              },
              true
            ),
          ]
        : [];

    const memberOptions = members.map(
      (member) => new MentionTypeaheadOption(member, member.isFlowViewer)
    );

    return [...celebrateOption, ...memberOptions];
  }, [mentionedUsersInPost, queryString, formatMessage, members]);

  const getFirstValidMentionedUser = useCallback(() => {
    const idx = mentionedUsersInEditor.findIndex(
      (user) => user.currentUserID !== user.memberID
    );
    return idx === -1 ? 0 : idx;
  }, [mentionedUsersInEditor]);

  const reBalance = useCallback(() => {
    editor.update(() => {
      const mentionNodes = $getMentionNodes();
      const filteredNodes = getFilteredNodes($getMentionNodes());

      if (mentionNodes.length === 0) {
        editor.dispatchCommand(POINTS_EXHAUSTED_COMMAND, {
          exhausted: false,
        });
        return;
      }

      if (
        !mentionNodes[getFirstValidMentionedUser()].mentionedMember
          .applyBoostToAll
      ) {
        return;
      }

      const maxPointsPerMention = Math.floor(
        boostOptions.maxGivingPointsPerPost / filteredNodes.length
      );

      if (
        maxPointsPerMention <= 1 &&
        filteredNodes.length >= boostOptions.maxGivingPointsPerPost
      ) {
        editor.dispatchCommand(POINTS_EXHAUSTED_COMMAND, {
          exhausted: true,
        });
      } else {
        editor.dispatchCommand(POINTS_EXHAUSTED_COMMAND, {
          exhausted: false,
        });
      }
      if (maxPointsPerMention < 1) {
        const seenMentions = new Map<string, number>();
        mentionNodes.reduce((accumulate, node) => {
          if (
            node.mentionedMember.currentUserID === node.mentionedMember.memberID
          ) {
            node.setPoint(0, editor);
            return accumulate;
          }
          if (seenMentions.has(node.mentionedMember.memberID)) {
            node.setPoint(
              seenMentions.get(node.mentionedMember.memberID) ?? 0,
              editor
            );
            return accumulate;
          }

          if (accumulate >= boostOptions.maxGivingPointsPerPost) {
            seenMentions.set(node.mentionedMember.memberID, 0);
            node.setPoint(0, editor);
            return accumulate;
          }
          seenMentions.set(node.mentionedMember.memberID, 1);
          node.setPoint(1, editor);

          return accumulate + 1;
        }, 0);
        return;
      }

      if (
        maxPointsPerMention >
        (mentionNodes[getFirstValidMentionedUser()].mentionedMember.points ?? 0)
      ) {
        const seenMentions = new Map<string, number>();
        mentionNodes.forEach((node) => {
          if (
            node.mentionedMember.currentUserID === node.mentionedMember.memberID
          ) {
            node.setPoint(0, editor);
            return;
          }
          if (seenMentions.has(node.mentionedMember.memberID)) {
            node.setPoint(
              seenMentions.get(node.mentionedMember.memberID) ?? 0,
              editor
            );
            return;
          }
          seenMentions.set(
            node.mentionedMember.memberID,
            node.mentionedMember.points ?? 0
          );
        });
        return;
      }

      const seenMentions = new Map<string, number>();
      mentionNodes.forEach((node) => {
        if (
          node.mentionedMember.currentUserID === node.mentionedMember.memberID
        ) {
          node.setPoint(0, editor);
          return;
        }
        if (seenMentions.has(node.mentionedMember.memberID)) {
          node.setPoint(
            seenMentions.get(node.mentionedMember.memberID) ?? 0,
            editor
          );
          return;
        }
        seenMentions.set(node.mentionedMember.memberID, maxPointsPerMention);

        node.setPoint(maxPointsPerMention, editor);
      });
    });
  }, [boostOptions.maxGivingPointsPerPost, editor, getFirstValidMentionedUser]);

  const shouldAddPointsToAll = useCallback(() => {
    return (
      mentionedUsersInEditor.length > 0 &&
      mentionedUsersInEditor[getFirstValidMentionedUser()].applyBoostToAll
    );
  }, [getFirstValidMentionedUser, mentionedUsersInEditor]);

  const shouldHidePoints = useCallback(() => {
    return (
      mentionedUsersInEditor.length > 0 &&
      mentionedUsersInEditor[getFirstValidMentionedUser()].pointsHidden
    );
  }, [getFirstValidMentionedUser, mentionedUsersInEditor]);

  const getDefaultPoints = useCallback(
    (user: MentionNodeUser) => {
      return shouldAddPointsToAll() && !isEditing
        ? mentionedUsersInEditor[getFirstValidMentionedUser()].points
        : (() => {
            const existingBoostedUser = selectedReply?.boost?.find(
              (x) => x.member.memberID === user.memberID && x.points > 0
            );

            if (existingBoostedUser) {
              return existingBoostedUser.points;
            }

            const node = mentionedUsersInEditor.find(
              (mentionedUser) => mentionedUser.memberID === user.memberID
            );
            if (!node) {
              return 0;
            }
            return node.points ?? 0;
          })();
    },
    [
      isEditing,
      selectedReply,
      shouldAddPointsToAll,
      mentionedUsersInEditor,
      getFirstValidMentionedUser,
    ]
  );

  const onSelectOption = useCallback(
    (
      selectedOption: MentionTypeaheadOption,
      nodeToReplace: TextNode | null,
      closeMenu: () => void
    ) => {
      editor.update(() => {
        let mentionNode;
        selectedOption.mentionNodeUser = {
          ...selectedOption.mentionNodeUser,
          points: getDefaultPoints(selectedOption.mentionNodeUser),
          applyBoostToAll: shouldAddPointsToAll(),
          pointsHidden: shouldHidePoints(),
        };

        mentionNode = $createMentionNode({
          ...selectedOption.mentionNodeUser,
          type: isMentionsTriggeredViaBoost ? 'boost-node' : 'mention-node',
        });

        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode);
        }
        setCurrentMentionedUsers([selectedOption.mentionNodeUser]);

        const spaceNode = $createTextNode(' ');
        mentionNode.getParent()?.append(spaceNode);

        const selection = $createRangeSelection();
        selection.anchor.set(spaceNode.getKey(), 1, 'text');
        selection.focus.set(spaceNode.getKey(), 1, 'text');
        $setSelection(selection);

        closeMenu();

        if (isMentionsTriggeredViaBoost) {
          setShowBoostManager(true);
          setIsMentionsTriggeredViaBoost(false);
        }
      });
    },
    [
      editor,
      getDefaultPoints,
      isMentionsTriggeredViaBoost,
      shouldAddPointsToAll,
      shouldHidePoints,
    ]
  );

  const onSelectMultipleOption = useCallback(
    (nodeToReplace: TextNode | null, closeMenu: () => void) => {
      editor.update(() => {
        if (mentionedUsersInPost?.length) {
          const firstMentionedUser = mentionedUsersInPost[0];
          const restOfMentionedUsers = mentionedUsersInPost.slice(1);

          const pointsHidden = shouldHidePoints();

          const mentionNode = $createMentionNode({
            ...firstMentionedUser,
            points: getDefaultPoints(firstMentionedUser),
            applyBoostToAll: true,
            pointsHidden,
            type: isMentionsTriggeredViaBoost ? 'boost-node' : 'mention-node',
          });

          if (nodeToReplace) {
            nodeToReplace.replace(mentionNode);
          }

          restOfMentionedUsers.forEach((mentionedUser) => {
            const spaceNode = $createTextNode(' ');
            mentionNode.getParent()?.append(spaceNode);
            mentionNode.getParent()?.append(
              $createMentionNode({
                ...mentionedUser,
                pointsHidden,
                applyBoostToAll: true,
                points: getDefaultPoints(mentionedUser),
                type: isMentionsTriggeredViaBoost
                  ? 'boost-node'
                  : 'mention-node',
              })
            );
          });

          setCurrentMentionedUsers(mentionedUsersInPost);

          const spaceNode = $createTextNode(' ');
          mentionNode.getParent()?.append(spaceNode);

          const selection = $createRangeSelection();
          selection.anchor.set(spaceNode.getKey(), 1, 'text');
          selection.focus.set(spaceNode.getKey(), 1, 'text');
          $setSelection(selection);

          closeMenu();

          if (isMentionsTriggeredViaBoost) {
            setShowBoostManager(true);
            setIsMentionsTriggeredViaBoost(false);
          }
        }
      });
    },
    [
      editor,
      getDefaultPoints,
      isMentionsTriggeredViaBoost,
      mentionedUsersInPost,
      shouldHidePoints,
    ]
  );

  const checkForMentionMatch = useCallback(
    (text: string) => {
      const slashMatch = checkForSlashTriggerMatch(text, editor);
      return slashMatch !== null ? null : getPossibleQueryMatch(text);
    },
    [checkForSlashTriggerMatch, editor]
  );

  const handleAddClick = (
    currentUserId: string,
    memberId: string,
    points: string,
    applyBoostToAll: boolean,
    hideBoost: boolean
  ) => {
    editor.update(() => {
      const nodes = $getMentionNodes();

      if (nodes.length === 0) {
        return;
      }

      nodes.forEach((node) => {
        if (applyBoostToAll) {
          node.setMentionDetails(
            {
              points:
                node.mentionedMember.currentUserID !==
                node.mentionedMember.memberID
                  ? Number(points)
                  : 0,
              applyBoostToAll: true,
              pointsHidden: hideBoost,
            },
            editor
          );
        } else {
          if (node.id === memberId && currentUserId !== memberId) {
            const pointsToBeSet =
              selectedReply?.boost?.find((x) => x.member.memberID === memberId)
                ?.points ?? Number(points);

            node.setMentionDetails(
              {
                points: pointsToBeSet,
                applyBoostToAll: false,
                pointsHidden: hideBoost,
              },
              editor
            );
          } else {
            node.setMentionDetails(
              { pointsHidden: hideBoost, applyBoostToAll: applyBoostToAll },
              editor
            );
          }
        }
      });
    });
  };

  const handleRemoveBoost = (memberId: string) => {
    editor.update(() => {
      const nodes = $getMentionNodes();
      nodes.forEach((node) => {
        if (node.id === memberId) {
          node.setMentionDetails(
            {
              points: 0,
              applyBoostToAll: node.mentionedMember.applyBoostToAll,
              pointsHidden: node.mentionedMember.pointsHidden,
            },
            editor
          );
        }
      });
    });
  };

  const canGiveBoost = useMemo(() => {
    return !(
      boostOptions.disableBoost ||
      boostOptions.hideBoost ||
      isAnonymousReply
    );
  }, [boostOptions.disableBoost, boostOptions.hideBoost, isAnonymousReply]);

  const anchorNodeDimensions = useMemo(
    () => selectedMentionedNode?.getBoundingClientRect(),
    [selectedMentionedNode]
  );

  if (previousMentionedUsersInEditor.length !== mentionedUsersInEditor.length) {
    setPreviousMentionedUsersInEditor(mentionedUsersInEditor);
    reBalance();
  }

  const uniqueUsers = useMemo(
    () => getFilteredNodes(mentionedUsersInEditor),
    [mentionedUsersInEditor]
  );

  useEffect(() => {
    if (postDrawerContext?.selectedBoostMember) {
      editor.update(() => {
        const { memberID, firstName, lastName } =
          postDrawerContext.selectedBoostMember ?? {};

        const mentionNodeUser: MentionNodeUser = {
          currency,
          id: memberID ?? '',
          pointsHidden: false,
          selectedReply: null,
          applyBoostToAll: false,
          canReceivePoints: true,
          lastName: lastName ?? '',
          isCurrentUserAnon: false,
          memberID: memberID ?? '',
          firstName: firstName ?? '',
          ...postDrawerContext.selectedBoostMember,
          name: `${firstName ?? ''} ${lastName ?? ''}`,
        };

        const mentionNode = $createMentionNode({
          ...mentionNodeUser,
          type: 'boost-node',
        });

        $insertNodes([mentionNode]);
        setCurrentMentionedUsers([mentionNodeUser]);

        const spaceNode = $createTextNode(' ');
        mentionNode.getParent()?.append(spaceNode);

        const selection = $createRangeSelection();
        selection.focus.set(spaceNode.getKey(), 1, 'text');
        selection.anchor.set(spaceNode.getKey(), 1, 'text');
        $setSelection(selection);
        setShowBoostManager(true);
        postDrawerContext.setSelectedBoostMember(null);
      });
    }
  }, [currency, editor, postDrawerContext]);

  return (
    <>
      <Popover
        open={Boolean(
          selectedMentionedNode &&
            selectedMentionedUser &&
            (showProfileCard || showBoostManager)
        )}
        onOpenChange={(open) => {
          if (!open) {
            handleBoostPopoverClose();
          }
        }}
      >
        <Portal>
          <PopoverAnchor asChild>
            <div
              style={{
                position: 'fixed',
                top: anchorNodeDimensions?.top,
                left: anchorNodeDimensions?.left,
                width: anchorNodeDimensions?.width,
              }}
            />
          </PopoverAnchor>
        </Portal>
        <PopoverPortal>
          <PopoverContent
            collisionBoundary={drawerContainer}
            className="z-50 p-0 transition-all"
            align="center"
            side="top"
            onOpenAutoFocus={(e) => {
              // disable the default focus
              e.preventDefault();
              // dispatch an event to cancel closing of the popover
              editor.dispatchCommand(BOOST_POPUP_CONTENT_HOVERED_COMMAND, null);
            }}
            collisionPadding={{ left: 32, right: 32, bottom: 24 }}
            onInteractOutside={(e) => {
              if (e.target instanceof HTMLElement) {
                if (e.target.closest('[data-lexical-node-type]')) {
                  e.preventDefault();
                }
              }
            }}
            {...(!isMobileOrTouchDevice && {
              onPointerEnter: () => {
                if (closeTimerRef.current) {
                  // If the user leaves and re-enters the popover, we want to cancel the close timer
                  clearTimeout(closeTimerRef.current);
                  closeTimerRef.current = null;
                }

                editor.dispatchCommand(
                  BOOST_POPUP_CONTENT_HOVERED_COMMAND,
                  null
                );
              },
              onPointerLeave: () => {
                closeTimerRef.current = setTimeout(() => {
                  handleBoostPopoverClose();
                }, 700);
              },
            })}
          >
            {selectedMentionedUser &&
            showBoostManager &&
            Array.isArray(currentMentionedUsers) &&
            currentMentionedUsers.length > 0 ? (
              <BoostManager
                key={selectedMentionedUser.currentUserID}
                mentionedUser={selectedMentionedUser}
                users={uniqueUsers}
                points={selectedMentionedUser.points?.toString() ?? null}
                onAddClick={({
                  applyBoostToAll,
                  hideBoost,
                  memberId,
                  points,
                }) => {
                  handleAddClick(
                    selectedMentionedUser.currentUserID ?? '',
                    memberId,
                    points,
                    applyBoostToAll,
                    hideBoost
                  );
                  handleBoostPopoverClose();
                }}
                onRemoveClick={({ memberId }) => {
                  handleRemoveBoost(memberId);
                  handleBoostPopoverClose();
                }}
                pointsLimit={pointsLimit}
                currency={currency}
                mode={selectedMentionedUser.points ? 'UPDATE' : 'ADD'}
                onClose={handleBoostPopoverClose}
                canGiveBoost={canGiveBoost}
                canReceiveBoost={Boolean(
                  selectedMentionedUser.canReceivePoints
                )}
                isEditing={isEditing}
              />
            ) : null}
            {selectedMentionedUser && showProfileCard && !showBoostManager ? (
              <BoostProfileCard
                currentBoostedUsers={
                  selectedReply?.boost
                    ? selectedReply.boost.map((x) => x.member.memberID)
                    : []
                }
                {...(onViewProfileClick && {
                  onViewProfileClick: () => {
                    onViewProfileClick(selectedMentionedUser.memberID);
                  },
                })}
                key={selectedMentionedUser.currentUserID}
                image={selectedMentionedUser.image ?? ''}
                firstName={selectedMentionedUser.firstName}
                lastName={selectedMentionedUser.lastName}
                department={selectedMentionedUser.department ?? ''}
                email={selectedMentionedUser.email}
                pronouns={selectedMentionedUser.pronouns ?? ''}
                jobTitle={selectedMentionedUser.role ?? ''}
                currency={selectedMentionedUser.currency}
                isAnonymousReply={isAnonymousReply}
                points={selectedMentionedUser.points}
                mode={'ADD'}
                canReceiveBoost={Boolean(
                  selectedMentionedUser.canReceivePoints
                )}
                reasonForCantGiveBoost={boostOptions.disableBoostReason}
                canGiveBoost={canGiveBoost}
                //TODO: More conditions to be added for hideBoost
                hideBoostButtonReason={
                  selectedMentionedUser.currentUserID ===
                  selectedMentionedUser.memberID
                    ? 'SELF'
                    : boostOptions.hideBoost
                      ? 'HIDE'
                      : undefined
                }
                onBoostClick={() => {
                  setCurrentMentionedUsers([selectedMentionedUser]);
                  setShowBoostManager(true);
                }}
                onClose={() => {
                  handleBoostPopoverClose();
                }}
                memberID={selectedMentionedUser.id}
                pointsExhausted={pointsLimit.remaining < 1}
                isEditing={isEditing}
              />
            ) : null}
          </PopoverContent>
        </PopoverPortal>
      </Popover>
      <LexicalTypeaheadMenuPlugin<MentionTypeaheadOption>
        options={options}
        onQueryChange={setQueryString}
        onSelectOption={(
          option: MentionTypeaheadOption,
          textNodeContainingQuery: TextNode | null,
          closeMenu: () => void
        ) => {
          if (option.key === mentionedTeammatesId) {
            onSelectMultipleOption(textNodeContainingQuery, closeMenu);
          } else {
            onSelectOption(option, textNodeContainingQuery, closeMenu);
          }
        }}
        triggerFn={checkForMentionMatch}
        onOpen={(menuResolution) => {
          refs.setPositionReference({
            getBoundingClientRect: menuResolution.getRect,
          });
        }}
        onClose={() => {
          onMenuOpenChange?.(false);
          document.body.style.overflow = 'unset';
        }}
        menuRenderFn={(
          anchorElementRef,
          { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }
        ) =>
          anchorElementRef.current && members.length ? (
            <FloatingPortal root={rootContainerRef}>
              <RemoveScroll>
                <div
                  ref={refs.setFloating}
                  style={{
                    top: y,
                    left: x,
                    position: strategy,
                    width: 'max-content',
                  }}
                  className={twMerge(
                    'shadow-md pointer-events-auto relative z-50 max-h-56 w-96 overflow-y-auto rounded-md bg-gray-1 shadow-lg-down ring-1 ring-gray-10 ring-opacity-5'
                  )}
                >
                  {isMentionsTriggeredViaBoost ? (
                    <div className="sticky top-0 bg-gray-1 px-4 py-2 font-medium text-gray-8">
                      {formatMessage(messages.mentionListHeader)}
                    </div>
                  ) : null}
                  <ul className="flex flex-col items-start justify-start py-1">
                    {options.map((option, i: number) => (
                      <Fragment key={option.key}>
                        {i === options.length - 1 && (
                          <li>
                            <div ref={endOfListRef} className="h-0" />
                          </li>
                        )}
                        <MentionsTypeaheadMenuItem
                          index={i}
                          option={option}
                          key={option.key}
                          onClick={() => {
                            setHighlightedIndex(i);
                            selectOptionAndCleanUp(option);
                          }}
                          isSelected={selectedIndex === i}
                          onMouseEnter={() => {
                            setHighlightedIndex(i);
                          }}
                        />
                        {isFetchingNextPage && i === options.length - 1 ? (
                          <li>
                            <div className="flex w-full items-center !justify-start gap-2 px-3 py-2">
                              <div className="h-6 w-6 animate-pulse rounded-full bg-gray-5"></div>
                              <div className="h-6 w-48 animate-pulse rounded-md bg-gray-5"></div>
                            </div>
                          </li>
                        ) : null}
                      </Fragment>
                    ))}
                  </ul>
                </div>
              </RemoveScroll>
            </FloatingPortal>
          ) : null
        }
      />
    </>
  );
}
