import type { ReactNode } from 'react';

import { ParticipationBlock } from '../../QuickParticipation/ParticipationBlock';

export const PersonBlockContainer = ({
  label,
  children,
}: {
  label: ReactNode;
  children?: ReactNode;
}) => {
  return <ParticipationBlock label={label}>{children}</ParticipationBlock>;
};
