import {
  MemberStatus,
  SplitNames,
  useFeatureSplit,
  useGetAwardsCatalog,
} from '@assembly-web/services';
import { useMemo } from 'react';

export const useActionBarVisibility = ({
  memberStatus,
  activeFlowsCount,
  isRecognitionOnly,
}: {
  memberStatus?: MemberStatus;
  activeFlowsCount: number;
  isRecognitionOnly: boolean;
}) => {
  const { isTreatmentActive: isAwardsEnabled } = useFeatureSplit(
    SplitNames.AwardsPage
  );
  const { data } = useGetAwardsCatalog({
    payload: {
      search: '',
    },
  });

  const showAwards = useMemo(
    () => Boolean(data?.total && data.total > 0 && isAwardsEnabled),
    [data, isAwardsEnabled]
  );
  const showGiveRecognitionButton =
    memberStatus !== MemberStatus.Observer &&
    memberStatus !== MemberStatus.Receiver;

  const showActionBar = !(
    (memberStatus === MemberStatus.Observer ||
      memberStatus === MemberStatus.Receiver) &&
    (isRecognitionOnly || activeFlowsCount === 0)
  );

  return {
    showGiveRecognitionButton,
    showActionBar,
    showAwards,
  };
};
