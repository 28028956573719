import {
  trackParticipationAction,
  trackParticipationShow,
} from '@assembly-web/services';
import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { flushSync } from 'react-dom';
import invariant from 'tiny-invariant';

import { RecognitionParticipationModal } from './RecognitionParticipation/modals/ParticipationModal';

type ParticipationModalContextType = {
  openModal: (params: FormState) => void;
  closeModal: () => void;
  isOpen: boolean;
  reset: () => void;
  formState: FormState | null;
  id: string;
  aiRecognitionModalOpen: boolean;
  setAiRecognitionModalOpen: (open: boolean) => void;
};

type FormState = {
  postId?: string;
} & {
  type: 'recognition';
  postMessage?: string;
  recipientIds?: string[];
  coreValue?: string;
  threadId?: string;
};

const ParticipationModalContext =
  createContext<ParticipationModalContextType | null>(null);

export const useParticipationModalContext = () => {
  const context = useContext(ParticipationModalContext);

  invariant(
    context != null,
    'useParticipationModalContext must be used within a ParticipationProvider'
  );

  return context;
};

export function ParticipationProvider({ children }: PropsWithChildren) {
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState('');
  const [formState, setFormState] = useState<FormState | null>(null);

  const closeModal = useCallback(() => {
    window.postMessage(
      { type: 'closedParticipationModal', payload: { id } },
      window.location.origin
    );
    setIsOpen(false);
  }, [id]);

  const reset = useCallback(() => setId(crypto.randomUUID()), []);
  const [aiRecognitionModalOpen, setAiRecognitionModalOpen] = useState(false);

  const openModal = useCallback(
    ({
      postId,
      recipientIds,
      postMessage,
      type,
      threadId,
      coreValue,
    }: FormState) => {
      setFormState({
        postId,
        recipientIds,
        postMessage,
        type,
        threadId,
        coreValue,
      });
      if (postId) {
        trackParticipationAction('editPostStarted', {
          postType: 'recognition',
        });
      } else {
        trackParticipationAction('participationStarted', {
          postType: 'recognition',
        });
      }
      flushSync(() => {
        reset();
      });
      setIsOpen(true);
      trackParticipationShow({
        postType: 'recognition',
      });
    },
    [reset]
  );

  return (
    <ParticipationModalContext.Provider
      value={useMemo(
        () => ({
          openModal,
          closeModal,
          isOpen,
          reset,
          formState,
          id,
          aiRecognitionModalOpen,
          setAiRecognitionModalOpen,
        }),
        [
          closeModal,
          isOpen,
          openModal,
          reset,
          formState,
          id,
          aiRecognitionModalOpen,
          setAiRecognitionModalOpen,
        ]
      )}
    >
      {children}
      <RecognitionParticipationModal />
    </ParticipationModalContext.Provider>
  );
}
