import {
  EntityType,
  fetchRecognitionDraftKey,
  getJSONFromPlainText,
  getMemberDetailsFromUserDetails,
  trackParticipationAction,
  useFetchRecognitionDraft,
  useGetMembersByIds,
  useGetRecognitionPost,
  useSaveDrafts,
} from '@assembly-web/services';
import {
  isFileUploadInProgress,
  TrackMessage,
  useOpenEndedFileUpload,
} from '@assembly-web/ui';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { CreateOnly } from '../components/CreateOnly';
import { Draft } from '../components/Draft';
import { useRecognitionForm } from '../hooks/useRecognitionForm';
import { useParticipationModalContext } from '../Provider';
import { Blocks } from './blocks/Blocks';
import { EditBadge } from './blocks/Editbadge';
import { Footer } from './footer/Footer';
import { DraftModal } from './modals/DraftModal';
import { EditConfirmationModal } from './modals/EditConfirmationModal';
import type { ParticipationModalProps } from './modals/ParticipationModal';

function RecognitionParticipationImpl(
  _: unknown,
  ref: React.Ref<ParticipationModalProps>
) {
  const { closeModal, reset, formState } = useParticipationModalContext();
  const {
    getParsedFormDataForDraft,
    getPostData,
    getSaveDraftPayload,
    getDraftData,
  } = useRecognitionForm();
  const { fileUploadOptions } = useOpenEndedFileUpload({
    key: 'recognition-participation',
  });
  const [isDraftOpen, setIsDraftOpen] = useState(false);
  const [isDraftSaving, setIsDraftSaving] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const isEditFlow = Boolean(formState?.postId);

  const { mutate: saveDraft } = useSaveDrafts({
    onMutate: () => {
      reset();
    },
  });

  const { data } = useFetchRecognitionDraft();
  const { data: postData } = useGetRecognitionPost({
    postId: formState?.postId || '',
  });

  const { data: recipients } = useGetMembersByIds({
    memberIds: formState?.recipientIds ?? [],
  });

  const draft = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    () => (data.draft && 'postData' in data.draft ? data.draft.postData : {}),
    [data.draft]
  );

  const generateDefaultValues = useCallback(() => {
    if (isEditFlow) {
      return getPostData(postData.post);
    } else if (recipients.membersDetails.length > 0 || formState?.postMessage) {
      return {
        message: {
          json: formState?.postMessage
            ? getJSONFromPlainText(formState.postMessage)
            : undefined,
        },
        recipients: recipients.membersDetails.map((x) => ({
          ...getMemberDetailsFromUserDetails(x),
          type: 'member' as const,
        })),
        recipientsCount: 0,
        coreValue: formState?.coreValue,
      };
    } else {
      return getDraftData(draft);
    }
  }, [
    isEditFlow,
    draft,
    postData,
    getPostData,
    getDraftData,
    formState,
    recipients,
  ]);

  const methods = useForm({
    reValidateMode: 'onChange',
    defaultValues: generateDefaultValues(),
  });

  useImperativeHandle(ref, () => ({
    onClose: handleCloseModal,
  }));

  const handleCloseModal = useCallback(() => {
    if (isEditFlow) {
      setIsConfirmationOpen(true);
    } else {
      const values = methods.getValues();

      const draft = getParsedFormDataForDraft(values);
      if (Object.keys(draft).length) {
        setIsDraftOpen(true);
        if (isFileUploadInProgress(values.attachments ?? [])) {
          setIsDraftSaving(true);
        }
      } else {
        saveDraft({
          queryCacheToUpdate: fetchRecognitionDraftKey,
          payload: getSaveDraftPayload(values),
        });
        closeModal();
      }
    }
  }, [
    methods,
    saveDraft,
    isEditFlow,
    closeModal,
    getParsedFormDataForDraft,
    getSaveDraftPayload,
  ]);

  useEffect(() => {
    return methods.watch((values) => {
      if (values.attachments && values.attachments.length > 0 && isDraftOpen) {
        const isInProgress = isFileUploadInProgress(values.attachments);
        setIsDraftSaving(isInProgress);
      }
    }).unsubscribe;
  }, [methods, isDraftOpen]);

  const handleDraftModalClose = useCallback(
    (param?: { isDeleteDraft: boolean }) => {
      const { isDeleteDraft } = param || {};
      if (isDeleteDraft) {
        saveDraft({
          payload: {
            toCache: {
              entityType: EntityType.Recognition,
              postData: {},
            },
            toServer: {
              entityType: EntityType.Recognition,
              postData: {},
            },
          },
          queryCacheToUpdate: fetchRecognitionDraftKey,
        });
        trackParticipationAction('deleteDraftClicked', {
          postType: 'recognition',
        });
      } else {
        saveDraft({
          payload: getSaveDraftPayload(methods.getValues()),
          queryCacheToUpdate: fetchRecognitionDraftKey,
        });
      }
      closeModal();
      methods.reset();
      setIsDraftOpen(false);
      fileUploadOptions.clear();
    },
    [
      methods,
      saveDraft,
      setIsDraftOpen,
      getSaveDraftPayload,
      closeModal,
      fileUploadOptions,
    ]
  );

  const discardChanges = useCallback(() => {
    closeModal();
    setIsConfirmationOpen(false);
    fileUploadOptions.clear();
    trackParticipationAction('discardChangesClicked', {
      postType: 'recognition',
    });
  }, [closeModal, fileUploadOptions]);

  const onContinueEdit = useCallback(() => {
    setIsConfirmationOpen(false);
  }, []);

  return (
    <FormProvider {...methods}>
      <EditBadge />
      <CreateOnly>
        <TrackMessage
          props={{
            postType: 'recognition',
          }}
        />
        <Draft
          getPayloadForSavingDraft={(fields) => {
            return {
              payload: getSaveDraftPayload(fields),
            };
          }}
        />
      </CreateOnly>
      <Blocks />
      <Footer />
      {isEditFlow ? (
        <EditConfirmationModal
          isOpen={isConfirmationOpen}
          onDiscard={discardChanges}
          onContinueEdit={onContinueEdit}
        />
      ) : (
        <DraftModal
          isOpen={isDraftOpen}
          onClose={(param) => handleDraftModalClose(param)}
          isSaving={isDraftSaving}
        />
      )}
    </FormProvider>
  );
}

export const RecognitionParticipation = forwardRef(
  RecognitionParticipationImpl
);
