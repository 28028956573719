import { Banner } from '@assembly-web/ui';
import { defineMessage, useIntl } from 'react-intl';

const message = defineMessage({
  defaultMessage:
    'This award will go through an approval process before it is posted.',
  id: 'mnsq29',
});

export function ApprovalProcessBanner() {
  const { formatMessage } = useIntl();

  return (
    <Banner status="alert" className="rounded-lg">
      {formatMessage(message)}
    </Banner>
  );
}
