import {
  trackAction,
  type TrackEventProps,
  userAuthStore,
} from '@assembly-web/services';

import { onBoardingState } from '../modules/onboarding/stores/onboardingStore';

export function trackRegistrationShow() {
  return trackAction({
    eventName: 'registration.events',
    eventProps: {
      url: window.location.href,
      event: 'show',
    },
  });
}

type RegistrationAction =
  | 'assemblyCreated'
  | 'assemblyMemberCreated'
  | 'codeSubmitted'
  | 'createNewWorkspaceClicked'
  | 'emailEntered'
  | 'logIntoExistingClicked'
  | 'logInWithDifferentEmailClicked'
  | 'signUpInsteadClicked'
  | 'ssoClicked'
  | 'workspaceClicked'
  | 'workspaceRejected'
  | 'emailProviderClicked';

type OnboardingAction =
  | 'managesPeopleSelected'
  | 'roleSelected'
  | 'addToBrowserClicked';

type TemplatesAction =
  | 'applyFilter'
  | 'search'
  | 'startFromScratch'
  | 'previewTemplateClicked'
  | 'useTemplateClicked'
  | 'seeAllTemplatesClicked'
  | 'exitTemplateLibrary';

export function trackRegistrationAction(
  actionName: RegistrationAction,
  props?: TrackEventProps
) {
  return trackAction({
    eventName: 'registration.events',
    eventProps: {
      url: window.location.href,
      event: 'action',
      action: actionName,
      ...props,
    },
  });
}

export function trackRegistrationError(
  actionName: RegistrationAction,
  props: TrackEventProps
) {
  return trackAction({
    eventName: 'registration.events',
    eventProps: {
      url: window.location.href,
      event: 'error',
      action: actionName,
      ...props,
    },
  });
}

export function trackOnboardingAction(
  actionName: OnboardingAction,
  props?: TrackEventProps
) {
  return trackAction({
    eventName: 'onboarding.events',
    eventProps: {
      url: window.location.href,
      event: 'action',
      action: actionName,
      ...props,
    },
  });
}

export function trackTemplatesAction(
  actionName: TemplatesAction,
  props?: TrackEventProps
) {
  return trackAction({
    eventName: 'template.events',
    eventProps: {
      url: window.location.href,
      event: 'action',
      action: actionName,
      v3enabled: true,
      ...props,
    },
  });
}

export function trackPageViewAction(pageName: string) {
  let { platform } = onBoardingState.getState();
  const { isLoginViaMobileApp } = userAuthStore.getState();
  const pageURL = window.location.href;
  const referrer = document.referrer;
  if (platform === 'ios' || platform === 'android') {
    platform = isLoginViaMobileApp ? 'mobile_app' : 'web';
  }

  return trackAction({
    eventName: 'page_view',
    eventProps: {
      url: window.location.href,
      event: 'action',
      action: 'page_view',
      'Page Name': pageName,
      Platform: platform,
      'Page URL': pageURL,
      'Referrer URL': referrer,
    },
  });
}
