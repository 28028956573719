import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { ParticipationBlock } from '../../QuickParticipation/ParticipationBlock';

export function CoreValueContainer({ children }: { children: ReactNode }) {
  return (
    <ParticipationBlock
      label={
        <FormattedMessage defaultMessage="Select core value" id="j8r6H0" />
      }
    >
      {children}
    </ParticipationBlock>
  );
}
