import { defineMessages } from 'react-intl';

export const commonRecognitionParticipationMessages = defineMessages({
  countWithBracket: {
    defaultMessage: '({count, number})',
    id: 'ZgwNJ8',
  },
  count: {
    defaultMessage:
      '{count, number} {count, plural, one {person} other {people}}',
    id: 'En4w3Z',
  },
});
