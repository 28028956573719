import { Button, Modal, TextStyle, useDeviceInfo } from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';

const labels = defineMessages({
  title: {
    id: '41zpq3',
    defaultMessage: 'Discard changes?',
  },
  content: {
    id: '7FKanQ',
    defaultMessage: 'Any changes you made will not be applied to the post.',
  },
  delete: {
    id: 'yxguVq',
    defaultMessage: 'Discard changes',
  },
  keepEditing: {
    id: 'nZCHBx',
    defaultMessage: 'Keep editing',
  },
});

export function EditConfirmationModal({
  isOpen,
  onDiscard,
  onContinueEdit,
}: {
  isOpen: boolean;
  onDiscard: () => void;
  onContinueEdit: () => void;
}) {
  const { formatMessage } = useIntl();
  const { isMobileDevice } = useDeviceInfo();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onContinueEdit}
      headerClassName="px-6 pt-4"
      className="flex max-h-[unset] flex-col max-sm:h-full max-sm:w-full max-sm:max-w-[unset] sm:max-h-[85vh]"
      bodyClassName="max-sm:flex-1"
      closeButtonSize="xLarge"
      title={
        <div className="flex items-center gap-2">
          <TextStyle
            as="span"
            variant={isMobileDevice ? 'xl-bold' : '2xl-bold'}
          >
            {formatMessage(labels.title)}
          </TextStyle>
        </div>
      }
      ctaSection={
        <div className="flex w-full justify-end gap-3">
          <Button variation="dangerLite" className="w-1/2" onClick={onDiscard}>
            {formatMessage(labels.delete)}
          </Button>
          <Button
            variation="primary"
            onClick={onContinueEdit}
            className="w-1/2"
          >
            {formatMessage(labels.keepEditing)}
          </Button>
        </div>
      }
    >
      <TextStyle>{formatMessage(labels.content)}</TextStyle>
    </Modal>
  );
}
