import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { forwardRef, type ReactNode, useId } from 'react';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../Feedback/TextStyle';

const Root = forwardRef<HTMLDivElement, RadixRadioGroup.RadioGroupProps>(
  function Root({ className, ...tail }, ref) {
    return (
      <RadixRadioGroup.Root
        className={twMerge('flex flex-col gap-2', className)}
        {...tail}
        ref={ref}
      />
    );
  }
);

const Item = forwardRef<
  HTMLDivElement,
  {
    value: string;
    children: ReactNode;
    disabled?: boolean;
    onClick?: () => void;
    className?: string;
    itemClassName?: string;
    labelClassName?: string;
  }
>(function Item(
  {
    children,
    disabled,
    value,
    onClick,
    className = '',
    labelClassName,
    itemClassName,
    ...tail
  },
  ref
) {
  const id = useId();

  return (
    <div
      className={twMerge('flex items-center gap-2', itemClassName)}
      ref={ref}
      {...tail}
    >
      <RadixRadioGroup.Item
        id={id}
        value={value}
        disabled={disabled}
        className={twMerge(
          'h-4 w-4 rounded-full focus:ring-offset-2 focus:ring-offset-gray-1 aria-checked:bg-primary-6 aria-checked:focus:ring-2 aria-checked:focus:ring-primary-6 aria-[checked=false]:ring-1 aria-[checked=false]:ring-inset aria-[checked=false]:ring-gray-6 data-[disabled]:bg-gray-3',
          className
        )}
        onClick={onClick}
      >
        <RadixRadioGroup.Indicator className="relative flex h-full w-full items-center justify-center after:h-1.5 after:w-1.5 after:rounded-full after:bg-gray-1" />
      </RadixRadioGroup.Item>
      <label htmlFor={id} className={labelClassName}>
        {labelClassName ? (
          <>{children}</>
        ) : (
          <TextStyle as="span" variant="sm-regular">
            {children}
          </TextStyle>
        )}
      </label>
    </div>
  );
});

export const RadioGroup = {
  Root,
  Item,
};
