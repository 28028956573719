import type { FileUploadMetaData } from '@assembly-web/services';
import type { UppyFile } from '@uppy/core';
import { createContext, type ReactNode, useEffect, useState } from 'react';

export const FileUploadContext = createContext<{
  loading: boolean;
  setFiles: (
    files?: UppyFile<FileUploadMetaData, Record<string, never>>[]
  ) => void;
}>({ loading: false, setFiles: () => {} });

export const FileUploadProvider = ({ children }: { children: ReactNode }) => {
  const [files, setFiles] = useState<
    UppyFile<FileUploadMetaData, Record<string, never>>[] | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (files?.[files.length - 1]?.progress.uploadStarted) {
      setLoading(true);
    }
  }, [files, setLoading]);

  useEffect(() => {
    if (files?.[files.length - 1]?.progress.uploadComplete) {
      setLoading(false);
    }
  }, [files, setLoading]);

  return (
    <FileUploadContext.Provider value={{ loading, setFiles }}>
      {children}
    </FileUploadContext.Provider>
  );
};
