import type { ReactNode } from 'react';

import { useParticipationModalContext } from '../Provider';

export function CreateOnly({ children }: { children?: ReactNode }) {
  const { formState } = useParticipationModalContext();
  const isEdit = Boolean(formState?.postId);

  if (isEdit) {
    return null;
  }

  return children;
}
