import { useDisplayCurrency } from '@assembly-web/services';
import { QuickParticipation } from '@assembly-web/ui';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useRecipientCount } from '../../hooks/useRecipientCount';
import { useParticipationModalContext } from '../../Provider';
import { commonRecognitionParticipationMessages } from '../utils/commonMessages';

function TotalRecipients() {
  const { formatMessage } = useIntl();
  const count = useRecipientCount();

  return formatMessage(commonRecognitionParticipationMessages.count, {
    count,
  });
}

function Points(): number {
  const { watch, control } = useFormContext();
  const { formState } = useParticipationModalContext();

  const recipientsCount = useRecipientCount();

  const isEditFlow = Boolean(formState?.postId);
  const pointsEach = isEditFlow
    ? control._defaultValues.points
    : watch('points', 0);

  const totalPoints = pointsEach * recipientsCount || 0;

  return totalPoints;
}

export function PointsStatus() {
  const recipientsCount = useRecipientCount();
  const currency = useDisplayCurrency();

  return (
    recipientsCount > 0 && (
      <QuickParticipation.Footer.PointsStatus
        currency={currency}
        totalPoints={<Points />}
        totalRecipients={<TotalRecipients />}
      />
    )
  );
}
