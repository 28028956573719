import type { MemberState } from '@assembly-web/services';
import { UserPlusIcon } from '@heroicons/react/20/solid';
import { Fallback, Image, Root } from '@radix-ui/react-avatar';
import { cva } from 'class-variance-authority';
import { forwardRef, memo, type ReactNode, useMemo } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import type { MergeExclusive } from 'type-fest';

import { AnonymousReplyIcon } from '../../UI/assets/icons';
import { Tooltip } from './Tooltip';

export enum AvatarSize {
  ExtraSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  ExtraLarge = 'xl',
  DoubleExtraLarge = '2xl',
  QuadExtraLarge = '4xl',
  HexExtraLarge = '6xl',
}

export const avatarSizes = cva([], {
  variants: {
    size: {
      xs: 'h-4 w-4',
      sm: 'h-6 w-6',
      md: 'h-8 w-8',
      lg: 'h-10 w-10',
      xl: 'h-11 w-11',
      '2xl': 'h-12 w-12',
      '4xl': 'h-14 w-14',
      '6xl': 'h-16 w-16',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export type UserAvatarProps = {
  image?: string;
  memberID: string;
  name: string;
  memberState?: MemberState;
};

export type FallbackAvatarProps = {
  fallback: ReactNode;
  fallbackGradientBackground: keyof typeof Gradients;
};

export type AvatarProps = {
  size: AvatarSize;
  className?: string;
  tooltipText?: string;
  isRounded?: boolean;
  overlay?: ReactNode;
} & MergeExclusive<UserAvatarProps, FallbackAvatarProps>;

const Gradients = {
  pink: 'bg-[linear-gradient(242deg,#FFCCC7_9.81%,#FFADD2_84.23%)]',
  blue: 'bg-[linear-gradient(44deg,#BAE7FF_0%,#ADC6FF_100%)]',
  green: 'bg-[linear-gradient(240deg,#B7EB8F_9.84%,#B5F5EC_107.68%)]',
  orange: 'bg-[linear-gradient(240deg,#FFC069_26.06%,#FFBB96_93%)]',
  purple: 'bg-[linear-gradient(240deg,#D3ADF7_25.42%,#D6E4FF_92.1%)]',
  cyan: 'bg-[linear-gradient(240deg,#87E8DE_25.42%,#BAE7FF_92.1%)]',
} as const;

/**
 *
 * @param userId
 * @returns a color which is based on the userID
 * We use User ID because that is unique to each user since the assignment and is consistent
 * Even if the user name is changed, the user ID will remain the same
 * which implies the color would remain consistent since initial login
 */
const getAvatarBackgroundColor = (userId: string) => {
  let lastChar = 'p';
  if (userId) {
    lastChar = userId.substr(userId.length - 1);
  }
  const charCode = lastChar.charCodeAt(0);
  switch (charCode) {
    case 97:
    case 113:
    case 103:
    case 119:
    case 49:
    case 111:
      return Gradients['pink'];
    case 98:
    case 114:
    case 104:
    case 120:
    case 50:
    case 57:
      return Gradients['cyan'];
    case 99:
    case 115:
    case 106:
    case 122:
    case 52:
    case 112:
      return Gradients['green'];
    case 100:
    case 116:
    case 105:
    case 121:
    case 51:
    case 108:
      return Gradients['orange'];
    case 101:
    case 117:
    case 107:
    case 53:
    case 110:
    case 56:
    case 54:
      return Gradients['purple'];
    case 102:
    case 118:
    case 48:
    case 109:
    case 55:
      return Gradients['blue'];
    default:
      return Gradients['blue'];
  }
};

function AvatarWrapper({
  children,
  tooltipText,
}: {
  children: ReactNode;
} & Pick<AvatarProps, 'tooltipText'>) {
  if (!tooltipText) {
    return <>{children}</>;
  }
  return (
    <Tooltip tooltipText={tooltipText}>
      <span className="w-fit">{children}</span>
    </Tooltip>
  );
}

const altMessage = defineMessage({
  id: 'Tygbom',
  defaultMessage: "{name}'s profile picture",
});

export const Avatar = memo(
  forwardRef<HTMLDivElement, AvatarProps>(function Avatar(
    { size, className, tooltipText, isRounded = true, overlay, ...rest },
    ref
  ) {
    const { formatMessage } = useIntl();

    // extracting the props that are not needed for the Avatar Root component
    // we need to spread the rest of the props for passing event handlers
    const {
      fallback: _fallback,
      fallbackGradientBackground: _fallbackGradientBackground,
      image: _image,
      memberID: _memberID,
      memberState: _memberState,
      name: _name,
      ...props
    } = rest;

    const avatarImage = !rest.fallback
      ? rest.name === 'Anonymous' && !rest.memberID
        ? AnonymousReplyIcon
        : rest.image
      : '';

    const fallbackInitials = useMemo(() => {
      if (rest.fallback) {
        return rest.fallback;
      }

      if (size === AvatarSize.ExtraSmall || size === AvatarSize.Small) {
        return rest.name ? rest.name.charAt(0) : '';
      }

      const nameParts = rest.name ? rest.name.split(' ') : [];

      return nameParts.length > 1
        ? nameParts[0].charAt(0) + nameParts[1].charAt(0)
        : nameParts[0]?.charAt(0) || '';
    }, [rest.fallback, rest.name, size]);

    return (
      <AvatarWrapper tooltipText={tooltipText}>
        <Root
          ref={ref}
          className={twMerge(
            'group relative flex flex-shrink-0 overflow-hidden ring-1 ring-gray-1',
            isRounded ? 'rounded-full' : 'rounded-md',
            className
          )}
          {...props}
        >
          {!rest.fallback && (
            <Image
              src={avatarImage}
              alt={formatMessage(altMessage, { name: rest.name })}
              className={twMerge(
                'inline min-h-4 min-w-4 object-cover group-hover:border-transparent max-md:max-w-none',
                isRounded ? 'rounded-full' : 'rounded-md',
                avatarSizes({ size })
              )}
            />
          )}
          <Fallback
            className={twMerge(
              'flex min-h-4 min-w-4 items-center justify-center',
              isRounded ? 'rounded-full' : 'rounded-md',
              (() => {
                if (rest.fallback) {
                  return Gradients[rest.fallbackGradientBackground];
                }
                if (rest.memberID) {
                  if (rest.memberState === 'PENDING') {
                    return 'bg-gray-6';
                  }
                  return getAvatarBackgroundColor(rest.memberID);
                }
                return undefined;
              })(),
              avatarSizes({ size })
            )}
          >
            {rest.fallback ? (
              rest.fallback
            ) : rest.memberState === 'PENDING' ? (
              <UserPlusIcon className="h-4 w-4" />
            ) : (
              <span
                className={twMerge(
                  'select-none text-sm font-medium uppercase text-gray-9',
                  size === AvatarSize.ExtraSmall && 'text-xs',
                  (size === AvatarSize.QuadExtraLarge ||
                    size === AvatarSize.HexExtraLarge) &&
                    'text-xl font-medium'
                )}
              >
                {fallbackInitials}
              </span>
            )}
          </Fallback>
          {Boolean(overlay) && (
            <span
              className={twMerge(
                'absolute inset-0 flex select-none items-center justify-center bg-gray-9/60',
                isRounded ? 'rounded-full' : 'rounded-md'
              )}
            >
              {overlay}
            </span>
          )}
        </Root>
      </AvatarWrapper>
    );
  })
);
