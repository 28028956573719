import {
  generateCriteriaRules,
  type GroupOrUserChipData,
  MemberStatus,
  trackParticipationAction,
  useCriteriaCount,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import {
  GroupOrUserChipList,
  PersonBlockContainer,
  PersonSelector as PersonSelectorImpl,
} from '@assembly-web/ui';
import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { useRecipientCount } from '../../hooks/useRecipientCount';
import { useParticipationModalContext } from '../../Provider';

const messages = defineMessages({
  label: {
    defaultMessage: 'Add recipients',
    id: 'ZGYPiR',
  },
  requiredValidation: {
    defaultMessage: 'You are required to add at least one recipient.',
    id: 'N4+mQ3',
  },
  recipientTooltip: {
    defaultMessage: 'Recipients can’t be edited.',
    id: 'dO2NpW',
  },
});

function TotalRecipients() {
  const { watch, setValue, control, register } = useFormContext();
  const { formState } = useParticipationModalContext();
  const isEditFlow = Boolean(formState?.postId);

  const recipients = (
    isEditFlow ? control._defaultValues.recipients : watch('recipients', [])
  ) as GroupOrUserChipData[];
  const groupedCriteria = generateCriteriaRules(recipients);

  const { data, status, fetchStatus } = useCriteriaCount({
    criteria: groupedCriteria,
    for: 'recognition',
  });

  useEffect(() => {
    register('recipientsCount');
  }, [register]);

  useEffect(() => {
    if (!recipients.length) {
      setValue('recipientsCount', 0, { shouldDirty: true, shouldTouch: true });
    }
  }, [recipients, setValue]);

  useEffect(() => {
    if (status === 'success' && fetchStatus === 'idle') {
      const count = data.data.count;
      setValue('recipientsCount', count, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  }, [data, status, fetchStatus, setValue]);

  return null;
}

function Selector() {
  const { formatMessage } = useIntl();

  const { control, getValues, watch } = useFormContext();

  const { formState } = useParticipationModalContext();
  const isEditFlow = Boolean(formState?.postId);

  const {
    data: { member: user },
  } = useSuspenseUserDetails();

  useEffect(() => {
    if (isEditFlow) {
      return;
    }
    const oldValue = getValues('recipients');
    const { unsubscribe } = watch((values) => {
      if (
        !isEqual(values.recipients, oldValue) &&
        values.recipients?.length > oldValue?.length
      ) {
        trackParticipationAction('recipientSelected', {
          postType: 'recognition',
        });
      }
    });
    return () => {
      unsubscribe();
    };
  }, [control, watch, getValues, isEditFlow]);

  return isEditFlow ? (
    <div className="flex flex-wrap gap-2 text-gray-9">
      <GroupOrUserChipList
        data={control._defaultValues.recipients}
        disabled={true}
        tooltipText={formatMessage(messages.recipientTooltip)}
      />
    </div>
  ) : (
    <Controller
      name="recipients"
      control={control}
      disabled={isEditFlow}
      rules={{
        validate: {
          required: (value?: GroupOrUserChipData[]) => {
            if (!value || !Array.isArray(value)) {
              return true;
            }
            if (!value.length) {
              return formatMessage(messages.requiredValidation);
            }
            return true;
          },
        },
      }}
      render={({ field: { onBlur, onChange }, fieldState: { error } }) => (
        <PersonSelectorImpl
          onChange={onChange}
          defaultRecipients={control._defaultValues.recipients}
          error={error}
          onBlur={onBlur}
          label={formatMessage(messages.label)}
          customFilterCallback={(member) =>
            member.memberID !== user.memberId &&
            (member.status === MemberStatus.Receiver ||
              member.status === MemberStatus.Normal)
          }
        />
      )}
    />
  );
}

function Label() {
  const count = useRecipientCount();

  return (
    <FormattedMessage
      defaultMessage="Add recipients ({count})"
      id="9XGQkw"
      values={{ count }}
    />
  );
}

export function PersonSelector() {
  return (
    <PersonBlockContainer label={<Label />}>
      <Selector />
      <TotalRecipients />
    </PersonBlockContainer>
  );
}
