export type NameWithCode = {
  code: string;
  name: string;
};

export type Country = NameWithCode;

export type CountriesAPIResponse = {
  countries: Country[];
};

export type SwagCategory = {
  id: number;
  value: string;
  data: SwagCategory[];
  isSelectable: boolean;
};

export type SwagCategoriesAPIResponse = SwagCategory[];

export type RewardSize = {
  id: string;
  name: string;
};

export type SearchableSelectOptionType = {
  id: string;
  name: string;
  value?: string | number | undefined;
};

export type RewardColor = SearchableSelectOptionType;

export type Quantity = SearchableSelectOptionType;

export type ShippingCountry = SearchableSelectOptionType;

export type ShippingState = SearchableSelectOptionType;

export type RewardDetailsOption = {
  name: 'size' | 'color' | 'quantity';
  options: RewardSize[] | RewardColor[] | Quantity[];
};

export type AmazonRewardDetailsOption = {
  name: string;
  options: SearchableSelectOptionType[];
};

export type AxomoSwagAPIResponse = {
  data: {
    name: string;
    desc: string;
    image: string;
    rewardType: string;
    rewardProvider: string;
    uid: string;
    enabled: boolean;
    points: number[];
    productLink: string;
    storeUID: string;
    sizes: RewardSize[];
    colors: RewardColor[];
    maxQuantity: number;
    shippingCost: {
      value: number;
      currency: string;
    };
    active: boolean;
    details: string;
  };
};

export type AxomoShippingCountryAPIResponse = {
  continentCode: string;
  countryCode: string;
  name: string;
  shortName: string;
};

export type AxomoShippingStateAPIResponse = {
  data: NameWithCode[];
};

export type RewardDetailsForm = {
  size: RewardSize | undefined;
  color: RewardColor | undefined;
  quantity: Quantity | undefined;
};

export type AmazonRewardDetailsForm = Record<
  string,
  SearchableSelectOptionType | undefined | number | string
>;

export type ShippingDetailsForm = {
  firstName: string;
  lastName: string;
  address: string;
  secondaryAddress?: string;
  zip: string;
  country: ShippingCountry;
  city: string;
  state: ShippingState | undefined;
  phoneNumber: string;
  company?: string;
};

export type RedemptionFormDetails = RewardDetailsForm & ShippingDetailsForm;

export type AmazonRedemptionFormDetails = AmazonRewardDetailsForm &
  ShippingDetailsForm;

export type AxomoOrderPayload = {
  storeUID: string;
  uid: string;
  size: RewardSize;
  quantity: number;
  company: string;
  color: Omit<RewardColor, 'value'>;
  shipping: {
    method?: null;
    tier?: number;
    address: {
      firstName: string;
      lastName: string;
      addressLine1: string;
      addressLine2: string;
      zipCode: string;
      country: NameWithCode;
      city: NameWithCode;
      state: NameWithCode;
      phone: string;
    };
  };
};

export type ShippingAddress = {
  fullName: string;
  phoneNumber: string;
  companyName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  stateOrRegion: string;
  postalCode: string;
  country: string;
};

export type AmazonOrderPayload = {
  shippingAddress: ShippingAddress;
  productId: string;
  quantity: number;
  price: {
    unitPrice: number;
    taxes: number;
    shipping: number;
    total: number;
    currencyCode: string;
    totalPointsToRedeem: number;
  };
  productDetails: {
    title: string;
    image: string;
    variations: string[];
  };
  trialMode: boolean;
};

export enum RewardType {
  GiftCards = 'giftCards',
  Swag = 'swag',
  Charities = 'charities',
  Culture = 'culture',
  MyRewards = 'myRewards',
  CashEquivalent = 'cash equivalent',
  Axomo = 'axomo',
  Discounts = 'discounts',
  Amazon = 'amazon',
  Switchfly = 'experiences',
}

export type AxomoPlaceOrderAPIResponse = {
  estimatedDeliveryDate: string;
  estimatedShipDate: string;
  pointsRedeemed: number;
};

export enum SwagCardStatuses {
  REDEEMABLE = 'REDEEMABLE',
  COMINGSOON = 'COMING_SOON',
  OUTOFSTOCK = 'OUT_OF_STOCK',
}

export type AxomoStoreInfoAPIResponse = {
  state: 'CONNECTED' | 'DISCONNECTED';
  name: string;
  meta: {
    storeName: string;
    registeredEmail: string;
  };
  uid: string;
  visibility: string;
  shippingCost: {
    value: number;
    currency: string;
  };
  exchangeRate: {
    source: {
      currency: string;
      value: number;
    };
    target: {
      currency: string;
      value: number;
    };
  };
};

export type ListAmazonProductsRequestBody = {
  country: string;
  pageNumber: number;
};

export enum AmazonConnectionKind {
  ThreePAdminModel = '3pAdminOnlyModel',
  ThreePModel = '3pModel',
  OnePModel = '1pModel',
}

export type AmazonConnectionsResponse =
  | 'OK'
  | {
      visibility: 'ON' | 'OFF';
      connections: Connection[];
      kind: AmazonConnectionKind;
      return: 'enabled' | 'disabled';
      cancellation: 'enabled' | 'disabled';
    };

export type AmazonProductPayload = {
  productIds: string[];
  country: string;
};

export type Connection = {
  connectionId: string;
  country: string;
  emailIdentifier?: string;
};

export type Rating = {
  numberOfReviews: number | null;
  starRating: number | null;
};

export type Amount = {
  value: number;
  currencyCode: string;
};

export type ChargeCategoryAmount = {
  amount: number;
  currencyCode: string;
};

export type ProductVariation = {
  dimensions: Dimension[];
  variations: Variation[];
};

export type Dimension = {
  index: number;
  displayString: string;
  dimensionValues: DimensionValue[];
};

export type DimensionValue = {
  index: number;
  displayString: string;
};

export type Variation = {
  asin: string;
  variationValues: VariationValue[];
};

export type VariationValue = {
  index: number;
  value: number;
};

export type AmazonProduct = {
  title: string;
  description: string;
  url: string;
  rating: Rating;
  amount: Amount;
  image: string;
  id: string;
  productVariations: ProductVariation;
  features?: string[];
};

export type AmazonFilters = {
  prime?: { isPrimeEligible: boolean; id: string };
};

export type AmazonProductsResponse = {
  filters: AmazonFilters;
  meta: {
    hasNextPage: boolean;
    pageNumber: number;
  };
  products: AmazonProduct[];
};

export type AmazonProductResponse = {
  products: AmazonProduct[];
};

export type ToggleAmazonRewardsPayload = {
  toggleValue: 'ON' | 'OFF';
};

export type AmazonProductsPayload = {
  country: 'US' | 'CA';
  keyword?: string;
  filters?: string[];
};

export type DeliveryTimeRange = {
  lowerBound: string;
  upperBound: string;
};

export type OrderTotalValue = {
  currencyCode: string;
  amount: number;
};

export type Charge = {
  type: string;
  amount: ChargeCategoryAmount;
};

export type AmazonOrderResponse = {
  deliveryTimeRange: DeliveryTimeRange;
  orderIdentifier: string;
  orderTotalValue: OrderTotalValue;
  charge: Charge[];
};

export type ConfirmDetailsData = {
  shippingCost: number;
  tax: number;
  totalCost: number;
  principalCost: number;
  currencyCode: string;
  totalCostInCarrots: number;
  principalCostInCarrots: number;
  shippingCostInCarrots: number;
  taxCostInCarrots: number;
};

export type AmazonOrderStatus =
  | 'PAYMENT_CONFIRMED'
  | 'PENDING_APPROVAL'
  | 'PENDING_FULFILLMENT'
  | 'PENDING'
  | 'CANCELLED'
  | 'CLOSED';

export type RedeemInfoResponse = {
  isRedeemable: boolean;
  hasCharities: boolean;
  hasGiftCards: boolean;
  hasCulture: boolean;
  hasSwags: boolean;
  hasDiscounts: boolean;
  hasAmazon: boolean;
};
