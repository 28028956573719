import { useMutation, type UseMutationOptions } from '@tanstack/react-query';

import { APIEndpoints } from '../../../APIEndpoints';
import { assemblyAPI } from '../../../assemblyAPI';
import type { ApproveDenyAwardPayload } from '../../../types/award';

export function useApproveDenyAwardMutation(
  options?: UseMutationOptions<unknown, unknown, ApproveDenyAwardPayload>
) {
  return useMutation({
    mutationFn: async ({
      awardApprovalId,
      action,
    }: ApproveDenyAwardPayload) => {
      const response = await assemblyAPI.post(
        APIEndpoints.approveDenyAward({
          awardApprovalId,
          action,
        })
      );
      return response.data;
    },
    onSuccess: (...args) => {
      options?.onSuccess?.(...args);
    },
    onError: (...args) => {
      options?.onError?.(...args);
    },
    onSettled: (...args) => {
      options?.onSettled?.(...args);
    },
  });
}
