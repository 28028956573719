import {
  doraAPI,
  DoraAPIEndpoints,
  type ReplyData,
} from '@assembly-web/services';
import {
  convertPostDataToFlowPostFormat,
  getFormattedMessage,
  type Reply,
} from '@assembly-web/ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import noop from 'lodash/noop';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

type Common = {
  localeCode: 'es';
};

type RecognitionPost = {
  type: 'recognition';
  entity: {
    postId: string;
  };
};

type RecognitionReply = {
  type: 'recognitionReply';
  entity: {
    postId: string;
    commentId: string;
  };
};

type FlowResponse = {
  type: 'flowResponse';
  entity: {
    flowId: string;
    responseId: string;
  };
};

type FlowResponseReply = {
  type: 'flowResponseReply';
  entity: {
    flowId: string;
    responseId: string;
    commentId: string;
  };
};

type TranslationPayload = Common &
  (RecognitionReply | RecognitionPost | FlowResponse | FlowResponseReply);

type ContentIdentifier = {
  flowId: string;
  responseId: string;
  commentId?: string;
};

export const generateContentKey = ({
  flowId,
  responseId,
  commentId,
}: ContentIdentifier): string => {
  if (flowId === 'recognition') {
    return commentId
      ? `recognition-${responseId}-${commentId}`
      : `recognition-${responseId}`;
  }
  return commentId
    ? `${flowId}-${responseId}-${commentId}`
    : `${flowId}-${responseId}`;
};

type BaseContent = {
  flowId?: string;
  responseId?: string;
  postId?: string;
};

type TranslationState<T> = {
  shouldShowTranslatedContent: boolean;
  translatedContent: T | null;
};

export function useContentTranslation<T extends BaseContent>({
  contents,
}: {
  contents: T[];
}) {
  const queryClient = useQueryClient();
  const [translationStates, setTranslationStates] = useState<
    Record<string, TranslationState<T> | undefined>
  >({});
  const [currentTranslatingId, setCurrentTranslatingId] = useState<
    string | null
  >(null);
  const { formatMessage } = useIntl();

  const { isPending: isTranslationInProgress, mutateAsync: translateContent } =
    useMutation({
      mutationFn: async (request: TranslationPayload) => {
        const contentKey = generateContentKey({
          flowId:
            request.type === 'recognition' ||
            request.type === 'recognitionReply'
              ? 'recognition'
              : request.entity.flowId,
          responseId:
            request.type === 'recognition' ||
            request.type === 'recognitionReply'
              ? request.entity.postId
              : request.entity.responseId,
          commentId:
            'commentId' in request.entity
              ? request.entity.commentId
              : undefined,
        });

        setCurrentTranslatingId(contentKey);

        const cacheKey = [JSON.stringify(request)];
        const cachedResponse = queryClient.getQueryData(cacheKey);

        if (cachedResponse) {
          setCurrentTranslatingId(null);
          return { contentKey, data: cachedResponse };
        }

        try {
          const res = await doraAPI.post(
            DoraAPIEndpoints.translateContent,
            request
          );

          const originalData = res.data.translations;

          if (request.type === 'recognition') {
            const formattedData = convertPostDataToFlowPostFormat(
              originalData,
              formatMessage
            );
            queryClient.setQueryData(cacheKey, formattedData);
            return { contentKey, data: formattedData };
          } else if (
            request.type === 'recognitionReply' ||
            request.type === 'flowResponseReply'
          ) {
            let sourceData = { ...originalData } as ReplyData;
            const data = {
              ...sourceData,
            } as unknown as Reply;

            if (data.version === 2) {
              data.messageContent = sourceData.messageHtml ?? '';
            } else {
              data.messageNode = getFormattedMessage(
                {
                  mentions: sourceData.taggedUsers.map((user) => user),
                  value: sourceData.message ?? '',
                },
                noop,
                {}
              );
            }

            queryClient.setQueryData(cacheKey, data);
            return { contentKey, data };
          }

          queryClient.setQueryData(cacheKey, originalData);

          return { contentKey, data: originalData };
        } finally {
          setCurrentTranslatingId(null);
        }
      },
      onSuccess: (result) => {
        setTranslationStates((prev) => ({
          ...prev,
          [result.contentKey]: {
            shouldShowTranslatedContent: true,
            translatedContent: result.data,
          },
        }));
      },
      onError: (_, variables) => {
        const contentKey = generateContentKey({
          flowId:
            variables.type === 'recognition' ||
            variables.type === 'recognitionReply'
              ? 'recognition'
              : variables.entity.flowId,
          responseId:
            variables.type === 'recognition' ||
            variables.type === 'recognitionReply'
              ? variables.entity.postId
              : variables.entity.responseId,
          commentId:
            'commentId' in variables.entity
              ? variables.entity.commentId
              : undefined,
        });

        setTranslationStates((prev) => ({
          ...prev,
          [contentKey]: {
            shouldShowTranslatedContent: false,
            translatedContent: null,
          },
        }));
      },
    });

  const showTranslatedContent = useCallback(
    (identifier: ContentIdentifier) => {
      const contentKey = generateContentKey(identifier);
      setCurrentTranslatingId(contentKey);

      if (identifier.flowId === 'recognition') {
        if (identifier.commentId) {
          translateContent({
            localeCode: 'es',
            type: 'recognitionReply',
            entity: {
              postId: identifier.responseId,
              commentId: identifier.commentId,
            },
          });
        } else {
          translateContent({
            localeCode: 'es',
            type: 'recognition',
            entity: {
              postId: identifier.responseId,
            },
          });
        }
      } else {
        if (identifier.commentId) {
          translateContent({
            localeCode: 'es',
            type: 'flowResponseReply',
            entity: {
              flowId: identifier.flowId,
              responseId: identifier.responseId,
              commentId: identifier.commentId,
            },
          });
        } else {
          translateContent({
            localeCode: 'es',
            type: 'flowResponse',
            entity: {
              flowId: identifier.flowId,
              responseId: identifier.responseId,
            },
          });
        }
      }
    },
    [translateContent]
  );

  const getContent = useCallback(
    (identifier: ContentIdentifier): T | undefined => {
      const contentKey = generateContentKey(identifier);
      const content = contents.find((c) => {
        if (identifier.commentId && 'cardId' in c) {
          return c.cardId === identifier.commentId;
        }
        if ('postId' in c && c.postId) {
          return c.postId === identifier.responseId;
        }
        if ('responseId' in c && c.responseId) {
          return c.responseId === identifier.responseId;
        }
        return false;
      });

      if (!content) {
        return undefined;
      }

      const state = translationStates[contentKey];

      if (
        !state ||
        !state.translatedContent ||
        !state.shouldShowTranslatedContent
      ) {
        return content;
      }

      if (identifier.commentId && 'messageContent' in state.translatedContent) {
        return {
          ...content,
          messageContent: state.translatedContent.messageContent,
        };
      }

      return { ...content, ...state.translatedContent };
    },
    [contents, translationStates]
  );

  const showOriginalContent = useCallback((identifier: ContentIdentifier) => {
    const contentKey = generateContentKey(identifier);
    setTranslationStates((prev) => {
      const state = prev[contentKey];
      if (!state) return prev;
      if (!state.translatedContent) return prev;

      return {
        ...prev,
        [contentKey]: {
          ...state,
          shouldShowTranslatedContent: false,
        },
      };
    });
  }, []);

  const isShowingOriginalContent = useCallback(
    (identifier: ContentIdentifier): boolean => {
      const contentKey = generateContentKey(identifier);
      const state = translationStates[contentKey];
      return !state?.shouldShowTranslatedContent;
    },
    [translationStates]
  );

  return {
    getContent,
    isTranslationInProgress,
    showTranslatedContent,
    isShowingOriginalContent,
    showOriginalContent,
    currentTranslatingId,
  };
}
