import type {
  DepartmentChipData,
  GroupOrUserRecipientData,
  MemberChipData,
} from '@assembly-web/services';
import { Fragment } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Tooltip } from '../../DesignSystem/Feedback/Tooltip';
import { DepartmentList } from './DepartmentList';
import { GroupAndUserChip } from './GroupAndUserChip';
import { ProfileViewer } from './ProfileViewer/ProfileViewer';

const messages = defineMessages({
  everyone: {
    defaultMessage: 'Everyone',
    id: '2XDuH4',
  },
});

type GroupOrUserChipListProps = {
  data: GroupOrUserRecipientData[];
  disabled?: boolean;
  tooltipText?: string;
};

function DepartmentChip({
  tooltipText,
  recipient,
  disabled,
}: {
  recipient: DepartmentChipData;
  tooltipText?: string;
  disabled?: boolean;
}) {
  const { formatMessage } = useIntl();
  const isEveryone = recipient.id === 'everyone';
  const name = isEveryone ? formatMessage(messages.everyone) : recipient.name;

  return disabled ? (
    <Tooltip tooltipText={tooltipText}>
      <GroupAndUserChip
        type={recipient.id ? 'everyone' : 'department'}
        name={name}
        count={recipient.count}
        disabled={disabled}
      />
    </Tooltip>
  ) : (
    <DepartmentList
      id={recipient.id ?? recipient.name}
      criteriaId={recipient.criteriaId}
      postId={recipient.postId}
      name={recipient.name}
      count={recipient.count}
    >
      <GroupAndUserChip
        type={recipient.id ? 'everyone' : 'department'}
        name={name}
        count={recipient.count}
      />
    </DepartmentList>
  );
}

function MemberChip({
  tooltipText,
  recipient,
  disabled,
}: {
  recipient: MemberChipData;
  tooltipText?: string;
  disabled?: boolean;
}) {
  return disabled ? (
    <Tooltip tooltipText={tooltipText}>
      <GroupAndUserChip
        key={recipient.memberID}
        {...recipient}
        disabled={disabled}
      />
    </Tooltip>
  ) : (
    <ProfileViewer userDetails={recipient}>
      <GroupAndUserChip key={recipient.memberID} {...recipient} />
    </ProfileViewer>
  );
}

export function GroupOrUserChipList({
  data,
  ...props
}: GroupOrUserChipListProps) {
  return data.map((recipient, i) => (
    <Fragment key={i}>
      {(() => {
        if (recipient.type === 'department') {
          return <DepartmentChip recipient={recipient} {...props} />;
        }
        return <MemberChip recipient={recipient} {...props} />;
      })()}
    </Fragment>
  ));
}
