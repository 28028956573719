import type { Recipients } from '@assembly-web/services';
import {
  BriefcaseIcon,
  CheckIcon,
  GlobeAltIcon,
  UserCircleIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import type { PropsWithChildren } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { Avatar, AvatarSize } from '../../../DesignSystem/Feedback/Avatar';

const messages = defineMessages({
  pending: {
    defaultMessage: '(Pending)',
    id: 'PmVVN7',
  },
});

type MemberItemProps = {
  id: string;
  name: string;
  isSelected: boolean;
  italic?: boolean;
} & (
  | {
      type: Recipients['individuals'][number]['type'];
      image?: string;
      memberId: string;
      memberState: Recipients['individuals'][number]['memberState'];
    }
  | {
      type: Recipients['department'][number]['type'];
      countDescription?: string;
    }
  | {
      type: Recipients['manager'][number]['type'];
    }
  | {
      type: Recipients['admins'][number]['type'];
    }
);

export function ItemWrapper({
  className,
  children,
  highlighted,
  isSelected,
}: PropsWithChildren<{
  className?: string;
  highlighted?: boolean;
  isSelected?: boolean;
}>) {
  return (
    <span
      className={twMerge(
        'flex cursor-pointer items-center gap-2 px-3 py-2 text-base text-gray-9',
        highlighted && 'bg-gray-3',
        isSelected && 'text-gray-7',
        className
      )}
    >
      {children}
    </span>
  );
}

export function MemberItem({
  id,
  isSelected,
  italic,
  name,
  ...rest
}: MemberItemProps) {
  const { formatMessage } = useIntl();

  const isPending = rest.type === 'member' && rest.memberState === 'PENDING';
  const isDepartment = rest.type === 'department';

  return (
    <>
      {rest.type === 'role' ? (
        <BriefcaseIcon className="size-4 flex-shrink-0" />
      ) : rest.type === 'manager' ? (
        <UserCircleIcon className="size-4 flex-shrink-0" />
      ) : rest.type === 'member' ? (
        <Avatar
          memberID={rest.memberId}
          name={name}
          image={rest.image}
          size={AvatarSize.Small}
          className="flex-shrink-0"
          memberState={rest.memberState}
        />
      ) : id === 'everyone' ? (
        <GlobeAltIcon className="h-4 w-4 flex-shrink-0 text-gray-9" />
      ) : (
        <UsersIcon className="h-4 w-4 flex-shrink-0 text-gray-9" />
      )}
      <span className="inline-flex flex-shrink items-center">
        <span
          className={twMerge(
            'inline-block select-none truncate',
            isDepartment && 'max-w-[22ch]',
            !isDepartment && 'max-w-[24ch]',
            isPending && 'max-w-[16ch]',
            italic && 'pr-2 italic'
          )}
        >
          {name}
        </span>
        {Boolean(isPending) && (
          <span className="ml-1 italic text-gray-8">
            {formatMessage(messages.pending)}
          </span>
        )}
      </span>
      {rest.type === 'department' && Boolean(rest.countDescription) && (
        <span className="flex-shrink-0">{rest.countDescription}</span>
      )}
      {Boolean(isSelected) && <CheckIcon className="h-6 w-6 flex-shrink-0" />}
    </>
  );
}
