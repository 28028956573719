import type {
  AssemblyCurrency,
  GroupedCommentSearchResult,
  MemberDetails,
  MemberState,
  ReactionDetails,
} from '@assembly-web/services';
import { defineMessages, useIntl } from 'react-intl';

import { type ToolbarItem, useToolbarState } from '../../..';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../../DesignSystem/Inputs/Button';
import type { MemberDetailsForViewProfile } from '../../Shared/AuthorButton';
import { cardParentDataAttributes, handleParentClick } from '../Cards/utils';
import {
  ConversationCard,
  type ConversationCardMemberDetails,
} from '../PostAndReplies/ConversationCard';

export type ReplyCardProps = {
  comment?: GroupedCommentSearchResult;
  content: string;
  commentId: string;
  postOwner: {
    firstName?: string;
    lastName?: string;
    profileStatus: MemberState;
    entityId: string;
    profileImageUrl?: string;
    fullName: string;
    type?: string;
    pronouns?: string;
    email?: string;
    department?: string;
    jobTitle?: string;
    image?: string;
  };
  title?: string;
  handleTitleClick?: () => void;
  flow?: string;
  conversationMemberDetails: ConversationCardMemberDetails<
    MemberDetailsForViewProfile & {
      isAnonymous: false;
      name: string;
    }
  >;
  handleMenuItem: (menu: ToolbarItem) => void;
  handlePostOwnerClick?: () => void;
  toolbarMenuItems: ToolbarItem[];
  handleViewRepliesClick: () => void;
  handleFlowClick?: () => void;
  handleReactionClick: (emoji: ReactionDetails) => void;
  reactions?: ReactionDetails[];
  currentUserId: string;
  commentCount?: number;
  buttonText: string;
  gifURL?: string;
  messageNode?: React.ReactNode;
  postCreatedAt?: string;
  isEdited?: boolean;
  pointsEach?: {
    icon: AssemblyCurrency;
    points?: number;
  };
  onCardClicked: () => void;
  onViewThreadClicked: () => void;
  onSeePostClicked: () => void;
  currencyDetails?: AssemblyCurrency;
  taggedUsers?: MemberDetails[];
  boostedUsers?: { member: MemberDetails; points: number }[];
  onMemberClick: (memberId: string) => void;
};

const labels = defineMessages({
  moreReplies: {
    defaultMessage: 'See more replies',
    id: 'lu4G5v',
  },
  replyWithCount: { defaultMessage: '{count} replies', id: 'SfLG8q' },
});

export function ReplyCardWithPostPreview({
  content,
  commentId,
  conversationMemberDetails,
  handleMenuItem,
  toolbarMenuItems,
  handleViewRepliesClick,
  handleReactionClick,
  reactions,
  currentUserId,
  commentCount = 0,
  buttonText,
  gifURL,
  messageNode,
  pointsEach,
  isEdited,
  onCardClicked,
  onViewThreadClicked,
  currencyDetails,
  taggedUsers,
  boostedUsers,
  onMemberClick,
}: ReplyCardProps) {
  const { formatMessage } = useIntl();
  const { getContainerProps } = useToolbarState();

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onClick={handleParentClick(() => onCardClicked())}
      onKeyDown={handleParentClick((e) => {
        if (e.key === 'Enter' || e.key === 'Space') {
          onCardClicked();
        }
      })}
      className="hover:shadow-gray-100 border-top-width-0 relative flex cursor-pointer flex-col rounded-lg rounded-t-none border border-t-0 border-gray-5 border-x-gray-5 border-b-gray-5 bg-gray-1 px-4 pb-4 hover:border-gray-6"
      {...cardParentDataAttributes}
      {...getContainerProps()}
    >
      {Boolean(commentCount) && commentCount > 0 && (
        <div className="flex justify-between pb-4">
          <TextStyle
            className="cursor-pointer text-sm text-primary-6 underline"
            onClick={onViewThreadClicked}
            variant="base-medium"
          >
            {formatMessage(labels.moreReplies)}
          </TextStyle>
          <TextStyle className="text-gray-8" variant="sm-regular">
            {formatMessage(labels.replyWithCount, {
              count: commentCount,
            })}
          </TextStyle>
        </div>
      )}
      <ConversationCard
        messageNode={messageNode}
        gifURL={gifURL}
        key={commentId}
        cardId={commentId}
        variant="single"
        canShowToolbar={true}
        messageContent={content}
        currentMemberId={currentUserId}
        onReactionClick={handleReactionClick}
        toolbarItems={toolbarMenuItems}
        onMemberClick={onMemberClick}
        memberDetails={conversationMemberDetails}
        onMenuItemClick={handleMenuItem}
        reactions={reactions}
        isGroupedReplyCard={true}
        pointsEach={pointsEach}
        isEdited={isEdited}
        taggedUsers={taggedUsers}
        boost={boostedUsers}
        currencyDetails={currencyDetails}
      />
      <Button
        size="small"
        className="mx-12 my-2 w-fit p-3"
        variation="secondaryLite"
        onClick={handleViewRepliesClick}
      >
        {buttonText}
      </Button>
    </div>
  );
}
