import type { FileDetails } from '@assembly-web/services';

import { FilePreview } from '../../../../Shared/FileUpload/FilePreview';
import type { UploadedFiles } from '../../../../Shared/FileUpload/useFileUpload';

type UploadedFilesPluginProps = {
  onRemoveFileClick: (fileId: string) => void;
  uploadedFiles: UploadedFiles;
  draftFiles: FileDetails[];
};

export function UploadedFilesPlugin({
  onRemoveFileClick,
  uploadedFiles,
  draftFiles,
}: UploadedFilesPluginProps) {
  if (!draftFiles.length && !uploadedFiles.length) {
    return null;
  }

  return (
    <div className="flex max-h-40 flex-wrap gap-4 overflow-y-auto p-2">
      {draftFiles.map((file) => (
        <FilePreview
          key={file.name}
          variant="editor"
          onRemoveFileClick={onRemoveFileClick}
          file={file}
        />
      ))}
      {uploadedFiles.map((file) => (
        <FilePreview
          key={file.id}
          variant="editor"
          onRemoveFileClick={onRemoveFileClick}
          uppyFile={file}
        />
      ))}
    </div>
  );
}
