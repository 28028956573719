import { MenuOption } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import {
  $applyNodeReplacement,
  type DOMExportOutput,
  type NodeKey,
  type SerializedLexicalNode,
} from 'lexical';
import type { Spread } from 'type-fest';

import { Chip } from '../../../../../../DesignSystem/Inputs/Chip';
import { BaseChipNode } from './BaseNode';
import { RemoveButton } from './RemoveButton';

type TextChipColors = 'purple' | 'blue';

export type SerializedTextChipNode = Spread<
  {
    text: string;
    color?: TextChipColors;
  },
  SerializedLexicalNode
>;

export class TextChipNode extends BaseChipNode {
  __text: string;
  __color?: TextChipColors = 'purple';

  static getType(): string {
    return 'combobox-chip';
  }

  static clone(node: TextChipNode): TextChipNode {
    return new TextChipNode(node.__text, node.__color, node.__key);
  }

  static importJSON(serializedNode: SerializedTextChipNode): TextChipNode {
    return $createTextChipNode(serializedNode.text, serializedNode.color);
  }

  constructor(text: string, color?: string, key?: NodeKey) {
    super(key);
    this.__text = text;
    this.__color = color as TextChipColors;
  }

  exportJSON(): SerializedTextChipNode {
    return {
      text: this.__text,
      color: this.__color,
      type: 'combobox-chip',
      version: 1,
    };
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-combobox-chip', 'true');
    element.textContent = this.__text;
    return { element };
  }

  decorate() {
    return (
      <Chip.Root
        intent="badge"
        color={this.__color || 'purple'}
        className="break-words"
      >
        <Chip.Text className="line-clamp-4 text-ellipsis">
          {this.__text}
        </Chip.Text>
        <RemoveButton name={this.__text} node={this} />
      </Chip.Root>
    );
  }
}

export class TextTypeaheadOption extends MenuOption {
  __text: string;
  __color?: TextChipColors;

  constructor(text: string, color?: string) {
    super(text);
    this.__text = text;
    this.__color = color as TextChipColors;
  }
}

export function $createTextChipNode(
  data: TextTypeaheadOption | string,
  color?: TextChipColors
): TextChipNode {
  const text = data instanceof TextTypeaheadOption ? data.__text : data;
  const chipNode = new TextChipNode(text, color);
  return $applyNodeReplacement(chipNode);
}
