import type { TrackEventProps } from '../types/analytics';
import { getPageType, trackAction } from './analytics';

export type ParticipationAction =
  | 'participationStarted'
  | 'participationPosted'
  | 'editPostStarted'
  | 'editPostSaved'
  | 'deleteDraftClicked'
  | 'discardChangesClicked'
  | 'recipientSelected'
  | 'pointsSelected'
  | 'coreValueSelected'
  | 'messageStarted';

export function trackParticipationShow(props?: TrackEventProps) {
  return trackAction({
    eventName: 'participation.events',
    eventProps: {
      event: 'show',
      ...props,
    },
  });
}

export function trackParticipationAction(
  actionName: ParticipationAction,
  props?: TrackEventProps
) {
  return trackAction({
    eventName: 'participation.events',
    eventProps: {
      event: 'action',
      action: actionName,
      ...props,
    },
  });
}

export function trackParticipationError(props?: TrackEventProps) {
  return trackAction({
    eventName: 'participation.events',
    eventProps: {
      event: 'error',
      pageType: getPageType(),
      ...props,
    },
  });
}

export function trackRecognitionParticipationAction(props?: TrackEventProps) {
  return trackAction({
    eventName: 'recognition.participation.post',
    eventProps: {
      event: 'action',
      ...props,
    },
  });
}
