import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { defineMessages, useIntl } from 'react-intl';

import {
  Avatar,
  AvatarSize,
  type UserAvatarProps,
} from '../../../../../DesignSystem/Feedback/Avatar';
import { TextStyle } from '../../../../../DesignSystem/Feedback/TextStyle';
import { OverflowCard } from '.';

dayjs.extend(relativeTime);

const messages = defineMessages({
  replyToThisPost: {
    defaultMessage: 'Reply to this post',
    id: 'AKLw8w',
  },
  lastReply: {
    defaultMessage: 'Last reply {timeAgo}',
    id: 'nZtCXw',
  },
  replies: {
    defaultMessage: '{replyCount} replies',
    id: 'oiloP2',
  },
});

export type PostOverflowCardProps = {
  lastReplyTS: string;
  title: string;
  onClose: () => void;
  onClick: () => void;
  avatarProps: UserAvatarProps;
  replyCount: number;
  isLoading: boolean;
};

export function PostOverflowCard(props: PostOverflowCardProps) {
  const {
    title,
    onClick,
    onClose,
    avatarProps,
    replyCount,
    lastReplyTS,
    isLoading,
  } = props;
  const { formatMessage } = useIntl();

  const avatar = (
    <Avatar
      className="flex-shrink-0"
      image={avatarProps.image}
      size={AvatarSize.Large}
      name={avatarProps.name}
      memberID={avatarProps.memberID}
      isRounded={false}
    />
  );

  const content = (
    <div>
      <TextStyle
        as="span"
        variant="xs-medium"
        className="mr-1.5 text-primary-6"
      >
        {formatMessage(messages.replies, {
          replyCount: replyCount,
        })}
      </TextStyle>
      <TextStyle as="span" variant="xs-regular" className="text-gray-8">
        {formatMessage(
          replyCount <= 0 ? messages.replyToThisPost : messages.lastReply,
          {
            timeAgo: dayjs(lastReplyTS).fromNow(),
          }
        )}
      </TextStyle>
    </div>
  );

  if (isLoading) {
    return (
      <div className="group-hover:shadow-base-downflex animate-puls flex w-full items-start rounded-lg border border-gray-5 p-3 group-hover:border-gray-6">
        <div className="h-10 w-10 flex-shrink-0 rounded-md bg-gray-5" />
        <div className="flex grow flex-col gap-1 pl-3">
          <div className="h-4 w-full max-w-[325px] rounded bg-gray-5" />
          <div className="h-5 w-full max-w-[179px] rounded bg-gray-5" />
        </div>
      </div>
    );
  }
  return (
    <OverflowCard
      title={title}
      content={content}
      avatar={avatar}
      onClose={onClose}
      onClick={onClick}
    />
  );
}
