import { SVGReactComponents } from '@assembly-web/assets';
import {
  SplitNames,
  useFeatureSplit,
  useUserDetails,
} from '@assembly-web/services';
import {
  Avatar,
  AvatarSize,
  Button,
  GaugeChart,
  TextStyle,
  Tooltip,
} from '@assembly-web/ui';
import {
  ChartBarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import * as Collapsible from '@radix-ui/react-collapsible';
import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { useNavigateToUserFeed } from '../../../hooks/useNavigateToUserFeed';
import { useMultiDrawerStore } from '../../../stores/useMultiDrawerStore';
import { useTeamRecognitionInsightsQuery } from '../hooks/useTeamRecognitionInsightsQuery';
import { trackDoraAction } from '../services/analytics';
import { setFullScreenDrawerId } from '../services/drawers';
import { getDoraChatStore } from '../stores/doraChatStore';

const messages = defineMessages({
  userDescriptionTooltipText: {
    defaultMessage:
      '{firstName} last received recognition on {lastRecognizedDate}',
    id: 'zmdc+5',
  },
  neverRecognizedUserDescriptionTooltipText: {
    defaultMessage: '{firstName} has never been recognized',
    id: 'qMkrP4',
  },
  giveRecognitionTooltipText: {
    defaultMessage: 'Give {firstName} recognition',
    id: 'Z+sHoo',
  },
  getAISummaryTooltipText: {
    defaultMessage: 'Get an AI summary for {firstName}',
    id: 'oHx9bp',
  },
  recognizeTeamButtonText: {
    defaultMessage: 'Recognize my team',
    id: 'iNj5dc',
  },
  teamInsightsHeader: {
    defaultMessage: 'Team Insights',
    id: 'W1Kxlu',
  },
  poweredByDoraAISubheader: {
    defaultMessage: 'Powered by DoraAI',
    id: 'MCVyLQ',
  },
  teamInsightsButtonText: {
    defaultMessage: 'Get more team insights',
    id: 'yblwJH',
  },
  gaugeSubheaderText: {
    defaultMessage: 'Percentage of team recognized this month',
    id: 'Zm3/RN',
  },
  neverRecognizedText: {
    defaultMessage: 'Never recognized',
    id: 'gDOwGY',
  },
  managerTipsHeaderText: {
    defaultMessage: '💡 Tips',
    id: 'k77XJ1',
  },
  managerTipsSubheaderText: {
    defaultMessage: 'Individual team member insights can provide:',
    id: 'OuKn33',
  },
  teamOverviewTipText: {
    defaultMessage:
      'An overview of consistent strengths and skills you can help develop',
    id: 'UkbE7E',
  },
  quotesTipText: {
    defaultMessage:
      'Quotes from coworkers to use in job promotion announcements',
    id: 'g3XknT',
  },
  inspirationTipText: {
    defaultMessage: 'Inspiration for appreciating them in your own post!',
    id: 'k5iz48',
  },
  teamDrawerTitleText: {
    defaultMessage: "Your team's recognition",
    id: 'RApOiq',
  },
  individualDrawerTitleText: {
    defaultMessage: "{firstName}'s recognition",
    id: 'H3+PG/',
  },
  openButtonText: {
    defaultMessage: 'Open',
    id: 'JfG49w',
  },
  closeButtonText: {
    defaultMessage: 'Close',
    id: 'rbrahO',
  },
  getTeamReportPrompt: {
    defaultMessage:
      "Give me an overall report of recognition related to my team(my direct reports). Tell me the total number of received and sent by the team over all time and for this month, what were the recent recognitions about, who was recognized the most in the last 30 days, who hasn't been recognized in the last 30 days. Provide links to any referenced posts. Provide links to send recognition as well for users that need it.",
    id: 'km27gq',
  },
  getUserReportPrompt: {
    defaultMessage:
      'Give me an overall report of recognition related to {fullName}. Tell me the total number of received and sent over all time and for this month, what were the last 5 recent recognitions about. Provide links to any referenced posts. ',
    id: 'JXi7yL',
  },
});

function UserList(props: {
  workspaceSlugPath: string;
  users: {
    firstName: string;
    lastName: string;
    image: string;
    memberID: string;
    lastRecognizedDate?: Date;
  }[];
}) {
  const { workspaceSlugPath, users } = props;
  const { formatMessage, formatDate } = useIntl();
  const createDrawer = useMultiDrawerStore((store) => store.createDrawer);
  const updateDraftInputValue = useMultiDrawerStore(
    (store) => store.updateDraftInputValue
  );
  const { navigate: navigateToUserFeed } = useNavigateToUserFeed();

  const listItems = users.map((user) => {
    const firstName = user.firstName;
    const fullName = `${user.firstName} ${user.lastName}`;
    const lastRecognizedFormatted = user.lastRecognizedDate
      ? formatDate(new Date(user.lastRecognizedDate), {
          day: 'numeric',
          month: 'short',
        })
      : null;
    const userDescriptionTooltipText = lastRecognizedFormatted
      ? formatMessage(messages.userDescriptionTooltipText, {
          firstName,
          lastRecognizedDate: lastRecognizedFormatted,
        })
      : formatMessage(messages.neverRecognizedUserDescriptionTooltipText, {
          firstName,
        });
    return (
      <div
        key={user.memberID}
        className="flex items-start justify-between self-stretch bg-gray-3"
      >
        <Tooltip tooltipText={userDescriptionTooltipText}>
          <div className="flex flex-col">
            <button
              className="flex items-center gap-2 pl-1 pr-1"
              onClick={() => navigateToUserFeed(user.memberID)}
            >
              <Avatar
                memberID={user.memberID}
                size={AvatarSize.Small}
                name={fullName}
                image={user.image}
              />

              <TextStyle as="span" variant="sm-regular">
                {`${user.firstName} ${user.lastName ? ` ${user.lastName.charAt(0).toUpperCase()}.` : ''}`}
              </TextStyle>
            </button>
            <div className="flex items-center gap-1 pl-9">
              <SVGReactComponents.HistoryIcon className="h-3 w-3" />
              <TextStyle
                as="span"
                variant="xs-regular"
                subdued
                className="leading-none"
              >
                <span className="capitalize">
                  {lastRecognizedFormatted ||
                    formatMessage(messages.neverRecognizedText)}
                </span>
              </TextStyle>
            </div>
          </div>
        </Tooltip>

        <div className="flex items-center gap-2">
          <Tooltip
            tooltipText={formatMessage(messages.getAISummaryTooltipText, {
              firstName,
            })}
          >
            <div>
              <ChartBarIcon
                className="m-1 h-4 w-4 cursor-pointer stroke-primary-6"
                onClick={() => {
                  const drawerId = createDrawer({
                    type: 'doraChat',
                    title: formatMessage(messages.individualDrawerTitleText, {
                      firstName,
                    }),
                    data: {},
                  });
                  const { doraChatStore } = getDoraChatStore(
                    `doraChat-${drawerId}`
                  );
                  doraChatStore.getState().setReportingCategory('recognition');
                  setFullScreenDrawerId(drawerId);
                  updateDraftInputValue(
                    drawerId,
                    formatMessage(messages.getUserReportPrompt, { fullName })
                  );
                  trackDoraAction('teamInsightsClicked', {
                    userId: user.memberID,
                  });
                }}
              />
            </div>
          </Tooltip>

          <Tooltip
            tooltipText={formatMessage(messages.giveRecognitionTooltipText, {
              firstName,
            })}
          >
            <div>
              <SVGReactComponents.RecognitionIcon
                className="m-1 h-4 w-4 cursor-pointer stroke-primary-6 stroke-[1.25]"
                onClick={() =>
                  window.open(
                    `/a/${workspaceSlugPath}/flows/recognition/answer?userId=${user.memberID}&fromWeb=ManagerWidget`,
                    '_blank',
                    'noopener,noreferrer'
                  )
                }
              />
            </div>
          </Tooltip>
        </div>
      </div>
    );
  });

  const userIds = users.map((user) => user.memberID).slice(0, 30); //Add arbitrary limit to prevent slowdown on recognition page
  const teamRecognitionUrl = `/a/${workspaceSlugPath}/flows/recognition/answer?userId=${userIds.join(',')}&fromWeb=ManagerWidget`;

  return (
    <div className="flex w-full flex-col gap-4 rounded-lg bg-gray-3 p-2">
      <div
        className={twMerge(
          'flex max-h-[350px] flex-col gap-3',
          listItems.length > 7 ? 'overflow-y-auto' : 'overflow-y-hidden'
        )}
        style={{
          scrollbarWidth: 'none',
        }}
      >
        {listItems}
      </div>

      <Button
        variation="secondaryEmphasized"
        isFullWidth
        onClick={() =>
          window.open(teamRecognitionUrl, '_blank', 'noopener,noreferrer')
        }
      >
        <SVGReactComponents.RecognitionIcon className="h-4 w-4 stroke-primary-6" />
        {formatMessage(messages.recognizeTeamButtonText)}
      </Button>
    </div>
  );
}

function ManagerTips({ trackOpen }: { trackOpen: () => void }) {
  const [isOpen, setOpen] = useState(false);
  const { formatMessage } = useIntl();

  return (
    <Collapsible.Root
      open={isOpen}
      onOpenChange={() => {
        setOpen(!isOpen);
        if (!isOpen) trackOpen();
      }}
      className="w-full rounded-lg bg-primary-1 p-2"
    >
      <div className="flex flex-row items-center justify-between">
        <TextStyle as="span" variant="base-medium">
          {formatMessage(messages.managerTipsHeaderText)}
        </TextStyle>
        <Collapsible.Trigger>
          <div className="flex items-center gap-2 px-3 py-1 text-gray-8">
            {isOpen ? (
              <>
                {formatMessage(messages.closeButtonText)}
                <ChevronUpIcon className="h-4 w-4" />
              </>
            ) : (
              <>
                {formatMessage(messages.openButtonText)}
                <ChevronDownIcon className="h-4 w-4" />
              </>
            )}
          </div>
        </Collapsible.Trigger>
      </div>
      <Collapsible.Content className="mt-2">
        <TextStyle as="span" variant="sm-medium" subdued>
          {formatMessage(messages.managerTipsSubheaderText)}
        </TextStyle>
        <ul className="ml-6 list-disc text-gray-8">
          <li>
            <TextStyle as="span" variant="sm-regular" subdued>
              {formatMessage(messages.teamOverviewTipText)}
            </TextStyle>
          </li>
          <li>
            <TextStyle as="span" variant="sm-regular" subdued>
              {formatMessage(messages.quotesTipText)}
            </TextStyle>
          </li>
          <li>
            <TextStyle as="span" variant="sm-regular" subdued>
              {formatMessage(messages.inspirationTipText)}
            </TextStyle>
          </li>
        </ul>
      </Collapsible.Content>
    </Collapsible.Root>
  );
}

export function TeamInsightsWidget() {
  const { formatMessage } = useIntl();
  const createDrawer = useMultiDrawerStore((store) => store.createDrawer);
  const updateDraftInputValue = useMultiDrawerStore(
    (store) => store.updateDraftInputValue
  );
  const { data: teamInsights, isLoading: insightsLoading } =
    useTeamRecognitionInsightsQuery();
  const { data: userDetails } = useUserDetails();
  const workspaceSlugPath = userDetails?.assembly.workspaceSlugPath;
  const { isTreatmentActive: isManagerReportingEnabled } = useFeatureSplit(
    SplitNames.DoraReportingInsightsManagers
  );

  return (
    !insightsLoading &&
    isManagerReportingEnabled &&
    workspaceSlugPath &&
    teamInsights?.usersToRecognize.length !== 0 && ( // For cases where all direct reports are deactivated
      <div className="hidden w-full min-w-[260px] flex-col justify-center gap-4 rounded-2xl bg-gray-1 p-4 shadow-md-down lg:flex">
        <div className="flex flex-col justify-center">
          <TextStyle as="span" variant="xl-medium">
            {formatMessage(messages.teamInsightsHeader)}
          </TextStyle>
          <TextStyle as="span" variant="sm-regular" subdued className="mt-1">
            {formatMessage(messages.poweredByDoraAISubheader)}
          </TextStyle>
        </div>

        <GaugeChart
          percentage={teamInsights?.percentageOfUsersRecognizedThisMonth ?? 0}
          subtitle={formatMessage(messages.gaugeSubheaderText)}
        />
        <Button
          isFullWidth
          onClick={() => {
            const drawerId = createDrawer({
              type: 'doraChat',
              title: formatMessage(messages.teamDrawerTitleText),
              data: {},
            });
            const { doraChatStore } = getDoraChatStore(`doraChat-${drawerId}`);
            doraChatStore.getState().setReportingCategory('recognition');
            setFullScreenDrawerId(drawerId);
            updateDraftInputValue(
              drawerId,
              formatMessage(messages.getTeamReportPrompt)
            );
            trackDoraAction('teamInsightsClicked');
          }}
        >
          <ChartBarIcon className="h-4 w-4" />{' '}
          {formatMessage(messages.teamInsightsButtonText)}
        </Button>
        <UserList
          workspaceSlugPath={workspaceSlugPath}
          users={teamInsights?.usersToRecognize ?? []}
        />
        <ManagerTips
          trackOpen={() =>
            trackDoraAction('teamInsightsTipsOpen', {
              userId: userDetails.member.memberId,
            })
          }
        />
      </div>
    )
  );
}
