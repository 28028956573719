import { getHandlerProps, MemberState } from '@assembly-web/services';
import {
  BriefcaseIcon,
  GlobeAltIcon,
  UserCircleIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { forwardRef, type ReactElement } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { Avatar, AvatarSize } from '../../DesignSystem/Feedback/Avatar';
import { Chip } from '../../DesignSystem/Inputs/Chip';

type GroupAndUserChipProps = {
  name: string;
  removeButton?: ReactElement;
  disabled?: boolean;
  className?: string;
} & (
  | {
      type: 'member';
      memberID: string;
      image?: string;
      memberState: MemberState;
    }
  | { type: 'department'; count: number }
  | { type: 'everyone'; count: number }
  | { type: 'manager'; count: number }
  | { type: 'role'; count: number }
);

const messages = defineMessages({
  formattedCount: {
    defaultMessage: '({count, number})',
    id: 'ZgwNJ8',
  },
  deactivatedUser: {
    defaultMessage: '<b>{name}</b> (deactivated)',
    id: 'Rwjaee',
  },
});

function Count({ count }: { count: number }) {
  const { formatMessage } = useIntl();

  return formatMessage(messages.formattedCount, {
    count,
  });
}

export const GroupAndUserChip = forwardRef<HTMLElement, GroupAndUserChipProps>(
  function GroupAndUserChip(
    { name, removeButton, disabled, className, ...rest },
    ref
  ) {
    const { formatMessage } = useIntl();

    const handlerProps = getHandlerProps(rest);

    return (
      <Chip.Root
        {...handlerProps}
        ref={ref}
        intent="badge"
        color="blue"
        isDisabled={disabled}
        className={twJoin(
          'select-none',
          rest.type === 'member' &&
            rest.memberState === MemberState.Deactivated &&
            'grayscale',
          className
        )}
      >
        {rest.type === 'role' ? (
          <BriefcaseIcon className="size-4 flex-shrink-0" />
        ) : rest.type === 'manager' ? (
          <UserCircleIcon className="size-4 flex-shrink-0" />
        ) : rest.type === 'member' ? (
          <Avatar
            memberID={rest.memberID}
            name={name}
            image={rest.image}
            size={AvatarSize.Small}
            className="flex-shrink-0"
            memberState={rest.memberState}
          />
        ) : rest.type === 'everyone' ? (
          <GlobeAltIcon className="h-4 w-4 flex-shrink-0 text-gray-9" />
        ) : (
          <UsersIcon className="h-4 w-4 flex-shrink-0 text-gray-9" />
        )}
        <Chip.Text className="flex w-full items-center whitespace-pre">
          {rest.type === 'member' &&
          rest.memberState === MemberState.Deactivated ? (
            formatMessage(messages.deactivatedUser, {
              name,
              b: (msg) => (
                <span className="inline-block max-w-[20ch] flex-shrink truncate">
                  {msg}
                </span>
              ),
            })
          ) : (
            <span className="inline-block max-w-[20ch] flex-shrink truncate">
              {name}
            </span>
          )}
          {(rest.type === 'department' || rest.type === 'everyone') && (
            <span className="flex-shrink-0">
              {' '}
              <Count count={rest.count} />
            </span>
          )}
        </Chip.Text>
        {removeButton}
      </Chip.Root>
    );
  }
);
