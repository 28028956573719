import {
  EntityType,
  generateCriteriaRules,
  type GetPostResponse,
  type GroupOrUserChipData,
  isTruthy,
  type Member,
  type MemberDetails,
  MemberRole,
  MemberState,
  MemberStatus,
  parsePostResponseRecipients,
  type RecognitionDraft,
  type SaveRecognitionDraftPayload,
} from '@assembly-web/services';
import { useCallback, useMemo } from 'react';

import { getDraftFileFormat } from '../RecognitionParticipation/utils/helpers';
import type { CacheDraftFile } from '../RecognitionParticipation/utils/types';

type RecognitionParticipationForm = {
  message: {
    json: string | undefined;
  };
  recipients?: (
    | {
        type: 'department';
        count: number;
        id: string;
        name: string;
      }
    | ({
        type: 'member';
      } & MemberDetails)
  )[];
  coreValue: string | undefined;
  attachments:
    | {
        location: string;
        name: string;
        size: number;
      }[]
    | undefined;
};

export function useRecognitionForm() {
  const getParsedFormDataForDraft = useCallback(
    (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fields: Record<string, any>,
      includeRecipientMeta = false
    ) => {
      const {
        message: { json = '', plainText = '' },
        recipients = [],
        coreValue = '',
        attachments = [],
        points = 0,
      } = fields;
      const groupedCriteria = generateCriteriaRules(
        recipients,
        includeRecipientMeta
      );
      const isValidDraft = isTruthy(
        plainText.length ||
          recipients.length ||
          coreValue.length ||
          attachments.length
      );
      if (isValidDraft) {
        return {
          messageTokens: plainText ? json : '',
          ...(recipients.length && {
            recipient: {
              criteria: groupedCriteria,
            },
          }),
          ...(points && { pointsEach: points }),
          ...(coreValue && { coreValue }),
          ...(attachments.length && { attachments }),
        } satisfies RecognitionDraft;
      } else {
        return {};
      }
    },
    []
  );

  const getSaveDraftPayload = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (fields: Record<string, any>) => {
      const cacheData = getParsedFormDataForDraft(fields, true);
      const attachmentsCache = cacheData.attachments?.map(
        (item: CacheDraftFile) => getDraftFileFormat(item)
      );
      return {
        toCache: {
          entityType: EntityType.Recognition,
          postData: {
            ...cacheData,
            ...(attachmentsCache?.length && { attachments: attachmentsCache }),
          },
        },
        toServer: {
          entityType: EntityType.Recognition,
          postData: getParsedFormDataForDraft(fields),
        },
      } satisfies SaveRecognitionDraftPayload;
    },
    [getParsedFormDataForDraft]
  );

  const getIndividuals = useCallback(
    (value: Partial<Member>): MemberDetails => {
      return {
        email: value.email ?? '',
        firstName: value.firstName ?? '',
        lastName: value.lastName ?? '',
        memberId: value.memberId ?? '',
        memberID: value.memberID ?? '',
        memberState: value.memberState ?? MemberState.Active,
        name: value.name ?? '',
        role: value.role ?? [MemberRole.Employee],
        status: value.status ?? MemberStatus.Normal,
        department: value.department ?? '',
        image: value.image ?? '',
        jobTitle: value.jobTitle ?? '',
        pronouns: value.pronouns ?? '',
        username: value.username ?? '',
      };
    },
    []
  );

  const getDraftData = useCallback(
    (draft: RecognitionDraft): RecognitionParticipationForm => {
      const response = {
        message: {
          json: draft.messageTokens,
        },
        recipients:
          draft.recipient?.criteria.include
            .map((criteria) =>
              criteria.field === 'department'
                ? criteria.values.map(
                    (value) =>
                      ({
                        type: 'department',
                        count: 0,
                        id: value.value,
                        name: value.value,
                      }) satisfies GroupOrUserChipData
                  )
                : criteria.field === 'everyone'
                  ? ({
                      type: 'department',
                      count: 0,
                      id: 'everyone',
                      name: 'everyone',
                    } satisfies GroupOrUserChipData)
                  : criteria.values.map(
                      (value) =>
                        ({
                          type: 'member',
                          ...getIndividuals({
                            ...value.meta,
                            username: value.meta?.username ?? '',
                            memberId: value.value,
                            memberID: value.value,
                          }),
                        }) satisfies GroupOrUserChipData
                    )
            )
            .flat() ?? undefined,
        coreValue: draft.coreValue,
        attachments: draft.attachments,
        recipientsCount: 0,
        points: draft.pointsEach,
      };
      return response;
    },
    [getIndividuals]
  );

  const getPostData = useCallback(
    (postData: GetPostResponse['post']): RecognitionParticipationForm => {
      const value = {
        message: {
          html: postData.messageHtml,
          plainText: postData.message,
          json: postData.messageTokens,
        },
        points: postData.pointsEach,
        isPrivate: postData.isPrivate,
        recipients: parsePostResponseRecipients(postData.recipient),
        coreValue: postData.coreValue,
        attachments: postData.attachments,
        recipientsCount: 0,
      };
      return value;
    },
    []
  );

  return useMemo(
    () => ({
      getParsedFormDataForDraft,
      getDraftData,
      getPostData,
      getIndividuals,
      getSaveDraftPayload,
    }),
    [
      getDraftData,
      getIndividuals,
      getParsedFormDataForDraft,
      getPostData,
      getSaveDraftPayload,
    ]
  );
}
