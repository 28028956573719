import { logger, useSuspenseUserDetails } from '@assembly-web/services';
import {
  OpenEndedBlock,
  OpenEndedContainer,
  useOpenEndedFileUpload,
  useOpenEndedValidation,
} from '@assembly-web/ui';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { defineMessage, useIntl } from 'react-intl';

const placeholder = defineMessage({
  defaultMessage: 'You can add a personal message to your award',
  id: 'PaDRpO',
});

function Editor() {
  const { control } = useFormContext();
  const { formatMessage } = useIntl();

  const { setValue } = useFormContext();

  const userDetails = useSuspenseUserDetails();

  const { fileUploadOptions, fileAttachment } = useOpenEndedFileUpload({
    key: 'give-awards',
  });

  const { validate } = useOpenEndedValidation({
    assemblyDetails: userDetails.data.assembly,
  });

  const { files, removeFile } = fileUploadOptions;

  useEffect(() => {
    setValue('attachments', [
      ...files.map((file) => {
        return {
          location: file.meta.location,
          name: file.meta.name,
          size: file.size,
          type: file.meta.type,
          uploadComplete: file.progress.uploadComplete,
          url: file.response?.uploadURL,
        };
      }),
    ]);
  }, [files, setValue]);

  function handleRemoveFile(fileId: string) {
    if (files.find((file) => file.id === fileId)) {
      removeFile(fileId);
    }
  }

  return (
    <Controller
      control={control}
      name="message"
      defaultValue={{ json: control._defaultValues.message?.json }}
      rules={{
        validate,
      }}
      render={({ field: { onChange, disabled }, fieldState: { error } }) => (
        <OpenEndedBlock
          placeholder={formatMessage(placeholder)}
          fileAttachment={fileAttachment}
          files={files}
          draftState={control._defaultValues.message?.json}
          onChange={onChange}
          error={error}
          disabled={disabled}
          handleRemoveFile={handleRemoveFile}
          onError={(error, editor) => {
            logger.error(
              'Awards Open ended Editor Error',
              {
                editor,
              },
              error
            );
          }}
        />
      )}
    />
  );
}

export function OpenEnded() {
  return (
    <OpenEndedContainer>
      <Editor />
    </OpenEndedContainer>
  );
}
