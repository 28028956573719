import { defaultAwardImage } from '@assembly-web/assets';
import {
  BlockResponseState,
  BlockTypes,
  LegacyPostTypes,
  type MemberDetails,
  type OpenEndedBlockResponseDetails,
} from '@assembly-web/services';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { AnimatePresence, motion } from 'framer-motion';
import { type MouseEvent, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin, twMerge } from 'tailwind-merge';

import { PostCard } from '../../..';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import {
  Toolbar,
  type ToolbarProps,
} from '../../../DesignSystem/Feedback/Toolbar/Toolbar';
import { useToolbarState } from '../../../DesignSystem/Feedback/Toolbar/useToolbarState';
import { IconButton } from '../../../DesignSystem/Inputs/IconButton';
import { HorizontalRule } from '../../../DesignSystem/Layout/HorizontalRule';
import { PostCardBody } from '../../Shared/PostCard/PostCardBody';
import {
  PostCardHeader,
  type PostCardHeaderProps,
} from '../../Shared/PostCard/PostCardHeader';
import type { PostCardProps } from '../../Shared/PostCard/PostCardUI';
import type { BoostOptions } from '../Editors/RepliesEditor/RepliesEditor';
import { ReactionsBar, type ReactionsBarProps } from './ReactionsBar';

export type PostProps = {
  postedAt: string;
  postHeaderProps: PostCardHeaderProps;
  postBodyProps: Pick<
    PostCardProps,
    | 'currency'
    | 'onFileClick'
    | 'flowResponse'
    | 'onPostCardBodyClick'
    | 'onBlockToolbarItemClick'
  > & { repliesCount: number };
  boostOptions?: BoostOptions;
  reactionsBarProps: ReactionsBarProps;
  onMemberClick: (memberId: string) => void;
  onPostToggleClick: (isExpanded: boolean) => void;
  canHidePoints?: boolean;
  handleInternalLinkClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  currentUser: MemberDetails;
} & Pick<ToolbarProps, 'secondaryToolbarItems' | 'onMenuItemClick'>;

const messages = defineMessages({
  originalPost: {
    defaultMessage: 'Original post',
    id: 'p1pzyX',
  },
  postedAt: {
    defaultMessage: 'Posted {postedAt} ago',
    id: 'xwpf2a',
  },
  repliesTurnedOff: {
    defaultMessage: 'Replies have been turned off',
    id: 'h9h1y6',
  },
  replyCount: {
    defaultMessage:
      '{count, plural, =0 {No Replies} one {1 reply} other {{count} replies}}',
    id: 'Sn3666',
  },
  postedJustNow: {
    defaultMessage: 'Posted just now',
    id: '+0gpoj',
  },
});

export function Post(props: PostProps) {
  const { formatMessage } = useIntl();

  const [isPostExpanded, setPostExpanded] = useState(true);

  const {
    onMemberClick,
    postBodyProps,
    onMenuItemClick,
    postHeaderProps,
    onPostToggleClick,
    reactionsBarProps,
    secondaryToolbarItems,
    boostOptions,
    canHidePoints,
    handleInternalLinkClick,
    currentUser,
  } = props;

  const { getToolbarProps } = useToolbarState({ isAlwaysVisible: true });

  return (
    <div
      className={twJoin(
        'flex w-full flex-col items-center gap-2 bg-gray-1',
        postBodyProps.flowResponse.postType !== LegacyPostTypes.Award && 'pt-4'
      )}
    >
      {postBodyProps.flowResponse.postType === LegacyPostTypes.Award ? (
        <PostCard
          containerProps={{ className: 'shadow-none -mx-6' }}
          postType={postBodyProps.flowResponse.postType}
          awardBanner={{
            title: postBodyProps.flowResponse.award?.name ?? '',
            description: postBodyProps.flowResponse.award?.description,
            bannerImage: {
              src:
                postBodyProps.flowResponse.award?.image?.location ??
                defaultAwardImage,
              alt: '',
            },
          }}
          headerProps={{
            ...postHeaderProps,
            showFlowDetails: false,
            boostOptions: boostOptions,
          }}
          toolbarProps={{
            ...getToolbarProps({ onMenuItemClick }),
            secondaryToolbarItems,
            onToolbarMenuItemClick: onMenuItemClick,
          }}
          bodyProps={{
            onMemberClick,
            response: (() => {
              const response = postBodyProps.flowResponse.responses.find(
                (block) => block.type === BlockTypes.OpenEnded
              ) as OpenEndedBlockResponseDetails | undefined;
              if (response?.state === BlockResponseState.Skipped) {
                return undefined;
              }
              return response?.response;
            })(),
            handleInternalLinkClick,
          }}
          metaProps={{
            coreValue: postBodyProps.flowResponse.coreValue,
            points: postBodyProps.flowResponse.points,
            hiddenPoints: canHidePoints,
            recipients: (() => {
              const recipients = postBodyProps.flowResponse.responses.find(
                (block) => block.type === BlockTypes.PersonSelector
              );
              if (
                !recipients ||
                recipients.state === BlockResponseState.Skipped
              ) {
                return undefined;
              }
              return 'recipients' in recipients.response
                ? recipients.response.recipients
                : [];
            })(),
          }}
          reactionProps={reactionsBarProps}
          currentMember={currentUser}
          currency={postBodyProps.currency}
          showDivide={false}
        />
      ) : (
        <>
          <div className="flex w-full justify-between gap-1">
            <PostCardHeader
              {...postHeaderProps}
              boostOptions={boostOptions}
              contentClassName="max-w-[180px] truncate"
            />
            <Toolbar
              {...getToolbarProps({ onMenuItemClick })}
              secondaryToolbarItems={secondaryToolbarItems}
              compact
              hideWhenDetached
            />
          </div>
          <div className="grid w-full grid-cols-[1fr_auto] justify-between">
            <IconButton
              variation="tertiaryLite"
              className="w-fit"
              onClick={() => {
                setPostExpanded(!isPostExpanded);
                onPostToggleClick(!isPostExpanded);
              }}
              size="small"
            >
              <TextStyle variant="sm-regular" className="text-gray-8">
                {formatMessage(messages.originalPost)}
              </TextStyle>
              <ChevronDownIcon
                className={twMerge(
                  'h-4 w-4 text-gray-8',
                  isPostExpanded && 'rotate-180'
                )}
              />
            </IconButton>
          </div>
          <AnimatePresence initial={false}>
            <motion.div
              animate={isPostExpanded ? 'open' : 'collapsed'}
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0, overflow: 'hidden' },
              }}
              transition={{ duration: 0.2, ease: 'easeInOut' }}
              className="flex w-full flex-col"
            >
              <PostCardBody
                className="mb-0"
                showInCarousel={false}
                contentClassName="mt-0"
                boostOptions={boostOptions}
                onMemberClick={onMemberClick}
                currency={postBodyProps.currency}
                onFileClick={postBodyProps.onFileClick}
                flowResponse={postBodyProps.flowResponse}
                onPostCardBodyClick={postBodyProps.onPostCardBodyClick}
                onBlockToolbarItemClick={postBodyProps.onBlockToolbarItemClick}
                canHidePoints={canHidePoints}
              />
              {!postBodyProps.flowResponse.hideReactions && (
                <ReactionsBar {...reactionsBarProps} />
              )}
            </motion.div>
          </AnimatePresence>
        </>
      )}
      <div className="grid w-full items-center gap-2">
        <HorizontalRule>
          <TextStyle variant="sm-regular" className="text-gray-8">
            {postBodyProps.flowResponse.hideReplies
              ? formatMessage(messages.repliesTurnedOff)
              : formatMessage(messages.replyCount, {
                  count: postBodyProps.repliesCount,
                })}
          </TextStyle>
        </HorizontalRule>
      </div>
    </div>
  );
}
