import { type ButtonHTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export type InlineButtonProps = {
  clickable?: boolean;
  disabled?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const InlineButton = forwardRef<HTMLButtonElement, InlineButtonProps>(
  function InlineButton(
    {
      clickable = true,
      disabled = false,
      className,
      onClick,
      onKeyDown,
      ...rest
    },
    ref
  ) {
    return (
      <span
        ref={ref}
        className={twMerge(
          'mr-1 cursor-default break-words',
          clickable &&
            !disabled &&
            'cursor-pointer text-primary-6 hover:text-primary-5',
          disabled && 'cursor-default text-gray-6',
          className
        )}
        {...rest}
        role="button"
        {...(!disabled &&
          clickable && {
            tabIndex: 0,
            onClick,
            onKeyDown: (e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                onClick?.(e as unknown as React.MouseEvent<HTMLButtonElement>);
              }
              onKeyDown?.(
                e as unknown as React.KeyboardEvent<HTMLButtonElement>
              );
            },
          })}
      />
    );
  }
);
