import mixpanel from 'mixpanel-browser';
import type { Primitive } from 'type-fest';

import { config } from '../config';
import type { ActionProps, UserProps } from '../types/analytics';

export function getPageType() {
  const mapRouteToPageType: Record<string, string> = {
    activity: 'activity',
    flows: 'flows',
    challenges: 'challenges',
    people: 'people',
    files: 'files',
    rewards: 'rewards',
    aiReporting: 'aiReporting',
    connections: 'appConnections',
    collections: 'collections',
  };
  try {
    if (window.location.pathname.includes('flows/recognition')) {
      return 'recognition';
    }
    return (
      mapRouteToPageType[window.location.pathname.split('/')[2]] ||
      mapRouteToPageType[window.location.pathname.split('/')[3]] ||
      'home'
    );
  } catch (e) {
    return 'home';
  }
}

function debugMixpanel({
  methodName,
  eventName,
  eventProps,
}: {
  methodName: string;
  eventName?: string;
  eventProps: Record<string, Primitive>;
}) {
  if (eventName) {
    console.log(
      `%c👓 mixpanel:%c${methodName}:%c${eventName}`,
      'color: dodgerblue; font-weight: bold;',
      'color: mediumseagreen; font-weight: bold;',
      'color: tomato; font-weight: bold;',
      eventProps
    );
  } else {
    console.log(
      `%c👓 mixpanel:%c${methodName}`,
      'color: dodgerblue; font-weight: bold;',
      'color: mediumseagreen; font-weight: bold;',
      eventProps
    );
  }
}

export function registerUserForTracking(userId: string, assemblyId: string) {
  if (config.env !== 'prod') {
    debugMixpanel({
      methodName: 'registerUserForTracking',
      eventProps: { userId, assemblyId },
    });
    return;
  }

  mixpanel.identify(userId);
  mixpanel.register({ assemblyID: assemblyId });
}

export function registerUserPropsForTracking(userProps: Partial<UserProps>) {
  if (config.env !== 'prod') {
    debugMixpanel({
      methodName: 'registerUserPropsForTracking',
      eventProps: userProps,
    });
    return;
  }

  mixpanel.register(userProps);
  mixpanel.identify(userProps.$distinct_id);
}

export function trackAction(props: ActionProps) {
  if (config.env !== 'prod') {
    const { eventName, eventProps } = props;
    debugMixpanel({
      methodName: 'trackAction',
      eventName,
      eventProps,
    });
    return;
  }

  if (props.userProps) {
    registerUserPropsForTracking(props.userProps);
  }

  mixpanel.track(props.eventName, props.eventProps);
}
