import { isTruthy, type OpenEndedBlockResponse } from '@assembly-web/services';
import parse from 'html-react-parser';
import type { MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { sanitizeMessageContent } from '../../Shared/messageUtils';

export type BodyProps = {
  onMemberClick: (memberId: string) => void;
  response?: OpenEndedBlockResponse;
  handleInternalLinkClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  isSpecialPost?: boolean;
};

export function Body({
  onMemberClick,
  response,
  handleInternalLinkClick,
  isSpecialPost,
}: BodyProps) {
  const { formatMessage } = useIntl();

  return (
    <section className="px-6">
      <div
        className={twJoin(
          'flex flex-col gap-4 rounded-2xl p-4',
          isSpecialPost && 'bg-gray-2'
        )}
      >
        {isTruthy(response) &&
          parse(
            response.valueHtml ?? '',
            sanitizeMessageContent({
              formatMessage,
              onMemberClick,
              taggedUsers: response.taggedUsers,
              handleInternalLinkClick,
            })
          )}
      </div>
    </section>
  );
}
