import { SVGReactComponents } from '@assembly-web/assets';
import { isTruthy } from '@assembly-web/services';
import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { CollapsibleContent } from '../../../DesignSystem/Feedback/CollapsibleContent';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';

export type AwardBannerProps = {
  title: string;
  description?: {
    message: string;
    messageHtml: string;
    messageTokens: string;
  };
  bannerImage?: { src: string; alt: string };
};

const Description = ({
  description,
}: Required<Pick<AwardBannerProps, 'description'>>) => {
  return <CollapsibleContent content={description.message} />;
};

export const AwardBanner = ({
  title,
  description,
  bannerImage,
}: AwardBannerProps) => {
  const bannerImageDataAttributes = useMemo(() => {
    if (bannerImage?.src.includes('.gif')) {
      return {
        'data-chromatic': 'ignore',
      };
    } else {
      return {};
    }
  }, [bannerImage?.src]);

  return (
    <section className="mx-6 flex gap-4 rounded-2xl bg-upgrade-1 p-4">
      <div
        className={twMerge(
          'flex flex-col items-baseline gap-4',
          isTruthy(bannerImage) && 'w-1/2'
        )}
      >
        <div className="flex items-center gap-4">
          <div className="flex-shrink-0">
            <SVGReactComponents.AwardBadge className="h-[45px] w-[32px] drop-shadow" />
          </div>
          <TextStyle variant="base-bold">{title}</TextStyle>
        </div>
        {isTruthy(description) && <Description description={description} />}
      </div>
      {isTruthy(bannerImage) && (
        <img
          {...bannerImageDataAttributes}
          src={bannerImage.src}
          alt={bannerImage.alt}
          className="h-fit w-1/2 rounded-2xl object-cover"
        />
      )}
    </section>
  );
};
