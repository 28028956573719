import {
  AwardsModal,
  GiveAwardLoader,
  useOpenEndedFileUpload,
} from '@assembly-web/ui';
import type {
  Dispatch,
  ElementRef,
  PropsWithChildren,
  SetStateAction,
} from 'react';
import {
  createContext,
  Suspense,
  useCallback,
  useContext,
  useState,
} from 'react';
import invariant from 'tiny-invariant';

import { GiveAward } from './GiveAward/GiveAward';
import { SelectAward } from './SelectAward';
import { Title } from './Title';
import type { SelectedAwardDetail } from './types';

const AwardsModalContext = createContext<Dispatch<
  SetStateAction<boolean>
> | null>(null);
const AwardDetailContext = createContext<SelectedAwardDetail | null>(null);
const AwardFooterRefContext = createContext<ElementRef<'div'> | null>(null);
const AwardCloseModalContext = createContext<() => void>(() => {});

export const useAwardDetail = () => {
  return useContext(AwardDetailContext);
};

export const useAwardFooterRef = () => {
  return useContext(AwardFooterRefContext);
};

export const useOpenAwardsModal = () => {
  const setIsOpen = useContext(AwardsModalContext);

  invariant(
    setIsOpen,
    'useOpenAwardsModal must be used within an Awards component'
  );

  return useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
};

export const useCloseAwardsModal = () => {
  const close = useContext(AwardCloseModalContext);

  invariant(
    close,
    'useCloseAwardsModal must be used within an Awards component'
  );

  return close;
};

export function Awards({ children }: PropsWithChildren) {
  const [footerRef, setFooterRef] = useState<ElementRef<'div'> | null>(null);
  const [open, setIsOpen] = useState(false);
  const [award, setAward] = useState<SelectedAwardDetail | null>(null);
  const { fileUploadOptions } = useOpenEndedFileUpload({
    key: 'give-awards',
  });

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setAward(null);
    fileUploadOptions.clear();
  }, [fileUploadOptions]);

  return (
    <AwardsModalContext.Provider value={setIsOpen}>
      <AwardCloseModalContext.Provider value={handleClose}>
        {children}
        <AwardDetailContext.Provider value={award}>
          <AwardFooterRefContext.Provider value={footerRef}>
            <AwardsModal
              isOpen={open}
              onClose={handleClose}
              title={<Title />}
              ctaSection={<div className="w-full" ref={setFooterRef} />}
            >
              {award ? (
                <Suspense fallback={<GiveAwardLoader />}>
                  <GiveAward />
                </Suspense>
              ) : (
                <SelectAward setAward={setAward} />
              )}
            </AwardsModal>
          </AwardFooterRefContext.Provider>
        </AwardDetailContext.Provider>
      </AwardCloseModalContext.Provider>
    </AwardsModalContext.Provider>
  );
}
