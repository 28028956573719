import type { AssemblyCurrency, MemberDetails } from '@assembly-web/services';
import { forwardRef, type HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

import { Toolbar } from '../../../DesignSystem/Feedback/Toolbar/Toolbar';
import { useToolbarState } from '../../../DesignSystem/Feedback/Toolbar/useToolbarState';
import { useDeviceInfo } from '../../hooks/useDeviceInfo';
import type { PostCardToolbarProps } from '../../Shared/PostCard/PostCardUI';
import type { PostTypeTagProps } from '../PostTypeTag';
import { AwardBanner, type AwardBannerProps } from './AwardBanner';
import { Body, type BodyProps } from './Body';
import { Divide } from './Divide';
import { Header, type HeaderProps } from './Header';
import { Meta, type MetaProps } from './Meta';
import { Reactions, type ReactionsProps } from './Reactions';
import { Replies, type RepliesProps } from './Replies';

type Base = {
  containerProps?: HTMLAttributes<HTMLDivElement>;
  headerProps?: Omit<HeaderProps, 'toolbar' | 'postType'>;
  currency: AssemblyCurrency;
  metaProps: Omit<MetaProps, 'currency'>;
  bodyProps: Omit<BodyProps, 'isSpecialPost'>;
  currentMember: MemberDetails;
  repliesProps?: Omit<RepliesProps, 'currentMember'>;
  readOnly?: boolean;
  reactionProps?: Pick<ReactionsProps, 'onReactionClick' | 'reactions'>;
  toolbarProps?: PostCardToolbarProps;
  showDivide?: boolean;
};

type PostType = Base &
  (
    | {
        postType?: Exclude<PostTypeTagProps['postType'], 'award'>;
      }
    | {
        postType?: 'award';
        awardBanner: AwardBannerProps;
      }
  );

export const Post = forwardRef<HTMLDivElement, PostType>(function Post(
  {
    containerProps: { className, ...containerProps } = {},
    headerProps,
    metaProps,
    currency,
    bodyProps,
    currentMember,
    repliesProps,
    readOnly,
    reactionProps,
    toolbarProps = {},
    showDivide = true,
    ...rest
  },
  ref
) {
  const { getContainerProps, getToolbarProps } = useToolbarState({
    isAlwaysVisible: true,
    supportsTouch: true,
  });
  const isMobile = useDeviceInfo().isMobileDevice;
  const { onToolbarMenuItemClick, primaryToolbarItems, secondaryToolbarItems } =
    toolbarProps;

  return (
    <section ref={ref} {...containerProps} className="w-full">
      <div
        className={twMerge(
          'flex flex-col gap-4 rounded-2xl bg-gray-1 pb-2 shadow-feed-post @container',
          readOnly && 'pointer-events-none select-none',
          className
        )}
        {...getContainerProps()}
      >
        {headerProps != null && (
          <Header
            {...headerProps}
            postType={rest.postType}
            toolbar={
              !readOnly &&
              Boolean(secondaryToolbarItems ?? primaryToolbarItems) &&
              Boolean(onToolbarMenuItemClick) && (
                <>
                  {Boolean(!isMobile) && (
                    <Toolbar
                      className="w-fit"
                      secondaryToolbarItems={secondaryToolbarItems ?? []}
                      primaryToolbarItems={primaryToolbarItems}
                      hideWhenDetached
                      {...getToolbarProps({
                        onMenuItemClick: onToolbarMenuItemClick,
                      })}
                    />
                  )}
                  {Boolean(isMobile) && (
                    <Toolbar
                      className="w-fit"
                      secondaryToolbarItems={[
                        ...(primaryToolbarItems ?? []),
                        ...(secondaryToolbarItems ?? []),
                      ]}
                      hideWhenDetached
                      {...getToolbarProps({
                        onMenuItemClick: onToolbarMenuItemClick,
                      })}
                    />
                  )}
                </>
              )
            }
          />
        )}
        <Meta currency={currency} {...metaProps} />
        {rest.postType === 'award' && <AwardBanner {...rest.awardBanner} />}
        <Body isSpecialPost={Boolean(rest.postType)} {...bodyProps} />
        {Boolean(showDivide) && <Divide />}
        {reactionProps != null && (
          <Reactions
            currentMemberId={currentMember.memberID}
            {...reactionProps}
          />
        )}
        {repliesProps != null && (
          <Replies currentMember={currentMember} {...repliesProps} />
        )}
      </div>
    </section>
  );
});
