import type { SubmitApprovalOptions } from '@assembly-web/services';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import {
  CheckCircleIcon,
  CheckIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import type React from 'react';
import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { LoadingSpinner } from '../../../../DesignSystem/Feedback/Icons/LoadingSpinner';
import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../../../DesignSystem/Inputs/Button';
import { Checkbox } from '../../../../DesignSystem/Inputs/Checkbox';
import { V3Modal } from '../../Modal/V3Modal';
import { useConfirmationApprovalStore } from './useConfirmationApproval';

type ConfirmActionApprovalModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  isFinalApprovals?: boolean;
  type: 'approve' | 'deny';
  awardApprovalId: string;
  submitApproval: SubmitApprovalOptions;
};

const messages = defineMessages({
  approveUndoneTitle: {
    defaultMessage: 'Approve award?',
    id: 'Lj4qAp',
  },
  approveUndoneDescription: {
    defaultMessage:
      '<bold>This action cannot be undone.</bold> Once approved, the award approval will pass on to the next level of approval.',
    id: '1QLRr/',
  },
  approveFinalTitle: {
    defaultMessage: 'Approve and post award?',
    id: 'l7NElO',
  },
  approveFinalDescription: {
    defaultMessage:
      '<bold>This action cannot be undone.</bold> Upon approval, the award will be posted immediately in the feed.',
    id: 'sTUmJF',
  },
  notShowAgain: {
    defaultMessage: 'Don’t show this again',
    id: 'HvFxVs',
  },
  denyTitle: {
    defaultMessage: 'Deny award approval?',
    id: '2HrYVy',
  },
  denyDescription: {
    defaultMessage:
      '<bold>This action cannot be undone.</bold> Once the award is denied, you will be able to see it in the award’s insights modal, but it cannot be approved and posted after it has been denied.',
    id: 'Q42kRi',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  denyAward: {
    defaultMessage: 'Deny award',
    id: 'DkLBUq',
  },
  approve: {
    defaultMessage: 'Approve',
    id: 'WCaf5C',
  },
  approveAndPost: {
    defaultMessage: 'Approve & post',
    id: 'ihAPFY',
  },
  submitting: {
    defaultMessage: 'Submitting..',
    id: 'qby25U',
  },
});

export const ConfirmActionApprovalModal = ({
  isOpen,
  handleClose,
  type,
  isFinalApprovals,
  awardApprovalId,
  submitApproval,
}: ConfirmActionApprovalModalProps) => {
  const { formatMessage } = useIntl();

  const { setNotShowAgain } = useConfirmationApprovalStore();

  const [isNotShowAgain, setIsNotShowAgain] = useState<boolean>(false);

  const titleMessage = useMemo(() => {
    if (type === 'deny') {
      return messages.denyTitle;
    }
    return isFinalApprovals
      ? messages.approveFinalTitle
      : messages.approveUndoneTitle;
  }, [isFinalApprovals, type]);

  const descriptionMessage = useMemo(() => {
    if (type === 'deny') {
      return messages.denyDescription;
    }
    return isFinalApprovals
      ? messages.approveFinalDescription
      : messages.approveUndoneDescription;
  }, [isFinalApprovals, type]);

  const messageSubmit = useMemo(() => {
    if (type === 'deny') {
      return messages.denyAward;
    }
    return isFinalApprovals ? messages.approveAndPost : messages.approve;
  }, [isFinalApprovals, type]);

  const handleChangeNotShowAgain = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsNotShowAgain(e.target.checked);
    },
    []
  );

  useEffect(() => {
    if (
      submitApproval.status === 'error' ||
      submitApproval.status === 'success'
    ) {
      handleClose();
    }
  }, [submitApproval, handleClose]);

  const handleSubmit = useCallback(() => {
    setNotShowAgain(type, isNotShowAgain);
    submitApproval.mutate({
      awardApprovalId,
      action: type,
    });
  }, [setNotShowAgain, isNotShowAgain, type, submitApproval, awardApprovalId]);

  const isSubmitting = useMemo(() => {
    return submitApproval.status === 'pending';
  }, [submitApproval.status]);

  return (
    <V3Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={
        <div className="flex items-center justify-start gap-2">
          {type === 'approve' ? (
            <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full bg-upgrade-2">
              <CheckIcon className="h-6 w-6 text-success-7" />
            </div>
          ) : (
            <ExclamationCircleIcon className="h-[30px] w-[30px] rounded-full bg-upgrade-8 text-upgrade-3" />
          )}
          <TextStyle variant="2xl-bold">
            {formatMessage(titleMessage)}
          </TextStyle>
        </div>
      }
      className="min-w-[571px] rounded-2xl"
      ctaSectionClassName="gap-4"
      ctaSection={
        <>
          <Button
            className="flex-1 rounded-lg !border-gray-5 !text-gray-8"
            variation="secondaryEmphasized"
            onClick={handleClose}
          >
            {formatMessage(messages.cancel)}
          </Button>
          <Button
            onClick={handleSubmit}
            className="flex-1 rounded-lg"
            disabled={isSubmitting}
            variation={type === 'approve' ? 'primary' : 'danger'}
          >
            {isSubmitting ? (
              <>
                <LoadingSpinner />
                <TextStyle variant="sm-regular">
                  {formatMessage(messages.submitting)}
                </TextStyle>
              </>
            ) : (
              <>
                {formatMessage(messageSubmit)}
                {type === 'approve' ? (
                  <CheckCircleIcon className="size-4 text-gray-1" />
                ) : (
                  <XCircleIcon className="size-4 text-gray-1" />
                )}
              </>
            )}
          </Button>
        </>
      }
    >
      <div className="space-y-4 p-1 text-gray-9">
        <TextStyle variant="base-regular">
          {formatMessage(descriptionMessage, {
            bold: (text: ReactNode) => {
              return <strong>{text}</strong>;
            },
          })}
        </TextStyle>

        <div className="flex items-center justify-start gap-2">
          <Checkbox
            checked={isNotShowAgain}
            onChange={handleChangeNotShowAgain}
            className="h-6 w-6 cursor-pointer checked:before:!ml-[8px] checked:before:!mt-[1px] checked:before:h-4 checked:before:w-2 indeterminate:before:!ml-2.5 indeterminate:before:!mt-[6px] indeterminate:before:!border-t-[10px]"
          />
          <TextStyle variant="base-regular">
            {formatMessage(messages.notShowAgain)}
          </TextStyle>
        </div>
      </div>
    </V3Modal>
  );
};
