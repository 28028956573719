import { useMemo } from 'react';

import { getIconElement } from '../currencyUtil';
import { AssemblyCurrencyType } from '../types/currency';
import { useUserDetails } from './query/useUserDetails';

export const useDisplayCurrency = () => {
  const { data: currentUserData } = useUserDetails();

  const currency = currentUserData?.assembly.currency;

  return useMemo(() => getIconElement(currency), [currency]);
};

export const useCurrency = () => {
  const { data: currentUserData } = useUserDetails();

  return (
    currentUserData?.assembly.currency ?? {
      value: '1f3c6',
      type: AssemblyCurrencyType.Regular,
      name: 'coin',
      pluralName: 'Points',
    }
  );
};
