import {
  usePusherChannel,
  usePusherEvent,
  usePusherEvents,
} from '@assembly-web/pusher';
import { useUserDetails } from '@assembly-web/services';
import { useQueryClient } from '@tanstack/react-query';

import { usePageMatcher } from '../../../../hooks/usePageMatcher';
import { useGetAnnouncementQuery } from '../useGetAnnouncementQuery';

export function useAnnouncementUpdatesEvents() {
  const queryClient = useQueryClient();

  const { isHomePage } = usePageMatcher();

  const { refetch } = useGetAnnouncementQuery({
    enabled: isHomePage,
  });

  const { data: userDetails } = useUserDetails();

  const assembly = usePusherChannel(
    `private-assembly-${userDetails?.assembly.assemblyId}`
  );

  const channel = usePusherChannel(
    `private-member-${userDetails?.member.memberId}`
  );

  const announcementEvents = [
    'ANNOUNCEMENT_ENDED',
    'ANNOUNCEMENT_CREATED',
    'ANNOUNCEMENT_UPDATED',
  ];

  type PusherData = {
    flowId: string;
    entityId: string;
    announcementId: string;
  };

  usePusherEvents<PusherData>(assembly, announcementEvents, async (args) => {
    const { flowId, entityId } = args.data ?? {};
    const queryCache = queryClient.getQueryCache();

    if (args.eventName !== 'ANNOUNCEMENT_CREATED') {
      await queryClient.invalidateQueries({
        queryKey: ['assemblyFlowPost', flowId, entityId],
      });
    }

    queryCache.getAll().forEach((query) => {
      const queryKey = query.queryKey;
      const keysToUpdate = ['userFeed', 'searchResults'];
      const keysToInvalidate = ['entityPermission'];

      if (
        Array.isArray(queryKey) &&
        keysToInvalidate.includes(queryKey[0] as string)
      ) {
        queryClient.invalidateQueries({
          queryKey: keysToInvalidate,
        });
      }

      if (
        Array.isArray(queryKey) &&
        keysToUpdate.includes(queryKey[0] as string)
      ) {
        queryClient.refetchQueries({ queryKey });
      }
    });

    await refetch();
  });

  usePusherEvent(channel, 'ANNOUNCEMENT_SEEN', () => {
    refetch();
  });
}
