import { CollapsibleContent, QuickParticipation } from '@assembly-web/ui';
import { FormattedMessage } from 'react-intl';

import { useGetAward } from '../hooks/useGetAward';

export function DescriptionBlock() {
  const { data } = useGetAward();
  return <CollapsibleContent content={data.post.description?.message} />;
}

export function Description() {
  const { data } = useGetAward();
  return (
    data.post.description?.message && (
      <div className="flex w-1/2 flex-col gap-2">
        <QuickParticipation.Label>
          <FormattedMessage defaultMessage="Description" id="Q8Qw5B" />
        </QuickParticipation.Label>
        <DescriptionBlock />
      </div>
    )
  );
}
