import { translations as participationTranslations } from '@assembly-web/participation';
import { translations as uiTranslations } from '@assembly-web/ui';
import dayjs from 'dayjs';
import { type ReactNode, useEffect, useState } from 'react';
import { createContext, useContext } from 'react';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';

const LocaleContext = createContext<{
  locale: string;
  setLocale: (locale: string) => void;
} | null>(null);

export const LocaleProvider = ({
  children,
  locale: passedLocale,
}: {
  children: ReactNode;
  locale: string;
}) => {
  const [locale, setLocale] = useState(passedLocale);

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      {children}
    </LocaleContext.Provider>
  );
};

export const useLocale = () => {
  const context = useContext(LocaleContext);
  if (!context) {
    throw new Error('useLocale must be used within a LocaleProvider');
  }
  return context;
};

export function Intl({ children }: { children: ReactNode }) {
  const [isInitialized, setIsInitialized] = useState(false);

  const { locale } = useLocale();

  const cache = createIntlCache();
  const [messages, setMessages] = useState({});

  useEffect(() => {
    const loadTranslations = async () => {
      let localeTranslations;
      switch (locale) {
        case 'es':
          localeTranslations = await import('../translations/es.json');
          await import(`dayjs/locale/es`);
          dayjs.locale(`es`);
          break;
        case 'en-XA':
          localeTranslations = await import('../translations/en-XA.json');
          break;
        case 'en':
        default:
          localeTranslations = await import('../translations/en.json');
          await import(`dayjs/locale/en`);
          dayjs.locale(`en`);
          break;
      }

      setMessages({
        ...localeTranslations.default,
        ...uiTranslations[locale],
        ...participationTranslations[locale],
      });

      if (!isInitialized) {
        setIsInitialized(true);
      }
    };

    loadTranslations();
  }, [isInitialized, locale]);

  const intl = createIntl({ locale, messages }, cache);

  if (!isInitialized) {
    return null;
  }

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
}
