import '../base/styles.css';
import './styles.css';

import type {
  AssemblyCurrency,
  FileDetails,
  FileUploadMetaData,
  GifRatingsProps,
  Nullable,
} from '@assembly-web/services';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { ORDERED_LIST, QUOTE, UNORDERED_LIST } from '@lexical/markdown';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import {
  type InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import type { UppyFile } from '@uppy/core';
import type { LexicalEditor } from 'lexical';
import type { FocusEventHandler, PropsWithChildren, ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';
import type { ToastKind } from '../../../Shared/Toast/Toast';
import { ImageNode } from '../base/nodes/ImageNode';
import { MentionNode } from '../base/nodes/MentionNode';
import { ExternalOnChangePlugin } from '../base/plugins/ExternalOnChangePlugin';
import { FormatPlugin } from '../base/plugins/FormatPlugin';
import { FormattingFloatingMenuPlugin } from '../base/plugins/FormattingFloatingMenuPlugin';
import { ImagesPlugin } from '../base/plugins/ImagePlugin';
import { LinkFloatingToolbarPlugin } from '../base/plugins/LinkFloatingToolbarPlugin';
import {
  type MentionedUser,
  MentionsPlugin,
} from '../base/plugins/MentionsPlugin';
import { PreventAutoFocusPlugin } from '../base/plugins/PreventAutoFocusPlugin';
import { UpdateEditorPlugin } from '../base/plugins/UpdateEditorPlugin';
import { UploadedFilesPlugin } from '../base/plugins/UploadedFilesPlugin';
import { EditorTheme } from '../base/theme';
import { MATCHERS, validateUrl } from '../base/utils/regexUtils';
import { HandleEnterKeyPressPlugin } from '../RepliesEditor/plugins/HandleEnterKeyPressPlugin';
import { ListMaxIndentLevelPlugin } from '../RepliesEditor/plugins/ListMaxIndentLevelPlugin';
import { Footer } from './components/Footer';

const config = {
  nodes: [
    LinkNode,
    ListNode,
    ImageNode,
    QuoteNode,
    HeadingNode,
    AutoLinkNode,
    ListItemNode,
    MentionNode,
  ],
  theme: {
    ...EditorTheme,
    list: {
      ...EditorTheme.list,
      ol: `${EditorTheme.list.ol} quick-participation-list-ol`,
      ul: `${EditorTheme.list.ul} quick-participation-list-ul`,
    },
  },
  namespace: 'quick-participation-editor',
  onError: () => {
    // noop, will be overridden by the component props
  },
} satisfies InitialConfigType;

export type AttachmentType = {
  location: string;
  name: string;
  size: number;
};

type QuickParticipationEditorProps = {
  disabled?: boolean;
  onBlur?: FocusEventHandler;
  onChange: (data: { plainText: string; html: string; json: string }) => void;
  onError?: (error: Error, editor: LexicalEditor) => void;
  onFocus?: FocusEventHandler;
  currency: AssemblyCurrency;
  showToast?: (kind: ToastKind, message: string) => void;
  value?: string;
  error?: ReactNode;
  members: MentionedUser[];
  onNextPageScrolled: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  isMembersFetching: boolean;
  isMembersLoading: boolean;
  onSearchQueryChange: (query: Nullable<string>) => void;
  draftState?: string;
  files?: UppyFile<FileUploadMetaData, Record<string, never>>[];
  draftFiles?: FileDetails[];
  fileAttachment?: ReactNode;
  onRemoveFile?: (fileId: string) => void;
  hideTextFormattingToolbar?: boolean;
  placeholder?: string;
  hideGifSelector?: boolean;
  hideMentions?: boolean;
  gifRating?: GifRatingsProps;
  giphyAPIKey?: string;
};

const messages = defineMessages({
  quickParticipationEditorAriaLabel: {
    defaultMessage: 'Quick participation editor',
    id: 'LKkn0z',
  },
  placeholder: {
    defaultMessage: 'Write your recognition message here',
    id: 'EjFXHr',
  },
});

function Container({ children }: PropsWithChildren) {
  return <div className="flex flex-col gap-2">{children}</div>;
}

function EditorContainer({
  children,
  hasError,
}: PropsWithChildren & { hasError?: boolean }) {
  return (
    <div
      className={twMerge(
        'relative flex h-full min-h-48 flex-col gap-6 overflow-hidden rounded-2xl border border-gray-5 p-4 md:min-h-44',
        hasError && 'border-error-6'
      )}
    >
      {children}
    </div>
  );
}

export function QuickParticipationEditor({
  currency,
  disabled,
  error,
  gifRating,
  giphyAPIKey,
  onBlur,
  onChange,
  onError,
  onFocus,
  showToast,
  members = [],
  onNextPageScrolled,
  hasNextPage,
  isFetchingNextPage,
  isMembersFetching,
  isMembersLoading,
  onSearchQueryChange,
  draftState,
  files = [],
  draftFiles = [],
  fileAttachment,
  onRemoveFile = () => {},
  hideTextFormattingToolbar = false,
  placeholder,
  hideMentions = false,
  hideGifSelector = false,
}: QuickParticipationEditorProps) {
  const { formatMessage } = useIntl();

  const hasError = Boolean(error);

  return (
    <Container>
      <EditorContainer hasError={hasError}>
        <LexicalComposer
          initialConfig={{
            ...config,
            editable: !disabled,
            onError: (error, editor) => onError?.(error, editor),
          }}
        >
          <RichTextPlugin
            ErrorBoundary={LexicalErrorBoundary}
            placeholder={
              <TextStyle
                as="span"
                variant="base-regular"
                className="pointer-events-none absolute text-gray-7"
              >
                {placeholder ?? formatMessage(messages.placeholder)}
              </TextStyle>
            }
            contentEditable={
              <ContentEditable
                className="text-base font-normal text-gray-9 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0"
                disabled={disabled}
                ariaLabel={formatMessage(
                  messages.quickParticipationEditorAriaLabel
                )}
                onBlur={onBlur}
                onFocus={onFocus}
              />
            }
          />
          <MarkdownShortcutPlugin
            transformers={[
              ORDERED_LIST,
              { ...UNORDERED_LIST, regExp: /^(\s*)([-*])\s/ },
              QUOTE,
            ]}
          />
          <ImagesPlugin />
          <ListPlugin />
          <LinkPlugin validateUrl={validateUrl} />
          <AutoLinkPlugin matchers={MATCHERS} />
          <FormattingFloatingMenuPlugin isFormattingToolbarVisible={false} />
          <FormatPlugin />
          <TabIndentationPlugin />
          <ListMaxIndentLevelPlugin maxDepth={5} />
          <LinkFloatingToolbarPlugin showToast={showToast} />
          <HistoryPlugin />
          <ExternalOnChangePlugin
            onChange={onChange}
            ignoreSelectionChange={true}
            ignoreHistoryMergeTagChange={true}
          />
          <UpdateEditorPlugin draftState={draftState} />
          <HandleEnterKeyPressPlugin />
          {!hideMentions && (
            <MentionsPlugin
              boostOptions={{
                disableBoost: true,
                hideBoost: true,
                maxGivingPointsPerPost: 0,
              }}
              currency={currency}
              isAnonymousReply={false}
              isEditing={false}
              members={members}
              mentionedUsersInPost={null}
              onNextPageScrolled={onNextPageScrolled}
              onMenuOpenChange={() => {}}
              selectedReply={null}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              isMembersFetching={isMembersFetching}
              isMembersLoading={isMembersLoading}
              onSearchQueryChange={onSearchQueryChange}
            />
          )}
          {Boolean(fileAttachment) && (
            <UploadedFilesPlugin
              draftFiles={draftFiles}
              uploadedFiles={files}
              onRemoveFileClick={onRemoveFile}
            />
          )}
          <Footer
            {...{
              hideTextFormattingToolbar,
              fileAttachment,
              hideMentions,
              hideGifSelector,
              gifRating,
              giphyAPIKey,
            }}
          />
          <PreventAutoFocusPlugin />
        </LexicalComposer>
      </EditorContainer>
      {Boolean(hasError) && (
        <TextStyle variant="sm-regular" className="text-error-7">
          {error}
        </TextStyle>
      )}
    </Container>
  );
}
