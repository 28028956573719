import { MemberState } from '@assembly-web/services';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import {
  TextStyle,
  type TextVariant,
} from '../../DesignSystem/Feedback/TextStyle';
import { InlineButton } from '../../DesignSystem/Inputs/InlineButton';
import type { BoostOptions } from '../Web/Editors/RepliesEditor/RepliesEditor';
import type { ProfileCardContentProps } from '../Web/ProfileCard/ProfileCard';
import { ProfileViewer } from '../Web/ProfileViewer/ProfileViewer';

export type MemberDetailsForViewProfile = ProfileCardContentProps & {
  memberState: MemberState;
};

export type AuthorButtonProps<T> = {
  label?: string;
  className?: string;
  onClick: () => void;
  respondent: T | null;
  textVariant?: TextVariant;
  boostOptions?: BoostOptions;
  wrapName?: boolean;
};

const messages = defineMessages({
  anonymous: {
    defaultMessage: 'Anonymous',
    id: 'LXxsbk',
  },
  deactivatedUser: {
    defaultMessage: '(deactivated)',
    id: 'osOx6w',
  },
});

export function AuthorButton({
  label,
  onClick,
  className,
  respondent,
  textVariant,
  boostOptions,
  wrapName,
}: AuthorButtonProps<MemberDetailsForViewProfile>) {
  const { formatMessage } = useIntl();

  const isUserAnonymous = respondent === null || respondent.memberID === '';
  const isUserDeactivated = respondent?.memberState === MemberState.Deactivated;
  const isUserActive =
    respondent !== null &&
    respondent.memberID !== '' &&
    respondent.memberState !== MemberState.Deactivated;

  const textClassName = twMerge(
    'text-left',
    !wrapName && 'line-clamp-1',
    isUserDeactivated && 'text-gray-8',
    className
  );

  const authorName =
    respondent === null
      ? formatMessage(messages.anonymous)
      : `${respondent.firstName} ${respondent.lastName}`.trim();

  const authorElement = (
    <InlineButton
      className={textClassName}
      data-testid="author"
      clickable={isUserActive}
      disabled={isUserDeactivated}
      {...(isUserAnonymous
        ? { 'aria-label': formatMessage(messages.anonymous) }
        : {
            onClick,
          })}
    >
      <TextStyle as="span" variant={textVariant ?? 'sm-medium'}>
        {label ?? authorName}
      </TextStyle>
      {Boolean(isUserDeactivated) && (
        <TextStyle as="span" variant="sm-regular" className="ml-1 text-gray-8">
          {formatMessage(messages.deactivatedUser)}
        </TextStyle>
      )}
    </InlineButton>
  );

  if (isUserAnonymous) {
    return authorElement;
  }

  return (
    <ProfileViewer
      userDetails={respondent}
      boostOptions={boostOptions}
      onViewProfileClick={onClick}
    >
      {authorElement}
    </ProfileViewer>
  );
}
