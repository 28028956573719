import {
  CheckIcon,
  ChevronUpIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { withoutDefaultEventBehavior } from '../../../../../services/lib/eventUtils';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Chip } from '../../../DesignSystem/Inputs/Chip';
import { TextField } from '../../../DesignSystem/Inputs/TextField';

export type Option = {
  id: string;
  label: string;
  isSelected?: boolean;
};

export type SearchableDropdownWithChipsProps = {
  onChange: (key: string) => void;
  onDelete: (key: string) => void;
  options: Option[];
  isLoading?: boolean;
  className?: string;
  toggleButtonLabel: string;
  inputPlaceHolderText?: string;
  hasMultipleInputs?: boolean; //Use it for cases where you have multiple dropdown lists, all using the same data. However, in case input A does not show the selected items in input B.
  selectedOptionsCustom?: Option[]; //Use it for cases where you have multiple dropdown lists. For example, delete entire input A, then input B will show all items that were selected in input A
  isOpenCustom?: boolean; // Use it for where you have multiple dropdown lists, in case input A & B show dropdown at the same time
  handleChangeCustomOpen?: () => void; // Use it for where you have multiple dropdown lists, in case input A & B show dropdown at the same time
  disabled?: boolean;
};

export const messages = defineMessages({
  noResultsFound: {
    defaultMessage: 'No results, please try another search.',
    id: 'vrQErH',
  },
  deleteChip: {
    defaultMessage: 'remove {chipLabel}',
    id: 'uSORM2',
  },
});

export function SearchableDropdownWithChips(
  props: SearchableDropdownWithChipsProps
) {
  const {
    options,
    isLoading = false,
    className,
    toggleButtonLabel,
    inputPlaceHolderText,
    onChange,
    onDelete,
    hasMultipleInputs,
    selectedOptionsCustom,
    isOpenCustom = false,
    handleChangeCustomOpen,
    disabled = false,
  } = props;

  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [filterValue, setFilterValue] = useState('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  //The action is used to update selected options when custom options are needed, and the default is an empty array.
  useEffect(() => {
    setSelectedOptions(selectedOptionsCustom ?? []);
  }, [selectedOptionsCustom]);

  //The action is used to update open the dropdown when custom open action is needed, and the default is false.
  useEffect(() => {
    setIsOpen(isOpenCustom);
  }, [isOpenCustom]);

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(filterValue.toLowerCase())
  );

  const handleSelectionChange = (key: string) => {
    const selectedOption = options.find((option) => option.id === key);

    if (selectedOption && !selectedOption.isSelected) {
      setSelectedOptions([
        ...selectedOptions,
        { ...selectedOption, isSelected: true },
      ]);
      onChange(key);
    }
  };

  const handleSelectionDelete = (key: string) => {
    if (disabled) return;
    const selectedOption = options.find((option) => option.id === key);
    if (hasMultipleInputs) {
      setSelectedOptions(selectedOptions.filter((option) => option.id !== key));
      onDelete(key);
    }
    if (selectedOption?.isSelected) {
      setSelectedOptions(selectedOptions.filter((option) => option.id !== key));
      onDelete(key);
    }
  };

  const focusOnInput = () => {
    document.getElementById('searchableDropdownWithChips')?.focus();
  };

  const blurInput = () => {
    document.getElementById('searchableDropdownWithChips')?.blur();
  };

  const handleDropdownOpen = () => {
    if (disabled) return;
    setIsOpen(true);
    if (handleChangeCustomOpen) {
      handleChangeCustomOpen();
    }
    if (!hasMultipleInputs) {
      focusOnInput();
    }
  };

  const handleDropdownClose = () => {
    blurInput();
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    if (isOpen) {
      handleDropdownClose();
    } else {
      handleDropdownOpen();
    }
  };

  return (
    <div className="relative w-full">
      <Trigger
        selectedOptions={selectedOptions}
        inputPlaceHolderText={inputPlaceHolderText}
        filterValue={filterValue}
        handleSelectionDelete={handleSelectionDelete}
        focusOnInput={focusOnInput}
        handleDropdownOpen={handleDropdownOpen}
        handleDropdownClose={handleDropdownClose}
        toggleDropdown={toggleDropdown}
        isOpen={isOpen}
        toggleButtonLabel={toggleButtonLabel}
        setFilterValue={setFilterValue}
        setIsOpen={setIsOpen}
        className={className}
        disabled={disabled}
      />

      {Boolean(isOpen) && (
        <Content
          options={options}
          isLoading={isLoading}
          filterValue={filterValue}
          filteredOptions={filteredOptions}
          handleSelectionChange={handleSelectionChange}
          handleSelectionDelete={handleSelectionDelete}
          setFilterValue={setFilterValue}
          focusOnInput={focusOnInput}
        />
      )}
    </div>
  );
}

function LoadingCard() {
  return (
    <>
      <div className="flex h-[40px] w-full flex-col justify-center gap-2 px-4 py-2">
        <div className="h-4 w-32 animate-pulse rounded bg-gray-3" />
      </div>
      <div className="flex h-[40px] w-full flex-col justify-center gap-2 px-4 py-2">
        <div className="h-4 w-32 animate-pulse rounded bg-gray-3" />
      </div>
    </>
  );
}

function Trigger({
  selectedOptions,
  inputPlaceHolderText,
  filterValue,
  handleSelectionDelete,
  focusOnInput,
  handleDropdownOpen,
  handleDropdownClose,
  toggleDropdown,
  isOpen,
  toggleButtonLabel,
  setFilterValue,
  className,
  setIsOpen,
  disabled,
}: {
  className?: string;
  inputPlaceHolderText?: string;
  selectedOptions: Option[];
  inputPlace?: string;
  filterValue: string;
  handleSelectionDelete: (key: string) => void;
  focusOnInput: () => void;
  handleDropdownOpen: () => void;
  handleDropdownClose: () => void;
  toggleDropdown: () => void;
  isOpen: boolean;
  toggleButtonLabel: string;
  setFilterValue: (value: string) => void;
  setIsOpen: (value: boolean) => void;
  disabled?: boolean;
}) {
  const { formatMessage } = useIntl();
  return (
    <div
      className={twMerge(
        'inline-flex w-full flex-col items-start justify-start rounded-lg border border-gray-5 bg-gray-1 py-[7px]',
        isOpen && 'border-primary-6 ring-1 ring-primary-6',
        !isOpen && 'hover:border-primary-6',
        className
      )}
    >
      <div
        role="presentation"
        className={twMerge(
          'flex grow flex-wrap items-center gap-1 px-3',
          selectedOptions.length > 0 ? 'max-w-[90%]' : 'w-full'
        )}
        onClick={withoutDefaultEventBehavior(handleDropdownOpen)}
      >
        {selectedOptions.map((option) => (
          <Chip.Root
            intent="input"
            key={option.id}
            onClick={() => {
              handleSelectionDelete(option.id);
              focusOnInput();
            }}
            className="max-w-full"
            id={`chipDelete-${option.id}`}
          >
            <Chip.Text className="overflow-hidden text-ellipsis text-sm font-normal leading-snug text-gray-9">
              {option.label}
            </Chip.Text>
            <Chip.Button
              aria-label={formatMessage(messages.deleteChip, {
                chipLabel: option.label,
              })}
            >
              <XMarkIcon className="relative h-3 w-3 stroke-[2px]" />
            </Chip.Button>
          </Chip.Root>
        ))}
        <div
          className={twMerge(
            'grow',
            selectedOptions.length > 0 ? 'w-[30%]' : 'w-full'
          )}
        >
          <TextField
            shouldUseReactAriaInput={false}
            autoComplete="off"
            hideLabel
            inputClassName="text-ellipsis border-none text-sm p-0 h-6 focus:outline-none focus:ring-0"
            inputSize="md"
            invalidText=""
            isInvalid={false}
            placeholder={
              selectedOptions.length === 0 ? inputPlaceHolderText : ''
            }
            onChange={(e) => {
              setFilterValue((e.target as HTMLInputElement).value);
              setIsOpen(true);
            }}
            onClick={withoutDefaultEventBehavior(handleDropdownOpen)}
            onBlur={() => {
              setTimeout(() => {
                if (
                  !document.activeElement?.id.includes('dropdownOption') &&
                  document.activeElement?.id !==
                    'searchableDropdownWithChips' &&
                  !document.activeElement?.id.includes('chipDelete') &&
                  !document.activeElement?.id.includes(
                    'searchableDropdownWithChipsToggle'
                  ) &&
                  !document.activeElement?.id.includes(
                    'content-shareCollection'
                  )
                ) {
                  handleDropdownClose();
                }
              }, 0);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Backspace') {
                if (filterValue === '') {
                  const lastSelectedOption =
                    selectedOptions[selectedOptions.length - 1];
                  if (lastSelectedOption.id) {
                    handleSelectionDelete(lastSelectedOption.id);
                  }
                }
              }
            }}
            disabled={disabled}
            value={filterValue}
            id="searchableDropdownWithChips"
          />
        </div>
      </div>
      <AnimatePresence initial={false}>
        <button
          aria-label={toggleButtonLabel}
          onClick={withoutDefaultEventBehavior(toggleDropdown)}
          id="searchableDropdownWithChipsToggle"
          className="items-top absolute right-3 top-0 flex h-full"
          tabIndex={0}
        >
          <motion.div
            className="mt-3 flex w-4"
            animate={isOpen ? 'opened' : 'closed'}
            variants={{
              opened: { rotate: 0 },
              closed: { rotate: -180 },
            }}
            transition={{ duration: 0.3 }}
          >
            <ChevronUpIcon className="h-4 w-4 stroke-[2px] text-gray-9" />
          </motion.div>
        </button>
      </AnimatePresence>
    </div>
  );
}

function Content({
  options,
  isLoading,
  filterValue,
  filteredOptions,
  handleSelectionChange,
  handleSelectionDelete,
  setFilterValue,
  focusOnInput,
}: {
  options: Option[];
  isLoading: boolean;
  filterValue: string;
  filteredOptions: Option[];
  handleSelectionChange: (key: string) => void;
  handleSelectionDelete: (key: string) => void;
  setFilterValue: (value: string) => void;
  focusOnInput: () => void;
}) {
  const { formatMessage } = useIntl();
  return (
    <div
      // This is required since the dropdown is absolute positioned and needs to be 10px below the input
      style={{ top: `calc(100% + 10px)`, zIndex: 100 }}
      className={twMerge(
        'absolute max-h-[235px] w-full overflow-auto rounded bg-gray-1 shadow-lg-down ring-1 ring-gray-10 ring-opacity-5'
      )}
    >
      {isLoading ? (
        <LoadingCard />
      ) : (
        <div className="py-1">
          {(filterValue ? filteredOptions : options).map((item) => (
            <button
              key={item.id}
              className={twMerge(
                'flex w-full cursor-pointer px-4 py-2',
                'cursor-pointer bg-gray-1 hover:bg-gray-3 focus:bg-gray-3 focus:outline-primary-6'
              )}
              onClick={withoutDefaultEventBehavior(() => {
                if (item.isSelected) {
                  handleSelectionDelete(item.id);
                } else {
                  handleSelectionChange(item.id);
                }
                setFilterValue('');
                focusOnInput();
              })}
              id={`dropdownOption-${item.id}`}
            >
              <TextStyle
                variant={item.isSelected ? 'sm-medium' : 'sm-regular'}
                className="flex w-full shrink grow basis-0 justify-start overflow-hidden text-ellipsis text-start leading-snug text-gray-8"
              >
                {item.label}
              </TextStyle>
              {Boolean(item.isSelected) && (
                <CheckIcon className="relative h-4 w-4 text-primary-6" />
              )}
            </button>
          ))}
          {Boolean(filterValue) && filteredOptions.length <= 0 && (
            <TextStyle className="flex w-full shrink grow basis-0 justify-between px-4 py-2 text-sm font-normal leading-snug text-gray-8">
              {formatMessage(messages.noResultsFound)}
            </TextStyle>
          )}
        </div>
      )}
    </div>
  );
}
