import {
  type MemberDetails,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import { useMemo } from 'react';

export const useCurrentUserDetail = () => {
  const { data: currentUserDetails } = useSuspenseUserDetails();

  return useMemo(() => {
    return {
      memberID: currentUserDetails.member.memberId,
      name: currentUserDetails.member.profile.fullName,
      image: currentUserDetails.member.profile.image,
      firstName: currentUserDetails.member.profile.firstName,
      lastName: currentUserDetails.member.profile.lastName,
      username: currentUserDetails.member.profile.username,
      pointsGiven: currentUserDetails.member.pointsGiven,
      totalPointsGiven: currentUserDetails.member.totalPointsGiven,
      memberState: currentUserDetails.member.state,
      role: currentUserDetails.member.role,
      email: currentUserDetails.member.email,
      status: currentUserDetails.member.status,
    } as MemberDetails;
  }, [currentUserDetails]);
};
