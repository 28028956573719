import {
  queryOptions,
  useSuspenseQuery,
  type UseSuspenseQueryOptions,
} from '@tanstack/react-query';

import { APIEndpoints } from '../../APIEndpoints';
import { assemblyAPI } from '../../assemblyAPI';
import type { Member } from '../../types/domain';

export type GetMembersByIdsResponse = {
  membersDetails: Member[];
};

export function getMembersByIds({
  memberIds,
  options,
}: {
  memberIds: string[];
  options?: Partial<UseSuspenseQueryOptions<GetMembersByIdsResponse>>;
}) {
  return queryOptions({
    ...options,
    queryKey: ['getMembersByIds', memberIds],
    queryFn: async () => {
      if (memberIds.length === 0) return { membersDetails: [] };
      const response = await assemblyAPI.put(APIEndpoints.getMembersByIds, {
        memberIds,
      });
      return response.data;
    },
  });
}

export function useGetMembersByIds({ memberIds }: { memberIds: string[] }) {
  return useSuspenseQuery(getMembersByIds({ memberIds }));
}
