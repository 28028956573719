import { SVGReactComponents } from '@assembly-web/assets';
import { useParticipationModalContext } from '@assembly-web/participation';
import {
  Button,
  IconButton,
  Modal,
  TextField,
  TextStyle,
  useAssemblyNavigate,
} from '@assembly-web/ui';
import { CheckIcon } from '@heroicons/react/20/solid';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import { trackDoraAction } from '../../services/analytics';
import { convertMarkDownResponseToHTML } from '../../services/dora';
import { DoraFeedbackSection } from '../dora/DoraFeedbackSection';
import { DoraLoadingLabel } from '../dora/DoraLoadingLabel';
import { useGetRecognitionWriterResponse } from './useGetRecognitionWriterResponse';

const messages = defineMessages({
  modalTitle: {
    defaultMessage: '🎉 Give Recognition',
    id: 'bcrMdz',
  },
  instructionsTitle: {
    defaultMessage: 'Welcome to DoraAI Recognition Writer!',
    id: 'LWUsIa',
  },
  instructionsDescription: {
    defaultMessage:
      "Just tell me <b>who</b> you want to recognize and <b>why</b> in a few words, and I'll draft a thoughtful post for you.",
    id: '4Zkr8s',
  },
  instructionsDisclaimer: {
    defaultMessage:
      'Remember, this is just a draft - you can review and edit before posting.',
    id: 'WdUdOW',
  },
  defaultPromptPlaceholder: {
    defaultMessage: 'e.g. Recognize Tom for his demo',
    id: 'SADIps',
  },
  addEditsPlaceholder: {
    defaultMessage: 'Add edits here: e.g. Make it funnier, make it longer',
    id: 'jJ+pLs',
  },
  useDraftButton: {
    defaultMessage: 'Use draft',
    id: 'CQ++fE',
  },
  undoButton: {
    defaultMessage: 'Undo',
    id: 'JkS37H',
  },
  exitButton: {
    defaultMessage: 'Exit AI recognition',
    id: 'CyBhHp',
  },
});

function ModalTitle({ onClose }: { onClose: () => void }) {
  const { formatMessage } = useIntl();
  const { openModal } = useParticipationModalContext();

  return (
    <div className="mr-6 flex items-center justify-between gap-4">
      <TextStyle variant="2xl-bold">
        {formatMessage(messages.modalTitle)}
      </TextStyle>
      <Button
        variation="secondaryLite"
        size="small"
        className="hidden rounded-lg sm:flex"
        onClick={() => {
          onClose();
          openModal({ type: 'recognition' });
        }}
      >
        <ArrowUturnLeftIcon className="h-4 w-4" />{' '}
        <span>{formatMessage(messages.exitButton)}</span>
      </Button>
    </div>
  );
}

export function AiRecognitionModal() {
  const { formatMessage } = useIntl();
  const navigate = useAssemblyNavigate();

  const { openModal, aiRecognitionModalOpen, setAiRecognitionModalOpen } =
    useParticipationModalContext();

  const [prompt, setPrompt] = useState('');
  const [promptPlaceholder, setPromptPlaceholder] = useState(
    formatMessage(messages.defaultPromptPlaceholder)
  );
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(e.target.value);
  };

  const [searchParams] = useSearchParams();
  const triggerDraftOnOpenRef = useRef(false);

  const {
    getRecognitionDraft,
    recognitionDraft,
    isLoading,
    isStreaming,
    getPreviousDraft,
    currentDraftIndex,
    errorMessage,
    threadId,
    resetWriter,
  } = useGetRecognitionWriterResponse();

  const onSubmit = useCallback(() => {
    const recipientIds = searchParams.get('userId') || undefined;
    setPromptPlaceholder(formatMessage(messages.addEditsPlaceholder));
    getRecognitionDraft(prompt, recipientIds);
    setPrompt('');
    trackDoraAction('aiRecognitionPromptSent', {
      prompt,
      threadId,
      recipientIds,
    });
  }, [prompt, threadId, formatMessage, getRecognitionDraft, searchParams]);

  useEffect(() => {
    const userId = searchParams.get('userId');
    if (userId && !triggerDraftOnOpenRef.current) {
      triggerDraftOnOpenRef.current = true;
      const defaultPrompt = 'Write a nice post for this user';
      getRecognitionDraft(defaultPrompt, userId);
      trackDoraAction('aiRecognitionPromptSent', {
        prompt: defaultPrompt,
        recipientIds: userId,
      });
    }
  }, [searchParams, getRecognitionDraft]);

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSubmit();
    }
  };

  const closeModal = useCallback(() => {
    setAiRecognitionModalOpen(false);
    resetWriter();
    if (searchParams.get('useAIDraft')) {
      const updatedSearchParams = new URLSearchParams(searchParams);
      updatedSearchParams.delete('useAIDraft');
      navigate({ search: updatedSearchParams.toString() }, { replace: true });
    }
  }, [setAiRecognitionModalOpen, resetWriter, searchParams, navigate]);

  return (
    <Modal
      isOpen={aiRecognitionModalOpen}
      title={<ModalTitle onClose={closeModal} />}
      className="rounded-[8px] sm:max-w-[560px]"
      headerClassName="py-4 pl-6 pr-4"
      closeButtonSize="xLarge"
      onClose={closeModal}
    >
      <div>
        <p className="mb-5">{formatMessage(messages.instructionsTitle)}</p>
        <TextStyle className="mb-5">
          {formatMessage(messages.instructionsDescription, {
            b: (str) => <strong>{str}</strong>,
          })}
        </TextStyle>
        <TextStyle className="mb-5">
          {formatMessage(messages.instructionsDisclaimer)}
        </TextStyle>
        <div className="rounded-lg bg-primary-1 p-4 align-bottom">
          <div className="flex gap-2">
            <TextField
              value={prompt}
              placeholder={promptPlaceholder}
              onChange={handleChange}
              onKeyDown={handleOnKeyDown}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />

            <IconButton variation="secondaryEmphasized" onClick={onSubmit}>
              <SVGReactComponents.SendIcon className="h-6 w-6" />
            </IconButton>
          </div>
          {Boolean(isLoading || isStreaming || recognitionDraft?.content) && (
            <hr className="mb-4 mt-6 border-gray-5" />
          )}

          {Boolean(isLoading) && <DoraLoadingLabel label="Writing" />}

          <TextStyle
            as="span"
            html={
              convertMarkDownResponseToHTML(recognitionDraft?.content ?? '', {
                showCursor: isStreaming,
              }) as string
            }
          />
          {Boolean(recognitionDraft?.content) && Boolean(!isStreaming) && (
            <div className="mt-4">
              <DoraFeedbackSection
                feedbackSource="aiRecognition"
                markdownResponse={recognitionDraft?.content ?? null}
                promptId={recognitionDraft?.promptId ?? null}
                displayCopyButton={false}
              />
            </div>
          )}
          {Boolean(errorMessage) && (
            <p className="mt-4 text-error-6">{errorMessage}</p>
          )}

          {!isStreaming && Boolean(recognitionDraft) && (
            <div className="flex gap-2 pt-8">
              <Button
                size="small"
                variation="primary"
                onClick={() => {
                  trackDoraAction('useDraftClicked', {
                    promptId: recognitionDraft?.promptId,
                    threadId,
                  });
                  resetWriter();
                  setAiRecognitionModalOpen(false);
                  openModal({
                    recipientIds: recognitionDraft?.users,
                    type: 'recognition',
                    postMessage: recognitionDraft?.content,
                    coreValue: recognitionDraft?.coreValue,
                    threadId,
                  });
                }}
              >
                <CheckIcon className="h-4 w-4" />
                {formatMessage(messages.useDraftButton)}
              </Button>
              {currentDraftIndex > 0 && (
                <Button
                  size="small"
                  variation="secondaryLite"
                  onClick={() => {
                    trackDoraAction('undoDraftClicked', {
                      promptId: recognitionDraft?.promptId,
                      threadId,
                    });
                    getPreviousDraft();
                  }}
                >
                  <ArrowUturnLeftIcon className="h-4 w-4" />
                  {formatMessage(messages.undoButton)}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
