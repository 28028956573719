import type { SelectableOptionProps } from '@assembly-web/ui';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { create } from 'zustand';

import {
  trackChallengeAction,
  trackDiscoverAction,
} from '../../services/analytics';
import { useInvalidateFeed } from '../useInvalidateFeed';

const initialSearchValues = {
  flowsSearchValue: '',
  assigneeSearchValue: '',
  postedBySearchValue: '',
  reportsToSearchValue: '',
  collectionsSearchValue: '',
  mentionedBySearchValue: '',
};

type SecondaryFilterStore = {
  flowsSearchValue: string;
  postedBySearchValue: string;
  assigneeSearchValue: string;
  reportsToSearchValue: string;
  collectionsSearchValue: string;
  mentionedBySearchValue: string;
  getSearchValues: () => Record<string, string>;
  setSearchValue: (key: string, value: string) => void;
  setSearchValues: (values: Record<string, string>) => void;
};

type FileSecondaryFilter = {
  flows: string;
  collections: string;
  apps: string;
  fileType: string;
};

type FlowSecondaryFilter = {
  flowStatus: string;
};

type PersonSecondaryFilter = {
  department: string;
  homeLocation: string;
  workLocation: string;
  jobTitle: string;
  reportsTo: string;
};

type ChallengeSecondaryFilter = {
  challengeStatus: string;
};

type TaskSecondaryFilter = {
  assignee: string;
  taskStatus: string;
};

type FilterToMap = {
  in: string;
  from: string;
  mention: string;
  contentType: string;
} & ChallengeSecondaryFilter &
  PersonSecondaryFilter &
  FlowSecondaryFilter &
  FileSecondaryFilter &
  TaskSecondaryFilter;

export type KeyToMap = Partial<FilterToMap>;

export type FilterKey = keyof FilterToMap;

const useSecondaryFilterStore = create<SecondaryFilterStore>((set, get) => ({
  flowsSearchValue: '',
  postedBySearchValue: '',
  assigneeSearchValue: '',
  reportsToSearchValue: '',
  collectionsSearchValue: '',
  mentionedBySearchValue: '',
  getSearchValues: () => {
    return {
      flowsSearchValue: get().flowsSearchValue,
      assigneeSearchValue: get().assigneeSearchValue,
      postedBySearchValue: get().postedBySearchValue,
      reportsToSearchValue: get().reportsToSearchValue,
      collectionsSearchValue: get().collectionsSearchValue,
      mentionedBySearchValue: get().mentionedBySearchValue,
    };
  },
  setSearchValues: (values) => set(values),
  setSearchValue: (key: string, value: string) =>
    set((state) => ({ ...state, [key]: value })),
}));

// TODO: move this to secondaryFiltersStore
export function useSecondaryFilterState() {
  const [params, setSearchParams] = useSearchParams();

  const { setSearchValues, getSearchValues } = useSecondaryFilterStore();

  const { invalidateFeed } = useInvalidateFeed();

  const handleClearClick = (filtersToReset: string[]) => {
    invalidateFeed();
    const updatedParams = new URLSearchParams(params);
    filtersToReset.forEach((filter) => {
      const value = updatedParams.get(filter);
      if (updatedParams.has(filter)) {
        trackDiscoverAction('secondaryFilterCleared', {
          secondaryFilterType: filter,
        });
        if (filter === 'challengeStatusType') {
          trackChallengeAction('secondaryFilterCleared', {
            secondaryFilterType: 'challengeStatus',
            selectedValues: value,
          });
        }
        updatedParams.delete(filter);
      }
    });
    setSearchParams(updatedParams);
  };

  const resetSearchFilters = useCallback(
    () => setSearchValues(initialSearchValues),
    [setSearchValues]
  );

  const handleDoneClick = useCallback(
    ({
      key,
      currentSelectedOptions,
      keyParamMap,
    }: {
      key: FilterKey;
      currentSelectedOptions: SelectableOptionProps[];
      keyParamMap: KeyToMap;
    }) => {
      const selectedOptionsIds = currentSelectedOptions
        .map((option) => option.id)
        .join(',');

      const filter = keyParamMap[key];

      resetSearchFilters();

      if (filter) {
        if (selectedOptionsIds.length) {
          const trackEventProps = {
            secondaryFilterType: filter,
            selectedValues: currentSelectedOptions.map(
              (option) => option.value as string
            ),
          };
          trackDiscoverAction('secondaryFilterApplied', trackEventProps);
          if (filter === 'challengeStatusType') {
            trackChallengeAction('secondaryFilterApplied', {
              secondaryFilterType: 'challengeStatus',
              selectedValues: selectedOptionsIds,
            });
          }
          setSearchParams({
            ...Object.fromEntries(params),
            [filter]: selectedOptionsIds,
          });
        } else {
          const updatedParams = new URLSearchParams(params);
          if (updatedParams.has(filter)) {
            updatedParams.delete(filter);
          }
          setSearchParams(updatedParams);
        }
      }
    },
    [params, resetSearchFilters, setSearchParams]
  );

  const handleSearchChange = useCallback(
    ({
      key,
      value,
      keyParamMap,
    }: {
      key: FilterKey;
      value: string;
      keyParamMap: KeyToMap;
    }) => {
      if (keyParamMap[key]) {
        const keyToUpdate = keyParamMap[
          key
        ] as keyof typeof initialSearchValues;
        setSearchValues({
          ...getSearchValues(),
          [keyToUpdate]: value,
        });
      }
    },
    [getSearchValues, setSearchValues]
  );

  return {
    handleDoneClick,
    handleClearClick,
    handleSearchChange,
    resetSearchFilters,
    searchValues: getSearchValues(),
  };
}
