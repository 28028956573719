import {
  type TrackEventProps,
  trackParticipationAction,
} from '@assembly-web/services';
import { useCallback, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

function TrackMessageStart({ trackEvent }: { trackEvent: () => void }) {
  const { watch, getFieldState } = useFormContext();
  const eventSentRef = useRef(false);

  useEffect(() => {
    const { unsubscribe } = watch((value) => {
      if (eventSentRef.current) {
        return;
      }
      if (
        value.message?.plainText?.length > 0 ||
        value.attachments?.length > 0
      ) {
        eventSentRef.current = true;
        trackEvent();
      }
    });
    return () => {
      unsubscribe();
    };
  }, [watch, getFieldState, trackEvent]);

  return null;
}

export function TrackMessage(props: TrackEventProps) {
  const trackEvent = useCallback(() => {
    trackParticipationAction('messageStarted', props);
  }, [props]);

  return <TrackMessageStart trackEvent={trackEvent} />;
}
