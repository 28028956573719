import { Slot, type SlotProps } from '@radix-ui/react-slot';
import { cva, cx, type VariantProps } from 'class-variance-authority';
import {
  createContext,
  forwardRef,
  type HTMLAttributes,
  useContext,
} from 'react';
import { twMerge } from 'tailwind-merge';

const IntentContext = createContext<ChipStyles['intent']>(null);

const styles = cva('inline-flex w-fit items-center', {
  variants: {
    intent: {
      input:
        'h-6 max-w-full cursor-default justify-start gap-1 rounded-md border-[0.5px] p-1 text-sm font-normal leading-snug [&_>_:first-child[data-type=text]]:pl-1 [&_>_:only-child[data-type=text]]:px-1 [&_>_:last-child[data-type=text]]:pr-2',
      badge:
        'gap-2 rounded-3xl p-1 text-sm text-gray-9 [&_>_:only-child[data-type=text]]:px-2 [&_>_:first-child[data-type=text]]:pl-1 [&_>_:last-child[data-type=text]]:pr-1',
      filter:
        'h-8 text-gray-9 gap-2.5 rounded-lg border border-gray-5 px-3 py-1 text-center text-base font-medium leading-normal hover:cursor-pointer hover:bg-gray-4 active:bg-gray-10 active:text-gray-1',
      awardGold:
        'gap-2 rounded-3xl p-1 text-sm text-gray-9 [&_>_:only-child[data-type=text]]:px-2 [&_>_:first-child[data-type=text]]:pl-1 [&_>_:last-child[data-type=text]]:pr-1 border border-upgrade-6 px-2 max-h-8',
      awardBlue:
        'gap-2 rounded-3xl p-1 text-sm text-gray-9 [&_>_:only-child[data-type=text]]:px-2 [&_>_:first-child[data-type=text]]:pl-1 [&_>_:last-child[data-type=text]]:pr-1 border border-primary-3 max-h-8',
      awardGreen:
        'gap-2 rounded-3xl p-1 text-sm text-gray-9 [&_>_:only-child[data-type=text]]:px-2 [&_>_:first-child[data-type=text]]:pl-1 [&_>_:last-child[data-type=text]]:pr-1 border border-success-5 px-2 max-h-8',
    },
    color: {
      blue: 'bg-primary-1',
      purple: 'bg-purple-1',
      gold: 'bg-upgrade-1',
      green: 'bg-success-1',
    },
    isDisabled: {
      true: 'hover:cursor-not-allowed',
      false: '',
    },
  },
  defaultVariants: {
    intent: 'input',
  },
  compoundVariants: [
    {
      intent: 'input',
      isDisabled: true,
      className: 'border-gray-5 bg-gray-4 text-gray-8',
    },
    {
      intent: 'input',
      isDisabled: false,
      className: 'border-gray-7 bg-gray-3 text-gray-9 hover:bg-gray-4',
    },
    {
      intent: 'badge',
      isDisabled: true,
      className: 'cursor-not-allowed !bg-gray-3 text-gray-8',
    },
  ],
});

const buttonStyles = cva('', {
  variants: {
    intent: {
      input: 'rounded-br-md rounded-tr-md border-gray-7 py-[6px] pl-1 pr-1',
      badge: 'h-6 w-6 flex-shrink-0 text-gray-8',
      filter: '',
      awardGold: 'h-6 w-6 flex-shrink-0 text-gray-9',
      awardBlue: 'h-6 w-6 flex-shrink-0 text-gray-9',
      awardGreen: 'h-6 w-6 flex-shrink-0 text-gray-9',
    },
  },
});

export type ChipStyles = VariantProps<typeof styles>;
type ChipProps =
  | (ChipStyles & {
      intent: Exclude<ChipStyles['intent'], 'badge'>;
    })
  | ({ intent: Extract<ChipStyles['intent'], 'badge'> } & Required<
      Pick<ChipStyles, 'color'>
    > &
      Pick<ChipStyles, 'isDisabled'>);

const ChipComp = forwardRef<
  HTMLElement,
  ChipProps & SlotProps & { asChild?: boolean; isDisabled?: boolean }
>(function Chip(
  { intent, color, className, asChild, children, isDisabled = false, ...rest },
  ref
) {
  const Comp = asChild ? Slot : 'span';

  return (
    <IntentContext.Provider value={intent}>
      <Comp
        {...rest}
        ref={ref}
        className={cx(
          styles({
            className,
            color,
            intent,
            isDisabled,
          })
        )}
      >
        {children}
      </Comp>
    </IntentContext.Provider>
  );
});

const Button = forwardRef<HTMLButtonElement, HTMLAttributes<HTMLButtonElement>>(
  function Button({ children, className, ...rest }, ref) {
    const intent = useContext(IntentContext);
    const styles = buttonStyles({ intent, className });

    return (
      <button {...rest} ref={ref} className={styles}>
        {children}
      </button>
    );
  }
);

function Text({
  className,
  asChild,
  ...rest
}: HTMLAttributes<HTMLSpanElement> & { asChild?: boolean }) {
  const Comp = asChild ? Slot : 'span';

  return (
    <Comp
      {...rest}
      data-type="text"
      className={twMerge(
        'inline-block flex-shrink truncate text-gray-9',
        className
      )}
    />
  );
}

export const Chip = {
  Button,
  Root: ChipComp,
  Text,
};
