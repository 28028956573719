import {
  mapHexCodeToEmoticon,
  SplitNames,
  useFeatureSplit,
} from '@assembly-web/services';
import { Button, TextStyle, useDeviceInfo } from '@assembly-web/ui';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';

import { useGiveRecognitionFlowDetails } from '../../hooks/useGiveRecognitionFlowDetails';
import { useParticipationModalContext } from '../../Provider';

export function Title() {
  const {
    data: {
      flowDetails: { name, icon },
      recognizeWithAiButton,
      recognizeWithAiTooltip,
    },
  } = useGiveRecognitionFlowDetails();
  const { isMobileDevice } = useDeviceInfo();

  const { closeModal, setAiRecognitionModalOpen } =
    useParticipationModalContext();

  const { isTreatmentActive: aiRecognitionEnabled } = useFeatureSplit(
    SplitNames.DoraAiRecognitionWriter
  );

  return (
    <div className="flex justify-between">
      <TextStyle as="span" variant={isMobileDevice ? 'xl-bold' : '2xl-bold'}>
        <FormattedMessage
          defaultMessage="{icon} {name}"
          id="4OjfWu"
          values={{ icon: mapHexCodeToEmoticon(icon.value), name }}
        />
      </TextStyle>
      {Boolean(aiRecognitionEnabled) && (
        <Button
          variation="secondaryLite"
          className="mr-4 rounded-lg border-0 !bg-dora-gradient-2 text-purple-8 hover:opacity-80"
          tooltipText={recognizeWithAiTooltip}
          onClick={() => {
            closeModal();
            setAiRecognitionModalOpen(true);
          }}
        >
          <SparklesIcon className="h-4 w-4" />{' '}
          <span className="hidden sm:inline">{recognizeWithAiButton}</span>
        </Button>
      )}
    </div>
  );
}
