import type {
  MemberDetails,
  ReactionDetails,
  RepliesResponse,
} from '@assembly-web/services';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { Avatar, AvatarSize } from '../../../DesignSystem/Feedback/Avatar';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { ReactionsBar } from '../../Web/PostAndReplies/ReactionsBar';
import { AvatarGroup } from '../AvatarGroup';
import { PostCardFooterHiddenState } from './PostCardFooterHiddenState';

const messages = defineMessages({
  draftLabel: {
    defaultMessage: 'Draft',
    id: 'W6nwjo',
  },
  viewReplies: {
    defaultMessage: 'View replies',
    id: 'hvCo0f',
  },
  lastRepliedAt: {
    defaultMessage: 'Last reply {timeAgo}',
    id: 'nZtCXw',
  },
  repliesTurnedOff: {
    defaultMessage: 'Replies have been turned off',
    id: 'h9h1y6',
  },
  repliesAndReactionsTurnedOff: {
    defaultMessage: 'Replies and reactions have been turned off',
    id: '7BL80V',
  },
  reactLabel: {
    defaultMessage: 'React',
    id: 'uX6eKe',
  },
  membersReactedWithEmoji: {
    defaultMessage: '{memberNames} reacted with emoji',
    id: '7r4xs8',
  },
  repliesTrigger: {
    defaultMessage:
      '{commentsCount, plural, =0 {Reply to this post} =1 {1 reply} other {# replies}}',
    id: 'O/Y2UF',
  },
});

export function PostCardFooter({
  showDraft,
  reactions,
  hideReplies,
  repliesData,
  hideReactions,
  currentMember,
  onReactionClick,
  onReplyButtonClick,
  isReplyPreviewCard,
}: {
  showDraft: boolean;
  hideReplies: boolean;
  hideReactions: boolean;
  reactions: ReactionDetails[];
  currentMember: MemberDetails;
  repliesData: RepliesResponse;
  onReplyButtonClick: () => void;
  isReplyPreviewCard?: boolean;
  onReactionClick: (reaction: ReactionDetails) => void;
}) {
  const {
    count: commentsCount,
    initialRespondents,
    respondentsCount,
    lastRepliedAt = '',
  } = repliesData;

  const { formatMessage } = useIntl();

  const { memberID: currentMemberId, image, name } = currentMember;

  if (hideReactions && hideReplies) {
    return (
      <PostCardFooterHiddenState
        name={name}
        image={image}
        currentMemberId={currentMemberId}
        text={formatMessage(messages.repliesAndReactionsTurnedOff)}
      />
    );
  }

  return (
    <section
      className={twJoin(
        !isReplyPreviewCard && 'pt-2',
        isReplyPreviewCard && !hideReactions && 'pb-4'
      )}
    >
      {!hideReactions && (
        <ReactionsBar
          currentMemberId={currentMemberId}
          onReactionClick={onReactionClick}
          reactions={reactions}
          variant="default"
        />
      )}
      {!isReplyPreviewCard && (
        <>
          {hideReplies ? (
            <PostCardFooterHiddenState
              name={name}
              image={image}
              currentMemberId={currentMemberId}
              text={formatMessage(messages.repliesTurnedOff)}
            />
          ) : (
            <button
              onClick={onReplyButtonClick}
              className="group mt-2 flex w-full items-center gap-2 rounded border border-transparent p-1 hover:border-primary-6 hover:bg-primary-1"
            >
              <div className="flex">
                {respondentsCount > 0 ? (
                  <AvatarGroup
                    avatars={initialRespondents}
                    size={AvatarSize.Medium}
                    limit={5}
                    totalCount={respondentsCount}
                  />
                ) : (
                  <Avatar
                    size={AvatarSize.Medium}
                    image={image}
                    name={name}
                    memberID={currentMemberId}
                    tooltipText={name}
                  />
                )}
              </div>

              <div className="flex w-full flex-1 items-center justify-between">
                <span className="flex gap-2 text-primary-6">
                  {formatMessage(messages.repliesTrigger, { commentsCount })}
                  {commentsCount > 0 && lastRepliedAt !== '' && (
                    <>
                      <span className="w-84 hidden text-left text-primary-6 @sm:group-hover:inline">
                        {formatMessage(messages.viewReplies)}
                      </span>
                      <span className="w-84 hidden text-left text-gray-8 group-hover:hidden @sm:inline">
                        {formatMessage(messages.lastRepliedAt, {
                          timeAgo: dayjs(lastRepliedAt).fromNow(),
                        })}
                      </span>
                    </>
                  )}
                  {Boolean(showDraft) && (
                    <TextStyle
                      variant="sm-regular"
                      className="hidden rounded-md bg-gray-3 px-2 pt-[1px] text-gray-9 group-hover:bg-primary-2 group-hover:text-primary-6 @sm:block"
                    >
                      {formatMessage(messages.draftLabel)}
                    </TextStyle>
                  )}
                </span>
                <div className="flex">
                  <ChevronRightIcon className="hidden h-4 text-primary-6 group-hover:inline" />
                </div>
              </div>
            </button>
          )}
        </>
      )}
    </section>
  );
}
