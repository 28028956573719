import { Heading } from '@assembly-web/ui';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import type { ReactElement, ReactNode } from 'react';
import { Children } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { TextButton } from './TextButton';

const headerBarText = defineMessages({
  clear: {
    defaultMessage: 'Clear',
    id: '/GCoTA',
  },
});

type HeaderBarSlot = {
  rightContent: ReactNode;
};

type HeaderBarProps = {
  logoSrc: string;
  companyName: string;
  showSearchBar?: boolean;
  showCompanyName?: boolean;
  searchPlaceholder?: string;
  children: ReactElement;
};

function RightContent(props: { children: ReactElement }) {
  return <>{props.children}</>;
}

function HeaderBar(props: HeaderBarProps) {
  const { formatMessage } = useIntl();
  const {
    logoSrc,
    companyName,
    children,
    showSearchBar = false,
    showCompanyName = false,
    searchPlaceholder,
  } = props;

  const slots: HeaderBarSlot = {
    rightContent: null,
  };

  Children.forEach(children, (child) => {
    switch (child.type) {
      case HeaderBar.RightContent: {
        slots.rightContent = child;
        break;
      }
    }
  });

  return (
    <header className="sticky top-[0] z-[2] flex h-[72px] items-center border-b-[1px] border-b-gray-4 bg-gray-1 px-6">
      <img
        src={logoSrc}
        className="h-12"
        alt="company logo"
        aria-label={companyName}
      />
      {Boolean(showCompanyName) && (
        <div className="ml-3">
          <Heading as="h6">{companyName}</Heading>
        </div>
      )}
      {Boolean(showSearchBar) && (
        <div className="relative h-12 w-full">
          <MagnifyingGlassIcon className="text-gray8 pointer-events-none absolute left-4 top-4 h-4 w-4" />
          <input
            id="search text input"
            className="ml-1 h-full w-full rounded-lg border border-solid border-gray-5 pl-9 text-base text-gray-9 placeholder-gray-8"
            placeholder={searchPlaceholder}
          />

          <TextButton
            className="absolute right-[12px] top-[12px] text-base text-gray-9"
            underline
          >
            {formatMessage(headerBarText.clear)}
          </TextButton>
        </div>
      )}
      {slots.rightContent}
    </header>
  );
}

HeaderBar.RightContent = RightContent;

export { HeaderBar };
