import type {
  InfiniteData,
  MutationOptions,
  QueryClient,
} from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';

import type { SearchIndexApiResponse } from '../..';
import { APIEndpoints } from '../../APIEndpoints';
import { assemblyAPI } from '../../assemblyAPI';
import type { MemberAPIResponse } from '../../types/domain';
import type {
  GroupOrUserChipData,
  SaveRecognitionAPIPayload,
} from '../../types/recognitionParticipation';
import type { PostSearchResult } from '../../types/searchIndex';
import type { UserFeedApiResponse } from '../../types/userFeed';
import { getPostCardData } from '../../utils/recognition';
import { useSuspenseUserDetails } from '../query/useUserDetails';

export type CreateRecognitionPayload = {
  toServer: {
    payload: SaveRecognitionAPIPayload;
  };
  toCache: {
    recipient: GroupOrUserChipData[];
  };
};

const updateUserFeedWithNewPost = ({
  queryClient,
  request,
  currentUser,
}: {
  queryClient: QueryClient;
  request: CreateRecognitionPayload;
  currentUser: MemberAPIResponse;
}) => {
  const queryCache = queryClient.getQueryCache();
  const userFeedKeys = queryCache
    .findAll({
      queryKey: ['userFeed'],
    })
    .map((query) => query.queryKey);
  userFeedKeys.forEach((key) => {
    const userFeedData: InfiniteData<UserFeedApiResponse> | undefined =
      queryClient.getQueryData(key);
    if (userFeedData) {
      const newPost: PostSearchResult = getPostCardData({
        request,
        currentUser,
      });
      const updatedData = produce(userFeedData, (draft) => {
        draft.pages[0].data.unshift(newPost);
      });
      queryClient.setQueryData(key, updatedData);
    }
  });
};

const updateSearchFeedWithNewPost = ({
  queryClient,
  request,
  currentUser,
}: {
  queryClient: QueryClient;
  request: CreateRecognitionPayload;
  currentUser: MemberAPIResponse;
}) => {
  const queryCache = queryClient.getQueryCache();
  const searchFeedKeys = queryCache
    .findAll({
      queryKey: ['searchResults'],
    })
    .map((query) => query.queryKey);
  searchFeedKeys.forEach((key) => {
    const searchFeedData: InfiniteData<SearchIndexApiResponse> | undefined =
      queryClient.getQueryData(key);
    if (searchFeedData) {
      const newPost: PostSearchResult = getPostCardData({
        request,
        currentUser,
      });
      const updatedData = produce(searchFeedData, (draft) => {
        draft.pages[0].data.data.unshift(newPost);
      });
      queryClient.setQueryData(key, updatedData);
    }
  });
};

export function useSaveRecognitionPost({
  options,
}: {
  options?: MutationOptions<unknown, unknown, CreateRecognitionPayload>;
}) {
  const queryClient = useQueryClient();
  const { data: currentUser } = useSuspenseUserDetails();
  return useMutation({
    mutationFn: async (request: CreateRecognitionPayload) => {
      const response = await assemblyAPI.post(
        APIEndpoints.saveRecognitionPost,
        request.toServer.payload
      );
      return response.data;
    },
    onMutate: (request: CreateRecognitionPayload) => {
      updateUserFeedWithNewPost({ queryClient, request, currentUser });
      updateSearchFeedWithNewPost({ queryClient, request, currentUser });
    },
    onSuccess: (data, variables) => {
      options?.onSuccess?.(data, variables, {});
    },
    onError: (error, variables) => {
      options?.onError?.(error, variables, {});
    },
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: ['userFeed'],
      });
      queryClient.invalidateQueries({
        queryKey: ['searchResults'],
      });
      options?.onSettled?.(...args);
    },
  });
}
