import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

import {
  type FileDetails,
  getMemberDetailsFromUserDetails,
  type MemberAPIResponse,
} from '@assembly-web/services';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { TooltipEnabledContext } from '../../../../../context/TooltipEnabledContext';
import { TextStyle } from '../../../../../DesignSystem/Feedback/TextStyle';
import { TextField } from '../../../../../DesignSystem/Inputs/TextField';
import { FilePreview } from '../../../../Shared/FileUpload/FilePreview';
import { ImageEditorModal } from '../../../../Shared/FileUpload/Modals/ImageEditorModal';
import type { useFileUpload } from '../../../../Shared/FileUpload/useFileUpload';
import { ChallengeCard } from '../../../Challenges/Card/ChallengeCard';
import { PostInteractionSettingItem } from '../../AnnouncementCenterModal/PostInteractionSettingItem';
import { dummyReactions, dummyReplies } from '../data';
import { messages } from '../message';

export function CustomizeAndStyling({
  userDetails,
  disabled = false,
  tooltipText,
  fileUploadOptions,
  image,
}: {
  image?: FileDetails;
  userDetails: MemberAPIResponse;
  disabled?: boolean;
  tooltipText?: string;
  fileUploadOptions: ReturnType<typeof useFileUpload>;
}) {
  const { formatMessage } = useIntl();

  const {
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useFormContext();
  const claimButtonError = errors.claimButtonText?.message as string;
  const inputClassName = 'mt-1 placeholder:text-gray-8 text-sm';
  const labelClassName = 'text-sm font-medium';

  const {
    title,
    description,
    points,
    individualClaimLimit,
    totalClaimLimit,
    imageUrl,
  } = getValues();
  const { claimButtonText, interactionSettings } = watch();

  const {
    files,
    removeFile,
    DragAndDrop,
    showImageEditor,
    setShowImageEditor,
  } = fileUploadOptions;

  return (
    <div className="flex flex-col gap-2">
      <ImageEditorModal
        open={showImageEditor}
        domain="create-challenge"
        onCloseClick={() => {
          setShowImageEditor(false);

          setValue('image', undefined);
          setValue('imageUrl', undefined);
        }}
        onUploadClick={async () => {
          setShowImageEditor(false);
        }}
      />
      <label className="text-sm font-medium">
        {formatMessage(messages.imageUpload)}
      </label>
      {files.length === 0 && image !== undefined && Boolean(imageUrl) && (
        <div className="flex flex-col gap-4 px-1 py-2">
          <FilePreview
            file={image}
            key={image.name}
            variant="challenge-preview"
            hideRemoveButton={disabled}
            onRemoveFileClick={() => {
              setValue('image', undefined);
              setValue('imageUrl', undefined);
            }}
          />
        </div>
      )}
      {files.length > 0 && (
        <div className="flex flex-col gap-4 px-1 py-2">
          <FilePreview
            key={files[0].id}
            uppyFile={files[0]}
            variant="challenge-preview"
            onRemoveFileClick={(fileId: string) => {
              removeFile(fileId);

              setValue('image', undefined);
              setValue('imageUrl', undefined);
            }}
          />
        </div>
      )}
      <DragAndDrop
        disabled={disabled}
        title={formatMessage(messages.clickToUploadTitle)}
        subTitle={formatMessage(messages.challengeAcceptedFileTypes)}
      />
      <div>
        <TextField
          {...register('claimButtonText')}
          type="text"
          placeholder={formatMessage(messages.buttonPlaceholder)}
          invalidText={claimButtonError}
          label={formatMessage(messages.customizeClaimButton)}
          isInvalid={Boolean(claimButtonError)}
          inputClassName={inputClassName}
          labelClassName={labelClassName}
          aria-label={'claimButtonText'}
          helpText={formatMessage(messages.buttonLimitHintLabel)}
          disabled={disabled}
          tooltipText={tooltipText}
        />
      </div>
      <section>
        <TextStyle as="span" variant="sm-medium">
          {formatMessage(messages.interactionSettings)}
        </TextStyle>
        <TextStyle variant="xs-regular" className="text-gray-9">
          {formatMessage(messages.postInteractionSettingsDescriptionLabel)}
        </TextStyle>

        <Controller
          name="interactionSettings.hideReplies"
          control={control}
          render={({ field: { onChange, value } }) => (
            <PostInteractionSettingItem
              className="mt-2"
              disabled={disabled}
              tooltipText={tooltipText}
              isChecked={!value}
              onCheckedChange={(val) => onChange(!val)}
              label={formatMessage(messages.allowRepliesLabel)}
            />
          )}
        />

        <Controller
          name="interactionSettings.hideReactions"
          control={control}
          render={({ field: { onChange, value } }) => (
            <PostInteractionSettingItem
              className="my-2"
              disabled={disabled}
              tooltipText={tooltipText}
              isChecked={!value}
              onCheckedChange={(val) => onChange(!val)}
              label={formatMessage(messages.allowReactionsLabel)}
            />
          )}
        />
      </section>
      <TextStyle as="span" variant="sm-medium">
        {formatMessage(messages.cardPreviewTitle)}
      </TextStyle>
      <TooltipEnabledContext.Provider value={false}>
        <ChallengeCard
          onUnarchiveChallenge={() => {}}
          variant="preview"
          title={title}
          imageUrl={imageUrl}
          description={description?.messageHtml ?? ''}
          points={points}
          state={'ACTIVE'}
          type={'STANDALONE'}
          createdBy={getMemberDetailsFromUserDetails(userDetails.member)}
          claimButtonText={claimButtonText}
          reactions={dummyReactions}
          isUserAdmin={false}
          hideReactions={interactionSettings?.hideReactions}
          hideReplies={interactionSettings?.hideReplies}
          launchedAt={new Date().toDateString()}
          claimsRemaining={totalClaimLimit > 0 ? totalClaimLimit : ''}
          canEdit={false}
          selfClaimDetails={{
            claimLimit: individualClaimLimit,
            totalEarnedPoints: 10,
            claims: [],
          }}
          repliesData={dummyReplies}
          currency={userDetails.assembly.currency}
          currentUserDetails={getMemberDetailsFromUserDetails(
            userDetails.member
          )}
        />
      </TooltipEnabledContext.Provider>
    </div>
  );
}
