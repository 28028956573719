import {
  type AssemblyCurrency,
  AssemblyCurrencyType,
  type ChallengeState,
  type FileDetails,
  mapHexCodeToEmoticon,
  type MemberDetails,
  type ReactionDetails,
  type RepliesResponse,
  type SelfClaimDetails,
  type UserAvatar,
} from '@assembly-web/services';
import { Root as HoverCardRoot } from '@radix-ui/react-hover-card';
import { useMemo } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import {
  Toolbar,
  type ToolbarItem,
} from '../../../../DesignSystem/Feedback/Toolbar/Toolbar';
import { useToolbarState } from '../../../../DesignSystem/Feedback/Toolbar/useToolbarState';
import { HorizontalRule } from '../../../../DesignSystem/Layout/HorizontalRule';
import { useDeviceInfo } from '../../../hooks/useDeviceInfo';
import { ReactionsBar } from '../../PostAndReplies/ReactionsBar';
import { useChallenge } from '../useChallenge';
import { ChallengeCardContent } from './ChallengeCardContent';
import { ChallengeCardFooter } from './ChallengeCardFooter';
import { ChallengeCardHeader } from './ChallengeCardHeader';
import { ChallengeCardHeaderBanner } from './ChallengeCardHeaderBanner';
import { ImageDisplay } from './ImageDisplay';

export type ChallengeCardProps = {
  challengeId?: string;
  title: string;
  imageUrl?: string;
  description?: string;
  image?: FileDetails;
  points: number;
  state: ChallengeState;
  type: string;
  createdBy: MemberDetails;
  claimButtonText: string;
  hideReactions: boolean;
  hideReplies: boolean;
  claimsRemaining: number;
  canEdit: boolean;
  showDraft?: boolean;
  reactions: ReactionDetails[];
  onClaim?: () => void;
  onEdit?: () => void;
  repliesData: RepliesResponse;
  currency: AssemblyCurrency | undefined;
  selfClaimDetails: SelfClaimDetails | null;
  isUserAdmin: boolean;
  launchedAt?: string;
  toolbarMenuItems?: {
    primaryToolBarItems?: ToolbarItem[];
    secondaryToolBarItems?: ToolbarItem[];
  };
  handleMenuItem?: (menu: ToolbarItem) => void;
  onClick?: (challengeId: string) => void;
  onHeaderButtonClick?: (button: string) => void;
  currentUserDetails: MemberDetails;
  isClaimButtonLoading?: boolean;
  onReactionClick?: (emoji: ReactionDetails) => void;
  onReplyButtonClick?: () => void;
  endedAt?: string;
  onViewClaims?: () => void;
  variant?: 'preview' | 'default' | 'drawer' | 'carousal';
  onUnarchiveChallenge: () => void;
};

export const ChallengeCard = (props: ChallengeCardProps) => {
  const {
    challengeId,
    onUnarchiveChallenge,
    onClick,
    title,
    description = '',
    image = null,
    imageUrl = null,
    claimButtonText,
    repliesData,
    reactions,
    createdBy,
    points,
    currency,
    selfClaimDetails = {
      claimLimit: 0,
      totalEarnedPoints: 0,
      claims: [],
    },
    state,
    isUserAdmin,
    hideReplies,
    hideReactions,
    claimsRemaining,
    launchedAt,
    toolbarMenuItems = {
      primaryToolBarItems: [],
      secondaryToolBarItems: [],
    },
    handleMenuItem,
    onHeaderButtonClick,
    currentUserDetails,
    onClaim,
    isClaimButtonLoading = false,
    onReactionClick = () => {},
    onReplyButtonClick = () => {},
    variant = 'default',
    endedAt,
    onViewClaims,
    showDraft = false,
  } = props;

  const icon = useMemo(() => {
    if (currency) {
      const { value, type, name } = currency;
      return type === AssemblyCurrencyType.Custom
        ? `<img alt=${name} class="mr-0.5 h-3 w-3 inline-block" src=${value} />`
        : mapHexCodeToEmoticon(value);
    }
    return mapHexCodeToEmoticon('1f3c6');
  }, [currency]);

  const userAvatarDetails: UserAvatar = {
    name: createdBy.name,
    memberID: createdBy.memberID,
    image: createdBy.image ?? '',
  };

  const currentUserId = currentUserDetails.memberID;

  const {
    showHeaderBanner,
    isChallengeArchived,
    isCurrentUserOwner,
    showClaimBadge,
    isAllApproved,
    claimCount,
    isClaimButtonDisabled,
    showClaimButton,
    showViewClaimsButton,
    showFooter,
    isNotParticipant,
  } = useChallenge({
    challengeId,
    selfClaimDetails,
    challengeState: state,
    isUserAdmin,
    claimsRemaining,
    currentUserId,
    memberID: createdBy.memberID,
    hideReactions,
    hideReplies,
    launchedAt,
    userAvatarDetails,
    claimButtonText,
  });

  const { getContainerProps, getToolbarProps } = useToolbarState();

  const isMobileView = useDeviceInfo().deviceType === 'mobile';

  const handleClick = () => {
    if (state === 'ARCHIVED') {
      onUnarchiveChallenge();
    }
  };
  return (
    <HoverCardRoot openDelay={200} closeDelay={200}>
      <div
        onClick={handleClick}
        className={twJoin(
          'w-full cursor-pointer rounded-lg',
          variant !== 'drawer' &&
            'border border-gray-5 @container/challenge-card',
          variant === 'drawer' && '-mt-1'
        )}
        role="presentation"
        onKeyDown={handleClick}
      >
        {Boolean(showHeaderBanner) && variant !== 'drawer' && (
          <ChallengeCardHeaderBanner
            variant={variant}
            challengeState={state}
            isUserAdmin={isUserAdmin}
            userDetails={userAvatarDetails}
            isCurrentUserOwner={isCurrentUserOwner}
            onHeaderButtonClick={onHeaderButtonClick}
            endedAt={endedAt}
          />
        )}

        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <section
          className={twMerge(
            'relative flex h-full flex-col overflow-hidden rounded-lg bg-gray-1',
            variant !== 'drawer' &&
              'hover:shadow-base-down @md/challenge-card:flex-row',
            isChallengeArchived && 'pointer-events-none grayscale',
            showHeaderBanner && 'rounded-t-none',
            showFooter && 'rounded-b-none',
            variant === 'carousal' && 'my-1 hover:shadow-none'
          )}
          data-testid={`challenge-card`}
          onClick={(event) => {
            event.stopPropagation();
            if (challengeId) {
              onClick?.(challengeId);
            }
          }}
          {...getContainerProps()} // a11y properties get added here
        >
          <ImageDisplay
            variant={variant}
            image={imageUrl || image?.location || ''}
          />
          {Boolean(isMobileView || variant === 'carousal') &&
            Boolean(
              (toolbarMenuItems.primaryToolBarItems &&
                toolbarMenuItems.primaryToolBarItems.length > 0) ||
                (toolbarMenuItems.secondaryToolBarItems &&
                  toolbarMenuItems.secondaryToolBarItems.length > 0)
            ) && (
              <Toolbar
                {...getToolbarProps({
                  onMenuItemClick(args) {
                    handleMenuItem?.(args);
                  },
                })}
                primaryToolbarItems={toolbarMenuItems.primaryToolBarItems}
                secondaryToolbarItems={
                  toolbarMenuItems.secondaryToolBarItems ?? []
                }
                className={twMerge(
                  'absolute right-4 m-3 flex h-8 w-8 items-center justify-center',
                  variant === 'carousal' && 'absolute right-0 m-3 w-14'
                )}
              />
            )}
          <div
            className={twJoin(
              'flex h-auto grow flex-col justify-center gap-2 overflow-hidden py-4',
              variant !== 'drawer' && 'px-4'
            )}
          >
            <ChallengeCardHeader
              title={title}
              variant={variant}
              challengeState={state}
              description={description}
              showClaimBadge={showClaimBadge}
              claimButtonText={claimButtonText}
              isAllApproved={isAllApproved}
              claimCount={claimCount}
              isClaimButtonDisabled={isClaimButtonDisabled}
              showClaimsButton={showClaimButton}
              showViewClaimsButton={showViewClaimsButton}
              onClaim={onClaim}
              onViewClaims={onViewClaims}
              toolbar={
                Boolean(
                  (variant === 'carousal' &&
                    toolbarMenuItems.primaryToolBarItems &&
                    toolbarMenuItems.primaryToolBarItems.length > 0) ||
                    (toolbarMenuItems.secondaryToolBarItems &&
                      toolbarMenuItems.secondaryToolBarItems.length > 0)
                ) && (
                  <Toolbar
                    {...getToolbarProps({
                      onMenuItemClick(args) {
                        handleMenuItem?.(args);
                      },
                    })}
                    primaryToolbarItems={toolbarMenuItems.primaryToolBarItems}
                    secondaryToolbarItems={
                      toolbarMenuItems.secondaryToolBarItems ?? []
                    }
                    className={'flex h-8 w-8 items-center justify-center'}
                  />
                )
              }
              isClaimButtonLoading={isClaimButtonLoading}
              isNotParticipant={isNotParticipant}
            />
            <ChallengeCardContent
              description={description}
              icon={icon}
              points={points}
              variant={variant}
              selfClaimDetails={selfClaimDetails}
              launchedAt={launchedAt}
              endedAt={endedAt}
              claimButtonText={claimButtonText}
              showClaimBadge={showClaimBadge}
              isClaimButtonDisabled={isClaimButtonDisabled}
              showClaimsButton={showClaimButton}
              showViewClaimsButton={showViewClaimsButton}
              onClaim={onClaim}
              onViewClaims={onViewClaims}
              isClaimButtonLoading={isClaimButtonLoading}
              isChallengeArchived={isChallengeArchived}
              claimsRemaining={claimsRemaining}
              isNotParticipant={isNotParticipant}
            />
          </div>
        </section>
        {variant === 'drawer' && !hideReactions && (
          <ReactionsBar
            currentMemberId={currentUserId}
            onReactionClick={onReactionClick}
            reactions={reactions}
            variant="default"
          />
        )}
        {Boolean(
          showFooter && variant !== 'drawer' && variant !== 'carousal'
        ) && (
          <>
            <HorizontalRule />
            <ChallengeCardFooter
              reactions={reactions}
              showDraft={showDraft}
              repliesData={repliesData}
              hideReplies={hideReplies}
              hideReactions={hideReactions}
              onReactionClick={onReactionClick}
              onReplyButtonClick={onReplyButtonClick}
              currentUserDetails={currentUserDetails}
            />
          </>
        )}
      </div>
    </HoverCardRoot>
  );
};
