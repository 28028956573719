import type { SVGProps } from 'react';

export function ReactComponent({
  fill = '#08979C',
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="48"
      height="46"
      viewBox="0 0 48 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.5 4.2832L16.899 19.0743C13.065 21.3415 10.5 25.3762 10.5 29.982C10.5 37.0663 16.5555 42.8314 24 42.8314C31.4445 42.8314 37.5 37.0663 37.5 29.982C37.5 25.3776 34.935 21.3429 31.101 19.0743L37.5 4.2832H34.257L28.389 17.8436C27.3892 17.5135 26.3521 17.2964 25.299 17.1968L30.8805 4.2832H27.6375L22.0095 17.2896C21.1934 17.4041 20.39 17.5895 19.6095 17.8436L13.743 4.2832H10.5ZM17.118 4.2832L20.9295 13.0979L22.5525 9.34729L20.3625 4.2832H17.118ZM24 19.988C29.7885 19.988 34.5 24.471 34.5 29.982C34.5 35.493 29.7885 39.976 24 39.976C18.2115 39.976 13.5 35.493 13.5 29.982C13.5 24.471 18.2115 19.988 24 19.988ZM24 24.5024L22.248 28.243L18 28.6856L21.18 31.404L20.2845 35.4159L24 33.3771L27.7155 35.4187L26.8215 31.4069L30 28.6842L25.752 28.2416L24 24.5024Z"
        fill={fill}
      />
    </svg>
  );
}
