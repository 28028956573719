import {
  GlobeAltIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { EyeSlashIcon } from '@heroicons/react/24/solid';
import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { SelectDropdown } from '../../../DesignSystem/Inputs/SelectDropdown';

const labels = defineMessages({
  sharingWith: {
    id: 'D4C9Xy',
    defaultMessage: 'Sharing with:',
  },
  private: {
    id: '7NPsts',
    defaultMessage: 'Private post',
  },
  everyone: {
    id: '2XDuH4',
    defaultMessage: 'Everyone',
  },
  visibilityPermission: {
    id: 'Vgnv4w',
    defaultMessage: 'Visibility Permission',
  },
});

enum VisibilityPermission {
  Everyone = 'everyone',
  Private = 'private',
}

export type VisibilitySelectorBlockProps = {
  isPrivatePostAllowed: boolean;
  privateToolTip?: string;
  onValueChange: (value: boolean) => void;
  value: boolean;
};

export function VisibilitySelectorBlock({
  isPrivatePostAllowed,
  privateToolTip = '',
  onValueChange,
  value,
}: VisibilitySelectorBlockProps) {
  const { formatMessage } = useIntl();

  const options = useMemo(() => {
    return [
      {
        id: VisibilityPermission.Private,
        value: VisibilityPermission.Private,
        label: formatMessage(labels.private),
        icon: <EyeSlashIcon className="h-4 w-4" />,
        tooltip: privateToolTip,
      },
      {
        id: VisibilityPermission.Everyone,
        value: VisibilityPermission.Everyone,
        label: formatMessage(labels.everyone),
        icon: <GlobeAltIcon className="h-4 w-4" />,
      },
    ];
  }, [privateToolTip, formatMessage]);

  return (
    <div className="flex items-center gap-1">
      <TextStyle>{formatMessage(labels.sharingWith)}</TextStyle>
      {isPrivatePostAllowed ? (
        <SelectDropdown
          label={''}
          aria-label={formatMessage(labels.visibilityPermission)}
          variant="secondary"
          value={
            value ? VisibilityPermission.Private : VisibilityPermission.Everyone
          }
          options={options}
          onSelect={(selectedOption) => {
            onValueChange(selectedOption === VisibilityPermission.Private);
          }}
          renderOption={(option) => (
            <div className="flex items-center gap-2">
              {option.icon}
              <TextStyle>{option.label}</TextStyle>
              {Boolean(option.tooltip) && (
                <Tooltip tooltipText={option.tooltip}>
                  <InformationCircleIcon className="h-4 w-4 text-primary-6" />
                </Tooltip>
              )}
            </div>
          )}
        />
      ) : (
        <div className="flex items-center gap-2 rounded-2xl bg-primary-1 px-3 py-1">
          <GlobeAltIcon className="h-4 w-4" />
          <TextStyle variant="sm-medium">
            {formatMessage(labels.everyone)}
          </TextStyle>
        </div>
      )}
    </div>
  );
}
