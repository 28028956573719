import { useTextOverflow } from '@assembly-web/services';

import { TextStyle, type TextStyleProps } from './TextStyle';
import { Tooltip } from './Tooltip';

type OverflowTextProps = TextStyleProps & {
  tooltipText?: string;
  tooltipClassName?: string;
};

function OverflowTextImpl(props: OverflowTextProps) {
  const { tooltipText, tooltipClassName, ...propsWithContent } = props;

  const { contentOverflows, textMountCallback } = useTextOverflow();

  return (
    <Tooltip
      tooltipText={Boolean(contentOverflows) && tooltipText}
      contentClassName={tooltipClassName}
    >
      <TextStyle {...propsWithContent} ref={textMountCallback} />
    </Tooltip>
  );
}

export function OverflowText({ className, ...tail }: OverflowTextProps) {
  return <OverflowTextImpl {...tail} className={className} key={className} />;
}
