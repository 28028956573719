import * as Tabs from '@radix-ui/react-tabs';
import {
  createContext,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useState,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import type { Step } from './Stepper';

export const StepperContext = createContext<{
  activeStepIndex: number;
  setActiveStepIndex: Dispatch<SetStateAction<number>>;
  steps: Step[];
  finalStepLabel: ReactNode;
  handleFinalStep: () => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
}>({
  activeStepIndex: 0,
  setActiveStepIndex: () => {},
  steps: [],
  finalStepLabel: '',
  handleFinalStep: () => {},
  goToNextStep: () => {},
  goToPreviousStep: () => {},
});

export function StepperProvider({
  children,
  steps,
  finalStepLabel,
  handleFinalStep,
  handleNextStep,
}: {
  children:
    | ReactNode
    | ((options: {
        setActiveStepIndex?: Dispatch<SetStateAction<number>>;
      }) => ReactNode);
  steps: Step[];
  finalStepLabel: ReactNode;
  handleFinalStep: () => void;
  handleNextStep?: () => Promise<boolean>;
}) {
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  async function goToNextStep() {
    if (!handleNextStep) {
      setActiveStepIndex((prev) => prev + 1);
      return;
    }
    const goToNext = await handleNextStep();
    if (!goToNext) return;
    if (activeStepIndex === steps.length - 1) {
      setActiveStepIndex(0);
      handleFinalStep();
      return;
    }
    setActiveStepIndex((prev) => prev + 1);
  }

  function goToPreviousStep() {
    setActiveStepIndex((prev) => prev - 1);
  }

  useHotkeys('left', () => {
    if (activeStepIndex !== 0) goToPreviousStep();
  });

  useHotkeys('right', () => {
    goToNextStep();
  });

  useHotkeys('enter', () => {
    if (activeStepIndex === steps.length - 1) handleFinalStep();
    goToNextStep();
  });

  return (
    <Tabs.Root
      value={activeStepIndex.toString()}
      onValueChange={(value) => setActiveStepIndex(parseInt(value))}
    >
      <StepperContext.Provider
        value={{
          activeStepIndex,
          setActiveStepIndex,
          steps,
          finalStepLabel,
          handleFinalStep,
          goToNextStep,
          goToPreviousStep,
        }}
      >
        {typeof children === 'function'
          ? children({ setActiveStepIndex })
          : children}
      </StepperContext.Provider>
    </Tabs.Root>
  );
}
