import {
  queryOptions,
  useQuery,
  type UseQueryOptions,
  useSuspenseQuery,
  type UseSuspenseQueryOptions,
} from '@tanstack/react-query';

import { APIEndpoints } from '../../../APIEndpoints';
import { assemblyAPI } from '../../../assemblyAPI';
import type { AwardsCatalogResponse } from '../../../types/awards';

type GetAwardToGiveListParam = {
  search: string;
};

const query = <T>(search?: string) =>
  queryOptions<AwardsCatalogResponse, Error, T>({
    queryKey: ['awardsCatalog', search ?? ''],
    queryFn: async ({ signal }) => {
      const response = await assemblyAPI.get<AwardsCatalogResponse>(
        APIEndpoints.getAwardsCatalog,
        {
          signal,
          params: search
            ? {
                search,
              }
            : undefined,
        }
      );
      return response.data;
    },
  });

export function useAwardsCatalog<T = AwardsCatalogResponse>({
  options,
  payload,
}: {
  options?: Partial<UseSuspenseQueryOptions<AwardsCatalogResponse, Error, T>>;
  payload?: GetAwardToGiveListParam;
} = {}) {
  return useSuspenseQuery({
    ...query<T>(payload?.search),
    ...options,
  });
}

export function useGetAwardsCatalog<T = AwardsCatalogResponse>({
  options,
  payload,
}: {
  options?: Partial<UseQueryOptions<AwardsCatalogResponse, Error, T>>;
  payload?: GetAwardToGiveListParam;
} = {}) {
  return useQuery({
    ...query<T>(payload?.search),
    ...options,
  });
}
