import { type ReactNode, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from './TextStyle';

const messages = defineMessages({
  more: {
    defaultMessage: 'See more',
    id: 'yoLwRW',
  },
  less: {
    defaultMessage: 'See less',
    id: 'GZo7vg',
  },
});

export type CollapsibleContentProps = {
  content: ReactNode;
  lineLimit?: number;
};

export function CollapsibleContent({
  content,
  lineLimit = 3,
}: CollapsibleContentProps) {
  const { formatMessage } = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCollapsible, setIsCollapsible] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (contentRef.current) {
      const lineHeight = parseFloat(
        getComputedStyle(contentRef.current).lineHeight
      );
      const maxHeight = lineHeight * lineLimit;
      setIsCollapsible(contentRef.current.scrollHeight > maxHeight);
    }
  }, [content, lineLimit]);

  return (
    <div className="inline-flex flex-col gap-2">
      <TextStyle
        variant="sm-regular"
        className={twMerge(
          isExpanded
            ? 'max-h-fit'
            : `max-h-[calc(1.5rem*${lineLimit})] line-clamp-3`
        )}
        ref={contentRef}
      >
        {content}
      </TextStyle>

      {Boolean(isCollapsible) && (
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="hover:bg-transparent! w-fit underline focus:ring-0"
        >
          <TextStyle variant="sm-regular">
            {isExpanded
              ? formatMessage(messages.less)
              : formatMessage(messages.more)}
          </TextStyle>
        </button>
      )}
    </div>
  );
}
