import {
  checkForProfanity,
  cleanEditorState,
  config,
  logger,
  MemberStatus,
  type Nullable,
  removeNewLinesAndTabs,
  type ReplyData,
  sanitizeHtml,
  useDebounceFn,
  useUserDetails,
} from '@assembly-web/services';
import { useGetMembers } from '@assembly-web/services';
import type {
  EditorData,
  EditorStateChangeArgs,
  MentionedUser,
  MentionedUserInReply,
  RepliesValidatorError,
} from '@assembly-web/ui';
import { RepliesEditor, TextStyle, useToastStore } from '@assembly-web/ui';
import { isAxiosError } from 'axios';
import {
  type FC,
  type MutableRefObject,
  type ReactNode,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useGetPostAndRepliesDetails } from '../../../../../hooks/useGetPostAndRepliesDetails';
import { useNavigateToUserFeed } from '../../../../../hooks/useNavigateToUserFeed';
import { useLayoutStore } from '../../../../../stores/useLayoutStore';
import { useMultiDrawerStore } from '../../../../../stores/useMultiDrawerStore';
import { ModalsContext } from '../../../contexts/ModalsContext';
import type { AddReplyPayload } from '../../../hooks/replies/types';
import {
  useAddReplyMutation,
  useUpdateReplyMutation,
} from '../../../hooks/replies/useRepliesQueries';
import { useSaveDraftsMutation } from '../../../hooks/replies/useSaveDraftsMutation';
import { useSearchMembersForFlow } from '../../../hooks/replies/useSearchMembersForFlow';
import {
  useGetRepliesDrafts,
  useSetRepliesDrafts,
} from '../../../hooks/useGetRepliesDrafts';
import {
  trackReplyDrawerAction,
  trackReplyDrawerError,
} from '../../../services/analytics';
import { useBoostOptions } from '../shared/hooks/useBoostOptions';
import type { Drawer } from '../types';

type RepliesEditorContainerProps = {
  flowId: string;
  drawerId: string;
  flowName?: string;
  commentId?: string;
  responseId: string;
  disabled?: boolean;
  kind: 'RESPONSE' | 'POST';
  isRepliesDisabled?: boolean;
  selectedReply: Nullable<ReplyData>;
  mentionedUsers: MentionedUserInReply[] | null;
  onSelectedReplyChange: (
    reply: Nullable<ReplyData>,
    commentId: string
  ) => void;
  isAnnouncement: boolean;
  containerRef: MutableRefObject<HTMLElement | null>;
  editedCommentId: Nullable<string>;
};

const messages = defineMessages({
  replyLabel: {
    defaultMessage: 'Reply...',
    id: '/dm2sj',
  },
  replyLimitMessage: {
    defaultMessage:
      "You've reached the maximum 5,000 character limit and cannot add more",
    id: '+ueERL',
  },
  profanityWarning: {
    defaultMessage:
      'Your message contains inappropriate language. Please remove it to post.',
    id: 'zM2kzT',
  },
  repliesTurnedOffTooltip: {
    defaultMessage:
      'Replies have been turned off for this post, so you cannot share this reply',
    id: 'y6u9cD',
  },
  boostDisabledNoAllowance: {
    defaultMessage: 'You are out of {currency} to give for this month.',
    id: 'Rgc6rs',
  },
  boostDisabledByAdmin: {
    defaultMessage: 'Boost disabled for this flow',
    id: 'Fc9hM3',
  },
  addReplyError: {
    defaultMessage: "You're posting a lot of replies, try again in a bit",
    id: 'DTgrc0',
  },
});

const MaxCharactersLimit = 5000;
const AutoSaveDebounceTime = 3000;

const isMatchingResponseDrawer = (
  drawer: Drawer,
  flowId: string,
  responseId: string,
  commentId?: string
) => {
  const isFlowTypeMatching =
    drawer.type === 'postsAndReplies' &&
    drawer.data.type === 'flow' &&
    drawer.data.flowId === flowId &&
    drawer.data.responseId === responseId &&
    drawer.data.commentId === commentId;

  const isPostTypeMatching =
    drawer.type === 'postsAndReplies' &&
    drawer.data.type === 'post' &&
    drawer.data.postId === responseId &&
    drawer.data.commentId === commentId;

  return isFlowTypeMatching || isPostTypeMatching;
};

const Error: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="flex-shrink-0">
      <TextStyle variant="xs-regular" className="text-error-5">
        {children}
      </TextStyle>
    </div>
  );
};

export function RepliesEditorContainer({
  kind,
  flowId,
  drawerId,
  flowName,
  commentId,
  responseId,
  containerRef,
  selectedReply,
  isAnnouncement,
  mentionedUsers,
  editedCommentId,
  isRepliesDisabled,
  onSelectedReplyChange,
}: RepliesEditorContainerProps) {
  const { formatMessage } = useIntl();
  const { showToast } = useToastStore();
  const { navigate: navigateToUserFeed } = useNavigateToUserFeed();

  const boostOptions = useBoostOptions({
    flowId,
    responseId,
  });

  const { isFormattingToolbarVisible, setIsFormattingToolbarVisible } =
    useLayoutStore();

  const drawers = useMultiDrawerStore((store) => store.getDrawers());
  const responseDrawer = drawers.find((drawer) =>
    isMatchingResponseDrawer(drawer, flowId, responseId, commentId)
  );
  const [isMentionsMenuOpen, setIsMentionsMenuOpen] = useState(false);
  const { data: userDetails } = useUserDetails();

  const { data: draftData, isPending: isDraftsPending } = useGetRepliesDrafts(
    responseId,
    {
      enabled: !selectedReply,
    }
  );

  const setDraft = useSetRepliesDrafts(responseId);
  const savedDraft = useRef<string | null>(null);

  const { onDeleteReplyOrPostClick } = useContext(ModalsContext);

  const { currentUser, postCardDetails } = useGetPostAndRepliesDetails({
    flowId,
    responseId,
    enabled: true,
  });

  const [replyType, setReplyType] = useState<'anonymous' | 'user'>(() => {
    if (!selectedReply) {
      return postCardDetails?.respondent ? 'user' : 'anonymous';
    }

    return 'anonymousIdentifier' in selectedReply.fromMember
      ? 'anonymous'
      : 'user';
  });

  const [membersSearchQuery, setMembersSearchQuery] = useState('');
  const [characterLength, setCharacterLength] = useState(0);
  const [hasProfanity, setHasProfanity] = useState(false);

  const {
    members,
    isPending: isMembersListPending,
    hasNextPage: hasNextPageInMembersList,
    fetchNextPage: fetchNextPageInMembersList,
    isFetchingNextPage: isFetchingNextPageInMembersList,
  } = useGetMembers({
    searchTerm: membersSearchQuery,
    options: {
      placeholderData: (previousData) => previousData,
      enabled: flowId === 'recognition',
    },
  });

  const {
    members: flowMembers,
    isPending: isFlowMembersListPending,
    hasNextPage: hasNextPageInFlowMembersList,
    fetchNextPage: fetchNextPageInFlowMembersList,
    isFetchingNextPage: isFetchingNextPageInFlowMembersList,
  } = useSearchMembersForFlow(
    {
      flowId,
      type: 'viewers',
      keyword: membersSearchQuery,
    },
    {
      placeholderData: (previousData) => previousData,
      enabled: flowId !== 'recognition',
    }
  );

  const { mutate: addReply, isError: isAddReplyError } = useAddReplyMutation({
    flowId,
    responseId,
    onError: (error: unknown) => {
      const errorObj = isAxiosError(error) ? error : undefined;
      trackReplyDrawerError({
        errorType: errorObj?.response?.data?.message ?? 'Error on add reply',
      });

      logger.error('Add Reply Error', {}, errorObj);
    },
  });

  const { mutate: saveDraft } = useSaveDraftsMutation({
    onError: (error: unknown) => {
      const errorObj = isAxiosError(error) ? error : undefined;
      trackReplyDrawerError({
        errorType: errorObj?.response?.data?.message ?? 'Error on save draft',
      });

      logger.error('Draft Save Error', {}, errorObj);
    },
  });

  const { mutate: updateReply } = useUpdateReplyMutation({
    flowId,
    responseId,
    onError: (error: unknown) => {
      const errorObj = isAxiosError(error) ? error : undefined;
      trackReplyDrawerError({
        errorType: errorObj?.response?.data?.message ?? 'Error on update reply',
      });

      logger.error('Update Reply Error', {}, errorObj);
    },
  });

  const handleOnMentionsSearchQueryChange = useCallback(
    (query: Nullable<string>) => {
      setMembersSearchQuery(query ?? '');
    },
    []
  );

  const handleOnNextPageScrolledInMentionsDropdown = useCallback(async () => {
    if (flowId === 'recognition' && hasNextPageInMembersList) {
      await fetchNextPageInMembersList();
    }

    if (
      flowId !== 'recognition' &&
      hasNextPageInFlowMembersList &&
      !membersSearchQuery
    ) {
      await fetchNextPageInFlowMembersList();
    }
  }, [
    flowId,
    membersSearchQuery,
    hasNextPageInMembersList,
    fetchNextPageInMembersList,
    hasNextPageInFlowMembersList,
    fetchNextPageInFlowMembersList,
  ]);

  const initialDraft =
    draftData?.draft && 'replyData' in draftData.draft
      ? draftData.draft.replyData.messageTokens
      : null;

  const handleOnChange = useCallback(
    (data: string) => {
      const isLoading =
        isDraftsPending ||
        (flowId === 'recognition' && isMembersListPending) ||
        (flowId !== 'recognition' && isFlowMembersListPending);

      const hasUpdatedDraft =
        JSON.stringify(savedDraft.current) !== JSON.stringify(data);

      if (
        !isLoading &&
        !hasProfanity &&
        responseDrawer &&
        hasUpdatedDraft &&
        !isMentionsMenuOpen
      ) {
        savedDraft.current = data;
        saveDraft({
          flowId,
          entityType: 'REPLY',
          replyData: {
            kind: 'RESPONSE',
            postId: responseId,
            messageTokens: data,
          },
        });
      }
    },
    [
      flowId,
      saveDraft,
      responseId,
      hasProfanity,
      responseDrawer,
      isDraftsPending,
      isMentionsMenuOpen,
      isMembersListPending,
      isFlowMembersListPending,
    ]
  );

  const { debouncedFn: debouncedOnChange, cancel } = useDebounceFn(
    handleOnChange,
    AutoSaveDebounceTime
  );

  const findAndUpdateDrawerField = useMultiDrawerStore(
    (store) => store.findAndUpdateDrawerField
  );

  const resetEditedCommentId = useCallback(() => {
    if (!editedCommentId) {
      return;
    }
    const postDrawerId =
      flowId === 'recognition' ? responseId : `${flowId}-${responseId}`;

    const update = (draft: Drawer) => {
      if (draft.type === 'postsAndReplies') {
        draft.data = {
          ...draft.data,
          editSelectedCommentId: undefined,
        };
      }
    };

    findAndUpdateDrawerField(postDrawerId, update);
  }, [editedCommentId, findAndUpdateDrawerField, flowId, responseId]);

  const handleOnPostReplyClick = useCallback(
    ({
      html,
      json,
      mentionIds,
      plainText,
      gifUrls,
      linkUrls,
      errors,
      boost,
    }: EditorData) => {
      if (errors && errors.length > 0) {
        if (errors.includes('HAS_PROFANITY')) {
          setHasProfanity(true);
        }

        return;
      }

      const cleanedState = cleanEditorState({
        html,
        json,
        plainText,
      });
      html = cleanedState.html;
      json = cleanedState.json;
      plainText = cleanedState.plainText;

      if (
        plainText.length === 0 &&
        !html.includes('<img') &&
        !html.includes('data-lexical-mention-id')
      ) {
        if (selectedReply) {
          resetEditedCommentId();
          onDeleteReplyOrPostClick({
            flowId,
            responseId,
            replyId: selectedReply.commentID,
            isLegacyReply: selectedReply.kind === 'POST',
            onReplyDeleteSuccess: () => {
              onSelectedReplyChange(null, drawerId);
            },
            onEditReplyCancel: () => {
              handleOnChange(selectedReply.messageTokens ?? '');
            },
          });
        }
        return;
      }

      cancel();
      handleOnChange('');

      const replyPayload: AddReplyPayload =
        kind === 'RESPONSE'
          ? {
              flowId: flowId,
              kind: 'RESPONSE',
              messageTokens: json,
              mentions: mentionIds,
              responseId: responseId,
              messageHtml: sanitizeHtml(html),
              isAnonymous: replyType === 'anonymous',
              plainText: removeNewLinesAndTabs(plainText),
              ...(replyType !== 'anonymous' && { boost }),
            }
          : {
              kind: 'POST',
              postId: responseId,
              messageTokens: json,
              mentions: mentionIds,
              messageHtml: sanitizeHtml(html),
              plainText: removeNewLinesAndTabs(plainText),
              ...(replyType !== 'anonymous' && { boost }),
            };

      if (selectedReply) {
        const selectedReplyType =
          'anonymousIdentifier' in selectedReply.fromMember
            ? 'anonymous'
            : 'user';
        const hasContentChanged = json !== selectedReply.messageTokens;
        const hasReplyTypeChanged = replyType !== selectedReplyType;
        const shouldUpdateReply = hasReplyTypeChanged || hasContentChanged;
        if (!shouldUpdateReply) {
          onSelectedReplyChange(null, drawerId);
          resetEditedCommentId();
        } else {
          updateReply({
            ...replyPayload,
            replyId: selectedReply.commentID,
          });

          trackReplyDrawerAction('replyEdited', {
            isAnnouncement,
            flowId: flowId,
            numReplyGifs: gifUrls?.length,
            isRecognition: kind === 'POST',
            numReplyLinks: linkUrls?.length,
            flowName: flowName ?? 'Recognition',
            isAnonymousReply: replyPayload.isAnonymous,
            numReplyBoosts: replyPayload.boost?.length,
            numReplyMentions: replyPayload.mentions?.length,
          });
          onSelectedReplyChange(null, drawerId);
          resetEditedCommentId();
        }
      } else {
        addReply(replyPayload);
        trackReplyDrawerAction('replyPosted', {
          isAnnouncement,
          flowId: flowId,
          numReplyGifs: gifUrls?.length,
          isRecognition: kind === 'POST',
          numReplyLinks: linkUrls?.length,
          flowName: flowName ?? 'Recognition',
          isAnonymousReply: replyPayload.isAnonymous,
          numReplyBoosts: replyPayload.boost?.length,
          numReplyMentions: replyPayload.mentions?.length,
        });
        if (containerRef.current) {
          containerRef.current.scrollTo({
            top: containerRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }
      }
      setDraft({ draft: {} });
    },
    [
      flowName,
      cancel,
      handleOnChange,
      kind,
      flowId,
      responseId,
      replyType,
      selectedReply,
      setDraft,
      resetEditedCommentId,
      onDeleteReplyOrPostClick,
      onSelectedReplyChange,
      drawerId,
      updateReply,
      isAnnouncement,
      addReply,
      containerRef,
    ]
  );

  const transformMentionedUsers = (): MentionedUser[] => {
    if (mentionedUsers) {
      return mentionedUsers.map((user) => {
        return {
          ...user,
          role: '',
          profileThumbnails: user.image && { 100: user.image },
          firstName: user.firstName ?? '',
          lastName: user.lastName ?? '',
          memberID: user.id,
          currency: currentUser.assembly.currency,
          isFlowViewer: true,
          currentUserID: currentUser.member.memberId,
          isCurrentUserAnon: replyType === 'anonymous',
          pointsHidden: false,
          applyBoostToAll: false,
          selectedReply: selectedReply,
          canReceivePoints:
            user.status === MemberStatus.Receiver ||
            user.status === MemberStatus.Normal,
        };
      });
    }
    return [];
  };

  const validator = useCallback(
    (text: string) => {
      let errors: RepliesValidatorError[] = [];

      const shouldCheckForProfanity =
        userDetails?.member.permissions.profanitySetting.enabled;
      if (shouldCheckForProfanity) {
        const hasProfaneWords =
          text.length > 0 &&
          checkForProfanity(
            text,
            userDetails.member.permissions.profanitySetting.wordsToCheck
          );
        if (hasProfaneWords) {
          errors.push('HAS_PROFANITY');
        }
      }

      return errors;
    },
    [userDetails]
  );

  const handleOnEditorChange = useCallback(
    (args: EditorStateChangeArgs) => {
      const { plainText, errors, gifUrls } = args;

      if (hasProfanity && !errors.includes('HAS_PROFANITY')) {
        setHasProfanity(false);
      }
      setCharacterLength(plainText.length);

      setDraft({
        draft: {
          flowId,
          entityType: 'REPLY',
          replyData: {
            kind: 'RESPONSE',
            postId: responseId,
            messageTokens: args.editorState,
          },
        },
      });

      if (
        (plainText.length !== 0 || gifUrls.length !== 0) &&
        !isMentionsMenuOpen
      ) {
        debouncedOnChange(args.editorState);
        if (plainText.length === 1 || gifUrls.length === 1) {
          trackReplyDrawerAction('replyStarted', {
            isAnnouncement,
            flowId: flowId,
            isRecognition: kind === 'POST',
            flowName: flowName ?? 'Recognition',
            isAnonymousReply: replyType === 'anonymous',
          });
        }
      } else {
        cancel();
        handleOnChange(args.editorState);
      }
    },
    [
      kind,
      flowName,
      hasProfanity,
      isMentionsMenuOpen,
      setDraft,
      flowId,
      responseId,
      debouncedOnChange,
      replyType,
      isAnnouncement,
      cancel,
      handleOnChange,
    ]
  );

  const handleCancelClick = useCallback(() => {
    onSelectedReplyChange(null, drawerId);
    cancel();
    handleOnChange('');
    setDraft({ draft: {} });
    resetEditedCommentId();
    trackReplyDrawerAction('replyEditCancelled', {
      isAnnouncement,
      flowId: flowId,
      isRecognition: kind === 'POST',
      flowName: flowName ?? 'Recognition',
      isAnonymousReply: replyType === 'anonymous',
    });
  }, [
    flowId,
    kind,
    flowName,
    onSelectedReplyChange,
    drawerId,
    cancel,
    handleOnChange,
    setDraft,
    resetEditedCommentId,
    isAnnouncement,
    replyType,
  ]);

  return (
    <>
      <RepliesEditor
        selectedReply={selectedReply}
        currency={currentUser.assembly.currency}
        onReplyTypeChange={(option) => {
          setReplyType(option.value as 'anonymous' | 'user');
        }}
        replyType={replyType}
        validator={validator}
        onChange={handleOnEditorChange}
        onViewProfileClick={navigateToUserFeed}
        isAnonymousPost={!postCardDetails?.respondent}
        mentionedUsersInPost={transformMentionedUsers()}
        onMentionsMenuOpenChange={setIsMentionsMenuOpen}
        isFormattingToolbarVisible={isFormattingToolbarVisible}
        setIsFormattingToolbarVisible={setIsFormattingToolbarVisible}
        onNextPageScrolledInMentionsDropdown={
          handleOnNextPageScrolledInMentionsDropdown
        }
        isFetchingNextPageForMentions={
          isFetchingNextPageInMembersList || isFetchingNextPageInFlowMembersList
        }
        disablePostButton={
          characterLength >= MaxCharactersLimit || hasProfanity
        }
        hasError={characterLength >= MaxCharactersLimit || hasProfanity}
        currentUserDetails={{
          id: currentUser.member.memberID,
          image: currentUser.member.profile.image,
          currency: currentUser.assembly.currency,
          allowance: currentUser.member.pointsLeftThisCycle,
          name: `${currentUser.member.profile.firstName} ${currentUser.member.profile.lastName}`.trim(),
        }}
        onError={(error, editor) => {
          logger.error(
            'Replies Editor Error',
            {
              editor,
            },
            error
          );
        }}
        onCancelClick={handleCancelClick}
        mentionedUsers={
          flowId === 'recognition'
            ? members.map((member) => ({
                ...member,
                id: member.memberID,
                currency: currentUser.assembly.currency,
                role: '',
                isFlowViewer: true,
                pointsHidden: false,
                applyBoostToAll: false,
                selectedReply: selectedReply,
                currentUserID: currentUser.member.memberId,
                isCurrentUserAnon: replyType === 'anonymous',
                canReceivePoints:
                  member.status === MemberStatus.Receiver ||
                  member.status === MemberStatus.Normal,
              }))
            : flowMembers.map((member) => ({
                ...member,
                role: '',
                pointsHidden: false,
                id: member.memberID,
                applyBoostToAll: false,
                selectedReply: selectedReply,
                isFlowViewer: member.isFlowViewer,
                currency: currentUser.assembly.currency,
                currentUserID: currentUser.member.memberId,
                isCurrentUserAnon: replyType === 'anonymous',
                canReceivePoints:
                  member.status === MemberStatus.Receiver ||
                  member.status === MemberStatus.Normal,
              }))
        }
        draftState={initialDraft}
        disabled={isDraftsPending}
        onReplyClick={handleOnPostReplyClick}
        editState={selectedReply?.messageTokens}
        hideToolbarButtons={Boolean(isRepliesDisabled)}
        onMentionsSearchQueryChange={handleOnMentionsSearchQueryChange}
        gifRating={currentUser.assembly.settings.gifAccessibility.value}
        giphyAPIKey={config.giphyKey}
        {...(isRepliesDisabled && {
          postButtonToolTipText: formatMessage(
            messages.repliesTurnedOffTooltip
          ),
        })}
        placeholder={formatMessage(messages.replyLabel)}
        disableAnonymousReplyOption={
          selectedReply
            ? Array.isArray(selectedReply.boost) &&
              selectedReply.boost.length > 0
            : false
        }
        boostOptions={boostOptions}
        showToast={showToast}
      />
      {Boolean(isAddReplyError) && (
        <Error>{formatMessage(messages.addReplyError)}</Error>
      )}
      {hasProfanity ? (
        <Error>{formatMessage(messages.profanityWarning)}</Error>
      ) : characterLength >= MaxCharactersLimit ? (
        <Error>{formatMessage(messages.replyLimitMessage)}</Error>
      ) : null}
    </>
  );
}
