import {
  type ExtendedGroupOrUserChipData,
  isUserAdmin,
  logger,
  type Recipients,
  useGetDepartmentsQuery,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useDeferredValue, useMemo, useState } from 'react';
import type { FieldError } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { Banner } from '../../DesignSystem/Feedback/Banner';
import {
  $createUserChipNode,
  type SerializedUserChipNode,
  UserChipNode,
  UserTypeaheadOption,
} from './Editors/base/nodes/ComboboxChipNode/UserChipNode';
import { ComboboxPlugin } from './Editors/base/plugins/ComboboxPlugin';
import { Combobox } from './Editors/Combobox/Combobox';
import { QuickParticipation } from './QuickParticipation/QuickParticipation';

const config = { ...Combobox.initialConfig, nodes: [UserChipNode] };

const messages = defineMessages({
  everyone: {
    defaultMessage: 'Everyone',
    id: '2XDuH4',
  },
});

export function DepartmentsSelector({
  error,
  onBlur,
  onChange,
  defaultDepartments,
  label,
}: {
  error?: FieldError;
  onBlur: () => void;
  onChange: (value: ExtendedGroupOrUserChipData[]) => void;
  defaultDepartments?: ExtendedGroupOrUserChipData[];
  label: string;
}) {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');
  const differedSearchTerm = useDeferredValue(searchTerm);

  const {
    data: departments,
    status: departmentsStatus,
    fetchStatus: departmentsFetchStatus,
  } = useGetDepartmentsQuery({ search: differedSearchTerm, for: 'award' });

  const {
    data: {
      member: { role },
    },
  } = useSuspenseUserDetails();

  const isAdmin = isUserAdmin({ role });
  const transformedDepartments = useMemo(() => {
    return (
      departments?.reduce(
        (department, cur) => {
          if (cur.id === 'everyone' && !isAdmin) {
            return department;
          }
          department.push({
            type: 'department',
            id: cur.id,
            count: cur.memberCount,
            name:
              cur.id === 'everyone'
                ? formatMessage(messages.everyone)
                : cur.name,
          });
          return department;
        },
        [] as Recipients['department']
      ) ?? []
    );
  }, [departments, formatMessage, isAdmin]);

  const options = useMemo(
    () => transformedDepartments.map((data) => new UserTypeaheadOption(data)),
    [transformedDepartments]
  );

  const defaultValue = useMemo(() => {
    if (!defaultDepartments?.length) {
      return undefined;
    }

    return ComboboxPlugin.$generateInitialState(
      defaultDepartments.map((value) => {
        if (value.type === 'department')
          return {
            type: 'combobox-chip',
            version: 1,
            data: {
              type: 'department',
              count:
                departments?.find(
                  ({ name }) => name.toLowerCase() === value.name.toLowerCase()
                )?.memberCount ?? 0,
              id: value.id,
              name:
                value.name === 'everyone'
                  ? formatMessage(messages.everyone)
                  : value.name,
            },
            id: value.id,
            name:
              value.name === 'everyone'
                ? formatMessage(messages.everyone)
                : value.name,
          } satisfies SerializedUserChipNode;
      })
    );
  }, [defaultDepartments, departments, formatMessage]);

  const isLoading =
    departmentsStatus === 'pending' && departmentsFetchStatus === 'fetching';

  return (
    <Combobox<UserTypeaheadOption, UserChipNode>
      error={error?.message}
      config={config}
      onBlur={onBlur}
      onError={(error, editor) => {
        logger.error(
          'Department Selector Editor Error',
          {
            editor,
          },
          error
        );
      }}
      placeholderVariant="blue"
      draftValue={defaultValue}
      ComboboxProps={{
        onSelectedNodesChange(nodes) {
          onChange(
            nodes.map(
              (node) => node.__data
            ) satisfies ExtendedGroupOrUserChipData[]
          );
        },
        $createChipNode: $createUserChipNode,
        type: 'multiple',
        getKey(option) {
          return option.__data.name;
        },
        getChipKey(chip) {
          return chip.__data.name;
        },
        onSearchQueryChange(query) {
          if (query == null || query === ' ') {
            setSearchTerm('');
            return;
          }
          setSearchTerm(query);
        },
        options,
        children({
          isSelected,
          options,
          selectOptionAndCleanUp,
          selectedIndex,
          setHighlightedIndex,
        }) {
          return (
            <ComboboxPlugin.Root
              className={twJoin(
                'z-20 max-h-[288px] overflow-auto',
                options.length > 5 && 'h-full'
              )}
            >
              <ComboboxPlugin.List>
                {options.length === 0 && (
                  <Banner status="info">
                    <FormattedMessage
                      defaultMessage="No results found. Try a new search."
                      id="KxjDle"
                    />
                  </Banner>
                )}
                {Boolean(isLoading) && <ComboboxPlugin.Loader />}
                {options.map((option, index) => {
                  const isHighlighted = selectedIndex === index;

                  const onClick = () => {
                    selectOptionAndCleanUp(option);
                  };

                  const onMouseEnter = () => {
                    setHighlightedIndex(index);
                  };

                  return (
                    <ComboboxPlugin.ListItem
                      key={option.__data.name}
                      onClick={onClick}
                      onMouseEnter={onMouseEnter}
                      index={index}
                      isSelected={isSelected(option)}
                      option={option}
                      renderOption={({ option, isSelected }) => (
                        <QuickParticipation.ItemWrapper
                          isSelected={isSelected}
                          highlighted={isHighlighted}
                        >
                          <span className="text w-full truncate">
                            {option.__data.name}
                          </span>
                          {Boolean(isSelected) && (
                            <CheckIcon className="h-6 w-6" />
                          )}
                        </QuickParticipation.ItemWrapper>
                      )}
                    />
                  );
                })}
              </ComboboxPlugin.List>
            </ComboboxPlugin.Root>
          );
        },
      }}
      label={label}
    />
  );
}
