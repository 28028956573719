import {
  cleanEditorState,
  generateCriteriaRules,
  type GiveAwardPayload,
  logger,
  removeNewLinesAndTabs,
  sanitizeHtml,
  useGiveAwardMutation,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import {
  Button,
  isFileUploadInProgress,
  LoadingSpinner,
  useToastStore,
} from '@assembly-web/ui';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';

import { useAwardDetail, useCloseAwardsModal } from '../../Awards';
import { useTrackAction } from '../hooks/useTrackAction';

const messages = defineMessages({
  giveAwardLabel: {
    defaultMessage: 'Give award',
    id: 'tXj5MO',
  },
  saving: {
    defaultMessage: 'Submitting...',
    id: 'txkW56',
  },
  success: {
    defaultMessage: 'Award given successfully',
    id: '9CTh80',
  },
  error: {
    defaultMessage: 'Something went wrong! Please try again later.',
    id: 'PPThGC',
  },
});

export function SubmitButton() {
  const { formatMessage } = useIntl();
  const closeHandler = useCloseAwardsModal();
  const [disabled, setDisabled] = useState(false);
  const { trackAwardAction, trackAwardError } = useTrackAction();
  const { data: userDetails } = useSuspenseUserDetails();

  const { showSuccessToast, showErrorToast } = useToastStore();
  const { mutate: giveAward, isPending: isSubmitting } = useGiveAwardMutation({
    onSuccess: () => {
      showSuccessToast(formatMessage(messages.success));
      trackAwardAction('participationPosted', {
        pointsHidden:
          userDetails.assembly.settings.postPermission.canHidePoints.enabled,
      });
    },
    onError: (err, payload) => {
      showErrorToast(formatMessage(messages.error));
      const errorInfo = err instanceof Error ? err : undefined;

      logger.error(
        'Failed to give award',
        { ...payload, error: errorInfo },
        errorInfo
      );
      trackAwardError({
        errorType: 'createAwardPost',
        message: errorInfo?.message,
      });
    },
    onSettled: () => {
      closeHandler();
    },
  });
  const { getValues, handleSubmit, watch } = useFormContext();
  const awardDetail = useAwardDetail();

  useEffect(() => {
    return watch((values) => {
      if (values.attachments && values.attachments.length > 0) {
        const isInProgress = isFileUploadInProgress(values.attachments);
        setDisabled(isInProgress);
      }
    }).unsubscribe;
  }, [watch]);

  const getPayload = useCallback(() => {
    const {
      message: { html, json, plainText },
      points,
      coreValue,
      recipients,
      isPrivate,
      attachments,
    } = getValues();
    const groupedCriteria = generateCriteriaRules(recipients);
    const cleanedState = cleanEditorState({
      html,
      json,
      plainText,
    });
    return {
      awardId: awardDetail?.id,
      message: removeNewLinesAndTabs(plainText),
      messageTokens: cleanedState.json,
      messageHtml: sanitizeHtml(cleanedState.html),
      pointsEach: Number(points) || 0,
      coreValue,
      isPrivate,
      recipient: {
        criteria: groupedCriteria,
      },
      attachments,
    } as GiveAwardPayload;
  }, [getValues, awardDetail?.id]);

  const onSubmit = useCallback(() => {
    giveAward(getPayload());
  }, [giveAward, getPayload]);

  return (
    <Button
      variation="primary"
      size="large"
      className="w-fit"
      isLoading={isSubmitting}
      onClick={handleSubmit(onSubmit)}
      disabled={disabled}
    >
      {isSubmitting ? (
        <>
          <LoadingSpinner />
          {formatMessage(messages.saving)}
        </>
      ) : (
        <>
          <CheckCircleIcon className="h-4 w-4" />
          {formatMessage(messages.giveAwardLabel)}
        </>
      )}
    </Button>
  );
}
