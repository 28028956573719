import { SVGReactComponents } from '@assembly-web/assets';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { forwardRef, type ReactNode } from 'react';
import { defineMessage, useIntl } from 'react-intl';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';

const title = defineMessage({
  defaultMessage: 'You have {count} <b>pending approvals</b>',
  id: 'mnLESZ',
});

export type ApprovalBannerProps = { count: number; onClick: () => void };

export const ApprovalBanner = forwardRef<HTMLDivElement, ApprovalBannerProps>(
  function ApprovalBanner({ count, onClick }, ref) {
    const { formatMessage } = useIntl();
    return (
      <div
        className="flex w-full cursor-pointer justify-between rounded-lg bg-warning-2 p-2"
        ref={ref}
        onClick={onClick}
        role="presentation"
      >
        <div className="flex gap-2">
          <SVGReactComponents.ActionItem className="h-6 w-6 text-warning-7" />
          <TextStyle>
            {formatMessage(title, {
              count,
              b: (text: ReactNode) => (
                <TextStyle variant="sm-medium" className="inline">
                  {text}
                </TextStyle>
              ),
            })}
          </TextStyle>
        </div>
        <ChevronRightIcon className="h-6 w-6" />
      </div>
    );
  }
);
