import { CheckIcon } from '@heroicons/react/20/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import type { ChangeEvent, CSSProperties, ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../DesignSystem/Inputs/Button';
import { Checkbox } from '../../DesignSystem/Inputs/Checkbox';
import { TextField } from '../../DesignSystem/Inputs/TextField';

const messages = defineMessages({
  clearAll: {
    defaultMessage: 'Clear all',
    id: 'QW+Q5N',
  },
  done: {
    defaultMessage: 'Done',
    id: 'JXdbo8',
  },
  noResultsFound: {
    defaultMessage: 'No results for this search. Please try again.',
    id: 'AW0ulw',
  },
  clearButtonText: {
    defaultMessage: 'Clear',
    id: '/GCoTA',
  },
});

export type BaseSelectableOption = {
  id: string;
  disabled?: boolean;
};

export type SelectableOptionProps = {
  id: string;
  disabled?: boolean;
  [key: string]: string | undefined | boolean | number;
};

export type SelectableListProps<
  T extends BaseSelectableOption = SelectableOptionProps,
> = {
  isLoading: boolean;
  className?: string;
  placeholder?: string;
  showSearch?: boolean;
  hasNextPage?: boolean;
  onLoadMore?: () => void;
  showCTAs?: boolean;
  showSelectedOnTop?: boolean;
  isFetchingNextPage?: boolean;
  options: T[];
  buttonsWrapperClassName?: string;
  nestedDropdownHeader?: ReactNode;
  onSearchChange?: (value: string) => void;
  selectedOptions: T[];
  loader: ReactNode;
  renderOption: (option: T) => ReactNode;
  onDoneClick?: (currentSelectedOptions: T[]) => void;
  onClearAllClick?: () => void;
  isSingleSelect?: boolean;
  onSelect?: (selectedOption: T) => void;
  isAsync?: boolean;
  showBtnClearSingleSelect?: ReactNode;
  onClearSingleSelected?: () => void;
};

function Options<T extends BaseSelectableOption>({
  index,
  options,
  focusedOptionIndex,
  handleOptionSelect,
  renderOption,
  currentSelectedOptions,
  isSingleSelect = false,
}: {
  index: number;
  focusedOptionIndex: number;
  options: T[];
  currentSelectedOptions: T[];
  renderOption: (option: T) => ReactNode;
  handleOptionSelect: (selectedOption: T) => void;
  isSingleSelect?: boolean;
}) {
  if (index >= options.length) {
    return null;
  }

  const option = options[index];

  const isItemFocused = index === focusedOptionIndex;

  const singleSelectOptionSelected =
    currentSelectedOptions[0]?.id === option.id;

  return (
    <div
      className={twMerge(
        'flex h-[40px] cursor-pointer items-center gap-2 px-4 outline-none hover:bg-gray-3 focus:bg-gray-3',
        isItemFocused && 'bg-gray-3',
        option.disabled && 'cursor-auto'
      )}
      tabIndex={0}
      role="menuitem"
      aria-label={`selectable option - ${option.id}`}
      onKeyDown={(event) => {
        if (event.code === 'Enter' || event.code === 'Space') {
          handleOptionSelect(option);
        }
      }}
      aria-disabled={option.disabled}
      onClick={() => {
        if (!option.disabled) {
          handleOptionSelect(option);
        }
      }}
    >
      {isSingleSelect ? (
        <div className="flex w-full items-center justify-between">
          {renderOption(option)}
          {Boolean(singleSelectOptionSelected) && (
            <CheckIcon className="h-4 w-4 text-primary-6" aria-hidden="true" />
          )}
        </div>
      ) : (
        <>
          <Checkbox
            name={`${option.id}-selectable-option`}
            aria-label={`Checkbox for selectable option - ${option.id}`}
            checked={currentSelectedOptions.some(
              (selectedOption) => selectedOption.id === option.id
            )}
            onChange={() => handleOptionSelect(option)}
            tabIndex={-1}
            disabled={option.disabled}
          />
          {renderOption(option)}
        </>
      )}
    </div>
  );
}

function ListItem<T extends BaseSelectableOption>({
  index,
  style,
  data,
}: {
  index: number;
  style: CSSProperties;
  data: {
    loader: ReactNode;
    focusedOptionIndex: number;
    isFetchingNextPage?: boolean;
    options: T[];
    currentSelectedOptions: T[];
    renderOption: (option: T) => ReactNode;
    handleOptionSelect: (selectedOption: T) => void;
    isSingleSelect?: boolean;
  };
}) {
  return (
    <div style={style}>
      <Options
        index={index}
        options={data.options}
        renderOption={data.renderOption}
        handleOptionSelect={data.handleOptionSelect}
        focusedOptionIndex={data.focusedOptionIndex}
        currentSelectedOptions={data.currentSelectedOptions}
        isSingleSelect={data.isSingleSelect}
      />
      {index === data.options.length - 1 &&
        Boolean(data.isFetchingNextPage) &&
        data.loader}
    </div>
  );
}

export function SelectableList<T extends BaseSelectableOption>({
  options,
  className,
  isLoading,
  showSearch,
  onLoadMore,
  placeholder,
  hasNextPage,
  onDoneClick,
  onClearAllClick,
  onSearchChange,
  selectedOptions,
  isFetchingNextPage,
  buttonsWrapperClassName,
  showCTAs = true,
  nestedDropdownHeader,
  loader,
  renderOption,
  isSingleSelect,
  onSelect,
  isAsync,
  showBtnClearSingleSelect,
  onClearSingleSelected,
}: SelectableListProps<T>) {
  const { formatMessage } = useIntl();
  const [searchValue, setSearchValue] = useState('');
  const [currentSelectedOptions, setCurrentSelectedOptions] =
    useState(selectedOptions);

  const [optionsToRender, setOptionsToRender] = useState(options);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(-1);

  const unSelectedOptions = useMemo(
    () =>
      options.filter(
        (option) =>
          !selectedOptions.some(
            (selectedOption) => selectedOption.id === option.id
          )
      ),
    [options, selectedOptions]
  );

  useEffect(() => {
    if (selectedOptions.length) {
      const optionsToRender = [...selectedOptions, ...unSelectedOptions];
      setOptionsToRender(optionsToRender);
    } else {
      setOptionsToRender(options);
    }
  }, [options, selectedOptions, unSelectedOptions]);
  const itemSize = 40;
  const listRef = useRef<FixedSizeList | null>(null);
  const isItemLoaded = (index: number) => index < options.length;
  const itemCount = hasNextPage ? options.length + 1 : options.length;

  const handleOptionSelect = useCallback(
    (selectedOption: T) => {
      if (isSingleSelect) {
        setCurrentSelectedOptions([selectedOption]);
      } else {
        const index = currentSelectedOptions.findIndex(
          (option) => option.id === selectedOption.id
        );
        if (index !== -1) {
          const modifiedSelectedOptions = [...currentSelectedOptions];
          modifiedSelectedOptions.splice(index, 1);
          setCurrentSelectedOptions(modifiedSelectedOptions);
        } else {
          setCurrentSelectedOptions([
            ...currentSelectedOptions,
            selectedOption,
          ]);
        }
      }
      onSelect?.(selectedOption);
    },
    [currentSelectedOptions, isSingleSelect, onSelect]
  );

  const handleClearAllClick = () => {
    setOptionsToRender(options);
    setCurrentSelectedOptions([]);
    onClearAllClick?.();
  };
  const handleClearSingleSelected = () => {
    setOptionsToRender(options);
    setCurrentSelectedOptions([]);
    onClearSingleSelected?.();
  };

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        setFocusedOptionIndex((prevIndex) => {
          let optionIndex;
          if (prevIndex > 0) {
            optionIndex = prevIndex - 1;
          } else if (prevIndex === -1) {
            optionIndex = prevIndex;
          } else {
            optionIndex = 0; // don't scroll above the first item
          }
          listRef.current?.scrollToItem(
            optionIndex,
            optionIndex === optionsToRender.length - 2 ? 'end' : 'smart' // scroll to end if the second last item is focused
          );
          return optionIndex;
        });
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        setFocusedOptionIndex((prevIndex) => {
          const optionIndex =
            prevIndex < optionsToRender.length - 1
              ? prevIndex + 1
              : optionsToRender.length - 1; // don't scroll below the last item
          listRef.current?.scrollToItem(optionIndex, 'smart');
          return optionIndex;
        });
      } else if (
        (e.key === 'Enter' || e.code === 'Space') &&
        focusedOptionIndex >= 0
      ) {
        e.preventDefault();
        handleOptionSelect(optionsToRender[focusedOptionIndex]);
      }
    },
    [focusedOptionIndex, handleOptionSelect, optionsToRender]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [focusedOptionIndex, handleKeyDown, handleOptionSelect, optionsToRender]);

  const listHeight = options.length ? options.length * itemSize : itemSize;
  return (
    <section>
      <div
        className={twMerge(
          'box-border flex h-fit max-h-[240px] w-full flex-col gap-2 rounded-lg rounded-b-none bg-gray-1 py-2',
          ((!isLoading && itemCount === 0) || isLoading || isSingleSelect) &&
            'rounded-b-lg',
          className
        )}
      >
        {nestedDropdownHeader}
        {Boolean(showSearch) && (
          <div className="px-2">
            <TextField
              type="text"
              value={searchValue}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setSearchValue(e.target.value);
                onSearchChange?.(e.target.value);
              }}
              invalidText=""
              isInvalid={false}
              placeholder={placeholder}
              connectedLeft={
                <MagnifyingGlassIcon className="pointer-events-none h-4 w-4 text-gray-9" />
              }
              aria-label={placeholder}
            />
          </div>
        )}
        <div style={{ height: listHeight }} role="menu">
          <AutoSizer>
            {({ height, width }) =>
              isAsync && onLoadMore ? (
                <InfiniteLoader
                  itemCount={itemCount}
                  loadMoreItems={onLoadMore}
                  isItemLoaded={isItemLoaded}
                >
                  {({ onItemsRendered, ref }) => (
                    <FixedSizeList
                      width={width}
                      itemData={{
                        options: optionsToRender,
                        loader,
                        renderOption,
                        optionsToRender,
                        isFetchingNextPage,
                        handleOptionSelect,
                        focusedOptionIndex,
                        currentSelectedOptions: onSelect
                          ? selectedOptions
                          : currentSelectedOptions,
                        isSingleSelect,
                      }}
                      height={height}
                      itemSize={itemSize}
                      itemCount={itemCount}
                      onItemsRendered={onItemsRendered}
                      ref={(list) => {
                        listRef.current = list;
                        ref(list);
                      }}
                      style={{ overflowX: 'hidden' }}
                    >
                      {ListItem<T>}
                    </FixedSizeList>
                  )}
                </InfiniteLoader>
              ) : (
                <FixedSizeList
                  width={width}
                  itemData={{
                    options: optionsToRender,
                    loader,
                    renderOption,
                    optionsToRender,
                    isFetchingNextPage,
                    handleOptionSelect,
                    focusedOptionIndex,
                    currentSelectedOptions: onSelect
                      ? selectedOptions
                      : currentSelectedOptions,
                    isSingleSelect,
                  }}
                  height={height}
                  itemSize={itemSize}
                  itemCount={itemCount}
                  ref={(list) => {
                    listRef.current = list;
                  }}
                  style={{ overflowX: 'hidden' }}
                >
                  {ListItem<T>}
                </FixedSizeList>
              )
            }
          </AutoSizer>
          {Boolean(isLoading) && itemCount === 0 && (
            <div className="px-2">{loader}</div>
          )}
          {itemCount === 0 && !isLoading && (
            <div role="menuitem">
              <TextStyle variant="sm-regular" className="px-6 pb-2 text-gray-8">
                {formatMessage(messages.noResultsFound)}
              </TextStyle>
            </div>
          )}
        </div>
      </div>
      {Boolean(showBtnClearSingleSelect) && (
        <div className="p-2">
          <Button
            size="small"
            variation="secondaryLite"
            onClick={handleClearSingleSelected}
            disabled={currentSelectedOptions.length === 0}
            className="w-full"
          >
            {formatMessage(messages.clearButtonText)}
          </Button>
        </div>
      )}
      {Boolean(showCTAs) && !isSingleSelect && itemCount > 0 && (
        <div
          className={twMerge(
            'grid w-full grid-cols-[1fr_auto] justify-between gap-2 rounded-b-lg bg-gray-1 p-2',
            buttonsWrapperClassName
          )}
        >
          <Button
            variation="secondaryEmphasized"
            onClick={() => onDoneClick?.(currentSelectedOptions)}
            size="small"
          >
            {formatMessage(messages.done)}
          </Button>
          <Button
            size="small"
            variation="secondaryLite"
            onClick={handleClearAllClick}
            disabled={currentSelectedOptions.length === 0}
          >
            {formatMessage(messages.clearAll)}
          </Button>
        </div>
      )}
    </section>
  );
}
