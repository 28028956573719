import {
  isTruthy,
  isUserAdmin,
  isUserManager,
  MemberStatus,
  userAuthStore,
  useRecognitionRoute,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import {
  PointsWidget,
  useDeviceInfo,
  useRefSetterContext,
} from '@assembly-web/ui';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { createPortal } from 'react-dom';
import { type UIMatch, useMatches, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { useIsRecognitionOnlyCustomer } from '../../../hooks/useIsRecognitionOnlyCustomer';
import { TeamInsightsWidget } from '../../../modules/discover/components/TeamInsightsWidget';
import { useOpenRecognitionParticipation } from '../../../modules/discover/hooks/participation/useOpenRecognitionParticipation';
import { trackDiscoverAction } from '../../../modules/discover/services/analytics';
import { LeaderBoard } from '../../../modules/recognition/LeaderBoard/LeaderBoard';
import { LeaderBoardTips } from '../../../modules/recognition/LeaderBoard/LeaderBoardTips';
import { useRewardsDetails } from '../../../modules/rewards/hooks/useRewardsDetails';

function useWidgetsVisibility() {
  const matches = useMatches();
  const { data: userDetails } = useSuspenseUserDetails();

  const workspaceSlugPath = userDetails.assembly.workspaceSlugPath;
  const isRecognitionOnly = useIsRecognitionOnlyCustomer();
  const canSendRecognition =
    userDetails.member.status == MemberStatus.Normal ||
    userDetails.member.status == MemberStatus.Giver;

  const { hasAllowance, hasEarnedPoints } = useRewardsDetails();
  const isRecognitionMatch = useRecognitionRoute();

  const { isMobileAppV3: isNativeMobileApp } = userAuthStore.getState();

  const showPointsWidget = useMemo(() => {
    const lastMatch = matches[matches.length - 1] as UIMatch<
      unknown,
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
      unknown | { route: 'recognition' }
    >;

    return (
      isTruthy(hasEarnedPoints || hasAllowance) &&
      ([
        'activity',
        'activity/unreads',
        'flows',
        'challenges',
        'people',
        'files',
        'rewards',
        'folders',
        `${workspaceSlugPath}/flows/recognition`,
        '', //for index page
      ].some((path) => lastMatch.pathname === `/a/${path}`) ||
        isRecognitionMatch)
    );
  }, [
    isRecognitionMatch,
    matches,
    workspaceSlugPath,
    hasEarnedPoints,
    hasAllowance,
  ]);

  const isAdmin = Boolean(isUserAdmin(userDetails.member));
  const isManager = Boolean(isUserManager(userDetails.member));

  const showLeaderBoardWidget = useMemo(() => {
    const lastMatch = matches[matches.length - 1].pathname;
    return (
      isAdmin &&
      ((!isRecognitionOnly && isRecognitionMatch) ||
        (isRecognitionOnly && lastMatch === '/a/')) &&
      !isNativeMobileApp
    );
  }, [
    matches,
    isAdmin,
    isRecognitionOnly,
    isRecognitionMatch,
    isNativeMobileApp,
  ]);

  const showTeamInsightsWidget = useMemo(() => {
    const lastMatch = matches[matches.length - 1].pathname;
    const enabledOnPage =
      lastMatch === '/a/' ||
      lastMatch === `/a/${workspaceSlugPath}/flows/recognition`;

    return (
      enabledOnPage &&
      isManager &&
      Boolean(canSendRecognition) &&
      !isNativeMobileApp
    );
  }, [
    matches,
    workspaceSlugPath,
    isManager,
    canSendRecognition,
    isNativeMobileApp,
  ]);

  const showLeaderBoardTipsWidget = isRecognitionMatch && !isNativeMobileApp;

  return {
    showPointsWidget,
    showTeamInsightsWidget,
    showLeaderBoardWidget,
    showLeaderBoardTipsWidget,
  };
}

function SecondaryContentSectionImpl({ className }: { className?: string }) {
  const {
    showPointsWidget,
    showTeamInsightsWidget,
    showLeaderBoardWidget,
    showLeaderBoardTipsWidget,
  } = useWidgetsVisibility();

  const { isMobileDevice } = useDeviceInfo();

  const navigate = useNavigate();

  const recognize = useOpenRecognitionParticipation();

  const {
    data: {
      assembly: {
        settings: {
          allowance: { nextCycleDate },
        },
      },
    },
  } = useSuspenseUserDetails();

  const {
    earnedBalance,
    spentAllowance,
    totalAllowance,
    currency,
    hasAllowance,
    isRewardsSetupForUser,
    hasEarnedPoints,
    remainingAllowance,
  } = useRewardsDetails();

  const navigateToGiveRecognition = () => {
    trackDiscoverAction('widgetGiveRecognitionClicked');

    recognize();
  };

  return (
    <aside className={twMerge('flex flex-col gap-4 lg:gap-6', className)}>
      {isTruthy(showPointsWidget) && (
        <PointsWidget
          nextCycleDate={dayjs(nextCycleDate)}
          currentDate={dayjs()}
          variant={isMobileDevice ? 'compact' : 'full'}
          earnedBalance={earnedBalance}
          spentAllowance={spentAllowance}
          totalAllowance={totalAllowance}
          currency={currency}
          hasAllowance={hasAllowance}
          canRedeem={isRewardsSetupForUser}
          remainingAllowance={remainingAllowance}
          hasEarnedPoints={hasEarnedPoints}
          onBrowseRewardsClick={() => {
            trackDiscoverAction('browseRewardsClicked', {
              v3enabled: true,
            });
            trackDiscoverAction('widgetBrowseRewardsClicked');
            navigate('/a/rewards');
          }}
          navigateToGiveRecognition={navigateToGiveRecognition}
        />
      )}
      {Boolean(showLeaderBoardWidget) && <LeaderBoard />}
      {Boolean(showLeaderBoardTipsWidget) && <LeaderBoardTips />}
      {Boolean(showTeamInsightsWidget) && <TeamInsightsWidget />}
    </aside>
  );
}

export function SecondaryContentSection({ className }: { className?: string }) {
  const ref = useRefSetterContext();

  if (!ref?.[0]) {
    return <SecondaryContentSectionImpl className={className} />;
  }

  return createPortal(
    <SecondaryContentSectionImpl className={className} />,
    ref[0]
  );
}
