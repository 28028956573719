import {
  generateAggregates,
  type SearchIndexApiResponse,
  type SearchParams,
  type UserDetails,
  useSearchIndex as useSearchIndexImpl,
} from '@assembly-web/services';
import { convertPostDataToFlowPostFormat } from '@assembly-web/ui';
import { useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

export const useSearchIndex = (payload: SearchParams) => {
  const queryClient = useQueryClient();

  const { formatMessage } = useIntl();

  return useSearchIndexImpl(payload, {
    select: (data) => {
      const userDetails: UserDetails | undefined = queryClient.getQueryData([
        'userDetails',
      ]);
      data.pages.forEach((page, index) => {
        if (index === 0) {
          const metadata = page.data.metadata;
          page.data.metadata = {
            ...page.data.metadata,
            aggregations: generateAggregates(metadata),
          };
        }
      });
      const modifiedPagesData: SearchIndexApiResponse[] = data.pages.map(
        (page) => {
          return {
            ...page,
            data: {
              ...page.data,
              data: page.data.data.map((searchResult) => {
                return searchResult.type === 'post' && searchResult.cardDetails
                  ? {
                      ...searchResult,
                      cardDetails: searchResult.cardDetails.post
                        ? {
                            ...convertPostDataToFlowPostFormat(
                              {
                                ...searchResult.cardDetails.post,
                                currencyName:
                                  userDetails?.assembly.currency.pluralName ??
                                  '',
                              },
                              formatMessage
                            ),
                            replySummary: searchResult.cardDetails.replySummary,
                          }
                        : searchResult.cardDetails,
                    }
                  : searchResult;
              }),
            },
          };
        }
      );
      return {
        pages: modifiedPagesData,
        pageParams: data.pageParams,
      };
    },
  });
};
