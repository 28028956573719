import { FormattedMessage } from 'react-intl';

import { Banner } from '../../DesignSystem/Feedback/Banner';

export function NoUsersFound() {
  return (
    <Banner status="info">
      <FormattedMessage
        defaultMessage="No results found. Try a new search or go to Admin > Users to invite them."
        id="wfl8ZC"
      />
    </Banner>
  );
}

export function NoUsersInWorkspace() {
  return (
    <Banner status="info">
      <FormattedMessage
        defaultMessage="You are the only person in this assembly. Go to Admin > Users to invite teammates."
        id="E1KMsj"
      />
    </Banner>
  );
}
