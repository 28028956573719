import {
  useSuspenseUserDetails,
  type WorkspaceSettings,
} from '@assembly-web/services';
import {
  Chip,
  CoreValueContainer,
  CoreValueSelector,
  Tooltip,
} from '@assembly-web/ui';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { Controller, useFormContext } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';

import { useTrackAction } from '../hooks/useTrackAction';

const messages = defineMessages({
  label: {
    defaultMessage: 'Core value',
    id: '+hmbqD',
  },
  requiredValidation: {
    defaultMessage: 'You are required to select a core value.',
    id: '+cid17',
  },
  tooltip: {
    defaultMessage:
      'Core value has been set by the award creator and can’t be edited.',
    id: 'HAFNEX',
  },
});

function Selector({
  coreValues,
}: {
  coreValues: WorkspaceSettings['coreValues'];
}) {
  const { formatMessage } = useIntl();
  const { control } = useFormContext();
  const { trackAwardAction } = useTrackAction();
  return control._defaultValues.coreValue ? (
    <Tooltip tooltipText={formatMessage(messages.tooltip)}>
      <Chip.Root intent="badge" color="purple" isDisabled={true}>
        <Chip.Text className="flex items-center gap-1 text-gray-9">
          {control._defaultValues.coreValue}
          <LockClosedIcon className="h-4 w-4 text-gray-8" />
        </Chip.Text>
      </Chip.Root>
    </Tooltip>
  ) : (
    <Controller
      name="coreValue"
      control={control}
      render={({
        field: { onBlur, onChange, value },
        fieldState: { error },
      }) => (
        <CoreValueSelector
          label={formatMessage(messages.label)}
          onChange={onChange}
          error={error}
          coreValues={coreValues}
          defaultValue={control._defaultValues.coreValue}
          onBlur={() => {
            onBlur();
            if (value.length > 0) {
              trackAwardAction('coreValueSelected');
            }
          }}
        />
      )}
    />
  );
}

export function CoreValue() {
  const {
    data: {
      assembly: {
        settings: { coreValues },
      },
    },
  } = useSuspenseUserDetails();
  if (!coreValues.enabled) {
    return null;
  }

  return (
    <CoreValueContainer>
      <Selector coreValues={coreValues} />
    </CoreValueContainer>
  );
}
