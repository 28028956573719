import parse from 'html-react-parser';

import { mapHexCodeToEmoticon } from './flowUtils';
import { type AssemblyCurrency, AssemblyCurrencyType } from './types/currency';

export function getIconElement(currency: AssemblyCurrency | undefined) {
  const icon = (() => {
    if (currency) {
      const { value, type, name } = currency;
      return type === AssemblyCurrencyType.Custom
        ? `<img alt=${name} class="mr-0.5 h-3 w-3 inline-block" src=${value} />`
        : mapHexCodeToEmoticon(value);
    }

    return mapHexCodeToEmoticon('1f3c6');
  })();

  return parse(icon);
}
