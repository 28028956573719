import { config, useSuspenseUserDetails } from '@assembly-web/services';
import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  name: {
    defaultMessage: 'Give Recognition',
    id: 'CPVG7R',
  },
  description: {
    defaultMessage: 'Recognize someone for doing something great!',
    id: 'g8V+PD',
  },
  fullscreenTooltip: {
    defaultMessage: 'Enter full screen mode',
    id: 'yPPS2Z',
  },
  recognizeWithAiButton: {
    defaultMessage: 'Draft with DoraAI',
    id: 'EkhIrd',
  },
  recognizeWithAiTooltip: {
    defaultMessage: 'Generate your entire recognition post using DoraAI',
    id: 'zQTji8',
  },
});

export function useGiveRecognitionFlowDetails(redirectUrl = '/a/discover') {
  const { formatMessage } = useIntl();
  const { data: userDetails } = useSuspenseUserDetails();

  const workspaceSlugPath = userDetails.assembly.workspaceSlugPath;

  const id = 'recognition';

  const feedLink = `/a/${workspaceSlugPath}/flows/${id}`;

  const link = `${feedLink}/answer?redirectUrl=${config.domains.app}${redirectUrl}`;
  const getEditLink = useCallback(
    (postId: string) =>
      `${feedLink}/edit/${postId}?redirectUrl=${config.domains.app}${redirectUrl}`,
    [feedLink, redirectUrl]
  );

  return useMemo(
    () => ({
      data: {
        link,
        feedLink,
        getEditLink,
        flowDetails: {
          id,
          name: formatMessage(messages.name),
          description: formatMessage(messages.description),
          icon: {
            kind: 'HEX_CODE',
            value: '1F389',
          },
        } as const,
        fullScreenTooltip: formatMessage(messages.fullscreenTooltip),
        recognizeWithAiButton: formatMessage(messages.recognizeWithAiButton),
        recognizeWithAiTooltip: formatMessage(messages.recognizeWithAiTooltip),
      },
    }),
    [feedLink, formatMessage, link, getEditLink]
  );
}
