import {
  type InfiniteData,
  type UndefinedInitialDataInfiniteOptions,
  useInfiniteQuery,
} from '@tanstack/react-query';

import { APIEndpoints } from '../../APIEndpoints';
import { assemblyAPI } from '../../assemblyAPI';
import type { MetadataProps } from '../../types/domain';
import type { MemberDetails } from '../../types/flowTypes';

type UseDepartmentMembers<TData> = {
  criteriaId?: string;
  group?: string;
  postId?: string;
  keyword?: string;
  options?: Partial<
    Partial<
      UndefinedInitialDataInfiniteOptions<Response, Error, InfiniteData<TData>>
    >
  >;
};

type Response = {
  total: number;
  data: MemberDetails[];
  metadata: MetadataProps;
};

export const useDepartmentMembers = <TData = Response>({
  criteriaId,
  keyword,
  group,
  postId,
  options,
}: UseDepartmentMembers<TData>) => {
  return useInfiniteQuery({
    ...options,
    queryKey: ['departmentMembers', postId, group, criteriaId, keyword],
    queryFn: async ({ pageParam, signal }) => {
      const response = await assemblyAPI.get<Response>(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        APIEndpoints.getPostDepartmentMembers(postId!),
        {
          signal,
          params: {
            criteriaId,
            group,
            cursor: pageParam,
            keyword,
          },
        }
      );

      return response.data;
    },
    initialPageParam: undefined,
    getNextPageParam: (page: Response) => {
      return page.metadata.pagination.cursor.next;
    },
  });
};
