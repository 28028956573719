import { type MutationOptions, useMutation } from '@tanstack/react-query';

import { APIEndpoints } from '../../../APIEndpoints';
import { assemblyAPI } from '../../../assemblyAPI';
import type { GiveAwardPayload } from '../../../types/awards';

export function useGiveAwardMutation(
  options?: MutationOptions<unknown, unknown, GiveAwardPayload>
) {
  return useMutation({
    mutationFn: async (payload: GiveAwardPayload) => {
      return assemblyAPI.post(APIEndpoints.giveAward, payload);
    },
    onSuccess: (...args) => {
      options?.onSuccess?.(...args);
    },
    onError: (...args) => {
      options?.onError?.(...args);
    },
    onSettled: (...args) => {
      options?.onSettled?.(...args);
    },
  });
}
