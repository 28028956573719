import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  Content,
  Item,
  ItemIndicator,
  Portal,
  Root,
  Trigger,
} from '@radix-ui/react-select';
import { type ReactNode, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { Button, TextStyle } from '../..';

const messages = defineMessages({
  clear: {
    id: '/GCoTA',
    defaultMessage: 'Clear',
  },
});

export type SelectOption<T> = {
  id: string;
  value: string;
  icon?: ReactNode;
  [key: string]: T | ReactNode;
};

export type SelectDropdownProps = {
  label: string;
  value: string;
  showClearButton?: boolean;
  options: SelectOption<number>[];
  onSelect: (value: string) => void;
  placeholderClassName?: string;
  buttonTriggerClassName?: string;
  contentClassName?: string;
  isCustomValueDisplay?: boolean; // Use it for where a custom selected value needs to be displayed. Ex: Selected option A will display as 'A' instead of the old format 'label: value.'
  renderOption: (option: SelectOption<number>) => ReactNode;
  variant?: 'primary' | 'secondary';
  valueClassName?: string;
};

const SelectDropdownContent = ({ children }: { children: ReactNode }) => {
  return children;
};

SelectDropdownContent.displayName = 'SelectDropdownContent';

SelectDropdown.Content = SelectDropdownContent;

const SelectDropdownFooter = ({ children }: { children: ReactNode }) => {
  return children;
};

SelectDropdownFooter.displayName = 'SelectDropdownFooter';

SelectDropdown.Footer = SelectDropdownFooter;

export function SelectDropdown({
  label,
  value,
  options,
  onSelect,
  renderOption,
  showClearButton,
  buttonTriggerClassName = '',
  placeholderClassName = '',
  contentClassName = '',
  isCustomValueDisplay,
  variant = 'primary',
  valueClassName = '',
}: SelectDropdownProps) {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState<boolean>(false);

  const showValueDisplay = () => {
    return isCustomValueDisplay ? `${value}` : `${label}: ${value}`;
  };
  return (
    <Root
      value={value}
      onValueChange={(option) => onSelect(option)}
      open={open}
      onOpenChange={setOpen}
    >
      <Trigger
        className={twMerge(
          'group',
          variant === 'primary' ? 'focus-visible:rounded-lg' : 'rounded-full',
          buttonTriggerClassName
        )}
        aria-label="trigger"
      >
        <div
          className={twMerge(
            'flex max-w-[280px] items-center gap-2 rounded-lg border border-gray-5 bg-gray-1 px-2 py-1 align-middle hover:bg-gray-3 focus:bg-gray-3',
            value && 'bg-gray-3 hover:bg-gray-4 focus:bg-gray-4',
            variant === 'secondary' &&
              'rounded-full bg-primary-1 hover:bg-primary-2 focus:border-primary-4 focus:bg-primary-2 group-aria-expanded:border-primary-4',
            placeholderClassName
          )}
        >
          {variant === 'primary' ? (
            <TextStyle
              as="p"
              variant={value ? 'sm-medium' : 'sm-regular'}
              className={twMerge('truncate text-gray-9', valueClassName)}
            >
              {value ? showValueDisplay() : label}
            </TextStyle>
          ) : (
            <>
              {options.find((option) => option.value === value)?.icon}
              <TextStyle
                as="p"
                variant="sm-medium"
                className="truncate text-gray-9"
              >
                {options.find((option) => option.value === value)?.label}
              </TextStyle>
            </>
          )}

          <ChevronDownIcon
            color="gray-9"
            className="h-4 w-4 text-gray-9 group-aria-expanded:rotate-180"
          />
        </div>
      </Trigger>
      <Portal>
        <Content
          align="start"
          sideOffset={4}
          className={twMerge(
            'z-20 rounded-lg border border-gray-4 bg-gray-1 p-0 shadow-lg-down',
            contentClassName
          )}
          position="popper"
        >
          {options.map((option) => {
            return (
              <Item
                className={twMerge(
                  'flex h-[40px] min-w-[222px] cursor-pointer items-center justify-between gap-2 rounded-md pl-4 pr-3 outline-none hover:bg-gray-3 focus:bg-gray-3'
                )}
                key={option.id}
                value={option.value}
              >
                <TextStyle className="flex w-full items-center gap-1 truncate align-middle text-gray-8">
                  {renderOption(option)}
                </TextStyle>
                <ItemIndicator className="absolute right-2 inline-flex w-[25px] items-center justify-center">
                  <CheckIcon
                    className="h-4 w-4 text-primary-6"
                    aria-hidden="true"
                  />
                </ItemIndicator>
              </Item>
            );
          })}
          {showClearButton ? (
            <Button
              size="small"
              variation="secondaryLite"
              disabled={value === ''}
              className={twMerge(
                'm-2 grid grid-cols-[1fr_auto] justify-between gap-2 rounded-b-lg bg-gray-1'
              )}
              onClick={() => {
                onSelect('');
                setOpen(false);
              }}
            >
              {formatMessage(messages.clear)}
            </Button>
          ) : null}
        </Content>
      </Portal>
    </Root>
  );
}
