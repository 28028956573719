import type { MemberAPIResponse } from './types/domain';
import { isUserAdmin } from './userUtils';

export function getCanUserCreateFlows(data: MemberAPIResponse) {
  const userFlowPermissions = data.assembly.flow.creation.value;
  const isAdmin = Boolean(isUserAdmin(data.member));
  const isManager = data.member.profile.isManager;

  if (isAdmin) {
    return true;
  }

  switch (userFlowPermissions) {
    case 'EVERYONE':
      return true;
    case 'MANAGER':
      return isManager;
    default:
      return false;
  }
}

export function getWorkspaceSlugPath(data: MemberAPIResponse) {
  return `${data.assembly.workspaceSlug.name}-${data.assembly.workspaceSlug.shortCode}`;
}

export function getCanUserInviteNewUsers(data: MemberAPIResponse) {
  const { value: accessLevels, enabled } =
    data.assembly.settings.userManagement.inviteAccess;

  if (!enabled) {
    return false;
  }
  const isAdmin = isUserAdmin(data.member);

  const isManager = data.member.profile.isManager;

  if (accessLevels.includes('EVERYONE')) {
    return true;
  } else if (accessLevels.includes('ADMINS_AND_OWNER')) {
    return isAdmin;
  } else if (accessLevels.includes('MANAGER')) {
    return isManager;
  }

  return false;
}

export const getHasProfanityTurnedOn = (profileData: MemberAPIResponse) => {
  const { assembly } = profileData;
  const { settings } = assembly;
  const { blockWordsMessage } = settings;
  const { enabled, value } = blockWordsMessage;
  return { enabled, wordsToCheck: value };
};

export function getCanUserPostAnnouncements(data: MemberAPIResponse) {
  const isAdmin = isUserAdmin(data.member);
  const isManager = data.member.profile.isManager;

  if (!data.assembly.flow.announcementPermission.enabled) {
    return false;
  }

  const announcementPermissionLevel =
    data.assembly.flow.announcementPermission.value;

  const canOwnFlows =
    data.assembly.flow.creation.enabled && getCanUserCreateFlows(data);

  return (
    announcementPermissionLevel === 'EVERYONE' ||
    (announcementPermissionLevel === 'MANAGER' && isManager) ||
    (announcementPermissionLevel === 'ADMINS_AND_OWNER' &&
      (isAdmin || canOwnFlows))
  );
}
