import { type Assembly, removeNewLinesAndTabs } from '@assembly-web/services';
import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  blockedWords: {
    defaultMessage: 'Your message contains restricted language.',
    id: 'd7QuGt',
  },
  minLength: {
    defaultMessage:
      'Your message has not met the minimum character amount. Please add {length, number} more character(s)',
    id: 'o+uLFn',
  },
});

export const useOpenEndedValidation = ({
  assemblyDetails,
}: {
  assemblyDetails: Assembly;
}) => {
  const { formatMessage } = useIntl();

  const validationOptions = useMemo(() => {
    const blockWordsMessage = assemblyDetails.settings.blockWordsMessage;
    const blockedWords = blockWordsMessage.enabled
      ? blockWordsMessage.value
      : [];

    const minLength = assemblyDetails.settings.minMessageChar;
    const minLengthValue = minLength.enabled
      ? Number.isNaN(minLength.value)
        ? 1
        : minLength.value
      : 1;
    return {
      blockedWords: (value?: { plainText: string }) => {
        const regExp = new RegExp(`(?:${blockedWords.join('|')})`, 'gi');

        return blockedWords.length === 0 || !value?.plainText
          ? true
          : value.plainText.match(regExp)
            ? formatMessage(messages.blockedWords)
            : true;
      },
      minLength: (value?: { plainText: string }) => {
        if (!value?.plainText) {
          return formatMessage(messages.minLength, {
            length: minLengthValue,
          });
        }

        const formattedValueWithoutGif = removeNewLinesAndTabs(value.plainText)
          .replace(/([%@]__[\w ]+__[%@])/gi, '')
          .trim();
        const valueLength = formattedValueWithoutGif.length;
        if (!minLengthValue) {
          return valueLength > 0
            ? true
            : formatMessage(messages.minLength, { length: 1 });
        }
        return valueLength >= minLengthValue
          ? true
          : formatMessage(messages.minLength, {
              length: minLengthValue - valueLength,
            });
      },
    };
  }, [assemblyDetails, formatMessage]);

  return {
    validate: validationOptions,
  };
};
