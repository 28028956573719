import type { UseMutationResult } from '@tanstack/react-query';

import type { Member } from './domain';

export type PostAward = {
  template: {
    message: string;
    messageTokens: string;
    messageHtml: string;
  };
  coreValue?: string;
  useAwardMessage: boolean;
  images?: {
    name: string;
    size: number;
    location: string;
    originalName: string;
    createdAt: string;
    type: string;
    _id: string;
    thumbnails: {
      '32': string;
      '256': string;
      '512': string;
    };
    original: {
      location: string;
      name: string;
      size: number;
    };
  }[];
};
export type RefreshCycle =
  | 'DAILY'
  | 'WEEKLY'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'YEARLY';

export type AwardDetailInfo = {
  name: string;
  awardId: string;
  post?: PostAward;
  budget: {
    refreshCycle: RefreshCycle;
    totalPoints: number;
    unitPoints?: number;
    maxPoints?: number;
  };
  giver: {
    criteria: {
      include: [];
      exclude?: [];
    };
  };
  approvals: {
    autoApprove: boolean;
    levels: {
      number: number;
      criteria: {
        include: [];
        exclude?: [];
      };
    }[];
  };
};
export type GetAwardDetailsResponse = AwardDetailInfo;

export type ApprovalInfo = {
  awardApprovalId: string;
  awardId: string;
  awardName: string;
  awardDescription: {
    message: string;
    messageHtml: string;
    messageTokens: string;
  };
  createdBy: Member;
  isPrivate: boolean;
  post: {
    message: string;
    messageTokens: string;
    messageHtml: string;
    coreValue?: string;
    image?: {
      location: string;
      name: string;
      size: number;
    };
    pointsEach: number;
  };
  state: 'PENDING' | 'APPROVED' | 'DENIED';
  recipient?: {
    criteriaId: string;
    totalCount: number;
    everyone?: {
      count: number;
    };
    departments?: {
      name: string;
      count: number;
    }[];
    individuals?: Member[];
  };
  approvals: {
    autoApprove: boolean;
    levels: {
      total: number;
      current: number;
    };
  };
  canApprove: boolean;
  createdAt: string;
  approvedAt?: string;
  deniedAt?: string;
};

export enum ApprovalStatusOption {
  Approved = 'approved',
  Pending = 'pending',
  Denied = 'denied',
  All = 'all',
}

export type ApproveDenyAwardPayload = {
  awardApprovalId: string;
  action: 'approve' | 'deny';
};

export type SubmitApprovalOptions = UseMutationResult<
  unknown,
  Error,
  ApproveDenyAwardPayload
>;
