import {
  cleanEditorState,
  type EditRecognitionPayload,
  EntityType,
  fetchRecognitionDraftKey,
  generateCriteriaRules,
  logger,
  removeNewLinesAndTabs,
  sanitizeHtml,
  trackParticipationAction,
  trackParticipationError,
  trackRecognitionParticipationAction,
  useEditRecognitionPost,
  useSaveDrafts,
  useSaveRecognitionPost,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import {
  Button,
  isFileUploadInProgress,
  LoadingSpinner,
  TextStyle,
  useDeviceInfo,
  useOpenEndedFileUpload,
  useToastStore,
} from '@assembly-web/ui';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';

import { useGiveRecognitionFlowDetails } from '../../hooks/useGiveRecognitionFlowDetails';
import { useParticipationModalContext } from '../../Provider';

const messages = defineMessages({
  success: {
    defaultMessage: 'Recognition posted successfully!',
    id: 'iWY56R',
  },
  editSuccessFull: {
    defaultMessage: 'Post Updated!',
    id: '2BW+sF',
  },
  editError: {
    defaultMessage: 'Sorry, error in editing recognition. Please try again.',
    id: 'mAIgNq',
  },
  error: {
    defaultMessage: 'Sorry, error in posting recognition. Please try again.',
    id: 'I6fOJO',
  },
  editing: {
    defaultMessage: 'Editing...',
    id: '6CJjWk',
  },
  saving: {
    defaultMessage: 'Saving...',
    id: 'TiR/Hq',
  },
});

export function SubmitButton() {
  const {
    handleSubmit,
    getValues,
    control,
    watch,
    reset: resetForm,
  } = useFormContext();
  const { closeModal, formState } = useParticipationModalContext();
  const { showSuccessToast, showErrorToast } = useToastStore();
  const { data: userDetails } = useSuspenseUserDetails();
  const { formatMessage } = useIntl();
  const isEditFlow = Boolean(formState?.postId);
  const [disabled, setDisabled] = useState(false);
  const { fileUploadOptions } = useOpenEndedFileUpload({
    key: 'recognition-participation',
  });

  const {
    data: {
      flowDetails: { name },
    },
  } = useGiveRecognitionFlowDetails();
  const { mutate: clearDraft } = useSaveDrafts();

  const { mutate: savePost, status: saveStatus } = useSaveRecognitionPost({
    options: {
      onSuccess: () => {
        showSuccessToast(formatMessage(messages.success));
        clearDraft({
          payload: {
            toCache: {
              entityType: EntityType.Recognition,
              postData: {},
            },
            toServer: {
              entityType: EntityType.Recognition,
              postData: {},
            },
          },
          queryCacheToUpdate: fetchRecognitionDraftKey,
        });
        trackParticipationAction('participationPosted', {
          postType: 'recognition',
          pointsHidden:
            userDetails.assembly.settings.postPermission.canHidePoints.enabled,
        });
        trackRecognitionParticipationAction({
          threadId: formState?.threadId,
          pointsHidden:
            userDetails.assembly.settings.postPermission.canHidePoints.enabled,
        });
      },
      onError: (err, payload) => {
        const errorInfo = err instanceof Error ? err : undefined;
        showErrorToast(formatMessage(messages.error));
        logger.error('Failed to create recognition post', payload, errorInfo);
        trackParticipationError({
          errorType: 'createPost',
          message: errorInfo?.message,
        });
      },
      onSettled: () => {
        closeModal();
        fileUploadOptions.clear();
        resetForm();
      },
    },
  });

  const { mutate: editPost, status: editStatus } = useEditRecognitionPost({
    options: {
      onSuccess: () => {
        showSuccessToast(formatMessage(messages.editSuccessFull));
        trackParticipationAction('editPostSaved', {
          postType: 'recognition',
        });
      },
      onError: (err, payload) => {
        const errorInfo = err instanceof Error ? err : undefined;
        showErrorToast(formatMessage(messages.editError));
        logger.error('Failed to edit recognition post', payload, errorInfo);
        trackParticipationError({
          errorType: 'editPost',
          message: errorInfo?.message,
        });
      },
      onSettled: () => {
        closeModal();
        fileUploadOptions.clear();
        resetForm();
      },
    },
  });
  const isLoading = editStatus === 'pending' || saveStatus === 'pending';

  const getPayload = useCallback(() => {
    const {
      message: { html, json, plainText, mentionIds },
      points: pointsEach = 0,
      coreValue,
      isPrivate,
      recipients,
      attachments,
    } = getValues();
    const groupedCriteria = generateCriteriaRules(
      isEditFlow ? control._defaultValues.recipients : recipients
    );

    const cleanedState = cleanEditorState({
      html,
      json,
      plainText,
    });

    return {
      message: removeNewLinesAndTabs(plainText),
      messageTokens: cleanedState.json,
      messageHtml: sanitizeHtml(cleanedState.html),
      mention: {
        memberIds: mentionIds,
      },
      pointsEach:
        (isEditFlow ? control._defaultValues.points : pointsEach) || 0,
      coreValue,
      isPrivate,
      recipient: {
        criteria: groupedCriteria,
      },
      postId: isEditFlow ? formState?.postId : '',
      attachments,
      threadId: formState?.threadId,
    };
  }, [isEditFlow, getValues, control, formState?.postId, formState?.threadId]);

  const onSubmit = useMemo(() => {
    return handleSubmit(() => {
      const payload = getPayload();
      const request = {
        toServer: { payload },
        toCache: {
          recipient: isEditFlow
            ? control._defaultValues.recipients
            : getValues().recipients,
        },
      };

      if (isEditFlow && payload.postId) {
        editPost(request as EditRecognitionPayload);
      } else {
        savePost(request);
      }
    });
  }, [
    getPayload,
    handleSubmit,
    isEditFlow,
    editPost,
    savePost,
    control,
    getValues,
  ]);

  useEffect(() => {
    return watch((values) => {
      if (values.attachments && values.attachments.length > 0) {
        const isInProgress = isFileUploadInProgress(values.attachments);
        setDisabled(isInProgress);
      }
    }).unsubscribe;
  }, [watch]);

  return (
    <Button
      onClick={onSubmit}
      isLoading={isLoading}
      disabled={disabled}
      variation="primary"
      size="large"
      isFullWidth={useDeviceInfo().isMobileDevice}
    >
      {isLoading ? (
        <>
          <LoadingSpinner />
          <TextStyle variant="sm-regular">
            {isEditFlow
              ? formatMessage(messages.editing)
              : formatMessage(messages.saving)}
          </TextStyle>
        </>
      ) : (
        <>
          <span className="h-4 w-4">
            <CheckCircleIcon />
          </span>
          {name}
        </>
      )}
    </Button>
  );
}
