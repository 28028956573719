import {
  APIEndpoints,
  assemblyAPI,
  type FileDetails,
} from '@assembly-web/services';
import { PaperClipIcon } from '@heroicons/react/24/outline';
import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { Tooltip } from '../../../../DesignSystem/Feedback/Tooltip';
import { useFileUpload } from '../../../Shared/FileUpload/useFileUpload';
import { useToastStore } from '../../../Shared/Toast/useToastStore';
import type { RecognitionFileUploadKey } from '../types';

const messages = defineMessages({
  attachmentLimit: {
    defaultMessage: 'File attachment limit is 15 files',
    id: 'x++T2n',
  },
});

export const useOpenEndedFileUpload = ({
  key,
  draftFiles,
}: {
  key: RecognitionFileUploadKey;
  draftFiles?: FileDetails[];
}) => {
  const { formatMessage } = useIntl();
  const { showErrorToast } = useToastStore();

  const fileUploadOptions = useFileUpload({
    key: key,
    subTitle: '',
    title: '',
    uploadOptions: {
      autoUpload: true,
      maxNumberOfFiles: 15,
      allowMultipleFiles: true,
      maxFileSize: 1024 * 1024 * 1024,
      maxTotalFileSize: 1024 * 1024 * 1024,
    },
    getUploadUrlForFile: async (fileName: string) => {
      const {
        data: { url: uploadUrl, location },
      } = await assemblyAPI.get(APIEndpoints.fetchUploadURI, {
        params: { fileName },
      });

      return {
        uploadUrl,
        location,
      };
    },
    onRestrictionsError: (error: Error) => {
      showErrorToast(error.message);
    },
    onError: (error: Error) => {
      showErrorToast(error.message);
    },
  });

  const { files, FileUploadTrigger } = fileUploadOptions;

  const disableFileUpload = useMemo(() => {
    return files.length + (draftFiles?.length || 0) >= 15;
  }, [files, draftFiles]);

  const fileAttachment = useMemo(() => {
    return (
      <FileUploadTrigger
        buttonClassName={twJoin(
          'flex h-10 w-10 items-center justify-center rounded-lg p-2.5 hover:bg-gray-3',
          disableFileUpload &&
            'border-none hover:bg-transparent bg-transparent cursor-not-allowed'
        )}
        disabled={disableFileUpload}
      >
        {disableFileUpload ? (
          <Tooltip tooltipText={formatMessage(messages.attachmentLimit)}>
            <PaperClipIcon className="h-8 w-8 text-gray-6" />
          </Tooltip>
        ) : (
          <PaperClipIcon className="h-8 w-8 text-gray-8" />
        )}
      </FileUploadTrigger>
    );
  }, [disableFileUpload, formatMessage, FileUploadTrigger]);

  return {
    fileUploadOptions,
    fileAttachment,
  };
};
